import { useState } from "react";

function Notification() {
  const [isToggled1, setIsToggled1] = useState(false);
  const [isToggled2, setIsToggled2] = useState(false);
  const [isToggled3, setIsToggled3] = useState(false);
  const [isToggled4, setIsToggled4] = useState(false);

  const handleToggle1 = () => {
    setIsToggled1((prevState) => !prevState);
  };
  const handleToggle2 = () => {
    setIsToggled2((prevState) => !prevState);
  };
  const handleToggle3 = () => {
    setIsToggled3((prevState) => !prevState);
  };
  const handleToggle4 = () => {
    setIsToggled4((prevState) => !prevState);
  };

  return (
    <>
      {/* LARGE SCREENS */}
      <div className={` 100:hidden xl:flex w-full flex flex-col gap-[20px] p-[20px] justify-between items-start`}>
        <div className="w-full h-[381px] px-5 pt-5 pb-10 flex-col justify-start items-center gap-10 inline-flex">
          <div className="self-stretch h-[321px] flex-col justify-start items-start gap-5 flex">
            <div className="self-stretch h-[321px] flex-col justify-start items-start gap-[15px] flex">
              <div className="self-stretch py-2.5 border-b border-stone-300 justify-between items-center inline-flex">
                <div className="flex-col justify-center items-start gap-[5px] inline-flex">
                  <div className="text-center text-zinc-800 text-sm font-medium font-monteserrat leading-snug tracking-wide">Transaction Alerts</div>
                  <div className="text-center text-neutral-400 text-xs font-normal font-monteserrat leading-snug tracking-wide">Receive alerts for every transaction.</div>
                </div>
                <button onClick={handleToggle1} className={`relative w-[22px] h-[13px] cursor-pointer rounded-[20px] border-[0.7px] border-[#777]  focus:outline-none ${isToggled1 ? "bg-[#4CAF50]   bg-opacity-15" : "bg-[#fff]"}`}>
                  <span className={`absolute inset-y-0 left-0 w-[12px] h-[12px] rounded-full  border-[0.7px] border-[#999] transform ${isToggled1 ? "translate-x-full bg-[#4CAF50]" : "bg-[#D9D9D9] "} transition-transform`}></span>
                </button>
              </div>
              <div className="self-stretch py-2.5 border-b border-stone-300 justify-between items-center inline-flex">
                <div className="flex-col justify-center items-start gap-[5px] inline-flex">
                  <div className="text-center text-zinc-800 text-sm font-medium font-monteserrat leading-snug tracking-wide">Promotions and Offers</div>
                  <div className="text-center text-neutral-400 text-xs font-normal font-monteserrat leading-snug tracking-wide">Enjoy exclusive deals with timely notifications.</div>
                </div>
                <button onClick={handleToggle2} className={`relative w-[22px] h-[13px] cursor-pointer rounded-[20px] border-[0.7px] border-[#777]  focus:outline-none ${isToggled2 ? "bg-[#4CAF50]   bg-opacity-15" : "bg-[#fff]"}`}>
                  <span className={`absolute inset-y-0 left-0 w-[12px] h-[12px] rounded-full  border-[0.7px] border-[#999] transform ${isToggled2 ? "translate-x-full bg-[#4CAF50]" : "bg-[#D9D9D9] "} transition-transform`}></span>
                </button>
              </div>
              <div className="self-stretch py-2.5 border-b border-stone-300 justify-between items-center inline-flex">
                <div className="flex-col justify-center items-start gap-[5px] inline-flex">
                  <div className="text-center text-zinc-800 text-sm font-medium font-monteserrat leading-snug tracking-wide">App Updates</div>
                  <div className="text-center text-neutral-400 text-xs font-normal font-monteserrat leading-snug tracking-wide">Stay current with the latest app features.</div>
                </div>
                <button onClick={handleToggle3} className={`relative w-[22px] h-[13px] cursor-pointer rounded-[20px] border-[0.7px] border-[#777]  focus:outline-none ${isToggled3 ? "bg-[#4CAF50]   bg-opacity-15" : "bg-[#fff]"}`}>
                  <span className={`absolute inset-y-0 left-0 w-[12px] h-[12px] rounded-full  border-[0.7px] border-[#999] transform ${isToggled3 ? "translate-x-full bg-[#4CAF50]" : "bg-[#D9D9D9] "} transition-transform`}></span>
                </button>
              </div>
              <div className="self-stretch py-2.5 border-b border-stone-300 justify-between items-center inline-flex">
                <div className="flex-col justify-center items-start gap-[5px] inline-flex">
                  <div className="text-center text-zinc-800 text-sm font-medium font-monteserrat leading-snug tracking-wide">News and Updates</div>
                  <div className="text-center text-neutral-400 text-xs font-normal font-monteserrat leading-snug tracking-wide">Stay connected with relevant myHood news.</div>
                </div>
                <button onClick={handleToggle4} className={`relative w-[22px] h-[13px] cursor-pointer rounded-[20px] border-[0.7px] border-[#777]  focus:outline-none ${isToggled4 ? "bg-[#4CAF50]   bg-opacity-15" : "bg-[#fff]"}`}>
                  <span className={`absolute inset-y-0 left-0 w-[12px] h-[12px] rounded-full  border-[0.7px] border-[#999] transform ${isToggled4 ? "translate-x-full bg-[#4CAF50]" : "bg-[#D9D9D9] "} transition-transform`}></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS */}
      <div className=" 100:flex xl:hidden w-full  pb-[50px] flex-col justify-between gap-[22px] h-fit ">
        <div className="w-full h-fit px-5 pt-5 pb-10 flex-col justify-start items-center gap-10 inline-flex">
          <div className="self-stretch h-[321px] flex-col justify-start items-start gap-5 flex">
            <div className="self-stretch h-[321px] flex-col justify-start items-start gap-[15px] flex">
              <div className="self-stretch py-2.5 border-b border-stone-300 justify-between items-center inline-flex">
                <div className="flex-col text-left justify-center items-start gap-[5px] inline-flex">
                  <div className=" text-zinc-800 text-sm font-medium font-monteserrat leading-snug tracking-wide">Transaction Alerts</div>
                  <div className=" 100:hidden sm:flex text-neutral-400 text-xs font-normal font-monteserrat leading-snug tracking-wide">Receive alerts for every transaction.</div>
                </div>
                <button onClick={handleToggle1} className={`relative w-[22px] h-[13px] cursor-pointer rounded-[20px] border-[0.7px] border-[#777]  focus:outline-none ${isToggled1 ? "bg-[#4CAF50]   bg-opacity-15" : "bg-[#fff]"}`}>
                  <span className={`absolute inset-y-0 left-0 w-[12px] h-[12px] rounded-full  border-[0.7px] border-[#999] transform ${isToggled1 ? "translate-x-full bg-[#4CAF50]" : "bg-[#D9D9D9] "} transition-transform`}></span>
                </button>
              </div>
              <div className="self-stretch py-2.5 border-b border-stone-300 justify-between items-center inline-flex">
                <div className="flex-col text-left justify-center items-start gap-[5px] inline-flex">
                  <div className=" text-zinc-800 text-sm font-medium font-monteserrat leading-snug tracking-wide">Promotions and Offers</div>
                  <div className=" 100:hidden sm:flex text-neutral-400 text-xs font-normal font-monteserrat leading-snug tracking-wide">Enjoy exclusive deals with timely notifications.</div>
                </div>
                <button onClick={handleToggle2} className={`relative w-[22px] h-[13px] cursor-pointer rounded-[20px] border-[0.7px] border-[#777]  focus:outline-none ${isToggled2 ? "bg-[#4CAF50]   bg-opacity-15" : "bg-[#fff]"}`}>
                  <span className={`absolute inset-y-0 left-0 w-[12px] h-[12px] rounded-full  border-[0.7px] border-[#999] transform ${isToggled2 ? "translate-x-full bg-[#4CAF50]" : "bg-[#D9D9D9] "} transition-transform`}></span>
                </button>
              </div>
              <div className="self-stretch py-2.5 border-b border-stone-300 justify-between items-center inline-flex">
                <div className="flex-col justify-center items-start gap-[5px] inline-flex">
                  <div className=" text-zinc-800 text-sm font-medium font-monteserrat leading-snug tracking-wide">App Updates</div>
                  <div className=" 100:hidden sm:flex text-neutral-400 text-xs font-normal font-monteserrat leading-snug tracking-wide">Stay current with the latest app features.</div>
                </div>
                <button onClick={handleToggle3} className={`relative w-[22px] h-[13px] cursor-pointer rounded-[20px] border-[0.7px] border-[#777]  focus:outline-none ${isToggled3 ? "bg-[#4CAF50]   bg-opacity-15" : "bg-[#fff]"}`}>
                  <span className={`absolute inset-y-0 left-0 w-[12px] h-[12px] rounded-full  border-[0.7px] border-[#999] transform ${isToggled3 ? "translate-x-full bg-[#4CAF50]" : "bg-[#D9D9D9] "} transition-transform`}></span>
                </button>
              </div>
              <div className="self-stretch py-2.5 border-b border-stone-300 justify-between items-center inline-flex">
                <div className="flex-col justify-center items-start gap-[5px] inline-flex">
                  <div className=" text-zinc-800 text-sm font-medium font-monteserrat leading-snug tracking-wide">News and Updates</div>
                  <div className=" 100:hidden sm:flex text-neutral-400 text-xs font-normal font-monteserrat leading-snug tracking-wide">Stay connected with relevant myHood news.</div>
                </div>
                <button onClick={handleToggle4} className={`relative w-[22px] h-[13px] cursor-pointer rounded-[20px] border-[0.7px] border-[#777]  focus:outline-none ${isToggled4 ? "bg-[#4CAF50]   bg-opacity-15" : "bg-[#fff]"}`}>
                  <span className={`absolute inset-y-0 left-0 w-[12px] h-[12px] rounded-full  border-[0.7px] border-[#999] transform ${isToggled4 ? "translate-x-full bg-[#4CAF50]" : "bg-[#D9D9D9] "} transition-transform`}></span>
                </button>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}

export default Notification;
