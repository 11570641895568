import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../images/Logo.svg";
import mobile from "../images/MobileMenu.svg";

function Header({ scrollToSection, aboutUs, faQs, contact, features }) {
  const [hamburger, setHamburger] = useState(false);
  const handleHamburger = () => {
    setHamburger(true);
  };

  return (
    <>
      {/* BIG SCREENS */}
      <section className=" mobile:hidden xl:flex 100:hidden z-10   w-[100%] fixed flex justify-center items-center mt-[15px] ">
        <div className="w-[95%] h-[77.29px] 4xl:max-w-[90%]  px-[70px] py-5 bg-neutral-100 bg-opacity-70 rounded-[50px] justify-between items-center  inline-flex">
          <Link onClick={() => window.scrollTo(0, 0)} to="/">
            <img src={logo} alt="myHood" />
          </Link>
          <nav className="  ">
            <ul className=" flex gap-[50px] whitespace-nowrap justify-between items-center text-zinc-800 text-lg font-medium font-monteserrat tracking-tight">
              <NavLink className=" hover:text-green-600 " onClick={() => scrollToSection(features)} to="">
                <li>Features</li>
              </NavLink>
              <NavLink className=" hover:text-green-600 " onClick={() => scrollToSection(aboutUs)} to="">
                <li>About Us</li>
              </NavLink>
              <NavLink className=" hover:text-green-600 " onClick={() => scrollToSection(faQs)} to="">
                <li>FaQs</li>
              </NavLink>
              <NavLink className=" hover:text-green-600 " onClick={() => scrollToSection(contact)} to="">
                <li>Contact</li>
              </NavLink>
            </ul>
          </nav>
          <nav>
            <ul className="flex gap-[20px] justify-between items-center">
              <li>
                <Link to="/signup">
                  <div className="w-32 hover:shadow-lg h-9 px-5 py-[7px] text-white text-lg font-medium font-monteserrat tracking-tight bg-orange-600 rounded justify-center items-center gap-2.5 inline-flex">Join Us</div>
                </Link>
              </li>
              <li>
                <Link to="/login">
                  <div className="w-32 hover:shadow-lg h-9 px-5 py-[7px] rounded border border-orange-600 justify-center items-center gap-2.5 inline-flex">
                    <div className="text-zinc-800 text-lg font-medium font-['Montserrat'] tracking-tight">Login</div>
                  </div>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      {/* SMALL SCREENS FROM MOBILE TO 1023PX */}
      <section className="mobile:flex xl:hidden w-full z-10 flex fixed 100:mt-[15px] 100:top-0 justify-center mt-[15px] ">
        <div className="w-[95%] h-[77.29px]   px-[40px] py-5 bg-neutral-100 bg-opacity-70 rounded-[50px] justify-between items-center  inline-flex">
          <img className="w-[35px]" src={logo} alt="myHood" />

          <img onClick={handleHamburger} src={mobile} alt="menubar" />
        </div>
      </section>
      {hamburger && (
        <div className="fixed top-0 z-20 left-0 bg-black bg-opacity-60 w-full h-full ">
          <svg onClick={() => setHamburger(false)} className=" float-end mt-[37px] mr-[45px] " width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 5L5 19M5 5L19 19" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <div className="w-full mt-[100px] flex justify-center flex-col ">
            <ul className=" flex gap-[50px] flex-col justify-between items-center text-white text-lg font-medium font-monteserrat tracking-tight">
              <NavLink onClick={() => scrollToSection(features)} to="">
                <li>Features</li>
              </NavLink>
              <NavLink onClick={() => scrollToSection(aboutUs)} to="">
                <li>About Us</li>
              </NavLink>
              <NavLink onClick={() => scrollToSection(faQs)} to="">
                <li>FaQs</li>
              </NavLink>
              <NavLink onClick={() => scrollToSection(contact)} to="">
                <li>Contact</li>
              </NavLink>
              <li>
                <Link to="/signup">
                  <div className="w-32 h-9 px-5 py-[7px] text-white text-lg font-medium font-monteserrat tracking-tight bg-orange-600 rounded justify-center items-center gap-2.5 inline-flex">Join Us</div>
                </Link>
              </li>
              <li>
                <Link to="/login">
                  <div className="w-32 h-9 px-5 py-[7px] rounded border border-orange-600 justify-center items-center gap-2.5 inline-flex">
                    <div className=" text-lg font-medium font-['Montserrat'] tracking-tight">Login</div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
      {/* <main>{children}</main> */}
    </>
  );
}

export default Header;
