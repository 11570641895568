import { Link } from "react-router-dom";
import logo from "../images/Logo.svg";
function Disclaimer() {
  return (
    <>
      {/* BIG SCREENS */}
      <div className=" 100:hidden xl:flex w-full pb-[100px] gap-[60px] flex flex-col justify-center items-center h-fit">
        <div className=" w-full px-[100px] flex flex-col justify-center gap-[20px] items-center py-[40px] bg-[#FFEEE9] h-[428px]">
          <div className="w-full justify-start flex items-start">
            <Link
              onClick={() =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                })
              }
              to="/"
            >
              <div className="text-orange-600 text-base font-normal font-openSans tracking-tight">Back</div>
            </Link>
          </div>
          <div className=" w-full text-center gap-[24px] flex flex-col justify-between items-center">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <img src={logo} alt="myHood" />
            </Link>
            <h4 className="text-orange-600 text-[12px] font-normal font-openSans tracking-tight">DISCLAIMER</h4>
            <h1 className="text-[#333] text-[36px] font-bold font-openSans tracking-tight">Navigating the Legal Waters: Our Disclaimer</h1>
            <p className="text-[#777]  text-[18px] font-normal font-monteserrat tracking-tight">By using our services, you agree to abide by the following disclaimer </p>
          </div>
        </div>
        <div className="w-[781px]  gap-[20px] flex flex-col justify-between items-start ">
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH
              THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON- INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
              COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
              (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
              FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY,
              AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT,
              ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY{" "}
            </p>
          </section>
        </div>
      </div>
      {/* SMALL SCREENS FROM MOBILE TO 1023PX */}
      <div className="100:flex xl:hidden w-full pb-[100px] gap-[40px] flex-col justify-center items-center h-fit">
        <div className=" w-full px-[40px] flex flex-col justify-center gap-[20px] items-center py-[40px] bg-[#FFEEE9] h-fit">
          <div className="w-full justify-start flex items-start">
            <Link
              onClick={() =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                })
              }
              to="/"
            >
              <div className="text-orange-600 text-base font-normal font-openSans tracking-tight">Back</div>
            </Link>
          </div>
          <div className=" w-full text-center gap-[15px] flex flex-col justify-between items-center">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <img src={logo} alt="myHood" />
            </Link>
            <h4 className="text-orange-600 text-[12px] font-normal font-openSans tracking-tight">DISCLAIMER</h4>
            <h1 className="text-[#333] text-[30px] font-bold font-openSans tracking-tight">Navigating the Legal Waters: Our Disclaimer</h1>
            <p className="text-[#777] sm:w-[600px] text-[14px] font-normal font-monteserrat tracking-tight">By using our services, you agree to abide by the following disclaimer</p>
          </div>
        </div>
        <div className="w-[90%] sm:max-w-[550px]  gap-[20px] flex flex-col justify-between items-start ">
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH
              THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON- INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
              COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
              (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
              FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY,
              AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT,
              ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY{" "}
            </p>
          </section>
        </div>
      </div>
    </>
  );
}

export default Disclaimer;
