import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import user from "../../images/user.png";
import UserImg from "../../images/avatar.png";
import { axiosInstance } from "../../Utils";
import axios from "axios";

function HeaderSuper({ name }) {
  const [profile, setProfile] = useState(false);
  const [notifications, setNotification] = useState(false);
  const [smallScreens, setSmallScreens] = useState(false);
  const location = useLocation();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // try {
      //   const res = await axios.post("http://www.myhood.ng/mobile_back_end/api/get_user.php?id=1", {
      //     data,
      //   });
      //   console.log(res);

      //   setData(res.data);
      // } catch (error) {
      //   console.log(error);
      // }
      const revData = localStorage.getItem("myHoodData");
      setData(JSON.parse(revData));
    };

    fetchData();
  }, []);
  const path = "/settingsuper";
  return (
    <>
      {/* LARGE SCREENS */}
      <div className={`justify-between 100:hidden xl:flex px-[10px] py-[25px]  items-center h-[100px] w-full `}>
        <h3 className=" font-openSans text-[18px] text-[#333] font-semibold ">{name}</h3>
        <div className="flex w-fit justify-center items-center gap-[20px]">
          <Link to={path}>
            <div title={` Settings`} className={` rounded-full  ${location.pathname === path ? "text-[#F5F5F5] p-[10px] bg-[#FF5722] w-fit h-fit" : " bg-none text-[#333]"}`}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.625 9C11.625 10.4497 10.4497 11.625 9 11.625C7.55025 11.625 6.375 10.4497 6.375 9C6.375 7.55025 7.55025 6.375 9 6.375C10.4497 6.375 11.625 7.55025 11.625 9Z" stroke="currentColor" strokeWidth="1.125" />
                <path
                  d="M15.7582 10.5724C16.1497 10.4668 16.3454 10.414 16.4227 10.3131C16.5 10.2122 16.5 10.0498 16.5 9.72516V8.27489C16.5 7.95021 16.5 7.78784 16.4227 7.68696C16.3454 7.58601 16.1497 7.53321 15.7582 7.42767C14.2954 7.03318 13.3799 5.50387 13.7575 4.05064C13.8613 3.65098 13.9132 3.45116 13.8636 3.33396C13.814 3.21675 13.6718 3.13599 13.3873 2.97446L12.0938 2.24004C11.8146 2.08153 11.675 2.00228 11.5498 2.01915C11.4245 2.03603 11.2832 2.17704 11.0004 2.45904C9.906 3.55085 8.0952 3.5508 7.00076 2.45897C6.71807 2.17696 6.57673 2.03596 6.45145 2.01908C6.32617 2.0022 6.18658 2.08146 5.9074 2.23996L4.61388 2.97439C4.3294 3.13591 4.18715 3.21667 4.13758 3.33385C4.08801 3.45104 4.1399 3.65089 4.24369 4.05059C4.62103 5.50386 3.70479 7.03321 2.24177 7.42769C1.85034 7.53321 1.65463 7.58601 1.57731 7.68689C1.5 7.78784 1.5 7.95021 1.5 8.27489V9.72516C1.5 10.0498 1.5 10.2122 1.57731 10.3131C1.65461 10.414 1.85033 10.4668 2.24177 10.5724C3.70455 10.9669 4.62006 12.4962 4.24254 13.9494C4.13872 14.3491 4.0868 14.5489 4.13637 14.6661C4.18595 14.7833 4.32819 14.8641 4.61269 15.0256L5.90621 15.76C6.18541 15.9185 6.325 15.9978 6.4503 15.9809C6.57559 15.964 6.71691 15.823 6.99953 15.541C8.09453 14.4483 9.9066 14.4482 11.0017 15.5409C11.2843 15.823 11.4256 15.964 11.5509 15.9808C11.6762 15.9977 11.8158 15.9184 12.095 15.76L13.3885 15.0255C13.673 14.864 13.8153 14.7832 13.8648 14.666C13.9144 14.5488 13.8625 14.349 13.7586 13.9493C13.3809 12.4962 14.2957 10.9669 15.7582 10.5724Z"
                  stroke="currentColor"
                  strokeWidth="1.125"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </Link>

          <span title="Notification">
            <svg
              className=" cursor-pointer"
              onClick={() => {
                setNotification(!notifications);
                setProfile(false);
              }}
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.89744 11.0772C1.73795 12.1227 2.451 12.8484 3.32404 13.2101C6.67111 14.5967 11.3289 14.5967 14.6759 13.2101C15.549 12.8484 16.262 12.1227 16.1026 11.0772C16.0046 10.4347 15.5199 9.89963 15.1608 9.37718C14.6905 8.68448 14.6438 7.92885 14.6437 7.125C14.6437 4.0184 12.1169 1.5 9 1.5C5.8831 1.5 3.35635 4.0184 3.35635 7.125C3.35627 7.92885 3.30954 8.68448 2.83921 9.37718C2.48013 9.89963 1.99546 10.4347 1.89744 11.0772Z"
                stroke="#333333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path d="M6 14.25C6.34387 15.5439 7.55662 16.5 9 16.5C10.4434 16.5 11.6561 15.5439 12 14.25" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
              <circle cx="14.1331" cy="3.91146" r="3.33333" fill="#E52C2C" />
              <path
                d="M12.9748 5.77808V5.34308L14.3648 4.02308C14.4815 3.91308 14.5681 3.81641 14.6248 3.73308C14.6815 3.64974 14.7181 3.57308 14.7348 3.50308C14.7548 3.42974 14.7648 3.36141 14.7648 3.29808C14.7648 3.13808 14.7098 3.01474 14.5998 2.92808C14.4898 2.83808 14.3281 2.79308 14.1148 2.79308C13.9448 2.79308 13.7898 2.82308 13.6498 2.88308C13.5131 2.94308 13.3948 3.03474 13.2948 3.15808L12.8398 2.80808C12.9765 2.62474 13.1598 2.48308 13.3898 2.38308C13.6231 2.27974 13.8831 2.22808 14.1698 2.22808C14.4231 2.22808 14.6431 2.26974 14.8298 2.35308C15.0198 2.43308 15.1648 2.54808 15.2648 2.69808C15.3681 2.84808 15.4198 3.02641 15.4198 3.23308C15.4198 3.34641 15.4048 3.45974 15.3748 3.57308C15.3448 3.68308 15.2881 3.79974 15.2048 3.92308C15.1215 4.04641 14.9998 4.18474 14.8398 4.33808L13.6448 5.47308L13.5098 5.22808H15.5548V5.77808H12.9748Z"
                fill="white"
              />
            </svg>
          </span>
          <div
            onClick={() => {
              setProfile(!profile);
              setNotification(false);
            }}
            className=" cursor-pointer flex justify-center gap-[10px] items-center"
          >
            <img className="w-[30px] rounded-full " src={data.profile_pic === "" ? UserImg : `data:image/png;base64,${data.profile_pic}`} alt={data.first_name + " " + data.last_name} title={data.first_name + " " + data.last_name} />
            <h3 className="   font-monteserrat text-[16px] text-[#333] font-normal  ">{data.first_name + " " + data.last_name}</h3>
            {profile ? (
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 15C18 15 13.5811 9.00001 12 9C10.4188 8.99999 6 15 6 15" stroke="#333333" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            ) : (
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 6.75004C13.5 6.75004 10.1858 11.25 9 11.25C7.8141 11.25 4.5 6.75 4.5 6.75" stroke="#333" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            )}
          </div>
        </div>
      </div>
      {/* SMALL SCREENS */}
      <div className="justify-between 100:flex xl:hidden px-[10px] py-[25px]  items-center h-[100px] w-full  ">
        <h3 className=" font-openSans text-[18px] text-[#333] font-semibold ">{name}</h3>
        <svg onClick={() => setSmallScreens(!smallScreens)} className=" cursor-pointer" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 5L20 5" stroke="#333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4 12L20 12" stroke="#333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4 19L20 19" stroke="#333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      {smallScreens && (
        <>
          <div className=" w-fit absolute top-[65px] right-[15px] flex flex-col items-start">
            <div className=" mb-[-10px] ml-[200px] w-0 h-0 border-b-[30px] border-b-[#FFEEE9] border-x-transparent  border-x-[25px]  "></div>
            <div className=" flex justify-between items-center bg-[#FFEEE9] gap-[20px] w-fit px-[20px] h-fit py-[20px] rounded-[20px] ">
              <Link to={path}>
                <div className={`  rounded-full  ${location.pathname === path ? "text-[#F5F5F5] p-[10px] bg-[#FF5722] w-fit h-fit" : " bg-none text-[#333]"}`}>
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.625 9C11.625 10.4497 10.4497 11.625 9 11.625C7.55025 11.625 6.375 10.4497 6.375 9C6.375 7.55025 7.55025 6.375 9 6.375C10.4497 6.375 11.625 7.55025 11.625 9Z" stroke="currentColor" strokeWidth="1.125" />
                    <path
                      d="M15.7582 10.5724C16.1497 10.4668 16.3454 10.414 16.4227 10.3131C16.5 10.2122 16.5 10.0498 16.5 9.72516V8.27489C16.5 7.95021 16.5 7.78784 16.4227 7.68696C16.3454 7.58601 16.1497 7.53321 15.7582 7.42767C14.2954 7.03318 13.3799 5.50387 13.7575 4.05064C13.8613 3.65098 13.9132 3.45116 13.8636 3.33396C13.814 3.21675 13.6718 3.13599 13.3873 2.97446L12.0938 2.24004C11.8146 2.08153 11.675 2.00228 11.5498 2.01915C11.4245 2.03603 11.2832 2.17704 11.0004 2.45904C9.906 3.55085 8.0952 3.5508 7.00076 2.45897C6.71807 2.17696 6.57673 2.03596 6.45145 2.01908C6.32617 2.0022 6.18658 2.08146 5.9074 2.23996L4.61388 2.97439C4.3294 3.13591 4.18715 3.21667 4.13758 3.33385C4.08801 3.45104 4.1399 3.65089 4.24369 4.05059C4.62103 5.50386 3.70479 7.03321 2.24177 7.42769C1.85034 7.53321 1.65463 7.58601 1.57731 7.68689C1.5 7.78784 1.5 7.95021 1.5 8.27489V9.72516C1.5 10.0498 1.5 10.2122 1.57731 10.3131C1.65461 10.414 1.85033 10.4668 2.24177 10.5724C3.70455 10.9669 4.62006 12.4962 4.24254 13.9494C4.13872 14.3491 4.0868 14.5489 4.13637 14.6661C4.18595 14.7833 4.32819 14.8641 4.61269 15.0256L5.90621 15.76C6.18541 15.9185 6.325 15.9978 6.4503 15.9809C6.57559 15.964 6.71691 15.823 6.99953 15.541C8.09453 14.4483 9.9066 14.4482 11.0017 15.5409C11.2843 15.823 11.4256 15.964 11.5509 15.9808C11.6762 15.9977 11.8158 15.9184 12.095 15.76L13.3885 15.0255C13.673 14.864 13.8153 14.7832 13.8648 14.666C13.9144 14.5488 13.8625 14.349 13.7586 13.9493C13.3809 12.4962 14.2957 10.9669 15.7582 10.5724Z"
                      stroke="currentColor"
                      strokeWidth="1.125"
                      strokeLinecap="round"
                    />
                  </svg>
                </div>
              </Link>
              <svg
                className=" cursor-pointer"
                onClick={() => {
                  setNotification(!notifications);
                  setProfile(false);
                }}
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.89744 11.0772C1.73795 12.1227 2.451 12.8484 3.32404 13.2101C6.67111 14.5967 11.3289 14.5967 14.6759 13.2101C15.549 12.8484 16.262 12.1227 16.1026 11.0772C16.0046 10.4347 15.5199 9.89963 15.1608 9.37718C14.6905 8.68448 14.6438 7.92885 14.6437 7.125C14.6437 4.0184 12.1169 1.5 9 1.5C5.8831 1.5 3.35635 4.0184 3.35635 7.125C3.35627 7.92885 3.30954 8.68448 2.83921 9.37718C2.48013 9.89963 1.99546 10.4347 1.89744 11.0772Z"
                  stroke="#333333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M6 14.25C6.34387 15.5439 7.55662 16.5 9 16.5C10.4434 16.5 11.6561 15.5439 12 14.25" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
                <circle cx="14.1331" cy="3.91146" r="3.33333" fill="#E52C2C" />
                <path
                  d="M12.9748 5.77808V5.34308L14.3648 4.02308C14.4815 3.91308 14.5681 3.81641 14.6248 3.73308C14.6815 3.64974 14.7181 3.57308 14.7348 3.50308C14.7548 3.42974 14.7648 3.36141 14.7648 3.29808C14.7648 3.13808 14.7098 3.01474 14.5998 2.92808C14.4898 2.83808 14.3281 2.79308 14.1148 2.79308C13.9448 2.79308 13.7898 2.82308 13.6498 2.88308C13.5131 2.94308 13.3948 3.03474 13.2948 3.15808L12.8398 2.80808C12.9765 2.62474 13.1598 2.48308 13.3898 2.38308C13.6231 2.27974 13.8831 2.22808 14.1698 2.22808C14.4231 2.22808 14.6431 2.26974 14.8298 2.35308C15.0198 2.43308 15.1648 2.54808 15.2648 2.69808C15.3681 2.84808 15.4198 3.02641 15.4198 3.23308C15.4198 3.34641 15.4048 3.45974 15.3748 3.57308C15.3448 3.68308 15.2881 3.79974 15.2048 3.92308C15.1215 4.04641 14.9998 4.18474 14.8398 4.33808L13.6448 5.47308L13.5098 5.22808H15.5548V5.77808H12.9748Z"
                  fill="white"
                />
              </svg>
              <div
                onClick={() => {
                  setProfile(!profile);
                  setNotification(false);
                }}
                className=" cursor-pointer flex justify-center gap-[10px] items-center"
              >
                <img className="w-[30px] rounded-full " src={data.profile_pic === "" ? UserImg : `data:image/png;base64,${data.profile_pic}`} alt={data.first_name + " " + data.last_name} title={data.first_name + " " + data.last_name} />
                <h3 className="   font-monteserrat text-[16px] text-[#333] font-normal  ">{data.first_name + " " + data.last_name}</h3>
                {profile ? (
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 15C18 15 13.5811 9.00001 12 9C10.4188 8.99999 6 15 6 15" stroke="#333333" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                ) : (
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.5 6.75004C13.5 6.75004 10.1858 11.25 9 11.25C7.8141 11.25 4.5 6.75 4.5 6.75" stroke="#333" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {notifications && (
        <>
          {/* LARGE SCREENS */}
          <div className={` 100:hidden xl:flex z-20 w-[532px] absolute p-[10px] top-[80px] right-[20px] h-[720px] gap-[10px] items-start flex flex-col rounded-[20px] bg-[#F5F5F5] `}>
            <div className=" w-full bg-[#FFE2D9] h-[80px] px-[30px] rounded-[20px] flex justify-between items-center ">
              <h3 className=" font-openSans tracking-[-0.4px] text-[#333] font-semibold text-[20px]">Notifications</h3>
              <div onClick={() => setNotification(false)} className=" bg-[#B8A9A9] rounded-full cursor-pointer w-fit p-[10px]">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.8332 4.16675L4.1665 15.8334M4.1665 4.16675L15.8332 15.8334" stroke="#F5F5F5" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className=" gap-[20px] rounded-[20px] px-[20px] py-[30px] flex flex-col justify-start items-center w-full bg-[#fff] h-fit ">
              <section className=" h-[64px] flex justify-start hover:bg-[#FFF5F4] rounded-[64px] items-center gap-[20px] w-full">
                <div className=" w-[64px] bg-opacity-5  bg-[#FF5722] h-[64px] flex justify-center items-center rounded-full ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 2V4M6 2V4" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M2.5 12.2432C2.5 7.88594 2.5 5.70728 3.75212 4.35364C5.00424 3 7.01949 3 11.05 3H12.95C16.9805 3 18.9958 3 20.2479 4.35364C21.5 5.70728 21.5 7.88594 21.5 12.2432V12.7568C21.5 17.1141 21.5 19.2927 20.2479 20.6464C18.9958 22 16.9805 22 12.95 22H11.05C7.01949 22 5.00424 22 3.75212 20.6464C2.5 19.2927 2.5 17.1141 2.5 12.7568V12.2432Z"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M3 8H21" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M9.08325 14.8333L10.5416 16.2916L14.9166 11.7083" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.5415 16.2916L14.9165 11.7083" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="flex gap-[5px] flex-col items-start">
                  <h3 className=" font-openSans tracking-[-0.4px] text-[14px] text-[#333] font-semibold">Football event coming up</h3>
                  <h3 className=" font-monteserrat tracking-[-0.4px] text-[12px] text-[#777777] font-semibold">a few seconds ago</h3>
                </div>
              </section>
              <section className=" h-[64px] flex justify-start hover:bg-[#FFF5F4] rounded-[64px] items-center gap-[20px] w-full">
                <div className=" w-[64px] bg-opacity-5  bg-[#FF5722] h-[64px] flex justify-center items-center rounded-full ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 9C19.2048 5.01455 15.5128 2 11.0793 2C6.06549 2 2 5.85521 2 10.61C2 12.8946 2.93819 14.9704 4.46855 16.5108C4.80549 16.85 5.03045 17.3134 4.93966 17.7903C4.78982 18.5701 4.45026 19.2975 3.95305 19.9037C5.26123 20.1449 6.62147 19.9277 7.78801 19.3127C8.20039 19.0954 8.40657 18.9867 8.55207 18.9646C8.65392 18.9492 8.78659 18.9636 9 19.0002"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11 16.2617C11 19.1674 13.4628 21.5234 16.5 21.5234C16.8571 21.5238 17.2132 21.4908 17.564 21.425C17.8165 21.3775 17.9428 21.3538 18.0309 21.3673C18.119 21.3807 18.244 21.4472 18.4938 21.58C19.2004 21.9558 20.0244 22.0885 20.8169 21.9411C20.5157 21.5707 20.31 21.1262 20.2192 20.6496C20.1642 20.3582 20.3005 20.075 20.5046 19.8677C21.4317 18.9263 22 17.6578 22 16.2617C22 13.356 19.5372 11 16.5 11C13.4628 11 11 13.356 11 16.2617Z"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex gap-[5px] flex-col items-start">
                  <h3 className=" font-openSans tracking-[-0.4px] text-[14px] text-[#333] font-semibold">Best places to deliver food around the estate?</h3>
                  <h3 className=" font-monteserrat tracking-[-0.4px] text-[12px] text-[#777777] font-semibold">about an hour ago</h3>
                </div>
              </section>
              <section className=" h-[64px] flex justify-start hover:bg-[#FFF5F4] rounded-[64px] items-center gap-[20px] w-full">
                <div className=" w-[64px] bg-opacity-5  bg-[#FF5722] h-[64px] flex justify-center items-center rounded-full ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 9C19.2048 5.01455 15.5128 2 11.0793 2C6.06549 2 2 5.85521 2 10.61C2 12.8946 2.93819 14.9704 4.46855 16.5108C4.80549 16.85 5.03045 17.3134 4.93966 17.7903C4.78982 18.5701 4.45026 19.2975 3.95305 19.9037C5.26123 20.1449 6.62147 19.9277 7.78801 19.3127C8.20039 19.0954 8.40657 18.9867 8.55207 18.9646C8.65392 18.9492 8.78659 18.9636 9 19.0002"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11 16.2617C11 19.1674 13.4628 21.5234 16.5 21.5234C16.8571 21.5238 17.2132 21.4908 17.564 21.425C17.8165 21.3775 17.9428 21.3538 18.0309 21.3673C18.119 21.3807 18.244 21.4472 18.4938 21.58C19.2004 21.9558 20.0244 22.0885 20.8169 21.9411C20.5157 21.5707 20.31 21.1262 20.2192 20.6496C20.1642 20.3582 20.3005 20.075 20.5046 19.8677C21.4317 18.9263 22 17.6578 22 16.2617C22 13.356 19.5372 11 16.5 11C13.4628 11 11 13.356 11 16.2617Z"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex gap-[5px] flex-col items-start">
                  <h3 className=" font-openSans tracking-[-0.4px] text-[14px] text-[#333] font-semibold">Best places to deliver food around the estate?</h3>
                  <h3 className=" font-monteserrat tracking-[-0.4px] text-[12px] text-[#777777] font-semibold">about an hour ago</h3>
                </div>
              </section>
              <section className=" h-[64px] flex justify-start hover:bg-[#FFF5F4] rounded-[64px] items-center gap-[20px] w-full">
                <div className=" w-[64px] bg-opacity-5  bg-[#FF5722] h-[64px] flex justify-center items-center rounded-full ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 2V4M6 2V4" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M2.5 12.2432C2.5 7.88594 2.5 5.70728 3.75212 4.35364C5.00424 3 7.01949 3 11.05 3H12.95C16.9805 3 18.9958 3 20.2479 4.35364C21.5 5.70728 21.5 7.88594 21.5 12.2432V12.7568C21.5 17.1141 21.5 19.2927 20.2479 20.6464C18.9958 22 16.9805 22 12.95 22H11.05C7.01949 22 5.00424 22 3.75212 20.6464C2.5 19.2927 2.5 17.1141 2.5 12.7568V12.2432Z"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M3 8H21" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M9.08325 14.8333L10.5416 16.2916L14.9166 11.7083" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.5415 16.2916L14.9165 11.7083" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="flex gap-[5px] flex-col items-start">
                  <h3 className=" font-openSans tracking-[-0.4px] text-[14px] text-[#333] font-semibold">Football event coming up</h3>
                  <h3 className=" font-monteserrat tracking-[-0.4px] text-[12px] text-[#777777] font-semibold">a few seconds ago</h3>
                </div>
              </section>
              <section className=" h-[64px] flex justify-start hover:bg-[#FFF5F4] rounded-[64px] items-center gap-[20px] w-full">
                <div className=" w-[64px] bg-opacity-5  bg-[#FF5722] h-[64px] flex justify-center items-center rounded-full ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 2V4M6 2V4" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M2.5 12.2432C2.5 7.88594 2.5 5.70728 3.75212 4.35364C5.00424 3 7.01949 3 11.05 3H12.95C16.9805 3 18.9958 3 20.2479 4.35364C21.5 5.70728 21.5 7.88594 21.5 12.2432V12.7568C21.5 17.1141 21.5 19.2927 20.2479 20.6464C18.9958 22 16.9805 22 12.95 22H11.05C7.01949 22 5.00424 22 3.75212 20.6464C2.5 19.2927 2.5 17.1141 2.5 12.7568V12.2432Z"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M3 8H21" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M9.08325 14.8333L10.5416 16.2916L14.9166 11.7083" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.5415 16.2916L14.9165 11.7083" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="flex gap-[5px] flex-col items-start">
                  <h3 className=" font-openSans tracking-[-0.4px] text-[14px] text-[#333] font-semibold">Football event coming up</h3>
                  <h3 className=" font-monteserrat tracking-[-0.4px] text-[12px] text-[#777777] font-semibold">a few seconds ago</h3>
                </div>
              </section>
              <section className=" h-[64px] flex justify-start hover:bg-[#FFF5F4] rounded-[64px] items-center gap-[20px] w-full">
                <div className=" w-[64px] bg-opacity-5  bg-[#FF5722] h-[64px] flex justify-center items-center rounded-full ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 9C19.2048 5.01455 15.5128 2 11.0793 2C6.06549 2 2 5.85521 2 10.61C2 12.8946 2.93819 14.9704 4.46855 16.5108C4.80549 16.85 5.03045 17.3134 4.93966 17.7903C4.78982 18.5701 4.45026 19.2975 3.95305 19.9037C5.26123 20.1449 6.62147 19.9277 7.78801 19.3127C8.20039 19.0954 8.40657 18.9867 8.55207 18.9646C8.65392 18.9492 8.78659 18.9636 9 19.0002"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11 16.2617C11 19.1674 13.4628 21.5234 16.5 21.5234C16.8571 21.5238 17.2132 21.4908 17.564 21.425C17.8165 21.3775 17.9428 21.3538 18.0309 21.3673C18.119 21.3807 18.244 21.4472 18.4938 21.58C19.2004 21.9558 20.0244 22.0885 20.8169 21.9411C20.5157 21.5707 20.31 21.1262 20.2192 20.6496C20.1642 20.3582 20.3005 20.075 20.5046 19.8677C21.4317 18.9263 22 17.6578 22 16.2617C22 13.356 19.5372 11 16.5 11C13.4628 11 11 13.356 11 16.2617Z"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex gap-[5px] flex-col items-start">
                  <h3 className=" font-openSans tracking-[-0.4px] text-[14px] text-[#333] font-semibold">Best places to deliver food around the estate?</h3>
                  <h3 className=" font-monteserrat tracking-[-0.4px] text-[12px] text-[#777777] font-semibold">about an hour ago</h3>
                </div>
              </section>
            </div>
          </div>
          {/* SMALL SCREENS */}
          <div className="justify-between 100:flex xl:hidden  absolute p-[10px] top-[80px] right-[20px] 100:w-[90%] h-fit sm:w-[532px] gap-[10px] items-start flex flex-col rounded-[20px] bg-[#F5F5F5]">
            <div className=" w-full bg-[#FFE2D9] h-[80px] px-[30px] rounded-[20px] flex justify-between items-center ">
              <h3 className=" font-openSans tracking-[-0.4px] text-[#333] font-semibold text-[20px]">Notifications</h3>
              <div onClick={() => setNotification(false)} className=" bg-[#B8A9A9] rounded-full cursor-pointer w-fit p-[10px]">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.8332 4.16675L4.1665 15.8334M4.1665 4.16675L15.8332 15.8334" stroke="#F5F5F5" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className=" gap-[20px] rounded-[20px] px-[20px] py-[30px] flex flex-col justify-start items-center w-full bg-[#fff] h-fit ">
              <section className=" h-[64px] flex justify-start hover:bg-[#FFF5F4] rounded-[64px] items-center gap-[20px] w-full">
                <div className=" w-[54px] bg-opacity-5  bg-[#FF5722] h-[64px] flex justify-center items-center rounded-full ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 2V4M6 2V4" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M2.5 12.2432C2.5 7.88594 2.5 5.70728 3.75212 4.35364C5.00424 3 7.01949 3 11.05 3H12.95C16.9805 3 18.9958 3 20.2479 4.35364C21.5 5.70728 21.5 7.88594 21.5 12.2432V12.7568C21.5 17.1141 21.5 19.2927 20.2479 20.6464C18.9958 22 16.9805 22 12.95 22H11.05C7.01949 22 5.00424 22 3.75212 20.6464C2.5 19.2927 2.5 17.1141 2.5 12.7568V12.2432Z"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M3 8H21" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M9.08325 14.8333L10.5416 16.2916L14.9166 11.7083" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.5415 16.2916L14.9165 11.7083" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="flex gap-[5px] flex-col items-start">
                  <h3 className=" font-openSans tracking-[-0.4px] text-[14px] text-[#333] font-semibold">Football event coming up</h3>
                  <h3 className=" font-monteserrat tracking-[-0.4px] text-[12px] text-[#777777] font-semibold">a few seconds ago</h3>
                </div>
              </section>
              <section className=" h-[64px] flex justify-start hover:bg-[#FFF5F4] rounded-[64px] items-center gap-[20px] w-full">
                <div className=" w-[54px] bg-opacity-5  bg-[#FF5722] h-[64px] flex justify-center items-center rounded-full ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 9C19.2048 5.01455 15.5128 2 11.0793 2C6.06549 2 2 5.85521 2 10.61C2 12.8946 2.93819 14.9704 4.46855 16.5108C4.80549 16.85 5.03045 17.3134 4.93966 17.7903C4.78982 18.5701 4.45026 19.2975 3.95305 19.9037C5.26123 20.1449 6.62147 19.9277 7.78801 19.3127C8.20039 19.0954 8.40657 18.9867 8.55207 18.9646C8.65392 18.9492 8.78659 18.9636 9 19.0002"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11 16.2617C11 19.1674 13.4628 21.5234 16.5 21.5234C16.8571 21.5238 17.2132 21.4908 17.564 21.425C17.8165 21.3775 17.9428 21.3538 18.0309 21.3673C18.119 21.3807 18.244 21.4472 18.4938 21.58C19.2004 21.9558 20.0244 22.0885 20.8169 21.9411C20.5157 21.5707 20.31 21.1262 20.2192 20.6496C20.1642 20.3582 20.3005 20.075 20.5046 19.8677C21.4317 18.9263 22 17.6578 22 16.2617C22 13.356 19.5372 11 16.5 11C13.4628 11 11 13.356 11 16.2617Z"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex gap-[5px] flex-col items-start">
                  <h3 className=" font-openSans tracking-[-0.4px] text-[14px] text-[#333] font-semibold">Best places to deliver food around the estate?</h3>
                  <h3 className=" font-monteserrat tracking-[-0.4px] text-[12px] text-[#777777] font-semibold">about an hour ago</h3>
                </div>
              </section>
              <section className=" h-[64px] flex justify-start hover:bg-[#FFF5F4] rounded-[64px] items-center gap-[20px] w-full">
                <div className=" w-[54px] bg-opacity-5  bg-[#FF5722] h-[64px] flex justify-center items-center rounded-full ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 9C19.2048 5.01455 15.5128 2 11.0793 2C6.06549 2 2 5.85521 2 10.61C2 12.8946 2.93819 14.9704 4.46855 16.5108C4.80549 16.85 5.03045 17.3134 4.93966 17.7903C4.78982 18.5701 4.45026 19.2975 3.95305 19.9037C5.26123 20.1449 6.62147 19.9277 7.78801 19.3127C8.20039 19.0954 8.40657 18.9867 8.55207 18.9646C8.65392 18.9492 8.78659 18.9636 9 19.0002"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11 16.2617C11 19.1674 13.4628 21.5234 16.5 21.5234C16.8571 21.5238 17.2132 21.4908 17.564 21.425C17.8165 21.3775 17.9428 21.3538 18.0309 21.3673C18.119 21.3807 18.244 21.4472 18.4938 21.58C19.2004 21.9558 20.0244 22.0885 20.8169 21.9411C20.5157 21.5707 20.31 21.1262 20.2192 20.6496C20.1642 20.3582 20.3005 20.075 20.5046 19.8677C21.4317 18.9263 22 17.6578 22 16.2617C22 13.356 19.5372 11 16.5 11C13.4628 11 11 13.356 11 16.2617Z"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex gap-[5px] flex-col items-start">
                  <h3 className=" font-openSans tracking-[-0.4px] text-[14px] text-[#333] font-semibold">Best places to deliver food around the estate?</h3>
                  <h3 className=" font-monteserrat tracking-[-0.4px] text-[12px] text-[#777777] font-semibold">about an hour ago</h3>
                </div>
              </section>
              <section className=" h-[64px] flex justify-start hover:bg-[#FFF5F4] rounded-[64px] items-center gap-[20px] w-full">
                <div className=" w-[54px] bg-opacity-5  bg-[#FF5722] h-[64px] flex justify-center items-center rounded-full ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 2V4M6 2V4" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M2.5 12.2432C2.5 7.88594 2.5 5.70728 3.75212 4.35364C5.00424 3 7.01949 3 11.05 3H12.95C16.9805 3 18.9958 3 20.2479 4.35364C21.5 5.70728 21.5 7.88594 21.5 12.2432V12.7568C21.5 17.1141 21.5 19.2927 20.2479 20.6464C18.9958 22 16.9805 22 12.95 22H11.05C7.01949 22 5.00424 22 3.75212 20.6464C2.5 19.2927 2.5 17.1141 2.5 12.7568V12.2432Z"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M3 8H21" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M9.08325 14.8333L10.5416 16.2916L14.9166 11.7083" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.5415 16.2916L14.9165 11.7083" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="flex gap-[5px] flex-col items-start">
                  <h3 className=" font-openSans tracking-[-0.4px] text-[14px] text-[#333] font-semibold">Football event coming up</h3>
                  <h3 className=" font-monteserrat tracking-[-0.4px] text-[12px] text-[#777777] font-semibold">a few seconds ago</h3>
                </div>
              </section>
              <section className=" h-[64px] flex justify-start hover:bg-[#FFF5F4] rounded-[64px] items-center gap-[20px] w-full">
                <div className=" w-[54px] bg-opacity-5  bg-[#FF5722] h-[64px] flex justify-center items-center rounded-full ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 2V4M6 2V4" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M2.5 12.2432C2.5 7.88594 2.5 5.70728 3.75212 4.35364C5.00424 3 7.01949 3 11.05 3H12.95C16.9805 3 18.9958 3 20.2479 4.35364C21.5 5.70728 21.5 7.88594 21.5 12.2432V12.7568C21.5 17.1141 21.5 19.2927 20.2479 20.6464C18.9958 22 16.9805 22 12.95 22H11.05C7.01949 22 5.00424 22 3.75212 20.6464C2.5 19.2927 2.5 17.1141 2.5 12.7568V12.2432Z"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M3 8H21" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path opacity="0.4" d="M9.08325 14.8333L10.5416 16.2916L14.9166 11.7083" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10.5415 16.2916L14.9165 11.7083" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
                <div className="flex gap-[5px] flex-col items-start">
                  <h3 className=" font-openSans tracking-[-0.4px] text-[14px] text-[#333] font-semibold">Football event coming up</h3>
                  <h3 className=" font-monteserrat tracking-[-0.4px] text-[12px] text-[#777777] font-semibold">a few seconds ago</h3>
                </div>
              </section>
              <section className=" h-[64px] flex justify-start hover:bg-[#FFF5F4] rounded-[64px] items-center gap-[20px] w-full">
                <div className=" w-[54px] bg-opacity-5  bg-[#FF5722] h-[64px] flex justify-center items-center rounded-full ">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M20 9C19.2048 5.01455 15.5128 2 11.0793 2C6.06549 2 2 5.85521 2 10.61C2 12.8946 2.93819 14.9704 4.46855 16.5108C4.80549 16.85 5.03045 17.3134 4.93966 17.7903C4.78982 18.5701 4.45026 19.2975 3.95305 19.9037C5.26123 20.1449 6.62147 19.9277 7.78801 19.3127C8.20039 19.0954 8.40657 18.9867 8.55207 18.9646C8.65392 18.9492 8.78659 18.9636 9 19.0002"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11 16.2617C11 19.1674 13.4628 21.5234 16.5 21.5234C16.8571 21.5238 17.2132 21.4908 17.564 21.425C17.8165 21.3775 17.9428 21.3538 18.0309 21.3673C18.119 21.3807 18.244 21.4472 18.4938 21.58C19.2004 21.9558 20.0244 22.0885 20.8169 21.9411C20.5157 21.5707 20.31 21.1262 20.2192 20.6496C20.1642 20.3582 20.3005 20.075 20.5046 19.8677C21.4317 18.9263 22 17.6578 22 16.2617C22 13.356 19.5372 11 16.5 11C13.4628 11 11 13.356 11 16.2617Z"
                      stroke="#FF5722"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex gap-[5px] flex-col items-start">
                  <h3 className=" font-openSans tracking-[-0.4px] text-[14px] text-[#333] font-semibold">Best places to deliver food around the estate?</h3>
                  <h3 className=" font-monteserrat tracking-[-0.4px] text-[12px] text-[#777777] font-semibold">about an hour ago</h3>
                </div>
              </section>
            </div>
          </div>
        </>
      )}
      {profile && (
        <>
          <div className=" z-20 w-[320px] absolute p-[10px] top-[80px] right-[20px] h-fit gap-[10px] items-start flex flex-col rounded-[20px] bg-[#F5F5F5] ">
            <div className=" w-full bg-[#FFE2D9] h-[80px] px-[30px] rounded-[20px] flex justify-end items-center ">
              <div onClick={() => setProfile(false)} className=" bg-[#B8A9A9] rounded-full cursor-pointer w-fit p-[10px]">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.8332 4.16675L4.1665 15.8334M4.1665 4.16675L15.8332 15.8334" stroke="#F5F5F5" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className=" w-full gap-[10px] flex-col flex justify-between">
              <img className=" w-[300px] h-[300px] object-cover  rounded-[20px]" loading="lazy" src={data.profile_pic === "" ? UserImg : `data:image/png;base64,${data.profile_pic}`} alt={data.first_name + " " + data.last_name} />
              <div className=" rounded-[20px] px-[20px] flex justify-between items-center w-full bg-[#fff] h-[98px] ">
                <div className="flex gap-[5px] flex-col items-start">
                  <h3 className=" font-openSans tracking-[-0.4px] text-[20px] text-[#333] font-semibold">{data.first_name + " " + data.last_name}</h3>
                  <h3 className=" font-openSans tracking-[-0.4px] text-[12px] text-[#777777] font-semibold">{data.email}</h3>
                </div>
                <Link to="/login">
                  <div className="  w-fit hit-fit p-[10px] rounded-[5px] border-solid border-[1px] border-[#FF5722] ">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.4165 1.75L6.02999 1.88641C4.52573 2.41733 3.77358 2.6828 3.34504 3.2885C2.9165 3.89419 2.9165 4.69181 2.9165 6.28705V7.71295C2.9165 9.30819 2.9165 10.1058 3.34504 10.7115C3.77358 11.3172 4.52573 11.5827 6.02999 12.1136L6.4165 12.25"
                        stroke="#333333"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M12.2498 6.99996H6.4165M12.2498 6.99996C12.2498 6.59151 11.0865 5.82835 10.7915 5.54163M12.2498 6.99996C12.2498 7.40841 11.0865 8.17158 10.7915 8.45829"
                        stroke="#333333"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default HeaderSuper;
