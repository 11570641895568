import { Link } from "react-router-dom";
import logo from "../images/Logo.svg";

function Footer() {
  const date = new Date().getFullYear();
  return (
    <>
      {/* BIG SCREENS */}
      <div className=" 100:hidden xl:flex w-full mt-[96px] h-[634px] px-[17px] py-[50px] myDiv   flex-col justify-center items-center flex">
        <div className="relative flex flex-col w-full justify-center items-center gap-[50px]">
          <img src={logo} alt="myHood" />
          <div className=" flex lg:gap-[50px] 2xl:gap-[100px] justify-between items-center">
            <div className=" w-[330px] grid gap-[20px] justify-between">
              <h4 className="text-zinc-800 text-[34px] font-semibold font-openSans tracking-tight">Get In Touch</h4>
              <p className="text-zinc-800 text-lg font-normal font-monteserratleading-[35px] tracking-tight">
                Have questions, suggestions, or want to collaborate? Reach out to us. Your thoughts matter, and we're here to listen. Contact us for anything you need.
              </p>
              <div className="flex w-[300px] justify-between items-center">
                <a title="Facebook" href="https://www.facebook.com/myHoodNG">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.785645" width="30" height="30" rx="15" fill="#FF5722" fillOpacity="0.3" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.1364 14.5356C10.403 14.5356 10.25 14.6795 10.25 15.3689V16.619C10.25 17.3084 10.403 17.4523 11.1364 17.4523H12.9091V22.4523C12.9091 23.1417 13.0621 23.2856 13.7955 23.2856H15.5682C16.3015 23.2856 16.4545 23.1417 16.4545 22.4523V17.4523H18.445C19.0012 17.4523 19.1446 17.3507 19.2973 16.8479L19.6772 15.5979C19.9389 14.7367 19.7776 14.5356 18.8249 14.5356H16.4545V12.4523C16.4545 11.9921 16.8514 11.619 17.3409 11.619H19.8636C20.5969 11.619 20.75 11.4751 20.75 10.7856V9.11898C20.75 8.42953 20.5969 8.28564 19.8636 8.28564H17.3409C14.8933 8.28564 12.9091 10.1511 12.9091 12.4523V14.5356H11.1364Z"
                      stroke="#333333"
                      strokeWidth="1.125"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <a title="X" href="https://twitter.com/myHoodNG">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.785645" width="30" height="30" rx="15" fill="#FF5722" fillOpacity="0.3" />
                    <path
                      d="M8.75 22.5356L14.4113 16.8743M14.4113 16.8743L8.75 9.03564H12.5L16.5887 14.6969M14.4113 16.8743L18.5 22.5356H22.25L16.5887 14.6969M22.25 9.03564L16.5887 14.6969"
                      stroke="#333333"
                      strokeWidth="1.125"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <a title="Linkedin" href="https://www.linkedin.com/company/myhood-ng/">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.785645" width="30" height="30" rx="15" fill="#FF5722" fillOpacity="0.3" />
                    <path d="M11.75 14.2856V19.5356" stroke="#333333" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M14.75 16.5356V19.5356M14.75 16.5356C14.75 15.293 15.7573 14.2856 17 14.2856C18.2427 14.2856 19.25 15.293 19.25 16.5356V19.5356M14.75 16.5356V14.2856"
                      stroke="#333333"
                      strokeWidth="1.125"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path d="M11.756 12.0356H11.7493" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path
                      d="M8.375 15.7856C8.375 12.4269 8.375 10.7475 9.41843 9.70407C10.4619 8.66064 12.1412 8.66064 15.5 8.66064C18.8587 8.66064 20.5381 8.66064 21.5816 9.70407C22.625 10.7475 22.625 12.4269 22.625 15.7856C22.625 19.1444 22.625 20.8238 21.5816 21.8672C20.5381 22.9106 18.8587 22.9106 15.5 22.9106C12.1412 22.9106 10.4619 22.9106 9.41843 21.8672C8.375 20.8238 8.375 19.1444 8.375 15.7856Z"
                      stroke="#333333"
                      strokeWidth="1.125"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.785645" width="30" height="30" rx="15" fill="#FF5722" fillOpacity="0.3" />
                  <path
                    d="M8.375 15.7856C8.375 12.4269 8.375 10.7475 9.41843 9.70407C10.4619 8.66064 12.1412 8.66064 15.5 8.66064C18.8587 8.66064 20.5381 8.66064 21.5816 9.70407C22.625 10.7475 22.625 12.4269 22.625 15.7856C22.625 19.1444 22.625 20.8238 21.5816 21.8672C20.5381 22.9106 18.8587 22.9106 15.5 22.9106C12.1412 22.9106 10.4619 22.9106 9.41843 21.8672C8.375 20.8238 8.375 19.1444 8.375 15.7856Z"
                    stroke="#333333"
                    strokeWidth="1.125"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.875 15.7856C18.875 17.6496 17.364 19.1606 15.5 19.1606C13.636 19.1606 12.125 17.6496 12.125 15.7856C12.125 13.9217 13.636 12.4106 15.5 12.4106C17.364 12.4106 18.875 13.9217 18.875 15.7856Z"
                    stroke="#333333"
                    strokeWidth="1.125"
                  />
                  <path d="M19.6309 11.6606H19.6241" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <a title="Email" href="mailto:contact@myhood.ng">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.785645" width="30" height="30" rx="15" fill="#FF5722" fillOpacity="0.3" />
                    <path d="M8 11.2856L13.1848 14.2234C15.0962 15.3064 15.9038 15.3064 17.8152 14.2234L23 11.2856" stroke="#333333" strokeWidth="1.5" strokeLinejoin="round" />
                    <path
                      d="M8.01183 16.8923C8.06086 19.1915 8.08537 20.3411 8.93372 21.1927C9.78206 22.0442 10.9627 22.0739 13.3241 22.1332C14.7795 22.1698 16.2205 22.1698 17.6759 22.1332C20.0373 22.0739 21.2179 22.0442 22.0663 21.1927C22.9147 20.3411 22.9392 19.1915 22.9881 16.8923C23.004 16.1531 23.004 15.4182 22.9881 14.6789C22.9392 12.3798 22.9147 11.2302 22.0663 10.3786C21.2179 9.52707 20.0373 9.49741 17.6759 9.43807C16.2205 9.4015 14.7795 9.4015 13.3241 9.43807C10.9627 9.49739 9.78206 9.52705 8.93371 10.3786C8.08537 11.2302 8.06085 12.3798 8.01182 14.6789C7.99605 15.4182 7.99606 16.1531 8.01183 16.8923Z"
                      stroke="#333333"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <a title="Telephone" href="tel:+2348023984075">
                  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.785645" width="30" height="30" rx="15" fill="#FF5722" fillOpacity="0.3" />
                    <path
                      d="M9.33321 15.7424C8.6222 14.5026 8.27889 13.4903 8.07188 12.4641C7.76571 10.9463 8.46636 9.46375 9.62703 8.51775C10.1176 8.11793 10.6799 8.25454 10.97 8.77495L11.6249 9.94983C12.144 10.8811 12.4035 11.3467 12.352 11.8403C12.3006 12.334 11.9505 12.736 11.2505 13.5402L9.33321 15.7424ZM9.33321 15.7424C10.7724 18.2519 13.0309 20.5116 15.5432 21.9524M15.5432 21.9524C16.783 22.6634 17.7954 23.0068 18.8216 23.2138C20.3393 23.5199 21.8219 22.8193 22.7679 21.6586C23.1677 21.1681 23.0311 20.6057 22.5107 20.3156L21.3358 19.6607C20.4045 19.1417 19.9389 18.8822 19.4453 18.9336C18.9516 18.9851 18.5496 19.3351 17.7455 20.0351L15.5432 21.9524Z"
                      stroke="#333333"
                      strokeWidth="1.125"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.5 12.0356H16.1428C16.4459 12.0356 16.5974 12.0356 16.6916 12.1272C16.7857 12.2187 16.7857 12.366 16.7857 12.6606C16.7857 13.2499 16.7857 13.5445 16.5974 13.7276C16.4542 13.8668 16.2447 13.9001 15.8859 13.9081C15.7019 13.9122 15.6099 13.9143 15.5549 13.9689C15.5 14.0236 15.5 14.1109 15.5 14.2856V15.1607C15.5 15.4553 15.5 15.6026 15.5941 15.6941C15.6883 15.7856 15.8398 15.7856 16.1428 15.7856H16.7857M20 12.0356V13.9106M20 13.9106H18.9072C18.6647 13.9106 18.5435 13.9106 18.4682 13.8374C18.3928 13.7642 18.3928 13.6463 18.3928 13.4106V12.0356M20 13.9106V15.7856"
                      stroke="#333333"
                      strokeWidth="1.125"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 10.0145C14.0668 9.93953 14.1361 9.86602 14.2081 9.79411C16.2193 7.78282 19.4803 7.78282 21.4915 9.79411C23.5028 11.8054 23.5028 15.0663 21.4915 17.0776C21.4196 17.1495 21.3461 17.2189 21.2711 17.2856"
                      stroke="#333333"
                      strokeWidth="1.125"
                      strokeLinecap="round"
                    />
                  </svg>
                </a>
              </div>
            </div>
            <div className="w-[420px]  justify-start items-start gap-[50px] inline-flex">
              <ul className="flex-col justify-center items-start gap-2.5 inline-flex">
                <li className="text-center text-zinc-800 text-[22px] font-semibold font-openSans tracking-tight">Legal</li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/termsofuse">
                  <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Terms Of use</li>
                </Link>
                <Link onClick={() => window.scrollTo(0, 0)} to="/privacypolicy">
                  <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Privacy Policy</li>
                </Link>
                <Link onClick={() => window.scrollTo(0, 0)} to="/cookiepolicy">
                  <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Cookie Policy</li>
                </Link>
                <Link onClick={() => window.scrollTo(0, 0)} to="/disclaimer">
                  <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Disclaimer</li>
                </Link>
              </ul>
              <ul className="flex-col justify-center items-start gap-2.5 inline-flex">
                <li className="text-center text-zinc-800 text-[22px] font-semibold font-openSans tracking-tight">links</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">home</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">About Us</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Bookings</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Blog</li>
              </ul>
              <ul className="flex-col justify-center items-start gap-2.5 inline-flex">
                <li className="text-center text-zinc-800 text-[22px] font-semibold font-openSans tracking-tight">Product</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Take Tour</li>
                <a className=" no-underline" href="https://wa.me/+2348023984075?text=Hi ">
                  <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Live Chat</li>
                </a>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Reviews</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Custom Checkout</li>
              </ul>
            </div>
            <div className="w-[370px] h-fit py-[50px] px-[30px] flex flex-col justify-center  bg-black bg-opacity-10 rounded-[10px] items-center gap-[20px] ">
              <div className="flex-col justify-start items-center gap-[30px] flex">
                <div className="flex-col justify-start items-center gap-2.5 flex">
                  <div className="text-zinc-800 text-2xl font-semibold font-openSans tracking-tight">Contact Us</div>
                </div>
                <form action="https://formsubmit.co/contact@myhood.ng" method="POST" className=" flex gap-[15px] items-center flex-col">
                  <input
                    placeholder="Enter Email"
                    type="email"
                    className="w-[300px] outline-orange-600 h-[33px] text-[#333] placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight px-[20px] bg-[#eeeeee] rounded-[5px] justify-start items-center inline-flex"
                  />
                  <input
                    placeholder="Enter Subject"
                    type="text"
                    className="w-[300px] outline-orange-600 h-[33px] text-[#333] placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight px-[20px] bg-[#eeeeee] rounded-[5px] justify-start items-center inline-flex"
                  />
                  <textarea
                    placeholder="Enter message....."
                    className="w-[300px] py-[5px] outline-orange-600 h-[60px] text-[#333] placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight px-[20px] bg-[#eeeeee] rounded-[5px] justify-start items-center inline-flex"
                  />
                  <button type="submit" className="px-10 w-[182px] mt-[20px] py-[7px] bg-orange-600 rounded justify-center items-center gap-2.5 inline-flex">
                    <div className="text-white text-xl font-medium font-monteserrat tracking-tight">Send</div>
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-40 text-zinc-800 text-base font-normal font-monteserrat tracking-tight">Copyrights @{date} | myHood</div>
        </div>
      </div>
      {/* SMALL SCREENS FROM MOBILE TO 1023PX */}
      <div className="mobile:flex  xl:hidden mt-[49.92px]  w-full h-fit  px-[37px] py-[50px] myDiv   justify-center items-center flex">
        <div className="relative   w-[95%] gap-[80px] flex flex-col justify-between items-center">
          <img src={logo} alt="myHood" />
          <div className="w-full flex flex-col gap-[50px]">
            <div className=" w-full phone:max-w-[400px] md:max-w-[650px] flex flex-col gap-[20px] justify-between">
              <h4 className="text-zinc-800 text-[34px] font-semibold font-openSans tracking-tight">Get In Touch</h4>
              <p className="text-zinc-800  text-lg font-normal font-monteserratleading-[35px] tracking-tight">
                Have questions, suggestions, or want to collaborate? Reach out to us. Your thoughts matter, and we're here to listen. Contact us for anything you need.
              </p>
              <div className="flex phone:max-w-[250px] justify-between items-center">
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.785645" width="30" height="30" rx="15" fill="#FF5722" fillOpacity="0.3" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.1364 14.5356C10.403 14.5356 10.25 14.6795 10.25 15.3689V16.619C10.25 17.3084 10.403 17.4523 11.1364 17.4523H12.9091V22.4523C12.9091 23.1417 13.0621 23.2856 13.7955 23.2856H15.5682C16.3015 23.2856 16.4545 23.1417 16.4545 22.4523V17.4523H18.445C19.0012 17.4523 19.1446 17.3507 19.2973 16.8479L19.6772 15.5979C19.9389 14.7367 19.7776 14.5356 18.8249 14.5356H16.4545V12.4523C16.4545 11.9921 16.8514 11.619 17.3409 11.619H19.8636C20.5969 11.619 20.75 11.4751 20.75 10.7856V9.11898C20.75 8.42953 20.5969 8.28564 19.8636 8.28564H17.3409C14.8933 8.28564 12.9091 10.1511 12.9091 12.4523V14.5356H11.1364Z"
                    stroke="#333333"
                    strokeWidth="1.125"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.785645" width="30" height="30" rx="15" fill="#FF5722" fillOpacity="0.3" />
                  <path
                    d="M8.75 22.5356L14.4113 16.8743M14.4113 16.8743L8.75 9.03564H12.5L16.5887 14.6969M14.4113 16.8743L18.5 22.5356H22.25L16.5887 14.6969M22.25 9.03564L16.5887 14.6969"
                    stroke="#333333"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.785645" width="30" height="30" rx="15" fill="#FF5722" fillOpacity="0.3" />
                  <path d="M11.75 14.2856V19.5356" stroke="#333333" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M14.75 16.5356V19.5356M14.75 16.5356C14.75 15.293 15.7573 14.2856 17 14.2856C18.2427 14.2856 19.25 15.293 19.25 16.5356V19.5356M14.75 16.5356V14.2856"
                    stroke="#333333"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path d="M11.756 12.0356H11.7493" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path
                    d="M8.375 15.7856C8.375 12.4269 8.375 10.7475 9.41843 9.70407C10.4619 8.66064 12.1412 8.66064 15.5 8.66064C18.8587 8.66064 20.5381 8.66064 21.5816 9.70407C22.625 10.7475 22.625 12.4269 22.625 15.7856C22.625 19.1444 22.625 20.8238 21.5816 21.8672C20.5381 22.9106 18.8587 22.9106 15.5 22.9106C12.1412 22.9106 10.4619 22.9106 9.41843 21.8672C8.375 20.8238 8.375 19.1444 8.375 15.7856Z"
                    stroke="#333333"
                    strokeWidth="1.125"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.785645" width="30" height="30" rx="15" fill="#FF5722" fillOpacity="0.3" />
                  <path
                    d="M8.375 15.7856C8.375 12.4269 8.375 10.7475 9.41843 9.70407C10.4619 8.66064 12.1412 8.66064 15.5 8.66064C18.8587 8.66064 20.5381 8.66064 21.5816 9.70407C22.625 10.7475 22.625 12.4269 22.625 15.7856C22.625 19.1444 22.625 20.8238 21.5816 21.8672C20.5381 22.9106 18.8587 22.9106 15.5 22.9106C12.1412 22.9106 10.4619 22.9106 9.41843 21.8672C8.375 20.8238 8.375 19.1444 8.375 15.7856Z"
                    stroke="#333333"
                    strokeWidth="1.125"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18.875 15.7856C18.875 17.6496 17.364 19.1606 15.5 19.1606C13.636 19.1606 12.125 17.6496 12.125 15.7856C12.125 13.9217 13.636 12.4106 15.5 12.4106C17.364 12.4106 18.875 13.9217 18.875 15.7856Z"
                    stroke="#333333"
                    strokeWidth="1.125"
                  />
                  <path d="M19.6309 11.6606H19.6241" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.785645" width="30" height="30" rx="15" fill="#FF5722" fillOpacity="0.3" />
                  <path d="M8 11.2856L13.1848 14.2234C15.0962 15.3064 15.9038 15.3064 17.8152 14.2234L23 11.2856" stroke="#333333" strokeWidth="1.5" strokeLinejoin="round" />
                  <path
                    d="M8.01183 16.8923C8.06086 19.1915 8.08537 20.3411 8.93372 21.1927C9.78206 22.0442 10.9627 22.0739 13.3241 22.1332C14.7795 22.1698 16.2205 22.1698 17.6759 22.1332C20.0373 22.0739 21.2179 22.0442 22.0663 21.1927C22.9147 20.3411 22.9392 19.1915 22.9881 16.8923C23.004 16.1531 23.004 15.4182 22.9881 14.6789C22.9392 12.3798 22.9147 11.2302 22.0663 10.3786C21.2179 9.52707 20.0373 9.49741 17.6759 9.43807C16.2205 9.4015 14.7795 9.4015 13.3241 9.43807C10.9627 9.49739 9.78206 9.52705 8.93371 10.3786C8.08537 11.2302 8.06085 12.3798 8.01182 14.6789C7.99605 15.4182 7.99606 16.1531 8.01183 16.8923Z"
                    stroke="#333333"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.785645" width="30" height="30" rx="15" fill="#FF5722" fillOpacity="0.3" />
                  <path
                    d="M9.33321 15.7424C8.6222 14.5026 8.27889 13.4903 8.07188 12.4641C7.76571 10.9463 8.46636 9.46375 9.62703 8.51775C10.1176 8.11793 10.6799 8.25454 10.97 8.77495L11.6249 9.94983C12.144 10.8811 12.4035 11.3467 12.352 11.8403C12.3006 12.334 11.9505 12.736 11.2505 13.5402L9.33321 15.7424ZM9.33321 15.7424C10.7724 18.2519 13.0309 20.5116 15.5432 21.9524M15.5432 21.9524C16.783 22.6634 17.7954 23.0068 18.8216 23.2138C20.3393 23.5199 21.8219 22.8193 22.7679 21.6586C23.1677 21.1681 23.0311 20.6057 22.5107 20.3156L21.3358 19.6607C20.4045 19.1417 19.9389 18.8822 19.4453 18.9336C18.9516 18.9851 18.5496 19.3351 17.7455 20.0351L15.5432 21.9524Z"
                    stroke="#333333"
                    strokeWidth="1.125"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.5 12.0356H16.1428C16.4459 12.0356 16.5974 12.0356 16.6916 12.1272C16.7857 12.2187 16.7857 12.366 16.7857 12.6606C16.7857 13.2499 16.7857 13.5445 16.5974 13.7276C16.4542 13.8668 16.2447 13.9001 15.8859 13.9081C15.7019 13.9122 15.6099 13.9143 15.5549 13.9689C15.5 14.0236 15.5 14.1109 15.5 14.2856V15.1607C15.5 15.4553 15.5 15.6026 15.5941 15.6941C15.6883 15.7856 15.8398 15.7856 16.1428 15.7856H16.7857M20 12.0356V13.9106M20 13.9106H18.9072C18.6647 13.9106 18.5435 13.9106 18.4682 13.8374C18.3928 13.7642 18.3928 13.6463 18.3928 13.4106V12.0356M20 13.9106V15.7856"
                    stroke="#333333"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 10.0145C14.0668 9.93953 14.1361 9.86602 14.2081 9.79411C16.2193 7.78282 19.4803 7.78282 21.4915 9.79411C23.5028 11.8054 23.5028 15.0663 21.4915 17.0776C21.4196 17.1495 21.3461 17.2189 21.2711 17.2856"
                    stroke="#333333"
                    strokeWidth="1.125"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>
            <div className="w-full flex 100:flex-col md:flex-wrap  justify-between items-start gap-[50px] ">
              <ul className="flex-col justify-center items-start gap-2.5 inline-flex">
                <li className="text-center text-zinc-800 text-[22px] font-semibold font-openSans tracking-tight">Legal</li>
                <Link onClick={() => window.scrollTo(0, 0)} to="/termsofuse">
                  <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Terms Of use</li>
                </Link>
                <Link onClick={() => window.scrollTo(0, 0)} to="/privacypolicy">
                  <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Privacy Policy</li>
                </Link>
                <Link onClick={() => window.scrollTo(0, 0)} to="/cookiepolicy">
                  <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Cookie Policy</li>
                </Link>

                <Link onClick={() => window.scrollTo(0, 0)} to="/disclaimer">
                  <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Disclaimer</li>
                </Link>
              </ul>
              <ul className="flex-col justify-center items-start gap-2.5 inline-flex">
                <li className="text-center text-zinc-800 text-[22px] font-semibold font-openSans tracking-tight">links</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">home</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">About Us</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Bookings</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Blog</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Bookings</li>
              </ul>
              <ul className="flex-col justify-center items-start gap-2.5 inline-flex">
                <li className="text-center text-zinc-800 text-[22px] font-semibold font-openSans tracking-tight">Product</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Take Tour</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Live Chat</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Reviews</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Custom Checkout</li>
                <li className="text-zinc-800 text-base font-normal font-monteserrat  tracking-tight">Reviews</li>
              </ul>
            </div>
            <div className=" w-full flex justify-center items-center">
              <div className="w-full phone:w-[370px] md:w-[650px]  h-fit py-[50px] px-[30px] flex flex-col justify-center  bg-black bg-opacity-10 rounded-[10px] items-center gap-[20px] ">
                <div className="flex-col justify-start items-center gap-[30px] flex">
                  <div className="flex-col justify-start items-center gap-2.5 flex">
                    <div className="text-zinc-800 text-2xl font-semibold font-openSans tracking-tight">Contact Us</div>
                  </div>
                  <form action="https://formsubmit.co/contact@myhood.ng" method="POST" className=" flex gap-[15px] items-center flex-col">
                    <input
                      placeholder="Enter Email"
                      type="email"
                      className="w-[280px] grow outline-orange-600 h-[33px] text-[#333] placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight px-[20px] bg-[#eeeeee] rounded-[5px] justify-start items-center inline-flex"
                    />
                    <input
                      placeholder="Enter Subject"
                      type="text"
                      className="w-[280px] outline-orange-600 h-[33px] text-[#333] placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight px-[20px] bg-[#eeeeee] rounded-[5px] justify-start items-center inline-flex"
                    />
                    <textarea
                      placeholder="Enter message....."
                      className="w-[280px] py-[5px] outline-orange-600 h-[60px] text-[#333] placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight px-[20px] bg-[#eeeeee] rounded-[5px] justify-start items-center inline-flex"
                    />
                    <button type="submit" className="px-10 w-[182px] mt-[20px] py-[7px] bg-orange-600 rounded justify-center items-center gap-2.5 inline-flex">
                      <div className="text-white text-xl font-medium font-monteserrat tracking-tight">Send</div>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-40 text-zinc-800 text-base font-normal font-monteserrat tracking-tight">Copyrights @{date} | myHood</div>
        </div>
      </div>
    </>
  );
}

export default Footer;
