function SupportAndFeedback() {
  return (
    <>
      {/* LARGE SCREENS */}
      <div className={` 100:hidden xl:flex w-full flex flex-col gap-[70px] p-[20px] justify-between items-start`}>
        <div className="w-full flex flex-col gap-[40px] justify-between items-start">
          <div className="self-stretch float-start h-fit pb-[20px] flex flex-col justify-between gap-[10px] border-b-[1px] border-solid border-b-neutral-200">
            <h3 className="grow shrink basis-0 self-stretch text-zinc-800 text-base font-semibold font-openSans tracking-tight">Support</h3>
            <p className="w-[835px] text-[#777] text-sm font-normal font-openSans leading-[22px] tracking-tight">
              At myHood, we're committed to providing excellent support to our users. Whether you have questions, need assistance, or want to share feedback, we're here to help. Contact us through any of the following channels:
            </p>
          </div>
          <div className="w-full flex-col h-fit px-6 justify-between items-start gap-[8px] flex">
            <h3 className="self-stretch text-zinc-800 text-sm font-semibold font-openSans leading-tight">Email:</h3>
            <p className="text-neutral-500 text-sm font-normal font-openSans leading-tight">
              Send us an email at{" "}
              <a href="mailto:contact@myhood.ng">
                <span className="text-orange-600  underline">support@myhood.com</span>
              </a>{" "}
              for any inquiries or feedback.
            </p>
          </div>
          <div className="w-full flex-col h-fit px-6 justify-between items-start gap-[8px] flex">
            <h3 className="self-stretch text-zinc-800 text-sm font-semibold font-openSans leading-tight">Phone:</h3>
            <p className="text-neutral-500 text-sm font-normal font-openSans leading-tight">
              Input Call our customer support hotline at{" "}
              <a href="tel:+2348023984075">
                <span className="text-orange-600  underline">+2348023984075</span>
              </a>{" "}
              during business hours for immediate assistance.
            </p>
          </div>
        </div>
        <form action="https://formsubmit.co/support@myhood.com" method="POST" className="w-full flex flex-col gap-[20px] justify-between items-start">
          <div className="self-stretch float-start h-fit pb-[20px] flex flex-col justify-between gap-[10px] border-b-[1px] border-solid border-b-neutral-200">
            <h3 className="grow shrink basis-0 self-stretch text-zinc-800 text-base font-semibold font-openSans tracking-tight">Feedback</h3>
            <p className=" w-[618px] text-[#777] text-sm font-normal font-openSans leading-[22px] tracking-tight">
              Your feedback is important to us! Please take a moment to share your thoughts, suggestions, or concerns using the form below. We greatly value your input as it helps us improve our services and make myHood a better platform for
              everyone.{" "}
            </p>
          </div>
          <div className="w-[527px] h-fit flex-col justify-start items-start gap-[8px] flex">
            <label htmlFor="Message" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
              Message
            </label>
            <textarea
              placeholder="Enter Your Message........"
              type="text"
              className=" outline-[#FF5722] w-full h-[110px]  text-[#333] px-[15px] py-[4px] bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 "
            />
          </div>
          <div className="w-[527px] flex justify-end">
            <button type="submit" className="w-[180px]  h-[38px] px-[57px] py-[7px] bg-orange-600 rounded justify-center items-center gap-2.5 inline-flex">
              <div className="text-center text-white text-base font-semibold font-['Open Sans'] leading-normal tracking-wide">Submit</div>
            </button>
          </div>
        </form>
      </div>
      {/* SMALL SCREENS */}
      <div className=" 100:flex xl:hidden w-full  pb-[50px] flex-col justify-between gap-[70px] h-fit ">
        <div className="w-full flex flex-col gap-[40px] justify-between items-start">
          <div className="self-stretch float-start h-fit pb-[20px] flex flex-col justify-between gap-[10px] border-b-[1px] border-solid border-b-neutral-200">
            <h3 className="grow shrink basis-0 self-stretch text-zinc-800 text-base font-semibold font-openSans tracking-tight">Support</h3>
            <p className="w-full text-[#777] text-sm font-normal font-openSans leading-[22px] tracking-tight">
              At myHood, we're committed to providing excellent support to our users. Whether you have questions, need assistance, or want to share feedback, we're here to help. Contact us through any of the following channels:
            </p>
          </div>
          <div className="w-full flex-col h-fit sm:px-6 justify-between items-start gap-[8px] flex">
            <h3 className="self-stretch text-zinc-800 text-sm font-semibold font-openSans leading-tight">Email:</h3>
            <p className="text-neutral-500  text-sm font-normal font-openSans leading-[20px]">
              Send us an email at{" "}
              <a href="mailto:contact@myhood.ng">
                <span className="text-orange-600  underline">support@myhood.com</span>
              </a>{" "}
              for any inquiries or feedback.
            </p>
          </div>
          <div className="w-full flex-col h-fit sm:px-6 justify-between items-start gap-[8px] flex">
            <h3 className="self-stretch text-zinc-800 text-sm font-semibold font-openSans leading-tight">Phone:</h3>
            <p className="text-neutral-500 text-sm font-normal font-openSans leading-[20px]">
              Input Call our customer support hotline at{" "}
              <a href="tel:+2348023984075">
                <span className="text-orange-600  underline">+2348023984075</span>
              </a>{" "}
              during business hours for immediate assistance.
            </p>
          </div>
        </div>
        <form action="https://formsubmit.co/support@myhood.com" method="POST" className="w-full flex flex-col gap-[20px] justify-between items-start">
          <div className="self-stretch float-start h-fit pb-[20px] flex flex-col justify-between gap-[10px] border-b-[1px] border-solid border-b-neutral-200">
            <h3 className="grow shrink basis-0 self-stretch text-zinc-800 text-base font-semibold font-openSans tracking-tight">Feedback</h3>
            <p className=" w-full text-[#777] text-sm font-normal font-openSans leading-[22px] tracking-tight">
              Your feedback is important to us! Please take a moment to share your thoughts, suggestions, or concerns using the form below. We greatly value your input as it helps us improve our services and make myHood a better platform for
              everyone.{" "}
            </p>
          </div>
          <div className="w-full h-fit flex-col justify-start items-start gap-[8px] flex">
            <label htmlFor="Message" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
              Message
            </label>
            <textarea
              placeholder="Enter Your Message........"
              type="text"
              className=" outline-[#FF5722] w-full h-[110px]  text-[#333] px-[15px] py-[4px] bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 "
            />
          </div>
          <div className="w-full flex justify-end">
            <button type="submit" className="w-[180px]  h-[38px] px-[57px] py-[7px] bg-orange-600 rounded justify-center items-center gap-2.5 inline-flex">
              <div className="text-center text-white text-base font-semibold font-['Open Sans'] leading-normal tracking-wide">Submit</div>
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default SupportAndFeedback;
