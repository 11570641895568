import { Link } from "react-router-dom";
import join from "../images/joinUs.png";

function JoinUs() {
  return (
    <>
      {/* BIG SCREENS */}
      <div className=" lg:flex 100:hidden relative  w-full mt-[50px] flex justify-center items-center lg:h-[734px] 2xl:h-[800px] 4xl:h-[850px]  ">
        <img src={join} alt="Join Us" className="bg-cover w-full h-full left-0 top-0 absolute " />
        <div className="w-[755px] h-[437px] text-center px-[101px] py-20 relative bg-neutral-100 bg-opacity-50 rounded-[10px] flex-col justify-between items-center gap-2.5 inline-flex">
          <h3 className="text-zinc-800 text-[40px] font-semibold font-openSans tracking-tight">
            Join <span className="text-[#4CAF50]">my</span>
            <span className="text-[#FF5722]">Hood</span>
          </h3>
          <p className="text-zinc-800 text-[22px] w-[500px] font-normal font-monteserrat leading-[45px] tracking-tight">Sign up now and be a part of the digital transformation of community living. Experience the power of connection.</p>
          <button>
            <Link to="/signup">
              <div className="w-[153px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-xl font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex">Join Us</div>
            </Link>
          </button>
        </div>
      </div>

      {/* SMALL SCREENS FROM MOBILE TO 1023PX */}
      <div className=" lg:hidden 100:flex relative  w-full mt-[50px] flex justify-center items-center h-[604px] ">
        <img src={join} alt="Join Us" className="bg-cover bg-no-repeat w-full h-full left-0 top-0 absolute " />
        <div className="w-[80%] h-[437px] text-center mobile:px-[20px] phone:px-[50px] mobile:py-[50px] relative bg-neutral-100 bg-opacity-50 rounded-[10px] flex justify-center items-center gap-2.5 ">
          <div className="grid items-center justify-center gap-2.5">
            <h3 className="text-zinc-800 text-[30px] font-semibold font-openSans tracking-tight">
              Join <span className="text-[#4CAF50]">my</span>
              <span className="text-[#FF5722]">Hood</span>
            </h3>
            <p className="text-zinc-800 text-[18px]  font-normal font-monteserrat leading-[35px] tracking-tight">Sign up now and be a part of the digital transformation of community living. Experience the power of connection.</p>
            <button>
              <Link to="">
                <div className="w-[153px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[18px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex">Join Us</div>
              </Link>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default JoinUs;
