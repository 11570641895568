import { useEffect, useState } from "react";
import HeaderSuper from "./HeaderSuper";
import { useLocation } from "react-router-dom";
import Footer from "../UserAdmin/Footer";
import footware from "../../images/footware.png";
import InputFiles from "react-input-files";
import axios from "axios";
// import SideBar from "../UserAdmin/SideBar";
import SideBarSuper from "./SideBar";

function AdvertManagementSuper() {
  const initialData = {
    image: footware, // Initial image
    category: "Clothing", // Initial category
    status: "Pending", // Initial status
    duration: "14 December 2024", // Initial duration
  };
  const [mini, setMini] = useState(false);
  const [dropDrown, setDropDrown] = useState(false);
  const [newAdvert, setnewAdvert] = useState(false);
  const [save, setSave] = useState(false);
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(0);
  const [edit, setEdit] = useState(false);
  const [view, setView] = useState(false);
  const [isdelete, setIsDelete] = useState(false);
  const [editedData, setEditedData] = useState(initialData);
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [successful, setSuccessful] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputField, setInputField] = useState(false);
  const [inputValue, setInputValue] = useState({
    title: "",
    description: "",
    image: "",
    start_date: "",
    contact_phone: "",
    contact_email: "",
    user_id: "",
    number_of_days: "",
    actionid: "2",
  });

  const menu = [
    {
      id: 1,
      path: "/dashboardsuper",
      name: "Dashboard",
      title: "Dashboard",
      icon1: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83334 3.17332 9.83334 4.73812C9.83334 6.30293 11.1018 7.57146 12.6666 7.57146Z" stroke="#333333" />
          <path d="M5 7.57146C6.5648 7.57146 7.83333 6.30293 7.83333 4.73812C7.83333 3.17332 6.5648 1.90479 5 1.90479C3.43519 1.90479 2.16666 3.17332 2.16666 4.73812C2.16666 6.30293 3.43519 7.57146 5 7.57146Z" stroke="#333333" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83334 10.8398 9.83334 12.4046C9.83334 13.9694 11.1018 15.238 12.6666 15.238Z" stroke="#333333" />
          <path d="M5 15.238C6.5648 15.238 7.83333 13.9694 7.83333 12.4046C7.83333 10.8398 6.5648 9.57129 5 9.57129C3.43519 9.57129 2.16666 10.8398 2.16666 12.4046C2.16666 13.9694 3.43519 15.238 5 15.238Z" stroke="#333333" />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83331 3.17332 9.83331 4.73812C9.83331 6.30293 11.1018 7.57146 12.6666 7.57146Z" fill="#F5F5F5" />
          <path d="M4.99997 7.57146C6.56477 7.57146 7.8333 6.30293 7.8333 4.73812C7.8333 3.17332 6.56477 1.90479 4.99997 1.90479C3.43516 1.90479 2.16663 3.17332 2.16663 4.73812C2.16663 6.30293 3.43516 7.57146 4.99997 7.57146Z" fill="#F5F5F5" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83331 10.8398 9.83331 12.4046C9.83331 13.9694 11.1018 15.238 12.6666 15.238Z" fill="#F5F5F5" />
          <path d="M4.99997 15.238C6.56477 15.238 7.8333 13.9694 7.8333 12.4046C7.8333 10.8398 6.56477 9.57129 4.99997 9.57129C3.43516 9.57129 2.16663 10.8398 2.16663 12.4046C2.16663 13.9694 3.43516 15.238 4.99997 15.238Z" fill="#F5F5F5" />
        </svg>
      ),
    },
    {
      id: 2,
      path: "/advertmanagementsuper",
      name: "Advert Management",
      title: "Advert Management",
      icon1: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.67053 11.7323L5.35427 7.09046C5.66045 6.44611 6.12866 5.9132 6.66514 7.19129C7.16036 8.3711 7.89923 10.5314 8.33536 11.7348M4.43555 9.7393H7.54763" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M2.30969 3.94997C1.33337 4.82865 1.33337 6.24287 1.33337 9.07129C1.33337 11.8996 1.33337 13.3139 2.30969 14.1926C3.28599 15.0712 4.85735 15.0712 8.00004 15.0712C11.1427 15.0712 12.7141 15.0712 13.6904 14.1926C14.6667 13.3139 14.6667 11.8996 14.6667 9.07129C14.6667 6.24287 14.6667 4.82865 13.6904 3.94997C12.7141 3.07129 11.1427 3.07129 8.00004 3.07129C4.85735 3.07129 3.28599 3.07129 2.30969 3.94997Z"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.3228 7.0625V9.05895M12.3228 9.05895V11.688M12.3228 9.05895H10.9773C10.8175 9.05895 10.659 9.08868 10.5096 9.14662C9.38042 9.58475 9.38042 11.2128 10.5096 11.651C10.659 11.7089 10.8175 11.7386 10.9773 11.7386H12.3228"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.14304 3.94997C2.16672 4.82865 2.16672 6.24287 2.16672 9.07129C2.16672 11.8996 2.16672 13.3139 3.14304 14.1926C4.11934 15.0712 5.6907 15.0712 8.83339 15.0712C11.976 15.0712 13.5474 15.0712 14.5237 14.1926C15.5 13.3139 15.5 11.8996 15.5 9.07129C15.5 6.24287 15.5 4.82865 14.5237 3.94997C13.5474 3.07129 11.976 3.07129 8.83339 3.07129C5.6907 3.07129 4.11934 3.07129 3.14304 3.94997Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M4.50388 11.7323L6.18762 7.09046C6.4938 6.44611 6.96201 5.9132 7.49849 7.19129C7.99371 8.3711 8.73257 10.5314 9.1687 11.7348M5.2689 9.7393H8.38098" stroke="#FF5722" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M13.1561 7.0625V9.05895M13.1561 9.05895V11.688M13.1561 9.05895H11.8106C11.6508 9.05895 11.4923 9.08868 11.3429 9.14662C10.2138 9.58475 10.2138 11.2128 11.3429 11.651C11.4923 11.7089 11.6508 11.7386 11.8106 11.7386H13.1561"
            stroke="#FF5722"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 3,
      path: "/accessmanagementsuper",
      name: "Access Management",
      title: "Access Management",
      icon1: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.66671 2.40472C2.82576 2.40472 1.33337 3.89711 1.33337 5.73805C1.33337 6.97186 2.00371 8.04912 3.00004 8.62545V12.9668C3.00004 13.5118 3.00004 13.7843 3.10153 14.0293C3.20303 14.2744 3.39571 14.4671 3.78109 14.8525L4.66671 15.7381L6.07217 14.3326C6.13699 14.2678 6.16942 14.2353 6.19631 14.2002C6.26691 14.1079 6.31215 13.9987 6.32752 13.8834C6.33337 13.8395 6.33337 13.7937 6.33337 13.702C6.33337 13.6278 6.33337 13.5907 6.32944 13.5547C6.31913 13.4601 6.28873 13.3689 6.24027 13.2871C6.22179 13.2559 6.19953 13.2263 6.15501 13.1669L5.33337 12.0714L5.80004 11.4492C6.06437 11.0967 6.19653 10.9205 6.26495 10.7153C6.33337 10.51 6.33337 10.2897 6.33337 9.84919V8.62545C7.32971 8.04912 8.00004 6.97186 8.00004 5.73805C8.00004 3.89711 6.50765 2.40472 4.66671 2.40472Z"
            stroke="#333333"
            strokeLinejoin="round"
          />
          <path d="M4.66663 5.73798H4.67261" stroke="#333333" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M8.66663 10.4047H12.6666C13.2879 10.4047 13.5985 10.4047 13.8436 10.5062C14.1702 10.6415 14.4298 10.9011 14.5652 11.2278C14.6666 11.4729 14.6666 11.7835 14.6666 12.4047C14.6666 13.026 14.6666 13.3366 14.5652 13.5817C14.4298 13.9083 14.1702 14.1679 13.8436 14.3033C13.5985 14.4047 13.2879 14.4047 12.6666 14.4047H8.66663"
            stroke="#333333"
            strokeLinecap="round"
          />
          <path
            d="M10 4.40472H12.6667C13.2879 4.40472 13.5985 4.40472 13.8436 4.50621C14.1703 4.64154 14.4299 4.90111 14.5652 5.22781C14.6667 5.47284 14.6667 5.78347 14.6667 6.40472C14.6667 7.02597 14.6667 7.3366 14.5652 7.58163C14.4299 7.90832 14.1703 8.16792 13.8436 8.30325C13.5985 8.40472 13.2879 8.40472 12.6667 8.40472H10"
            stroke="#333333"
            strokeLinecap="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.8334 2.40479C2.99245 2.40479 1.50006 3.89718 1.50006 5.73812C1.50006 6.97193 2.1704 8.04919 3.16673 8.62552V12.9669C3.16673 13.5119 3.16673 13.7844 3.26822 14.0294C3.36972 14.2745 3.5624 14.4672 3.94778 14.8526L4.8334 15.7382L6.23886 14.3327C6.30368 14.2679 6.33611 14.2354 6.363 14.2003C6.4336 14.108 6.47884 13.9988 6.49421 13.8835C6.50006 13.8396 6.50006 13.7938 6.50006 13.7021C6.50006 13.6279 6.50006 13.5908 6.49613 13.5548C6.48582 13.4602 6.45542 13.369 6.40696 13.2872C6.38848 13.256 6.36622 13.2264 6.3217 13.167L5.50006 12.0715L5.96673 11.4493C6.23106 11.0968 6.36322 10.9206 6.43164 10.7154C6.50006 10.5101 6.50006 10.2898 6.50006 9.84926V8.62552C7.4964 8.04919 8.16673 6.97193 8.16673 5.73812C8.16673 3.89718 6.67434 2.40479 4.8334 2.40479Z"
            fill="#F5F5F5"
          />
          <path d="M4.83331 5.73779H4.83929H4.83331Z" fill="#FF5722" />
          <path d="M4.83331 5.73779H4.83929" stroke="#FF5722" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M8.83331 10.4045H12.8333C13.4546 10.4045 13.7652 10.4045 14.0103 10.506C14.3369 10.6413 14.5965 10.9009 14.7319 11.2276C14.8333 11.4727 14.8333 11.7833 14.8333 12.4045C14.8333 13.0258 14.8333 13.3364 14.7319 13.5815C14.5965 13.9081 14.3369 14.1677 14.0103 14.3031C13.7652 14.4045 13.4546 14.4045 12.8333 14.4045H8.83331"
            stroke="#F5F5F5"
            strokeLinecap="round"
          />
          <path
            d="M10.1667 4.40479H12.8334C13.4546 4.40479 13.7652 4.40479 14.0103 4.50628C14.337 4.64161 14.5966 4.90118 14.7319 5.22788C14.8334 5.47291 14.8334 5.78354 14.8334 6.40479C14.8334 7.02604 14.8334 7.33667 14.7319 7.5817C14.5966 7.90839 14.337 8.16799 14.0103 8.30332C13.7652 8.40479 13.4546 8.40479 12.8334 8.40479H10.1667"
            stroke="#F5F5F5"
            strokeLinecap="round"
          />
        </svg>
      ),
    },
    {
      id: 4,
      path: "/revenuetracking",
      name: "Revenue Tracking",
      title: "Revenue Tracking",
      icon1: (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.3334 14.7856H7.00004C4.80015 14.7856 3.70021 14.7856 3.01679 14.1022C2.33337 13.4188 2.33337 12.3188 2.33337 10.119V2.78564" stroke="#333333" strokeLinecap="round" />
          <path d="M9.00003 7.45239V14.7857" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.3334 9.45239V14.7857" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.66672 9.45239V14.1191" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M14.3334 6.11018C13.1067 6.11018 11.7948 6.28058 10.9181 5.11454C9.91991 3.78702 8.08017 3.78702 7.08197 5.11454C6.20525 6.28058 4.89339 6.11018 3.66671 6.11018H2.33337"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.8334 14.2856H7.50004C5.30015 14.2856 4.20021 14.2856 3.51679 13.6022C2.83337 12.9188 2.83337 11.8188 2.83337 9.61898V2.28564" stroke="#F5F5F5" strokeLinecap="round" />
          <path d="M9.5 6.95239V14.2857" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.8334 8.95239V14.2857" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6.16675 8.95239V13.6191" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M14.8334 5.61018C13.6067 5.61018 12.2948 5.78058 11.4181 4.61454C10.4199 3.28702 8.58017 3.28702 7.58197 4.61454C6.70525 5.78058 5.39339 5.61018 4.16671 5.61018H2.83337"
            stroke="#F5F5F5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 5,
      path: "/subscriptionmanagement",
      name: "Subscription Management",
      title: "Subscription Management",
      icon1: (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.16675 6.95239H5.50008" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M2.16675 11.6189H5.50008" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M2.16675 2.28564H13.5001" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M11.5 12.0953V12.9524M11.5 12.0953C10.7288 12.0953 10.0494 11.7165 9.65091 11.1413M11.5 12.0953C12.2712 12.0953 12.9506 11.7165 13.3492 11.1413M9.65091 11.1413L8.83364 11.6667M9.65091 11.1413C9.41524 10.8011 9.27784 10.3922 9.27784 9.95239C9.27784 9.51259 9.41517 9.10379 9.65084 8.76366M13.3492 11.1413L14.1664 11.6667M13.3492 11.1413C13.5848 10.8011 13.7222 10.3922 13.7222 9.95239C13.7222 9.51259 13.5849 9.10379 13.3492 8.76366M11.5 7.80953C12.2713 7.80953 12.9508 8.18839 13.3492 8.76366M11.5 7.80953C10.7288 7.80953 10.0493 8.18839 9.65084 8.76366M11.5 7.80953V6.95239M13.3492 8.76366L14.1667 8.23813M9.65084 8.76366L8.83337 8.23813"
            stroke="#333333"
            strokeLinecap="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.33337 7.45239H4.66671" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M1.33337 12.1189H4.66671" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M1.33337 2.78564H12.6667" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M10.6667 12.5953V13.4524M10.6667 12.5953C9.89547 12.5953 9.21607 12.2165 8.81753 11.6413M10.6667 12.5953C11.4379 12.5953 12.1173 12.2165 12.5158 11.6413M8.81753 11.6413L8.00027 12.1667M8.81753 11.6413C8.58187 11.3011 8.44447 10.8922 8.44447 10.4524C8.44447 10.0126 8.5818 9.60379 8.81747 9.26366M12.5158 11.6413L13.3331 12.1667M12.5158 11.6413C12.7515 11.3011 12.8889 10.8922 12.8889 10.4524C12.8889 10.0126 12.7515 9.60379 12.5159 9.26366M10.6667 8.30953C11.4379 8.30953 12.1174 8.68839 12.5159 9.26366M10.6667 8.30953C9.8954 8.30953 9.21593 8.68839 8.81747 9.26366M10.6667 8.30953V7.45239M12.5159 9.26366L13.3333 8.73813M8.81747 9.26366L8 8.73813"
            stroke="#F5F5F5"
            strokeLinecap="round"
          />
        </svg>
      ),
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.post("https://www.myhood.ng/mobile_back_end/api/adverts.php", {
          data,
        });
        console.log(res);

        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const activeItem = menu.find((item) => item.path === location.pathname);

  const cards = [
    {
      id: 1,
      text: "Total Advert",
      figure: "87,890",
      backgroundColor: "#EDF0FF",
      icon: (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.58816 14.6834L6.69284 8.88101C7.07556 8.07558 7.66082 7.40944 8.33142 9.00705C8.95045 10.4818 9.87404 13.1823 10.4192 14.6865M5.54444 12.1921H9.43454" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M2.88711 4.95541C1.66671 6.05376 1.66671 7.82153 1.66671 11.3571C1.66671 14.8925 1.66671 16.6604 2.88711 17.7588C4.10749 18.857 6.07169 18.857 10.0001 18.857C13.9284 18.857 15.8926 18.857 17.113 17.7588C18.3334 16.6604 18.3334 14.8925 18.3334 11.3571C18.3334 7.82153 18.3334 6.05376 17.113 4.95541C15.8926 3.85706 13.9284 3.85706 10.0001 3.85706C6.07169 3.85706 4.10749 3.85706 2.88711 4.95541Z"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.4035 8.84607V11.3416M15.4035 11.3416V14.628M15.4035 11.3416H13.7216C13.5219 11.3416 13.3238 11.3788 13.137 11.4512C11.7255 11.9989 11.7255 14.034 13.137 14.5817C13.3238 14.6541 13.5219 14.6912 13.7216 14.6912H15.4035"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 2,
      text: "Active Advert",
      figure: "87,890",
      backgroundColor: "#F0FFF1",
      icon: (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.58816 14.6834L6.69284 8.88101C7.07556 8.07558 7.66082 7.40944 8.33142 9.00705C8.95045 10.4818 9.87404 13.1823 10.4192 14.6865M5.54444 12.1921H9.43454" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M2.88711 4.95541C1.66671 6.05376 1.66671 7.82153 1.66671 11.3571C1.66671 14.8925 1.66671 16.6604 2.88711 17.7588C4.10749 18.857 6.07169 18.857 10.0001 18.857C13.9284 18.857 15.8926 18.857 17.113 17.7588C18.3334 16.6604 18.3334 14.8925 18.3334 11.3571C18.3334 7.82153 18.3334 6.05376 17.113 4.95541C15.8926 3.85706 13.9284 3.85706 10.0001 3.85706C6.07169 3.85706 4.10749 3.85706 2.88711 4.95541Z"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.4035 8.84607V11.3416M15.4035 11.3416V14.628M15.4035 11.3416H13.7216C13.5219 11.3416 13.3238 11.3788 13.137 11.4512C11.7255 11.9989 11.7255 14.034 13.137 14.5817C13.3238 14.6541 13.5219 14.6912 13.7216 14.6912H15.4035"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 3,
      text: "Pending Approval",
      figure: "87,890",
      backgroundColor: "#FFF3E5",
      icon: (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.58816 14.6834L6.69284 8.88101C7.07556 8.07558 7.66082 7.40944 8.33142 9.00705C8.95045 10.4818 9.87404 13.1823 10.4192 14.6865M5.54444 12.1921H9.43454" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M2.88711 4.95541C1.66671 6.05376 1.66671 7.82153 1.66671 11.3571C1.66671 14.8925 1.66671 16.6604 2.88711 17.7588C4.10749 18.857 6.07169 18.857 10.0001 18.857C13.9284 18.857 15.8926 18.857 17.113 17.7588C18.3334 16.6604 18.3334 14.8925 18.3334 11.3571C18.3334 7.82153 18.3334 6.05376 17.113 4.95541C15.8926 3.85706 13.9284 3.85706 10.0001 3.85706C6.07169 3.85706 4.10749 3.85706 2.88711 4.95541Z"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.4035 8.84607V11.3416M15.4035 11.3416V14.628M15.4035 11.3416H13.7216C13.5219 11.3416 13.3238 11.3788 13.137 11.4512C11.7255 11.9989 11.7255 14.034 13.137 14.5817C13.3238 14.6541 13.5219 14.6912 13.7216 14.6912H15.4035"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Save logic, for example, update state or call an API to save changes
    console.log("Saving changes:", editedData);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setEditedData(initialData);
    setIsEditing(false);
    setEdit(false);
    setModal(false);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Remove the prefix
        setSelectedImage(base64String);
        // console.log("Base64 String:", base64String);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };
  const handleSub = async (event) => {
    event.preventDefault();

    console.log("Input 1:", inputValue);

    // e.preventDefault();

    const paylod = {
      title: inputValue.title,
      description: inputValue.description,
      image: inputValue.image,
      start_date: inputValue.start_date,
      contact_phone: inputValue.contact_phone,
      contact_email: inputValue.contact_email,
      user_id: inputValue.user_id,
      number_of_days: inputValue.number_of_days,
      actionid: "2",
    };

    // console.log(paylod);
    try {
      const response = await axios.post("https://www.myhood.ng/mobile_back_end/api/adverts.php", paylod);
      console.log(response);
      if (response.data.responsecode === "200") {
        // navigate("/login");
        setSave(true);
      } else {
        // display error
      }
      return response.data;
    } catch (error) {
      console.error("Error registering user:", error);
      // Rethrow the error for the caller to handle
    }
  };
  return (
    <>
      <div className="flex   justify-between items-start">
        <SideBarSuper mini={mini} setMini={setMini} menu={menu} />
        {/* LARGE SCREENS */}
        <div className={` 100:hidden xl:flex w-full pr-[25px] pb-[50px] flex flex-col justify-between gap-[22px] h-fit ${mini ? "pl-[130px]" : "pl-[320px]"}`}>
          <HeaderSuper mini={mini} name={activeItem ? activeItem.name : null} />
          {/* Button */}
          <div className="flex px-[40px] justify-end items-center">
            <button onClick={() => setnewAdvert(true)}>
              <div className="w-fit h-[33px] px-[20px] py-[7px] bg-[#FF5722] rounded text-white text-[14px] font-semibold font-monteserrat tracking-tight justify-center items-center  inline-flex">Add Advert</div>
            </button>
          </div>
          {/* Cards */}
          <div className=" py-[25px]  gap-[10px] flex justify-between items-center ">
            {cards.map((item) => (
              <div title={item.text} className="w-full h-[102px] px-5 py-[21px] rounded-[10px] shadow border border-neutral-200 justify-between items-center inline-flex">
                <div className="py-[5px] flex-col justify-start items-start gap-5 inline-flex">
                  <h4 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[15px] tracking-tight">{item.text}</h4>
                  <h3 className="text-zinc-800 text-xl font-normal font-['Montserrat'] leading-[15px] tracking-tight">{item.figure}</h3>
                </div>
                <div style={{ backgroundColor: item.backgroundColor }} className={`p-2.5 rounded-[100px] justify-center items-center flex `}>
                  {item.icon}
                </div>
              </div>
            ))}
          </div>
          {/* Table 1 */}
          <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <div className=" flex gap-[16px] justify-between items-center w-full">
                <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Live Adverts</h4>
                <div className=" rounded-[20px] border-[0.6px] border-solid h-[38px] px-[23px] py-[10px] border-[#9E9E9E] flex justify-between items-center gap-[10px] w-[321px]">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.125 13.125L16.5 16.5" stroke="#777777" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15 8.25C15 4.52208 11.978 1.5 8.25 1.5C4.52208 1.5 1.5 4.52208 1.5 8.25C1.5 11.978 4.52208 15 8.25 15C11.978 15 15 11.978 15 8.25Z" stroke="#777777" strokeWidth="1.125" strokeLinejoin="round" />
                  </svg>
                  <input
                    placeholder="Search advert"
                    className=" text-[16px] font-monteserrat font-medium text-[#333] placeholder:text-[14px] placeholder:font-monteserrat placeholder:font-normal w-full h-full bg-transparent outline-none border-none"
                    type="text"
                  />
                </div>
                <div className="w-[75px] h-[38px] px-[5px] bg-neutral-100 rounded-[10px] shadow justify-start items-center gap-[5px] inline-flex">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 11.5H14.5001" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.5 15H13.5" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.5 8H15.5" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>

                  <div className="text-neutral-500 text-sm font-normal font-['Montserrat'] tracking-tight">Filter</div>
                </div>
              </div>
            </section>

            <div className=" flex justify-between w-full items-center">
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Advertiser </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Category</h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Local Business and Services </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Local Business and Services </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Local Business and Services </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Local Business and Services </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Local Business and Services </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Start Date </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">End Date </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>
              </div>
            </div>
          </div>
          {/* Table 2 */}
          <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <div className=" flex gap-[16px] justify-between items-center w-full">
                <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Adverts History</h4>
              </div>
            </section>

            <div className=" flex justify-between w-full items-center">
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Advert Title </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Duration</h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">30 Days </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">30 Days </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">30 Days </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">30 Days </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">30 Days </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Performance </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      100%
                    </span>
                    Views{" "}
                  </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      100%
                    </span>
                    Views{" "}
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      100%
                    </span>
                    Views{" "}
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      100%
                    </span>
                    Views{" "}
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#E52C2C] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 10V2" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 7.5C9 7.5 7.1588 10 6.5 10C5.8412 10 4 7.5 4 7.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      20%
                    </span>
                    Views{" "}
                  </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Status </h2>
                </div>
                {/* {data.map((item) => ( */}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" : "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    )} */}
                    {/* {item.status} */} Expired
                  </h2>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" : "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    )} */}
                    {/* {item.status} */} Expired
                  </h2>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" : "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    )} */}
                    {/* {item.status} */} Expired
                  </h2>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" : "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    )} */}
                    {/* {item.status} */} Expired
                  </h2>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" : "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    )} */}
                    {/* {item.status} */} Expired
                  </h2>
                </div>
                {/* ))} */}
              </div>
              <div className="w-[150px] cursor-pointer h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Action </h2>
                </div>{" "}
                {/* {data.map((item) => ( */}
                <div onClick={() => setDropDrown(!dropDrown)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.8333 10C10.8333 9.53976 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16668 9.53976 9.16668 10C9.16668 10.4602 9.53977 10.8333 10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.00001 10C5.00001 9.53976 4.62691 9.16666 4.16668 9.16666C3.70644 9.16666 3.33334 9.53976 3.33334 10C3.33334 10.4602 3.70644 10.8333 4.16668 10.8333C4.62691 10.8333 5.00001 10.4602 5.00001 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.6667 10C16.6667 9.53976 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53976 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div onClick={() => setDropDrown(!dropDrown)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.8333 10C10.8333 9.53976 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16668 9.53976 9.16668 10C9.16668 10.4602 9.53977 10.8333 10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.00001 10C5.00001 9.53976 4.62691 9.16666 4.16668 9.16666C3.70644 9.16666 3.33334 9.53976 3.33334 10C3.33334 10.4602 3.70644 10.8333 4.16668 10.8333C4.62691 10.8333 5.00001 10.4602 5.00001 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.6667 10C16.6667 9.53976 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53976 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div onClick={() => setDropDrown(!dropDrown)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.8333 10C10.8333 9.53976 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16668 9.53976 9.16668 10C9.16668 10.4602 9.53977 10.8333 10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.00001 10C5.00001 9.53976 4.62691 9.16666 4.16668 9.16666C3.70644 9.16666 3.33334 9.53976 3.33334 10C3.33334 10.4602 3.70644 10.8333 4.16668 10.8333C4.62691 10.8333 5.00001 10.4602 5.00001 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.6667 10C16.6667 9.53976 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53976 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div onClick={() => setDropDrown(!dropDrown)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.8333 10C10.8333 9.53976 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16668 9.53976 9.16668 10C9.16668 10.4602 9.53977 10.8333 10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.00001 10C5.00001 9.53976 4.62691 9.16666 4.16668 9.16666C3.70644 9.16666 3.33334 9.53976 3.33334 10C3.33334 10.4602 3.70644 10.8333 4.16668 10.8333C4.62691 10.8333 5.00001 10.4602 5.00001 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.6667 10C16.6667 9.53976 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53976 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div onClick={() => setDropDrown(!dropDrown)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.8333 10C10.8333 9.53976 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16668 9.53976 9.16668 10C9.16668 10.4602 9.53977 10.8333 10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.00001 10C5.00001 9.53976 4.62691 9.16666 4.16668 9.16666C3.70644 9.16666 3.33334 9.53976 3.33334 10C3.33334 10.4602 3.70644 10.8333 4.16668 10.8333C4.62691 10.8333 5.00001 10.4602 5.00001 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.6667 10C16.6667 9.53976 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53976 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* ))} */}
              </div>{" "}
            </div>
          </div>
          <div className=" flex w-full gap-[10px] items-center">
            {/* Table 3 */}
            <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
              <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
                <div className=" flex gap-[16px] justify-between items-center w-full">
                  <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Adverts for Review</h4>
                </div>
              </section>

              <div className=" flex justify-between w-full items-center">
                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Advert Title </h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                  </div>
                </div>
                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Submission Date </h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>
                </div>

                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Status </h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2
                      className={`${
                        // item.status === "expired" ?
                        // "text-[#E52C2C] bg-[#FFECEC]"
                        //  : item.status === "active" ?
                        // "bg-[#E6FFE7] text-[#4CAF50]" :
                        "text-[#E37A00] border-b-neutral-200"
                      } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                    >
                      {/* {item.status === "expired" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : item.status === "active" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : ( */}
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* )} */}
                      {/* {item.status} */} Pending
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2
                      className={`${
                        // item.status === "expired" ?
                        // "text-[#E52C2C] bg-[#FFECEC]"
                        //  : item.status === "active" ?
                        // "bg-[#E6FFE7] text-[#4CAF50]" :
                        "text-[#E37A00] border-b-neutral-200"
                      } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                    >
                      {/* {item.status === "expired" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : item.status === "active" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : ( */}
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* )} */}
                      {/* {item.status} */} Pending
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2
                      className={`${
                        // item.status === "expired" ?
                        // "text-[#E52C2C] bg-[#FFECEC]"
                        //  : item.status === "active" ?
                        // "bg-[#E6FFE7] text-[#4CAF50]" :
                        "text-[#E37A00] border-b-neutral-200"
                      } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                    >
                      {/* {item.status === "expired" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : item.status === "active" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : ( */}
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* )} */}
                      {/* {item.status} */} Pending
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2
                      className={`${
                        // item.status === "expired" ?
                        // "text-[#E52C2C] bg-[#FFECEC]"
                        //  : item.status === "active" ?
                        // "bg-[#E6FFE7] text-[#4CAF50]" :
                        "text-[#E37A00] border-b-neutral-200"
                      } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                    >
                      {/* {item.status === "expired" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : item.status === "active" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : ( */}
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* )} */}
                      {/* {item.status} */} Pending
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2
                      className={`${
                        // item.status === "expired" ?
                        // "text-[#E52C2C] bg-[#FFECEC]"
                        //  : item.status === "active" ?
                        // "bg-[#E6FFE7] text-[#4CAF50]" :
                        "text-[#E37A00] border-b-neutral-200"
                      } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                    >
                      {/* {item.status === "expired" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : item.status === "active" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : ( */}
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                      {/* )} */}
                      {/* {item.status} */} Pending
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 4 */}
            <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
              <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
                <div className=" flex gap-[16px] justify-between items-center w-full">
                  <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Approved Adverts</h4>
                </div>
              </section>

              <div className=" flex justify-between w-full items-center">
                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Advert Title </h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                  </div>
                </div>
                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Approved Date </h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>
                </div>

                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Status </h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2
                      className={`${
                        // item.status === "expired" ?
                        // "text-[#E52C2C] bg-[#FFECEC]"
                        //  : item.status === "active" ?
                        "bg-[#E6FFE7] text-[#4CAF50]"
                        // :"text-[#E37A00] border-b-neutral-200"
                      } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                    >
                      {/* {item.status === "expired" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : item.status === "active" ? ( */}
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      {/* ) : ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg> */}
                      {/* )} */}
                      {/* {item.status} */} Active
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2
                      className={`${
                        // item.status === "expired" ?
                        // "text-[#E52C2C] bg-[#FFECEC]"
                        //  : item.status === "active" ?
                        "bg-[#E6FFE7] text-[#4CAF50]"
                        // :"text-[#E37A00] border-b-neutral-200"
                      } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                    >
                      {/* {item.status === "expired" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : item.status === "active" ? ( */}
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      {/* ) : ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg> */}
                      {/* )} */}
                      {/* {item.status} */} Active
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2
                      className={`${
                        // item.status === "expired" ?
                        // "text-[#E52C2C] bg-[#FFECEC]"
                        //  : item.status === "active" ?
                        "bg-[#E6FFE7] text-[#4CAF50]"
                        // :"text-[#E37A00] border-b-neutral-200"
                      } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                    >
                      {/* {item.status === "expired" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : item.status === "active" ? ( */}
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      {/* ) : ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg> */}
                      {/* )} */}
                      {/* {item.status} */} Active
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2
                      className={`${
                        // item.status === "expired" ?
                        // "text-[#E52C2C] bg-[#FFECEC]"
                        //  : item.status === "active" ?
                        "bg-[#E6FFE7] text-[#4CAF50]"
                        // :"text-[#E37A00] border-b-neutral-200"
                      } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                    >
                      {/* {item.status === "expired" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : item.status === "active" ? ( */}
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      {/* ) : ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg> */}
                      {/* )} */}
                      {/* {item.status} */} Active
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2
                      className={`${
                        // item.status === "expired" ?
                        // "text-[#E52C2C] bg-[#FFECEC]"
                        //  : item.status === "active" ?
                        "bg-[#E6FFE7] text-[#4CAF50]"
                        // :"text-[#E37A00] border-b-neutral-200"
                      } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                    >
                      {/* {item.status === "expired" ? ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                      {/* ) : item.status === "active" ? ( */}
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      {/* ) : ( */}
                      {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg> */}
                      {/* )} */}
                      {/* {item.status} */} Active
                    </h2>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SMALL SCREENS */}
        <div className=" 100:flex xl:hidden w-full pr-[25px] pb-[50px] flex-col justify-between gap-[22px] h-fit pl-[130px]">
          <HeaderSuper mini={mini} name={activeItem ? activeItem.name : null} />
          {/* Button */}
          <div className="flex px-[40px] justify-end items-center">
            <button onClick={() => setnewAdvert(true)}>
              <div className="w-fit h-[33px] px-[20px] py-[7px] bg-[#FF5722] rounded text-white text-[14px] font-semibold font-monteserrat tracking-tight justify-center items-center  inline-flex">Add Advert</div>
            </button>
          </div>
          {/* Cards */}
          <div className=" py-[25px]  gap-[10px] flex flex-col justify-between items-center ">
            {cards.map((item) => (
              <div title={item.text} className="w-full h-[102px] px-5 py-[21px] rounded-[10px] shadow border border-neutral-200 justify-between items-center inline-flex">
                <div className="py-[5px] flex-col justify-start items-start gap-5 inline-flex">
                  <h4 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[15px] tracking-tight">{item.text}</h4>
                  <h3 className="text-zinc-800 text-xl font-normal font-['Montserrat'] leading-[15px] tracking-tight">{item.figure}</h3>
                </div>
                <div style={{ backgroundColor: item.backgroundColor }} className={`p-2.5 rounded-[100px] justify-center items-center flex `}>
                  {item.icon}
                </div>
              </div>
            ))}
          </div>
          {/* Table 1 */}
          <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <div className=" flex gap-[16px] justify-between items-center w-full">
                {inputField ? null : <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Live Adverts</h4>}
                <div className={`rounded-[20px]  h-[38px] px-[10px] py-[10px]  flex justify-between items-center gap-[10px] ${inputField ? "w-full border-[0.6px] border-solid border-[#9E9E9E]" : "w-fit"} `}>
                  <svg onClick={() => setInputField(!inputField)} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.125 13.125L16.5 16.5" stroke="#777777" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M15 8.25C15 4.52208 11.978 1.5 8.25 1.5C4.52208 1.5 1.5 4.52208 1.5 8.25C1.5 11.978 4.52208 15 8.25 15C11.978 15 15 11.978 15 8.25Z" stroke="#777777" stroke-width="1.125" stroke-linejoin="round" />
                  </svg>
                  {inputField && (
                    <input
                      placeholder="Search advert"
                      className=" text-[16px] font-monteserrat font-medium text-[#333] placeholder:text-[14px] placeholder:font-monteserrat placeholder:font-normal w-full h-full bg-transparent outline-none border-none"
                      type="text"
                    />
                  )}
                </div>
                <div className="w-fit h-[38px] px-[10px] bg-neutral-100 rounded-[10px] shadow justify-start items-center gap-[5px] inline-flex">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 11.5H14.5001" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9.5 15H13.5" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M7.5 8H15.5" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            </section>
            {/* Table Starts here  */}
            <div className=" overflow-x-scroll whitespace-nowrap flex justify-between w-full items-center">
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Advertiser </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Category</h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Local Business and Services </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Local Business and Services </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Local Business and Services </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Local Business and Services </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Local Business and Services </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Start Date </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">End Date </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>
              </div>
            </div>
          </div>
          {/* Table 2 */}
          <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <div className=" flex gap-[16px] justify-between items-center w-full">
                <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Adverts History</h4>
              </div>
            </section>
            {/* Table Starts here  */}
            <div className=" overflow-x-scroll whitespace-nowrap flex justify-between w-full items-center">
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Advert Title </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Duration</h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">30 Days </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">30 Days </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">30 Days </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">30 Days </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">30 Days </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Performance </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      100%
                    </span>
                    Views{" "}
                  </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      100%
                    </span>
                    Views{" "}
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      100%
                    </span>
                    Views{" "}
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      100%
                    </span>
                    Views{" "}
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#E52C2C] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 10V2" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 7.5C9 7.5 7.1588 10 6.5 10C5.8412 10 4 7.5 4 7.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      20%
                    </span>
                    Views{" "}
                  </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Status </h2>
                </div>
                {/* {data.map((item) => ( */}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" : "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    )} */}
                    {/* {item.status} */} Expired
                  </h2>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" : "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    )} */}
                    {/* {item.status} */} Expired
                  </h2>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" : "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    )} */}
                    {/* {item.status} */} Expired
                  </h2>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" : "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    )} */}
                    {/* {item.status} */} Expired
                  </h2>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" : "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    )} */}
                    {/* {item.status} */} Expired
                  </h2>
                </div>
                {/* ))} */}
              </div>
              <div className="w-[150px] cursor-pointer h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Action </h2>
                </div>{" "}
                {/* {data.map((item) => ( */}
                <div onClick={() => setDropDrown(!dropDrown)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.8333 10C10.8333 9.53976 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16668 9.53976 9.16668 10C9.16668 10.4602 9.53977 10.8333 10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.00001 10C5.00001 9.53976 4.62691 9.16666 4.16668 9.16666C3.70644 9.16666 3.33334 9.53976 3.33334 10C3.33334 10.4602 3.70644 10.8333 4.16668 10.8333C4.62691 10.8333 5.00001 10.4602 5.00001 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.6667 10C16.6667 9.53976 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53976 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div onClick={() => setDropDrown(!dropDrown)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.8333 10C10.8333 9.53976 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16668 9.53976 9.16668 10C9.16668 10.4602 9.53977 10.8333 10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.00001 10C5.00001 9.53976 4.62691 9.16666 4.16668 9.16666C3.70644 9.16666 3.33334 9.53976 3.33334 10C3.33334 10.4602 3.70644 10.8333 4.16668 10.8333C4.62691 10.8333 5.00001 10.4602 5.00001 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.6667 10C16.6667 9.53976 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53976 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div onClick={() => setDropDrown(!dropDrown)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.8333 10C10.8333 9.53976 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16668 9.53976 9.16668 10C9.16668 10.4602 9.53977 10.8333 10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.00001 10C5.00001 9.53976 4.62691 9.16666 4.16668 9.16666C3.70644 9.16666 3.33334 9.53976 3.33334 10C3.33334 10.4602 3.70644 10.8333 4.16668 10.8333C4.62691 10.8333 5.00001 10.4602 5.00001 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.6667 10C16.6667 9.53976 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53976 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div onClick={() => setDropDrown(!dropDrown)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.8333 10C10.8333 9.53976 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16668 9.53976 9.16668 10C9.16668 10.4602 9.53977 10.8333 10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.00001 10C5.00001 9.53976 4.62691 9.16666 4.16668 9.16666C3.70644 9.16666 3.33334 9.53976 3.33334 10C3.33334 10.4602 3.70644 10.8333 4.16668 10.8333C4.62691 10.8333 5.00001 10.4602 5.00001 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.6667 10C16.6667 9.53976 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53976 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* ))} */} {/* {data.map((item) => ( */}
                <div onClick={() => setDropDrown(!dropDrown)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.8333 10C10.8333 9.53976 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16668 9.53976 9.16668 10C9.16668 10.4602 9.53977 10.8333 10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.00001 10C5.00001 9.53976 4.62691 9.16666 4.16668 9.16666C3.70644 9.16666 3.33334 9.53976 3.33334 10C3.33334 10.4602 3.70644 10.8333 4.16668 10.8333C4.62691 10.8333 5.00001 10.4602 5.00001 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.6667 10C16.6667 9.53976 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53976 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10Z"
                      stroke="#9E9E9E"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {/* ))} */}
              </div>{" "}
            </div>
          </div>
          {/* Table 3 */}
          <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <div className=" flex gap-[16px] justify-between items-center w-full">
                <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Adverts for Review</h4>
              </div>
            </section>
            {/* Table Starts here  */}
            <div className=" overflow-x-scroll whitespace-nowrap flex justify-between w-full items-center">
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Advert Title </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Submission Date </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>
              </div>

              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Status </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      // "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" :
                      "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1356_3235)">
                        <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                        <path
                          d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                          stroke="#E37A00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1356_3235">
                          <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                        </clipPath>
                      </defs>
                    </svg>
                    {/* )} */}
                    {/* {item.status} */} Pending
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      // "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" :
                      "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1356_3235)">
                        <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                        <path
                          d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                          stroke="#E37A00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1356_3235">
                          <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                        </clipPath>
                      </defs>
                    </svg>
                    {/* )} */}
                    {/* {item.status} */} Pending
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      // "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" :
                      "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1356_3235)">
                        <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                        <path
                          d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                          stroke="#E37A00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1356_3235">
                          <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                        </clipPath>
                      </defs>
                    </svg>
                    {/* )} */}
                    {/* {item.status} */} Pending
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      // "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" :
                      "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1356_3235)">
                        <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                        <path
                          d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                          stroke="#E37A00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1356_3235">
                          <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                        </clipPath>
                      </defs>
                    </svg>
                    {/* )} */}
                    {/* {item.status} */} Pending
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      // "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      // "bg-[#E6FFE7] text-[#4CAF50]" :
                      "text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : item.status === "active" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1356_3235)">
                        <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                        <path
                          d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                          stroke="#E37A00"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1356_3235">
                          <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                        </clipPath>
                      </defs>
                    </svg>
                    {/* )} */}
                    {/* {item.status} */} Pending
                  </h2>
                </div>
              </div>
            </div>
          </div>
          {/* Table 4 */}
          <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <div className=" flex gap-[16px] justify-between items-center w-full">
                <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Approved Adverts</h4>
              </div>
            </section>
            {/* Table Starts here  */}
            <div className=" overflow-x-scroll whitespace-nowrap flex justify-between w-full items-center">
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Advert Title </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Approved Date </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>
              </div>

              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Status </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      // "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      "bg-[#E6FFE7] text-[#4CAF50]"
                      // :"text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : item.status === "active" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg> */}
                    {/* )} */}
                    {/* {item.status} */} Active
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      // "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      "bg-[#E6FFE7] text-[#4CAF50]"
                      // :"text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : item.status === "active" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg> */}
                    {/* )} */}
                    {/* {item.status} */} Active
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      // "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      "bg-[#E6FFE7] text-[#4CAF50]"
                      // :"text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : item.status === "active" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg> */}
                    {/* )} */}
                    {/* {item.status} */} Active
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      // "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      "bg-[#E6FFE7] text-[#4CAF50]"
                      // :"text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : item.status === "active" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg> */}
                    {/* )} */}
                    {/* {item.status} */} Active
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2
                    className={`${
                      // item.status === "expired" ?
                      // "text-[#E52C2C] bg-[#FFECEC]"
                      //  : item.status === "active" ?
                      "bg-[#E6FFE7] text-[#4CAF50]"
                      // :"text-[#E37A00] border-b-neutral-200"
                    } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                  >
                    {/* {item.status === "expired" ? ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                    {/* ) : item.status === "active" ? ( */}
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    {/* ) : ( */}
                    {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg> */}
                    {/* )} */}
                    {/* {item.status} */} Active
                  </h2>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer setPage={setPage} page={page} />
      {dropDrown && (
        <>
          <div className="w-fit right-[40px] absolute 100:top-[1290px] xl:top-[1060px] h-fit px-[11px] py-[13px] bg-white rounded-[10px] shadow flex-col justify-start items-center gap-2.5 inline-flex">
            <div
              onClick={() => {
                setModal(true);
                setView(false);
                setEdit(true);
                setIsDelete(false);
                setDropDrown(false);
                setSuccessful(false);
              }}
              className="self-stretch hover:bg-[#E7E7E7] rounded-[10px] cursor-pointer px-5 py-[9px] border-b border-neutral-200 justify-start items-center gap-2.5 inline-flex"
            >
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.87481 3.98964L9.69247 3.17195C10.1441 2.72035 10.8763 2.72035 11.3279 3.17195C11.7795 3.62355 11.7795 4.35574 11.3279 4.80734L10.5102 5.62503M8.87481 3.98964L4.07172 8.79276C3.46196 9.40252 3.15707 9.70737 2.94947 10.0789C2.74186 10.4504 2.53299 11.3277 2.33325 12.1666C3.17214 11.9669 4.04941 11.758 4.42094 11.5504C4.79246 11.3427 5.09734 11.0379 5.70711 10.4281L10.5102 5.62503M8.87481 3.98964L10.5102 5.62503"
                  stroke="#333333"
                  strokeWidth="0.875"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M6.41675 12.1667H9.91675" stroke="#333333" strokeWidth="0.875" strokeLinecap="round" />
              </svg>

              <div className="text-zinc-800 text-xs font-normal font-['Montserrat'] tracking-wide">Edit</div>
            </div>
            <div
              onClick={() => {
                setModal(true);
                setView(true);
                setEdit(false);
                setIsDelete(false);
                setDropDrown(false);
                setSuccessful(false);
              }}
              className="self-stretch hover:bg-[#E7E7E7] rounded-[10px] cursor-pointer px-5 py-[9px] border-b border-neutral-200 justify-start items-center gap-2.5 inline-flex"
            >
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.5674 6.94291C12.7447 7.19158 12.8334 7.31595 12.8334 7.49999C12.8334 7.68403 12.7447 7.8084 12.5674 8.05707C11.7705 9.17451 9.73545 11.5833 7.00008 11.5833C4.2647 11.5833 2.22964 9.17451 1.43277 8.05707C1.25542 7.8084 1.16675 7.68403 1.16675 7.49999C1.16675 7.31595 1.25542 7.19158 1.43277 6.94291C2.22964 5.8255 4.2647 3.41666 7.00008 3.41666C9.73545 3.41666 11.7705 5.8255 12.5674 6.94291Z"
                  stroke="#333333"
                  strokeWidth="0.875"
                />
                <path d="M8.75 7.5C8.75 6.53347 7.96652 5.75 7 5.75C6.03347 5.75 5.25 6.53347 5.25 7.5C5.25 8.46652 6.03347 9.25 7 9.25C7.96652 9.25 8.75 8.46652 8.75 7.5Z" stroke="#333333" strokeWidth="0.875" />
              </svg>

              <div className="text-zinc-800 text-xs font-normal font-['Montserrat'] tracking-wide">View</div>
            </div>
            <div
              onClick={() => {
                setModal(true);
                setView(false);
                setEdit(false);
                setIsDelete(true);
                setDropDrown(false);
                setSuccessful(false);
              }}
              className="self-stretch hover:bg-[#E7E7E7] rounded-[10px] cursor-pointer px-5 py-[9px] border-b border-neutral-200 justify-start items-center gap-2.5 inline-flex"
            >
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M11.375 3.70825L11.0135 9.55623C10.9211 11.0503 10.875 11.7974 10.5005 12.3345C10.3153 12.6001 10.0769 12.8242 9.80041 12.9926C9.24122 13.3333 8.49275 13.3333 6.99574 13.3333C5.49682 13.3333 4.74734 13.3333 4.18778 12.9919C3.91113 12.8232 3.67267 12.5987 3.48756 12.3327C3.11318 11.7948 3.06801 11.0466 2.97769 9.55045L2.625 3.70825"
                  stroke="#333333"
                  strokeWidth="0.875"
                  strokeLinecap="round"
                />
                <path
                  d="M1.75 3.70841H12.25M9.36582 3.70841L8.96764 2.88692C8.7031 2.34123 8.5708 2.06838 8.34266 1.89822C8.29208 1.86047 8.23848 1.8269 8.18242 1.79782C7.92978 1.66675 7.62656 1.66675 7.02012 1.66675C6.39847 1.66675 6.08767 1.66675 5.83081 1.80332C5.77389 1.83359 5.71957 1.86852 5.66842 1.90776C5.43762 2.08482 5.3087 2.36765 5.05086 2.93332L4.69754 3.70841"
                  stroke="#333333"
                  strokeWidth="0.875"
                  strokeLinecap="round"
                />
                <path d="M5.54175 10.125V6.625" stroke="#333333" strokeWidth="0.875" strokeLinecap="round" />
                <path d="M8.45825 10.125V6.625" stroke="#333333" strokeWidth="0.875" strokeLinecap="round" />
              </svg>

              <div className="text-zinc-800 text-xs font-normal font-['Montserrat'] tracking-wide">Delete</div>
            </div>
          </div>
        </>
      )}
      {modal && (
        <>
          <div className=" w-full bg-black bg-opacity-40 flex justify-center items-center h-screen fixed top-0 left-0">
            <div className=" w-fit  p-[10px]  h-fit gap-[10px] items-start flex flex-col rounded-[20px] bg-[#F5F5F5] ">
              <div className=" w-full bg-[#FFE2D9] h-[80px] px-[30px] rounded-[20px] flex justify-between items-center ">
                <h3 className=" font-openSans tracking-[-0.4px] text-[#333] font-semibold text-[20px]">
                  {view && <>History</>}
                  {edit && <>Advert</>}
                </h3>
                <div onClick={() => setModal(false)} className=" bg-[#B8A9A9] rounded-full cursor-pointer w-fit p-[10px]">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 4.16675L4.1665 15.8334M4.1665 4.16675L15.8332 15.8334" stroke="#F5F5F5" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              {edit && (
                <>
                  <div className="w-full h-full px-[30px] py-[40px] bg-white rounded-[20px] flex-col justify-center items-center gap-5 inline-flex">
                    <form className="sm:w-[527px] 100:w-full h-fit flex-col justify-start items-start gap-[20px] inline-flex" onSubmit={handleFormSubmit}>
                      <div className="self-stretch h-[61px] flex-col justify-start items-start gap-[8px] flex">
                        <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Advert Title</h3>
                        <input
                          value={editedData.category}
                          onChange={handleInputChange}
                          placeholder="Enter Advert Title"
                          type="text"
                          name="category"
                          className=" w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-['Montserrat'] tracking-tight rounded border-[0.5px] border-neutral-500 "
                        />
                      </div>
                      <div className="self-stretch h-[61px] flex-col justify-start items-start gap-[8px] flex">
                        <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Advert Duration</h3>

                        <input
                          value={editedData.duration}
                          onChange={handleInputChange}
                          placeholder="Select Advert Duration"
                          type="text"
                          name="duration"
                          className=" w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-['Montserrat'] tracking-tight rounded border-[0.5px] border-neutral-500 "
                        />
                      </div>
                      <div className="self-stretch h-[61px] flex-col justify-start items-start gap-[8px] flex">
                        <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Advert Status</h3>
                        <input
                          value={editedData.status}
                          onChange={handleInputChange}
                          placeholder="Select Advert Status"
                          type="text"
                          name="status"
                          className=" w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-['Montserrat'] tracking-tight rounded border-[0.5px] border-neutral-500 "
                        />
                      </div>
                      <div className="sm:w-[527px] 100:w-full 100:h-fit sm:h-[126px] 100:flex-col 100:justify-center 100:item-center sm:flex-row sm:justify-start sm:items-start gap-5 flex">
                        <img alt="" className="w-16 h-16  rounded-[200px]" name="image" value={editedData.image} onChange={handleInputChange} src={selectedImage ? URL.createObjectURL(selectedImage) : editedData.image} />
                        <InputFiles style={{ width: "100%" }} onChange={handleFileChange}>
                          <div className="self-stretch w-full h-[126px] px-6 py-4 rounded-lg border-[0.5px] border-neutral-500 flex-col justify-start items-center gap-1 flex grow shrink basis-0 bg-zinc-100 ">
                            <div className="self-stretch h-[94px] flex-col justify-center items-center gap-3 flex">
                              <div className="w-10 h-10 p-2.5 bg-gray-100 rounded-[28px] border-4 border-gray-50 justify-center items-center inline-flex">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1694_1007)">
                                    <path
                                      d="M13.3335 13.8332L10.0002 10.4999M10.0002 10.4999L6.66688 13.8332M10.0002 10.4999V17.9999M16.9919 15.8249C17.8047 15.3818 18.4467 14.6806 18.8168 13.8321C19.1868 12.9835 19.2637 12.0359 19.0354 11.1388C18.807 10.2417 18.2865 9.44616 17.5558 8.87778C16.8251 8.30939 15.9259 8.00052 15.0002 7.9999H13.9502C13.698 7.02427 13.2278 6.11852 12.5752 5.35073C11.9225 4.58295 11.1042 3.97311 10.182 3.56708C9.25967 3.16104 8.25734 2.96937 7.25031 3.00647C6.24328 3.04358 5.25777 3.30849 4.36786 3.78129C3.47795 4.2541 2.7068 4.92249 2.1124 5.73622C1.51799 6.54996 1.11579 7.48785 0.936028 8.4794C0.756269 9.47095 0.803632 10.4903 1.07456 11.461C1.34548 12.4316 1.83291 13.3281 2.50021 14.0832"
                                      stroke="#777777"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1694_1007">
                                      <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="self-stretch justify-center items-start gap-1 flex-col flex">
                                <div className="justify-center w-full flex-col items-center gap-2 flex">
                                  <div className="text-orange-600 text-sm font-normal font-['Open Sans'] leading-tight">
                                    Click to upload <span className="text-neutral-500 text-sm font-normal font-['Open Sans'] leading-tight">or drag and drop</span>
                                  </div>
                                  <div className="self-stretch text-center text-neutral-500 text-xs font-normal font-['Open Sans'] leading-[18px]">SVG, PNG, JPG or GIF (max. 800x400px)</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </InputFiles>
                      </div>
                      <div className="flex 100:flex-col 100:gap-[10px] md:gap-0 md:flex-row w-full p-[10px] justify-between items-center">
                        <button onClick={handleCancelClick} className="w-[180px] h-[38px]  rounded border border-orange-600 text-center text-zinc-800 text-base font-semibold font-['Open Sans'] leading-normal tracking-wide" type="button">
                          Cancel
                        </button>
                        <button
                          onClick={() => {
                            setSuccessful(true);
                            setEdit(false);
                            setIsDelete(false);
                            setView(false);
                          }}
                          className="w-[180px] h-[38px]  rounded  bg-orange-600 text-center text-white text-base font-semibold font-['Open Sans'] leading-normal tracking-wide"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              )}{" "}
              {successful && (
                <>
                  <div className="w-[500px] h-[226px] p-[30px]  bg-white rounded-[20px] justify-center items-center flex">
                    <div className="flex gap-[40px] flex-col justify-between items-center">
                      <div className="w-20 h-20 p-5 bg-emerald-100 rounded-[100px] border-8 border-green-50 justify-center items-center gap-2.5 inline-flex">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.33325 23.333L14.1666 29.1663L31.6666 10.833" stroke="#4CAF50" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                      <div className="w-[222px] text-center text-zinc-800 text-sm font-semibold font-openSans leading-[23px] tracking-tight">Your advert details has been updated successfully</div>
                    </div>
                  </div>
                </>
              )}
              {view && (
                <>
                  <div className=" w-full h-full xl:flex-row 100:flex-col flex justify-between items-center gap-[10px] ">
                    <img className="w-full h-[230px]" src={footware} alt="footware" />
                    <div className="w-full h-full px-[30px] py-[40px] bg-white rounded-[20px] flex-col justify-center items-center gap-5 inline-flex">
                      <div className="justify-between md:flex-row 100:flex-col items-start 100:gap-[20px] md:gap-[70px] whitespace-nowrap flex">
                        <div className="p-2.5 flex-col justify-start items-start gap-[30px] flex">
                          <div className="pt-px flex-col h-[50px] justify-center items-start gap-[7px] inline-flex">
                            <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Title:</div>
                            <div className="text-zinc-800 text-sm font-semibold font-['Open Sans']">{/* {moment(reciept.date_created).format("Do MMMM YYYY")} */} Sneaker Ride</div>
                          </div>
                          <div className="pt-px flex-col h-[50px] justify-center items-start gap-[7px] inline-flex">
                            <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Perfomance:</div>
                            <div className="text-zinc-800 text-sm font-semibold font-['Open Sans']">
                              {/* {moment(reciept.date_created).format("h:mm:ss a")} */}
                              <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                                {" "}
                                <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                                  </svg>
                                  100%
                                </span>
                                Views{" "}
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="p-2.5 flex-col justify-start items-start gap-[30px] flex">
                          <div className=" pt-px flex-col h-[50px] justify-center items-start gap-[7px] inline-flex">
                            <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Duration:</div>
                            <div className="text-zinc-800 text-sm font-semibold font-['Open Sans']">90 Days{/* {formattedAmount} */}</div>
                          </div>
                          <div className="pt-px flex-col h-[50px] justify-center items-start gap-[7px] inline-flex">
                            <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Status:</div>
                            <div className="text-zinc-800 text-sm font-semibold font-['Open Sans']">
                              {/* {reciept.id} */}{" "}
                              <h2
                                className={`${
                                  // item.status === "expired" ?
                                  "text-[#E52C2C] bg-[#FFECEC]"
                                  //  : item.status === "active" ?
                                  // "bg-[#E6FFE7] text-[#4CAF50]" : "text-[#E37A00] border-b-neutral-200"
                                } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                              >
                                {/* {item.status === "expired" ? ( */}
                                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                {/* ) : item.status === "active" ? ( */}
                                {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg> */}
                                {/* ) : ( */}
                                {/* <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1356_3235)">
                          <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" strokeLinecap="round" strokeLinejoin="round" />
                          <path
                            d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                            stroke="#E37A00"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1356_3235">
                            <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                          </clipPath>
                        </defs>
                      </svg>
                    )} */}
                                {/* {item.status} */} Expired
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {receiptContent} */}
                  </div>
                </>
              )}
              {isdelete && (
                <>
                  <div className="w-[500px] h-[226px] p-[30px]  bg-white rounded-[20px] justify-center items-center flex">
                    <div className="flex gap-[40px] flex-col justify-between items-center">
                      <div className="w-20 h-20 p-5 bg-[#FFCCCC] rounded-[100px] border-8 border-[#FFE4E4] justify-center items-center gap-2.5 inline-flex">
                        <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M31.6663 8.83325L8.33301 32.1666M8.33301 8.83325L31.6663 32.1666" stroke="#E52C2C" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                      <div className="w-[222px] text-center text-[#777] text-sm font-semibold font-openSans leading-[23px] tracking-tight">Are you sure you want to delete this advert details</div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      {newAdvert && (
        <>
          <div className=" w-full bg-black bg-opacity-40 flex justify-center items-center h-screen fixed top-0 left-0">
            <div className=" md:w-fit 100:w-[85%] overflow-y-scroll  p-[10px]  h-[400px] gap-[10px] items-start flex flex-col rounded-[20px] bg-[#F5F5F5] ">
              <div className=" w-full bg-[#FFE2D9] h-[80px] py-[20px] px-[30px] rounded-[20px] flex justify-between items-center ">
                {save ? <div></div> : <h3 className=" font-openSans tracking-[-0.4px] text-[#333] font-semibold text-[20px]">Add Advert</h3>}
                <div onClick={() => setnewAdvert(false)} className=" bg-[#B8A9A9] rounded-full  cursor-pointer w-fit p-[10px]">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 4.16675L4.1665 15.8334M4.1665 4.16675L15.8332 15.8334" stroke="#F5F5F5" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>

              <div className="md:w-[728px] 100:w-full h-fit px-[30px] py-[30px] bg-white rounded-[20px] flex-col justify-between items-center gap-[40px] inline-flex">
                {save ? (
                  <>
                    <div className="flex gap-[40px] flex-col justify-between items-center">
                      <div className="w-20 h-20 p-5 bg-emerald-100 rounded-[100px] border-8 border-green-50 justify-center items-center gap-2.5 inline-flex">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.33325 23.333L14.1666 29.1663L31.6666 10.833" stroke="#4CAF50" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                      <div className=" text-center text-zinc-800 text-sm font-semibold font-openSans leading-[23px] tracking-tight">New advert added successfully</div>
                    </div>
                  </>
                ) : (
                  <>
                    <form className="sm:w-[527px] 100:w-full h-fit flex-col justify-start items-start gap-[20px] inline-flex">
                      <div className="self-stretch h-[61px] flex-col justify-start items-start gap-[8px] flex">
                        <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Advert Title</h3>

                        <div className="w-full h-[37px] flex justify-between items-center pr-[15px]  bg-[#eee] border-[0.7px] border-[#777] rounded-[4px] ">
                          <input
                            onChange={handleChange}
                            type="text"
                            name="title"
                            value={inputValue.title}
                            className="w-full pl-[15px] h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                            placeholder="Enter Advert Title"
                          />
                        </div>
                      </div>
                      <div className="self-stretch h-[61px] flex-col justify-start items-start gap-[8px] flex">
                        <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">User ID</h3>

                        <div className="w-full h-[37px] flex justify-between items-center pr-[15px]  bg-[#eee] border-[0.7px] border-[#777] rounded-[4px] ">
                          <input
                            onChange={handleChange}
                            type="text"
                            name="user_id"
                            value={inputValue.user_id}
                            className="w-full pl-[15px] h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                            placeholder="Enter User ID"
                          />
                        </div>
                      </div>
                      <div className="self-stretch  flex-col justify-start items-start gap-[8px] flex">
                        <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Description</h3>

                        <div className="w-full h-fit flex justify-between items-center px-[15px]  bg-[#eee] border-[0.7px] border-[#777] rounded-[4px] ">
                          <textarea
                            onChange={handleChange}
                            // type="text"
                            name="description"
                            value={inputValue.description}
                            className="w-full  h-[87px]  pt-[5px] text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                            placeholder="Enter Description"
                          />
                        </div>
                      </div>
                      <div className="self-stretch h-[61px] flex-col justify-start items-start gap-[8px] flex">
                        <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Contact Phone Number</h3>

                        <div className="w-full h-[37px] flex justify-between items-center pr-[15px]  bg-[#eee] border-[0.7px] border-[#777] rounded-[4px] ">
                          <input
                            onChange={handleChange}
                            type="number"
                            name="contact_phone"
                            value={inputValue.contact_phone}
                            className="w-full  h-full pl-[15px] text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                            placeholder="Enter Phone Number"
                          />
                        </div>
                      </div>
                      <div className="self-stretch h-[61px] flex-col justify-start items-start gap-[8px] flex">
                        <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Contact Email</h3>

                        <div className="w-full h-[37px] flex justify-between items-center pr-[15px]  bg-[#eee] border-[0.7px] border-[#777] rounded-[4px] ">
                          <input
                            onChange={handleChange}
                            type="email"
                            name="contact_email"
                            value={inputValue.contact_email}
                            className="w-full  h-full pl-[15px] text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                            placeholder="Enter Email Address"
                          />
                        </div>
                      </div>
                      <div className="self-stretch h-[61px] flex-col justify-start items-start gap-[8px] flex">
                        <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Start Date</h3>

                        <div className="w-full h-[37px] flex justify-between items-center pr-[15px]  bg-[#eee] border-[0.7px] border-[#777] rounded-[4px] ">
                          <input
                            onChange={handleChange}
                            type="date"
                            name="start_date"
                            value={inputValue.start_date}
                            className="w-full  h-full pl-[15px] text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                            placeholder="Enter Start Date"
                          />
                        </div>
                      </div>
                      {/* <div className="sm:w-[527px] 100:w-full 100:h-fit sm:h-[126px] 100:flex-col 100:justify-center 100:item-center sm:flex-row sm:justify-start sm:items-start gap-5 flex">
                        <img alt="" className="w-16 h-16  rounded-[200px]" name="image" value={inputValue.image} onChange={handleInputChange} src={selectedImage ? URL.createObjectURL(selectedImage) : inputValue.image} />
                        <div className="file-input-container w-full">
                          <input onChange={handleFileChange} type="file" id="file-input" className="hidden" />
                          <label htmlFor="file-input" className=" w-full h-[126px] px-6 py-4 rounded-lg border-[0.5px] border-neutral-500 flex-col justify-start items-center gap-1 flex grow shrink basis-0 bg-zinc-100 ">
                            <div className="self-stretch h-[94px] flex-col justify-center items-center gap-3 flex">
                              <div className="w-10 h-10 p-2.5 bg-gray-100 rounded-[28px] border-4 border-gray-50 justify-center items-center inline-flex">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1694_1007)">
                                    <path
                                      d="M13.3335 13.8332L10.0002 10.4999M10.0002 10.4999L6.66688 13.8332M10.0002 10.4999V17.9999M16.9919 15.8249C17.8047 15.3818 18.4467 14.6806 18.8168 13.8321C19.1868 12.9835 19.2637 12.0359 19.0354 11.1388C18.807 10.2417 18.2865 9.44616 17.5558 8.87778C16.8251 8.30939 15.9259 8.00052 15.0002 7.9999H13.9502C13.698 7.02427 13.2278 6.11852 12.5752 5.35073C11.9225 4.58295 11.1042 3.97311 10.182 3.56708C9.25967 3.16104 8.25734 2.96937 7.25031 3.00647C6.24328 3.04358 5.25777 3.30849 4.36786 3.78129C3.47795 4.2541 2.7068 4.92249 2.1124 5.73622C1.51799 6.54996 1.11579 7.48785 0.936028 8.4794C0.756269 9.47095 0.803632 10.4903 1.07456 11.461C1.34548 12.4316 1.83291 13.3281 2.50021 14.0832"
                                      stroke="#777777"
                                      strokeWidth="1.66667"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1694_1007">
                                      <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="self-stretch justify-center items-start gap-1 flex-col flex">
                                <div className="justify-center w-full flex-col items-center gap-2 flex">
                                  <div className="text-orange-600 text-sm font-normal font-['Open Sans'] leading-tight">
                                    Click to upload <span className="text-neutral-500 text-sm font-normal font-['Open Sans'] leading-tight">or drag and drop</span>
                                  </div>
                                  <div className="self-stretch text-center text-neutral-500 text-xs font-normal font-['Open Sans'] leading-[18px]">SVG, PNG, JPG or GIF (max. 800x400px)</div>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div> */}
                      <div className="self-stretch h-[61px] flex-col justify-start items-start gap-[8px] flex">
                        <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Number of Days</h3>

                        <div className="w-full h-[37px] flex justify-between items-center pr-[15px]  bg-[#eee] border-[0.7px] border-[#777] rounded-[4px] ">
                          <input
                            onChange={handleChange}
                            type="number"
                            name="number_of_days"
                            value={inputValue.number_of_days}
                            className="w-full  h-full pl-[15px] text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                            placeholder="Enter Number of Days"
                          />
                        </div>
                      </div>
                    </form>
                    <div className="flex w-full py-[10px] justify-center items-center">
                      <button
                        onClick={(e) => {
                          handleSub(e);
                          // handleSubmitPassword(e);
                          setSave(true);
                        }}
                        type="submit"
                      >
                        <div className="w-fit h-[33px] px-[57px] py-[7px] bg-[#FF5722] rounded text-white text-[14px] font-semibold font-monteserrat tracking-tight justify-center items-center  inline-flex">Confrim</div>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AdvertManagementSuper;
