import { useState } from "react";
import { useLocation } from "react-router-dom";
// import SideBar from "./SideBarExco";
import Header from "./HeaderExco";
import ProfileManagement from "./SubSettings/ProfileManagement";
import AccountManagement from "./SubSettings/AccountManagement";
import Notification from "./SubSettings/Notification";
import Security from "./SubSettings/Security";
import SideBarExco from "./SideBarExco";

function SettingExco() {
  const [mini, setMini] = useState(false);
  const [profileManagement, setProfileManagement] = useState(true);
  const [accountManagement, setAccountManagement] = useState(false);
  const [notification, setNotification] = useState(false);
  const [security, setSecurity] = useState(false);
  const [modal, setModal] = useState(false);
  const [editSecurity, setEditSecurity] = useState(false);
  const location = useLocation();
  const menu = [
    {
      id: 1,
      path: "/dashboardexco",
      name: "Dashboard",
      title: "Dashboard",
      icon1: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83334 3.17332 9.83334 4.73812C9.83334 6.30293 11.1018 7.57146 12.6666 7.57146Z" stroke="#333333" />
          <path d="M5 7.57146C6.5648 7.57146 7.83333 6.30293 7.83333 4.73812C7.83333 3.17332 6.5648 1.90479 5 1.90479C3.43519 1.90479 2.16666 3.17332 2.16666 4.73812C2.16666 6.30293 3.43519 7.57146 5 7.57146Z" stroke="#333333" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83334 10.8398 9.83334 12.4046C9.83334 13.9694 11.1018 15.238 12.6666 15.238Z" stroke="#333333" />
          <path d="M5 15.238C6.5648 15.238 7.83333 13.9694 7.83333 12.4046C7.83333 10.8398 6.5648 9.57129 5 9.57129C3.43519 9.57129 2.16666 10.8398 2.16666 12.4046C2.16666 13.9694 3.43519 15.238 5 15.238Z" stroke="#333333" />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83331 3.17332 9.83331 4.73812C9.83331 6.30293 11.1018 7.57146 12.6666 7.57146Z" fill="#F5F5F5" />
          <path d="M4.99997 7.57146C6.56477 7.57146 7.8333 6.30293 7.8333 4.73812C7.8333 3.17332 6.56477 1.90479 4.99997 1.90479C3.43516 1.90479 2.16663 3.17332 2.16663 4.73812C2.16663 6.30293 3.43516 7.57146 4.99997 7.57146Z" fill="#F5F5F5" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83331 10.8398 9.83331 12.4046C9.83331 13.9694 11.1018 15.238 12.6666 15.238Z" fill="#F5F5F5" />
          <path d="M4.99997 15.238C6.56477 15.238 7.8333 13.9694 7.8333 12.4046C7.8333 10.8398 6.56477 9.57129 4.99997 9.57129C3.43516 9.57129 2.16663 10.8398 2.16663 12.4046C2.16663 13.9694 3.43516 15.238 4.99997 15.238Z" fill="#F5F5F5" />
        </svg>
      ),
    },
    {
      id: 2,
      path: "/estatemanagementexco",
      name: "Estate Management",
      title: "Estate Management",
      icon1: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.66665 2.11902L1.33331 5.45235" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 2.78564V15.4523H4.66667C3.40959 15.4523 2.78105 15.4523 2.39053 15.0618C2 14.6712 2 14.0427 2 12.7856V5.45231" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 5.45227L14.6667 8.7856" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6.66669 15.4521H11.3334C12.5904 15.4521 13.219 15.4521 13.6095 15.0615C14 14.671 14 14.0425 14 12.7854V8.45227" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 7.45227V5.45227" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4.66669 8.11902H5.33335M4.66669 10.7857H5.33335" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.6667 10.119H11.3334" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M11 15.4523V12.7856" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
      icon2: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.66668 2.40466L1.33334 5.73799" stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 3.07129V15.7379H4.66667C3.40959 15.7379 2.78105 15.7379 2.39053 15.3474C2 14.9568 2 14.3283 2 13.0712V5.73796" fill="#F5F5F5" />
          <path d="M8 3.07129V15.7379H4.66667C3.40959 15.7379 2.78105 15.7379 2.39053 15.3474C2 14.9568 2 14.3283 2 13.0712V5.73796" stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 5.73792L14.6667 9.07124" stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6.66666 15.7377H11.3333C12.5904 15.7377 13.2189 15.7377 13.6095 15.3471C14 14.9566 14 14.3281 14 13.071V8.73792" fill="#F5F5F5" />
          <path d="M6.66666 15.7377H11.3333C12.5904 15.7377 13.2189 15.7377 13.6095 15.3471C14 14.9566 14 14.3281 14 13.071V8.73792" stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 7.73792V5.73792" stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4.66666 8.40466H5.33332M4.66666 11.0713H5.33332" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.6667 10.4047H11.3333" stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M11 15.738V13.0713V15.738Z" fill="#FF5722" />
          <path d="M11 15.738V13.0713" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
    },
    {
      id: 3,
      path: "/communicationexco",
      name: "Communication",
      title: "Communication",
      icon1: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 6.78568C12.8032 4.12872 10.3418 2.11902 7.38618 2.11902C4.04364 2.11902 1.33331 4.68916 1.33331 7.85902C1.33331 9.38208 1.95877 10.766 2.97901 11.7929C3.20364 12.019 3.35361 12.328 3.29309 12.6459C3.19319 13.1658 2.96682 13.6507 2.63535 14.0548C3.50747 14.2156 4.41429 14.0708 5.19199 13.6608C5.46691 13.516 5.60436 13.4435 5.70136 13.4288C5.76926 13.4185 5.85771 13.4281 5.99998 13.4525"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.33331 11.6268C7.33331 13.564 8.97518 15.1346 11 15.1346C11.238 15.1349 11.4754 15.1129 11.7093 15.069C11.8776 15.0374 11.9618 15.0216 12.0206 15.0306C12.0793 15.0395 12.1626 15.0838 12.3292 15.1724C12.8002 15.4229 13.3496 15.5114 13.8779 15.4131C13.6771 15.1662 13.54 14.8698 13.4794 14.5521C13.4428 14.3578 13.5336 14.169 13.6697 14.0308C14.2878 13.4032 14.6666 12.5576 14.6666 11.6268C14.6666 9.68968 13.0248 8.11902 11 8.11902C8.97518 8.11902 7.33331 9.68968 7.33331 11.6268Z"
            stroke="#333333"
            stroke-linejoin="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 6.78568C12.8032 4.12872 10.3419 2.11902 7.3862 2.11902C4.04366 2.11902 1.33334 4.68916 1.33334 7.85902C1.33334 9.38208 1.9588 10.766 2.97904 11.7929C3.20366 12.019 3.35364 12.328 3.29311 12.6459C3.19322 13.1658 2.96684 13.6507 2.63537 14.0548C3.50749 14.2156 4.41432 14.0708 5.19201 13.6608C5.46693 13.516 5.60438 13.4435 5.70138 13.4288C5.76928 13.4185 5.85773 13.4281 6 13.4525"
            stroke="#F5F5F5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.33334 11.6268C7.33334 13.564 8.9752 15.1346 11 15.1346C11.2381 15.1349 11.4755 15.1129 11.7093 15.069C11.8777 15.0374 11.9619 15.0216 12.0206 15.0306C12.0793 15.0395 12.1627 15.0838 12.3292 15.1724C12.8003 15.4229 13.3496 15.5114 13.8779 15.4131C13.6771 15.1662 13.54 14.8698 13.4795 14.5521C13.4428 14.3578 13.5337 14.169 13.6697 14.0308C14.2878 13.4032 14.6667 12.5576 14.6667 11.6268C14.6667 9.68968 13.0248 8.11902 11 8.11902C8.9752 8.11902 7.33334 9.68968 7.33334 11.6268Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 4,
      path: "/issuetrackingexco",
      name: "Issue Tracking",
      title: "Issue Tracking",
      icon1: (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.38115 6.74101C5.99027 3.89372 6.79482 2.47006 7.89887 2.1036C8.50621 1.90199 9.16048 1.90199 9.76781 2.1036C10.8719 2.47006 11.6764 3.89372 13.2855 6.74101C14.8947 9.58832 15.6992 11.0119 15.4579 12.1719C15.3251 12.8101 14.9979 13.389 14.5233 13.8255C13.6607 14.6191 12.0516 14.6191 8.83334 14.6191C5.61511 14.6191 4.00599 14.6191 3.14331 13.8255C2.66873 13.389 2.3416 12.8101 2.20882 12.1719C1.96747 11.0119 2.77203 9.58832 4.38115 6.74101Z"
            stroke="#333333"
          />
          <path d="M8.99481 11.619V8.95231C8.99481 8.63804 8.99481 8.48091 8.89714 8.38324C8.79954 8.28564 8.64241 8.28564 8.32814 8.28564" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8.828 6.28564H8.8355" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
      icon2: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.54785 7.52665C5.15697 4.67936 5.96152 3.2557 7.06558 2.88924C7.67291 2.68763 8.32718 2.68763 8.93451 2.88924C10.0386 3.2557 10.8431 4.67936 12.4522 7.52665C14.0614 10.374 14.8659 11.7976 14.6246 12.9576C14.4918 13.5958 14.1646 14.1747 13.69 14.6112C12.8274 15.4047 11.2183 15.4047 8.00004 15.4047C4.78181 15.4047 3.17269 15.4047 2.31002 14.6112C1.83543 14.1747 1.5083 13.5958 1.37552 12.9576C1.13417 11.7976 1.93873 10.374 3.54785 7.52665Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
          />
          <path d="M8.16142 12.4046V9.73796C8.16142 9.42369 8.16142 9.26656 8.06375 9.16889C7.96615 9.07129 7.80902 9.07129 7.49475 9.07129" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.99463 7.07129H8.00213" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
    },
  ];
  const activeItem = menu.find((item) => item.path === location.pathname);

  function handleProfileManagement() {
    setProfileManagement(true);
    setNotification(false);

    setAccountManagement(false);
    setSecurity(false);
  }
  function handleAccountManagement() {
    setProfileManagement(false);
    setNotification(false);

    setAccountManagement(true);
    setSecurity(false);
  }
  function handleNotification() {
    setProfileManagement(false);
    setNotification(true);

    setAccountManagement(false);
    setSecurity(false);
  }
  function handleSecurity() {
    setProfileManagement(false);
    setNotification(false);

    setAccountManagement(false);
    setSecurity(true);
  }

  return (
    <>
      <div className="flex w-full justify-between items-start">
        <SideBarExco mini={mini} setMini={setMini} menu={menu} />
        {/* LARGE SCREENS */}
        <div className={` 100:hidden xl:flex w-full pr-[25px] pb-[50px] flex flex-col justify-between gap-[22px] h-fit ${mini ? "pl-[130px]" : "pl-[280px]"}`}>
          <Header mini={mini} name={activeItem ? activeItem.name : "Settings"} />
          <div className="flex justify-start items-start gap-[8px]">
            <div
              onClick={handleProfileManagement}
              className={`w-fit cursor-pointer h-9 px-3 py-2 ${
                profileManagement ? "bg-red-50 text-orange-600 shadow" : " bg-transparent text-[#333]"
              }  rounded-md  justify-center items-center gap-2 inline-flex text-sm font-normal font-openSans leading-tight`}
            >
              Profile Management
            </div>
            <div
              onClick={handleAccountManagement}
              className={`w-fit cursor-pointer h-9 px-3 py-2 ${
                accountManagement ? "bg-red-50 shadow text-orange-600" : " bg-transparent text-[#333]"
              }  rounded-md  justify-center items-center gap-2 inline-flex text-sm font-normal font-openSans leading-tight`}
            >
              Account Management
            </div>
            <div
              onClick={handleNotification}
              className={`w-fit cursor-pointer h-9 px-3 py-2 ${
                notification ? "bg-red-50 shadow text-orange-600" : " bg-transparent text-[#333]"
              }  rounded-md  justify-center items-center gap-2 inline-flex text-sm font-normal font-openSans leading-tight`}
            >
              Notification
            </div>
            <div
              onClick={handleSecurity}
              className={`w-fit cursor-pointer h-9 px-3 py-2 ${security ? "bg-red-50 shadow text-orange-600" : " bg-transparent text-[#333]"}  rounded-md  justify-center items-center gap-2 inline-flex text-sm font-normal font-openSans leading-tight`}
            >
              Security
            </div>
          </div>
          <div className="w-full h-fit gap-[50px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            {profileManagement && <ProfileManagement setModal={setModal} />}
            {accountManagement && <AccountManagement setModal={setModal} />}
            {notification && <Notification />}
            {security && <Security editSecurity={editSecurity} setEditSecurity={setEditSecurity} />}
          </div>
        </div>
        {/* SMALL SCREENS */}
        <div className=" 100:flex xl:hidden w-full pr-[25px] pb-[50px] flex-col justify-between gap-[22px] h-fit pl-[130px]">
          <Header mini={mini} name={activeItem ? activeItem.name : "Settings"} />

          <div className="flex whitespace-nowrap overflow-x-scroll justify-start items-start gap-[8px]">
            <div
              onClick={handleProfileManagement}
              className={`w-fit cursor-pointer h-9 px-3 py-2 ${
                profileManagement ? "bg-red-50 text-orange-600 shadow" : " bg-transparent text-[#333]"
              }  rounded-md  justify-center items-center gap-2 inline-flex text-sm font-normal font-openSans leading-tight`}
            >
              Profile Management
            </div>
            <div
              onClick={handleAccountManagement}
              className={`w-fit cursor-pointer h-9 px-3 py-2 ${
                accountManagement ? "bg-red-50 shadow text-orange-600" : " bg-transparent text-[#333]"
              }  rounded-md  justify-center items-center gap-2 inline-flex text-sm font-normal font-openSans leading-tight`}
            >
              Account Management
            </div>
            <div
              onClick={handleNotification}
              className={`w-fit cursor-pointer h-9 px-3 py-2 ${
                notification ? "bg-red-50 shadow text-orange-600" : " bg-transparent text-[#333]"
              }  rounded-md  justify-center items-center gap-2 inline-flex text-sm font-normal font-openSans leading-tight`}
            >
              Notification
            </div>
            <div
              onClick={handleSecurity}
              className={`w-fit cursor-pointer h-9 px-3 py-2 ${security ? "bg-red-50 shadow text-orange-600" : " bg-transparent text-[#333]"}  rounded-md  justify-center items-center gap-2 inline-flex text-sm font-normal font-openSans leading-tight`}
            >
              Security
            </div>
          </div>
          <div className="w-full h-fit gap-[50px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            {profileManagement && <ProfileManagement setModal={setModal} />}
            {accountManagement && <AccountManagement setModal={setModal} />}
            {notification && <Notification />}
            {security && <Security editSecurity={editSecurity} setEditSecurity={setEditSecurity} />}
          </div>
        </div>
      </div>
      {modal && (
        <>
          <div className=" w-full z-30 bg-black bg-opacity-40 flex justify-center items-center h-screen fixed top-0 left-0">
            <div className=" lg:w-[748px] 100:w-[85%] phone:w-[80%]   p-[10px]  h-fit gap-[10px] items-start flex flex-col rounded-[20px] bg-[#F5F5F5] ">
              <div className=" w-full bg-[#FFE2D9] h-[80px] px-[30px] rounded-[20px] flex justify-end items-center ">
                <div onClick={() => setModal(false)} className=" bg-[#B8A9A9] rounded-full cursor-pointer w-fit p-[10px]">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 4.16675L4.1665 15.8334M4.1665 4.16675L15.8332 15.8334" stroke="#F5F5F5" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="w-full h-[226px] p-[30px]  bg-white rounded-[20px] justify-center items-center flex">
                <div className="flex gap-[40px] flex-col justify-between items-center">
                  <div className="w-20 h-20 p-5 bg-emerald-100 rounded-[100px] border-8 border-green-50 justify-center items-center gap-2.5 inline-flex">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.33325 23.333L14.1666 29.1663L31.6666 10.833" stroke="#4CAF50" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                  <div className="w-[222px] text-center text-zinc-800 text-sm font-semibold font-openSans leading-[23px] tracking-tight">Your personal details has been updated successfully</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default SettingExco;
