import keyFeatures from "../images/keyFeatures.png";

function Features() {
  return (
    <>
      {/* BIG SCREENS */}
      <div className="mobile:hidden 100:hidden xl:flex w-[100%] mt-[49.92px]  justify-center flex items-center">
        <div className="w-[90%] 3xl:max-w-[1350px]  flex justify-between items-center gap-[50px] ">
          <img className="lg:w-[570px] 2xl:w-[700px]" src={keyFeatures} alt="Key Features" />
          <div className=" gap-[20px] grid">
            <h3 className="text-zinc-800 text-[40px] mb-[20px] font-semibold font-openSans tracking-tight">Key Features </h3>
            <p className="text-[22px] text-neutral-500 font-normal font-monteserrat leading-[45px] tracking-tight">
              <span className="text-neutral-800 font-medium ">Community Connection:</span> Stay connected with your neighbors through a dedicated platform built for community engagement.
            </p>
            <p className="text-[22px] text-neutral-500 font-normal font-monteserrat leading-[45px] tracking-tight">
              <span className="text-neutral-800 font-medium "> Local Happenings:</span> Stay informed about local events, news, and important updates in real-time.
            </p>
            <p className="text-[22px] text-neutral-500 font-normal font-monteserrat leading-[45px] tracking-tight">
              <span className="text-neutral-800 font-medium "> Access Code Feature:</span> Secure your community with MyHood! Access Codes provide exclusive entry, ensuring privacy and safety. Try it now!
            </p>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM MOBILE TO 1023PX */}
      <div className="mobile:flex  xl:hidden mt-[49.92px] w-full justify-center items-center">
        <div className="flex flex-col items-center w-[85%] gap-[40px] ">
          <img src={keyFeatures} alt="Key Features" />
          <div className=" gap-[20px] grid">
            <h3 className="text-zinc-800 text-[30px] mb-[20px] font-semibold font-openSans tracking-tight">Key Features </h3>
            <p className="text-[18px] text-neutral-500 font-normal font-monteserrat leading-[45px] tracking-tight">
              <span className="text-neutral-800 font-medium ">Community Connection:</span> Stay connected with your neighbors through a dedicated platform built for community engagement.
            </p>
            <p className="text-[18px] text-neutral-500 font-normal font-monteserrat leading-[45px] tracking-tight">
              <span className="text-neutral-800 font-medium "> Local Happenings:</span> Stay informed about local events, news, and important updates in real-time.
            </p>
            <p className="text-[18px] text-neutral-500 font-normal font-monteserrat leading-[45px] tracking-tight">
              <span className="text-neutral-800 font-medium "> Access Code Feature:</span> Secure your community with MyHood! Access Codes provide exclusive entry, ensuring privacy and safety. Try it now!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
