import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import sideImg from "../images/loginmyhood.png";
import lock from "../images/createpassword.png";
import axios from "axios";

function CreatePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const location = useLocation();
  const [inputType, setInputType] = useState("password");
  const [inputType2, setInputType2] = useState("password");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const email = location.state?.email || "";
  const [error, setError] = useState("");
  const token = location.state?.token || "";
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
    }

    console.log(password);

    try {
      const response = await axios.post("https://www.myhood.ng/mobile_back_end/api/password_resset.php", {
        user_id: email,
        reset_code: token,
        password: password,
      });

      console.log(response.data);
      if (response.data.responsecode === "200") {
        //   setPassword("");
        //   setConfirmPassword("");
        navigate("/login", { state: { email, token, password } });
      } else {
        // Handle error response
        setError(response.data.responsemessage || "Error setting password.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred while setting the password.");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setInputType(showPassword ? "password" : "text");
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
    setInputType2(showPassword2 ? "password" : "text");
  };

  return (
    <>
      {/* BIG SCREENS */}
      <div className="flex w-full 100:hidden lg:flex h-screen items-center justify-between">
        <img className="fixed   h-full w-[50%]  " src={sideImg} alt="housing" />
        <div className=" ml-[52%] px-[48px] gap-[50px] flex flex-col justify-start items-center pb-[52px] w-[50%] h-full ">
          <div className="w-full top-[70px]  relative float-left ">
            <Link className="" to="/otp">
              <h3 className=" text-[16px]  w-[36px] font-normal font-openSans text-[#FF5722] tracking-[0.9px] ">Back</h3>
            </Link>
          </div>
          <div className="flex mt-[150px] flex-col justify-between gap-[50px] items-center">
            <div className="flex flex-col text-center justify-between items-center gap-[40px]">
              <img src={lock} alt="Create Password" />
              <div className=" flex flex-col items-center justify-between gap-[15px]">
                <h1 className="text-[32px] text-[#333333] font-semibold font-openSans leading-[35px] tracking-wide ">Create Your Password </h1>
                <p className="text-[#777777] text-[20px] w-[380px] font-normal font-monteserrat leading-[35px] tracking-tight">Choose a strong and secure password for your myHOOD account.</p>
              </div>
            </div>
            <form onSubmit={handleSubmit} className=" flex flex-col justify-center items-center gap-[20px]" action="/" method="get">
              <div className="w-[400px] h-[37px] flex justify-between items-center px-[15px]  bg-[#eee] border-[0.7px] border-[#777] rounded-[4px] ">
                <input
                  type={inputType}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full h-full text-[20px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                  placeholder="Create Password"
                  required
                />
                <span onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                  {showPassword ? (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                      />
                      <path d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z" stroke="#777777" strokeWidth="0.875" />
                    </svg>
                  ) : (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                      <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                </span>
              </div>
              <p className="text-[13px] w-[400px] font-monteserrat font-normal text-[#777] ">Password should be at least 8 characters long and include a mix of letters, numbers, and symbols.</p>
              <div className="w-[400px] h-[37px] flex justify-between items-center px-[15px]  bg-[#eee] border-[0.7px] border-[#777] rounded-[4px] ">
                <input
                  className="w-full h-full text-[20px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                  type={inputType2}
                  nname="confirmpassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  required
                />
                <span onClick={togglePasswordVisibility2} style={{ cursor: "pointer" }}>
                  {showPassword2 ? (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                      />
                      <path d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z" stroke="#777777" strokeWidth="0.875" />
                    </svg>
                  ) : (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                      <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                </span>
              </div>
            </form>

            <button
              onClick={() => {
                handleSubmit();
                console.log("i got clicked");
              }}
              className="w-[243px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[16px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex"
            >
              Set Password
            </button>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1024PX */}
      <div className="flex flex-col w-full overflow-x-hidden 100:flex gap-[70px] lg:hidden h-fit py-[50px] items-center justify-center">
        <div className="w-full  left-[50px] relative float-left ">
          <Link className="" to="/otp">
            <h3 className=" text-[16px]  w-[36px] font-normal font-openSans text-[#FF5722] tracking-[0.9px] ">Back</h3>
          </Link>
        </div>
        <div className=" phone:max-w-[450px]  px-[48px]  flex flex-col justify-center items-center pb-[52px] w-[90%] ">
          <div className="flex flex-col justify-between gap-[50px] items-center">
            <div className="flex flex-col text-center justify-between items-center gap-[40px]">
              <img src={lock} alt="Create Password" />
              <div className=" flex flex-col items-center justify-between gap-[15px]">
                <h1 className="phone:text-[32px] whitespace-nowrap 100:text-[28px] text-[#333333] font-semibold font-openSans leading-[35px] tracking-wide ">Create Your Password</h1>
                <p className="text-[#777777] 100:text-[18px] phone:text-[20px] w-full phone:w-[450px] font-normal font-monteserrat leading-[35px] tracking-tight">Choose a strong and secure password for your myHOOD account.</p>
              </div>
            </div>

            <form onSubmit={handleSubmit} className=" w-full item-center flex flex-col  gap-[20px]" action="/" method="get">
              <div className="w-full h-[37px] flex justify-between items-center px-[15px]  bg-[#eee] border-[0.7px] border-[#777] rounded-[4px] ">
                <input
                  type={inputType}
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full h-full text-[20px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                  placeholder="Create Password"
                  required
                />
                <span onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                  {showPassword ? (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                      />
                      <path d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z" stroke="#777777" strokeWidth="0.875" />
                    </svg>
                  ) : (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                      <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                </span>
              </div>
              <p className="text-[13px] w-full font-monteserrat font-normal text-[#777] ">Password should be at least 8 characters long and include a mix of letters, numbers, and symbols.</p>
              <div className="w-full h-[37px] flex justify-between items-center px-[15px]  bg-[#eee] border-[0.7px] border-[#777] rounded-[4px] ">
                <input
                  className="w-full h-full text-[20px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                  type={inputType2}
                  nname="confirmpassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  required
                />
                <span onClick={togglePasswordVisibility2} style={{ cursor: "pointer" }}>
                  {showPassword2 ? (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                      />
                      <path d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z" stroke="#777777" strokeWidth="0.875" />
                    </svg>
                  ) : (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                      <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                </span>
              </div>
            </form>
            <button
              onClick={() => {
                handleSubmit();
                console.log("i got cllicked");
              }}
              type="submit"
              className="w-[243px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[16px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex"
            >
              Set Password
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePassword;
