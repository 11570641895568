// export const apiBaseUrl = viteEnvs.VITE_HOST_API;
import axios from "axios";
// import { Navigate } from "react-router-dom";
// import { store } from "../redux/store";
// import { dispatchLogout, dispatchSetAccessToken } from "../redux/userSlice";

// const viteEnvs = import.meta.env;

export const apiBaseUrl = "https://myhood.ng/mobile_back_end/api";

export const axiosInstance = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});
// console.log(axios);

// axiosInstance.interceptors.request.use(
//   (request) => {
//     const stored = store.getState();
//     // ts-ignore
//     const token = stored.user.access_token;

//     if (token) {
//       request.headers["Authorization"] = `Bearer ${token}`;
//     }
//     return request;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     // console.log(error);
//     if ((error?.response.status === 400 && error?.response.data.message === "Token expired") || (error?.response.status === 400 && error?.response.data.message === "Jwt malformed")) {
//       try {
//         // Log information about token refresh
//         console.log("Token expired. Refreshing...");
//         // Extract the refresh token from your stored state

//         const response = await axiosInstance.get(`${apiBaseUrl}/auth/token`, {
//           withCredentials: true,
//         });

//         const access_token = response?.data.data.accessToken;

//         console.log(response?.data);

//         store.dispatch(dispatchSetAccessToken(access_token));

//         // Log information about successful token refresh
//         console.log("Token refreshed successfully.");

//         // Retry the original request with the new access token
//         return axiosInstance(error.config);
//       } catch (error) {
//         // Reject for other non-401 errors

//         return Promise.reject(error);
//       }
//     }

//     if ((error?.response.status === 401 && error?.response.data.message === "Unauthorized access: Token not found") || (error?.response.status === 401 && error?.response.data.message === "Invalid or expired token")) {
//       store.dispatch(dispatchLogout());

//       <Navigate to="/auth/login" replace />;
//     }

//     return Promise.reject(error);
//   }
// );
