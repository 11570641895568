import axios from "axios";

const authRequest = {
  userLogin: async (username, password) => {
    let loginInfo = {
      username: username,
      password: password,
    };

    console.log(loginInfo);

    return await axios.post("https://myhood.ng/mobile_back_end/api/login.php", loginInfo).then((response) => {
      //   console.log("tempId", response.data.id);

      return response;
    });
  },

  getUserInfo: async (userId) => {
    return await axios.post(`https://www.myhood.ng/mobile_back_end/api/get_user.php?id=${userId}`).then((response) => {
      return response;
    });
  },
};

export default authRequest;
