import { useState } from "react";

function Security({ editSecurity, setEditSecurity }) {
  const [oldPassword, setOldPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [oldInputType, setoldInputType] = useState("password");
  const [newInputType, setNewInputType] = useState("password");
  const [confirmInputType, setConfirmInputType] = useState("password");
  const [modal, setModal] = useState(false);

  const toggleOldPasswordVisibility = () => {
    setOldPassword(!oldPassword);
    setoldInputType(oldPassword ? "password" : "text");
  };
  const toggleNewPasswordVisibility = () => {
    setNewPassword(!newPassword);
    setNewInputType(newPassword ? "password" : "text");
  };
  const toggleConfirmPasswordVisibility = () => {
    setConfirmPassword(!confirmPassword);
    setConfirmInputType(confirmPassword ? "password" : "text");
  };
  return (
    <>
      {editSecurity ? (
        <>
          {/* LARGE SCREENS */}
          <div className={` 100:hidden xl:flex  flex flex-col justify-between w-full p-[20px] items-start gap-[40px]`}>
            <div className=" w-full flex flex-col gap-[20px]  justify-between items-start">
              <div className="self-stretch flex justify-between w-full items-center h-[59px] border-b-[1px] border-solid border-b-neutral-200">
                <div className=" pb-[22px]  flex justify-between items-center w-full">
                  <h3 className="grow shrink basis-0 self-stretch text-zinc-800 text-base font-semibold font-openSans tracking-tight">Password</h3>
                </div>
              </div>
              <form className=" flex flex-col justify-between gap-[30px] ">
                <div className=" flex items-center justify-start gap-[24px]  ">
                  <div className="w-[284px] h-[61px] flex-col justify-start items-start gap-[8px] flex">
                    <label htmlFor="Old Password" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                      Old Password
                    </label>
                    <div className="outline-[#FF5722] flex justify-between items-center w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 ">
                      <input placeholder="Enter Old Password" type={oldInputType} className=" border-none outline-none bg-transparent w-full h-full " />
                      <span onClick={toggleOldPasswordVisibility} style={{ cursor: "pointer" }}>
                        {oldPassword ? (
                          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                              stroke="#777777"
                              strokeWidth="0.875"
                            />
                            <path
                              d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z"
                              stroke="#777777"
                              strokeWidth="0.875"
                            />
                          </svg>
                        ) : (
                          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                            <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" flex items-start justify-between gap-[24px]  ">
                  <div className="w-[284px] h-fit flex-col justify-start items-start gap-[8px] flex">
                    <label htmlFor="New Password" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                      New Password
                    </label>
                    <div className=" flex gap-[8px] justify-between flex-col">
                      <div className="outline-[#FF5722] flex justify-between items-center w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 ">
                        <input placeholder="Enter New Password" type={newInputType} className=" border-none outline-none bg-transparent w-full h-full " />
                        <span onClick={toggleNewPasswordVisibility} style={{ cursor: "pointer" }}>
                          {newPassword ? (
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                                stroke="#777777"
                                strokeWidth="0.875"
                              />
                              <path
                                d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z"
                                stroke="#777777"
                                strokeWidth="0.875"
                              />
                            </svg>
                          ) : (
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                              <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          )}
                        </span>
                      </div>
                      <p className="w-full text-neutral-400 text-[11px] font-normal font-monteserrat leading-[16px] tracking-tight">Password should be at least 8 characters long and include a mix of letters, numbers, and symbols.</p>
                    </div>
                  </div>

                  <div className="w-[284px] h-[61px] flex-col justify-start items-start gap-[8px] flex">
                    <label htmlFor="Confirm Password" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                      Confirm Password
                    </label>
                    <div className="outline-[#FF5722] flex justify-between items-center w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 ">
                      <input placeholder="Confirm New Password" type={confirmInputType} className=" border-none outline-none bg-transparent w-full h-full " />
                      <span onClick={toggleConfirmPasswordVisibility} style={{ cursor: "pointer" }}>
                        {confirmPassword ? (
                          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                              stroke="#777777"
                              strokeWidth="0.875"
                            />
                            <path
                              d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z"
                              stroke="#777777"
                              strokeWidth="0.875"
                            />
                          </svg>
                        ) : (
                          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                            <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className=" p-[10px] flex w-full justify-end items-center">
              <div
                onClick={() => setModal(true)}
                className=" cursor-pointer text-white text-xs font-semibold font-openSans tracking-tight  flex justify-center items-center gap-[10px] w-[180px] h-[38px] py-[5px] px-[20px] rounded-[5px]  bg-[#FF5722] "
              >
                Save
              </div>
            </div>
          </div>
          {/* SMALL SCREENS */}
          <div className=" 100:flex xl:hidden w-full  pb-[50px] flex-col justify-between gap-[22px] h-fit ">
            <div className=" w-full flex flex-col gap-[20px]  justify-between items-start">
              <div className="self-stretch flex justify-between w-full items-center h-[59px] border-b-[1px] border-solid border-b-neutral-200">
                <div className=" pb-[22px]  flex justify-between items-center w-full">
                  <h3 className="grow shrink basis-0 self-stretch text-zinc-800 text-base font-semibold font-openSans tracking-tight">Password</h3>
                </div>
              </div>
              <form className=" flex flex-col justify-between gap-[30px] ">
                <div className=" flex items-center justify-start gap-[24px]  ">
                  <div className="sm:w-[284px] 100:w-full h-[61px] flex-col justify-start items-start gap-[8px] flex">
                    <label htmlFor="Old Password" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                      Old Password
                    </label>
                    <div className="outline-[#FF5722] flex justify-between items-center w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 phone:text-sm 100:text-[10px] font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 ">
                      <input placeholder="Enter Old Password" type={oldInputType} className=" border-none outline-none bg-transparent w-full h-full " />
                      <span onClick={toggleOldPasswordVisibility} style={{ cursor: "pointer" }}>
                        {oldPassword ? (
                          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                              stroke="#777777"
                              strokeWidth="0.875"
                            />
                            <path
                              d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z"
                              stroke="#777777"
                              strokeWidth="0.875"
                            />
                          </svg>
                        ) : (
                          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                            <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className=" flex 100:flex-col md:flex-row  items-start justify-between gap-[24px]  ">
                  <div className="sm:w-[284px] 100:w-full h-fit flex-col justify-start items-start gap-[8px] flex">
                    <label htmlFor="New Password" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                      New Password
                    </label>
                    <div className=" flex gap-[8px] justify-between flex-col">
                      <div className="outline-[#FF5722] flex justify-between items-center w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 phone:text-sm 100:text-[10px] font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 ">
                        <input placeholder="Enter New Password" type={newInputType} className=" border-none outline-none bg-transparent w-full h-full " />
                        <span onClick={toggleNewPasswordVisibility} style={{ cursor: "pointer" }}>
                          {newPassword ? (
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                                stroke="#777777"
                                strokeWidth="0.875"
                              />
                              <path
                                d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z"
                                stroke="#777777"
                                strokeWidth="0.875"
                              />
                            </svg>
                          ) : (
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                              <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          )}
                        </span>
                      </div>
                      <p className="w-full text-neutral-400 text-[11px] font-normal font-monteserrat leading-[16px] tracking-tight">Password should be at least 8 characters long and include a mix of letters, numbers, and symbols.</p>
                    </div>
                  </div>

                  <div className="sm:w-[284px] 100:w-full h-[61px] flex-col justify-start items-start gap-[8px] flex">
                    <label htmlFor="Confirm Password" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                      Confirm Password
                    </label>
                    <div className="outline-[#FF5722] flex justify-between items-center w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 phone:text-sm 100:text-[10px]  font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 ">
                      <input placeholder="Confirm New Password" type={confirmInputType} className=" border-none outline-none bg-transparent w-full h-full " />
                      <span onClick={toggleConfirmPasswordVisibility} style={{ cursor: "pointer" }}>
                        {confirmPassword ? (
                          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                              stroke="#777777"
                              strokeWidth="0.875"
                            />
                            <path
                              d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z"
                              stroke="#777777"
                              strokeWidth="0.875"
                            />
                          </svg>
                        ) : (
                          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                            <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </form>
              <div className=" p-[10px] 100:flex-col 100:gap-[10px] md:gap-[0] md:flex-row flex w-full justify-between items-center">
                <div
                  onClick={() => setModal(true)}
                  className=" cursor-pointer text-white text-xs font-semibold font-openSans tracking-tight  flex justify-center items-center gap-[10px] 100:w-[50%] md:w-[180px] h-[38px] py-[5px] px-[20px] rounded-[5px]  bg-[#FF5722] "
                >
                  Save
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* LARGE SCREENS */}
          <div className={` 100:hidden xl:flex  w-full flex flex-col gap-[20px] p-[20px] justify-between items-start`}>
            <div className="self-stretch flex justify-between w-full items-center h-[59px] border-b-[1px] border-solid border-b-neutral-200">
              <div className=" pb-[22px]  flex justify-between items-center w-full">
                <h3 className="grow shrink basis-0 self-stretch text-zinc-800 text-base font-semibold font-openSans tracking-tight">Security</h3>
                <div onClick={() => setEditSecurity(true)} className={`w-[63px] cursor-pointer h-[37px] p-2.5 bg-white rounded-lg shadow border border-gray-200 justify-center items-center gap-[5px] inline-flex `}>
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.1426 4.48858L11.077 3.55408C11.5932 3.03796 12.43 3.03796 12.9461 3.55408C13.4622 4.07019 13.4622 4.90698 12.9461 5.42309L12.0116 6.3576M10.1426 4.48858L4.65332 9.97786C3.95646 10.6747 3.60801 11.0231 3.37075 11.4477C3.13348 11.8723 2.89477 12.8749 2.6665 13.8337C3.62523 13.6054 4.62783 13.3667 5.05243 13.1294C5.47703 12.8921 5.82546 12.5437 6.52234 11.8469L12.0116 6.3576M10.1426 4.48858L12.0116 6.3576"
                      stroke="#777777"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="text-neutral-500 text-xs font-normal font-['Open Sans'] leading-[17.40px]">Edit</div>
                </div>
              </div>
            </div>
            <div className=" flex justify-between gap-[24px] items-center">
              <div className=" w-[284px] h-16 flex-col justify-start items-start gap-2 inline-flex">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Password</h3>

                  <h4 className="text-zinc-800 h-[40px] flex justify-start items-center text-sm font-medium font-monteserrat leading-tight">*****************</h4>
                </div>
              </div>
              <div className=" w-[284px] h-16 flex-col justify-start items-start gap-2 inline-flex">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Security Question</h3>

                  <h4 className="text-zinc-800 h-[40px] flex justify-start items-center text-sm font-medium font-monteserrat leading-tight">Null</h4>
                </div>
              </div>
            </div>
            <div className=" flex justify-between gap-[24px] items-center">
              <div className=" w-[284px] h-16 flex-col justify-start items-start gap-2 inline-flex">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Security Answer</h3>

                  <h4 className="text-zinc-800 h-[40px] flex justify-start items-center text-sm font-medium font-monteserrat leading-tight">Null</h4>
                </div>
              </div>
            </div>
          </div>
          {/* SMALL SCREENS */}
          <div className=" 100:flex xl:hidden w-full  pb-[50px] flex-col justify-between gap-[22px] h-fit ">
            <div className=" self-stretch  flex justify-between w-full items-center 100:h-fit phone:h-[59px] border-b-[1px] border-solid border-b-neutral-200">
              <div className=" pb-[22px] 100:flex-col phone:flex-row gap-[50px]  flex justify-between 100:items-end phone:items-center w-full">
                <h3 className="grow shrink basis-0 self-stretch text-zinc-800 text-base font-semibold font-openSans tracking-tight">Security</h3>
                <div onClick={() => setEditSecurity(true)} className={`w-[63px] cursor-pointer h-[37px] p-2.5 bg-white rounded-lg shadow border border-gray-200 justify-center items-center gap-[5px] inline-flex `}>
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.1426 4.48858L11.077 3.55408C11.5932 3.03796 12.43 3.03796 12.9461 3.55408C13.4622 4.07019 13.4622 4.90698 12.9461 5.42309L12.0116 6.3576M10.1426 4.48858L4.65332 9.97786C3.95646 10.6747 3.60801 11.0231 3.37075 11.4477C3.13348 11.8723 2.89477 12.8749 2.6665 13.8337C3.62523 13.6054 4.62783 13.3667 5.05243 13.1294C5.47703 12.8921 5.82546 12.5437 6.52234 11.8469L12.0116 6.3576M10.1426 4.48858L12.0116 6.3576"
                      stroke="#777777"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="text-neutral-500 text-xs font-normal font-['Open Sans'] leading-[17.40px]">Edit</div>
                </div>
              </div>
            </div>
            <div className=" 100:flex-col phone:flex-row flex justify-between gap-[24px] items-center">
              <div className=" w-[284px] h-16 flex-col justify-start items-start gap-2 inline-flex">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Password</h3>

                  <h4 className="text-zinc-800 h-[40px] flex justify-start items-center text-sm font-medium font-monteserrat leading-tight">*****************</h4>
                </div>
              </div>
              <div className=" w-[284px] h-16 flex-col justify-start items-start gap-2 inline-flex">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Security Question</h3>

                  <h4 className="text-zinc-800 h-[40px] flex justify-start items-center text-sm font-medium font-monteserrat leading-tight">Null</h4>
                </div>
              </div>
            </div>
            <div className="100:flex-col phone:flex-row flex justify-between gap-[24px] items-center">
              <div className=" w-[284px] h-16 flex-col justify-start items-start gap-2 inline-flex">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Security Answer</h3>

                  <h4 className="text-zinc-800 h-[40px] flex justify-start items-center text-sm font-medium font-monteserrat leading-tight">Null</h4>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {modal && (
        <>
          <div className=" w-full z-30 bg-black bg-opacity-40 flex justify-center items-center h-screen fixed top-0 left-0">
            <div className=" lg:w-[748px] 100:w-[85%] phone:w-[80%]    p-[10px]  h-fit gap-[10px] items-start flex flex-col rounded-[20px] bg-[#F5F5F5] ">
              <div className=" w-full bg-[#FFE2D9] h-[80px] px-[30px] rounded-[20px] flex justify-end items-center ">
                <div onClick={() => setModal(false)} className=" bg-[#B8A9A9] rounded-full cursor-pointer w-fit p-[10px]">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 4.16675L4.1665 15.8334M4.1665 4.16675L15.8332 15.8334" stroke="#F5F5F5" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="w-full h-[226px] p-[30px]  bg-white rounded-[20px] justify-center items-center flex">
                <div className="flex gap-[40px] flex-col justify-between items-center">
                  <div className="w-20 h-20 p-5 bg-emerald-100 rounded-[100px] border-8 border-green-50 justify-center items-center gap-2.5 inline-flex">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.33325 23.333L14.1666 29.1663L31.6666 10.833" stroke="#4CAF50" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                  <div className="w-[222px] text-center text-zinc-800 text-sm font-semibold font-openSans leading-[23px] tracking-tight">Your security details has been updated successfully</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Security;
