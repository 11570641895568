import { useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../UserAdmin/Footer";
import HeaderExco from "./HeaderExco";
import general from "../../images/general.png";
import events from "../../images/event.png";
import amenities from "../../images/amenities.png";
import avartar2 from "../../images/Avatar wrap-1.png";
import avartar3 from "../../images/Avatar wrap-2.png";
import avartar4 from "../../images/Avatar wrap-3.png";
import avartar1 from "../../images/Avatar wrap.png";
import illustration from "../../images/drop.png";
import Select from "react-select";
import InputFiles from "react-input-files";
// import SideBar from "../UserAdmin/SideBar";
import SideBarExco from "./SideBarExco";
import axios from "axios";

function CommunicationExco() {
  const [mini, setMini] = useState(false);
  const location = useLocation();
  const [createAnnouncement, setCreateAnnouncement] = useState(false);
  const [save, setSave] = useState(false);
  const [dateOption, setDateOption] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [inputValue, setInputValue] = useState({
    title: "",
    description: "",
    image: "",
    expireydate: "",
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Remove the prefix
        setSelectedImage(base64String);
        // console.log("Base64 String:", base64String);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };
  const handleRoleChange = (selectedOption) => {
    setInputValue({
      ...inputValue,
      description: selectedOption ? selectedOption.value : "",
    });
  };
  const handleSub = async (event) => {
    event.preventDefault();

    console.log("Input 1:", inputValue);

    // e.preventDefault();

    const paylod = {
      title: inputValue.title,
      description: inputValue.description,
      image: inputValue.selectedImage,
      expireydate: inputValue.expireydate,
    };

    // console.log(paylod);
    try {
      const response = await axios.post("https://www.myhood.ng/mobile_back_end/api/add_announcement.php", paylod);
      console.log(response);
      if (response.data.responsecode === "200") {
        // navigate("/login");
        setSave(true);
      } else {
        // display error
      }
      return response.data;
    } catch (error) {
      console.error("Error registering user:", error);
      // Rethrow the error for the caller to handle
    }
  };

  const menu = [
    {
      id: 1,
      path: "/dashboardexco",
      name: "Dashboard",
      title: "Dashboard",
      icon1: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83334 3.17332 9.83334 4.73812C9.83334 6.30293 11.1018 7.57146 12.6666 7.57146Z" stroke="#333333" />
          <path d="M5 7.57146C6.5648 7.57146 7.83333 6.30293 7.83333 4.73812C7.83333 3.17332 6.5648 1.90479 5 1.90479C3.43519 1.90479 2.16666 3.17332 2.16666 4.73812C2.16666 6.30293 3.43519 7.57146 5 7.57146Z" stroke="#333333" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83334 10.8398 9.83334 12.4046C9.83334 13.9694 11.1018 15.238 12.6666 15.238Z" stroke="#333333" />
          <path d="M5 15.238C6.5648 15.238 7.83333 13.9694 7.83333 12.4046C7.83333 10.8398 6.5648 9.57129 5 9.57129C3.43519 9.57129 2.16666 10.8398 2.16666 12.4046C2.16666 13.9694 3.43519 15.238 5 15.238Z" stroke="#333333" />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83331 3.17332 9.83331 4.73812C9.83331 6.30293 11.1018 7.57146 12.6666 7.57146Z" fill="#F5F5F5" />
          <path d="M4.99997 7.57146C6.56477 7.57146 7.8333 6.30293 7.8333 4.73812C7.8333 3.17332 6.56477 1.90479 4.99997 1.90479C3.43516 1.90479 2.16663 3.17332 2.16663 4.73812C2.16663 6.30293 3.43516 7.57146 4.99997 7.57146Z" fill="#F5F5F5" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83331 10.8398 9.83331 12.4046C9.83331 13.9694 11.1018 15.238 12.6666 15.238Z" fill="#F5F5F5" />
          <path d="M4.99997 15.238C6.56477 15.238 7.8333 13.9694 7.8333 12.4046C7.8333 10.8398 6.56477 9.57129 4.99997 9.57129C3.43516 9.57129 2.16663 10.8398 2.16663 12.4046C2.16663 13.9694 3.43516 15.238 4.99997 15.238Z" fill="#F5F5F5" />
        </svg>
      ),
    },
    {
      id: 2,
      path: "/estatemanagementexco",
      name: "Estate Management",
      title: "Estate Management",
      icon1: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.66665 2.11902L1.33331 5.45235" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 2.78564V15.4523H4.66667C3.40959 15.4523 2.78105 15.4523 2.39053 15.0618C2 14.6712 2 14.0427 2 12.7856V5.45231" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 5.45227L14.6667 8.7856" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6.66669 15.4521H11.3334C12.5904 15.4521 13.219 15.4521 13.6095 15.0615C14 14.671 14 14.0425 14 12.7854V8.45227" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 7.45227V5.45227" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4.66669 8.11902H5.33335M4.66669 10.7857H5.33335" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.6667 10.119H11.3334" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M11 15.4523V12.7856" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
      icon2: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.66668 2.40466L1.33334 5.73799" stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 3.07129V15.7379H4.66667C3.40959 15.7379 2.78105 15.7379 2.39053 15.3474C2 14.9568 2 14.3283 2 13.0712V5.73796" fill="#F5F5F5" />
          <path d="M8 3.07129V15.7379H4.66667C3.40959 15.7379 2.78105 15.7379 2.39053 15.3474C2 14.9568 2 14.3283 2 13.0712V5.73796" stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 5.73792L14.6667 9.07124" stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6.66666 15.7377H11.3333C12.5904 15.7377 13.2189 15.7377 13.6095 15.3471C14 14.9566 14 14.3281 14 13.071V8.73792" fill="#F5F5F5" />
          <path d="M6.66666 15.7377H11.3333C12.5904 15.7377 13.2189 15.7377 13.6095 15.3471C14 14.9566 14 14.3281 14 13.071V8.73792" stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M12 7.73792V5.73792" stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M4.66666 8.40466H5.33332M4.66666 11.0713H5.33332" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.6667 10.4047H11.3333" stroke="#F5F5F5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M11 15.738V13.0713V15.738Z" fill="#FF5722" />
          <path d="M11 15.738V13.0713" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
    },
    {
      id: 3,
      path: "/communicationexco",
      name: "Communication",
      title: "Communication",
      icon1: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 6.78568C12.8032 4.12872 10.3418 2.11902 7.38618 2.11902C4.04364 2.11902 1.33331 4.68916 1.33331 7.85902C1.33331 9.38208 1.95877 10.766 2.97901 11.7929C3.20364 12.019 3.35361 12.328 3.29309 12.6459C3.19319 13.1658 2.96682 13.6507 2.63535 14.0548C3.50747 14.2156 4.41429 14.0708 5.19199 13.6608C5.46691 13.516 5.60436 13.4435 5.70136 13.4288C5.76926 13.4185 5.85771 13.4281 5.99998 13.4525"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.33331 11.6268C7.33331 13.564 8.97518 15.1346 11 15.1346C11.238 15.1349 11.4754 15.1129 11.7093 15.069C11.8776 15.0374 11.9618 15.0216 12.0206 15.0306C12.0793 15.0395 12.1626 15.0838 12.3292 15.1724C12.8002 15.4229 13.3496 15.5114 13.8779 15.4131C13.6771 15.1662 13.54 14.8698 13.4794 14.5521C13.4428 14.3578 13.5336 14.169 13.6697 14.0308C14.2878 13.4032 14.6666 12.5576 14.6666 11.6268C14.6666 9.68968 13.0248 8.11902 11 8.11902C8.97518 8.11902 7.33331 9.68968 7.33331 11.6268Z"
            stroke="#333333"
            stroke-linejoin="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 6.78568C12.8032 4.12872 10.3419 2.11902 7.3862 2.11902C4.04366 2.11902 1.33334 4.68916 1.33334 7.85902C1.33334 9.38208 1.9588 10.766 2.97904 11.7929C3.20366 12.019 3.35364 12.328 3.29311 12.6459C3.19322 13.1658 2.96684 13.6507 2.63537 14.0548C3.50749 14.2156 4.41432 14.0708 5.19201 13.6608C5.46693 13.516 5.60438 13.4435 5.70138 13.4288C5.76928 13.4185 5.85773 13.4281 6 13.4525"
            stroke="#F5F5F5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M7.33334 11.6268C7.33334 13.564 8.9752 15.1346 11 15.1346C11.2381 15.1349 11.4755 15.1129 11.7093 15.069C11.8777 15.0374 11.9619 15.0216 12.0206 15.0306C12.0793 15.0395 12.1627 15.0838 12.3292 15.1724C12.8003 15.4229 13.3496 15.5114 13.8779 15.4131C13.6771 15.1662 13.54 14.8698 13.4795 14.5521C13.4428 14.3578 13.5337 14.169 13.6697 14.0308C14.2878 13.4032 14.6667 12.5576 14.6667 11.6268C14.6667 9.68968 13.0248 8.11902 11 8.11902C8.9752 8.11902 7.33334 9.68968 7.33334 11.6268Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 4,
      path: "/issuetrackingexco",
      name: "Issue Tracking",
      title: "Issue Tracking",
      icon1: (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.38115 6.74101C5.99027 3.89372 6.79482 2.47006 7.89887 2.1036C8.50621 1.90199 9.16048 1.90199 9.76781 2.1036C10.8719 2.47006 11.6764 3.89372 13.2855 6.74101C14.8947 9.58832 15.6992 11.0119 15.4579 12.1719C15.3251 12.8101 14.9979 13.389 14.5233 13.8255C13.6607 14.6191 12.0516 14.6191 8.83334 14.6191C5.61511 14.6191 4.00599 14.6191 3.14331 13.8255C2.66873 13.389 2.3416 12.8101 2.20882 12.1719C1.96747 11.0119 2.77203 9.58832 4.38115 6.74101Z"
            stroke="#333333"
          />
          <path d="M8.99481 11.619V8.95231C8.99481 8.63804 8.99481 8.48091 8.89714 8.38324C8.79954 8.28564 8.64241 8.28564 8.32814 8.28564" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8.828 6.28564H8.8355" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
      icon2: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.54785 7.52665C5.15697 4.67936 5.96152 3.2557 7.06558 2.88924C7.67291 2.68763 8.32718 2.68763 8.93451 2.88924C10.0386 3.2557 10.8431 4.67936 12.4522 7.52665C14.0614 10.374 14.8659 11.7976 14.6246 12.9576C14.4918 13.5958 14.1646 14.1747 13.69 14.6112C12.8274 15.4047 11.2183 15.4047 8.00004 15.4047C4.78181 15.4047 3.17269 15.4047 2.31002 14.6112C1.83543 14.1747 1.5083 13.5958 1.37552 12.9576C1.13417 11.7976 1.93873 10.374 3.54785 7.52665Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
          />
          <path d="M8.16142 12.4046V9.73796C8.16142 9.42369 8.16142 9.26656 8.06375 9.16889C7.96615 9.07129 7.80902 9.07129 7.49475 9.07129" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.99463 7.07129H8.00213" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
    },
  ];
  const activeItem = menu.find((item) => item.path === location.pathname);

  return (
    <>
      <div className="flex   justify-between items-start">
        <SideBarExco mini={mini} setMini={setMini} menu={menu} />
        {/* LARGE SCREENS */}
        <div className={` 100:hidden xl:flex w-full pr-[25px] pb-[50px] flex flex-col justify-between gap-[22px] h-fit ${mini ? "pl-[130px]" : "pl-[280px]"}`}>
          <HeaderExco mini={mini} name={activeItem ? activeItem.name : null} />
          {/* Button */}
          <div className="flex px-[40px] justify-end items-center">
            <button onClick={() => setCreateAnnouncement(true)}>
              <div className="w-fit h-[33px] px-[20px] py-[7px] bg-[#FF5722] rounded text-white text-[14px] font-semibold font-monteserrat tracking-tight justify-center items-center  inline-flex">Create Announcement</div>
            </button>
          </div>
          {/* Content 1 */}
          <div className="w-full h-fit gap-[22px] pb-[40px] px-[40px] flex flex-col  ">
            <section className=" flex h-[44px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[16px] text-[#333] ">General Discussions Forum</h4>
            </section>
            <div className="py-[24px] px-[20px] gap-[20px] w-full rounded-[10px] shadow border border-neutral-200 justify-start flex items-center">
              <img src={general} alt="" />
              <div className=" flex flex-col items-start w-full justify-start p-2.5">
                <h3 className="text-zinc-800 text-2xl font-semibold font-['Open Sans'] leading-loose">Best places to deliver food around the estate?</h3>
                <p className="self-stretch text-neutral-500 text-base font-normal font-['Open Sans'] leading-[30px]">
                  Lorem ipsum dolor sit amet consectetur. Tincidunt nascetur eu congue bibendum. Odio amet hendrerit commodo risus laoreet ac vulputate orci quam. At ultricies neque maecenas vitae quis turpis.{" "}
                </p>
              </div>
              {/* Images and Icons */}
              <div className="w-fit h-6 justify-start items-center gap-[30px] inline-flex">
                <div className="w-[104px]  h-6 justify-start items-start inline-flex">
                  <img src={avartar1} alt="" />
                  <img className="ml-[-7px]" src={avartar2} alt="" />
                  <img className="ml-[-7px]" src={avartar3} alt="" />
                  <img className="ml-[-7px]" src={avartar4} alt="" />
                  <div className="w-6 ml-[-7px] h-6 pt-1 pb-0.5 bg-rose-50 rounded-[200px] border-2 border-white justify-center items-center inline-flex">
                    <div className="w-6 text-center text-orange-600 text-xs font-medium font-openSans leading-[18px]">+5</div>
                  </div>
                </div>
                <div className="w-[50px] h-6 justify-start items-center gap-1.5 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.66663 9.639C1.66663 14.0417 5.39813 17.6112 9.99996 17.6112C10.5411 17.612 11.0806 17.5619 11.6121 17.4622C11.9947 17.3902 12.186 17.3543 12.3195 17.3747C12.4531 17.3952 12.6423 17.4957 13.0208 17.6971C14.0915 18.2665 15.34 18.4676 16.5407 18.2442C16.0844 17.6829 15.7727 17.0094 15.6352 16.2874C15.5518 15.8457 15.7583 15.4167 16.0676 15.1027C17.4722 13.6763 18.3333 11.7543 18.3333 9.639C18.3333 5.23639 14.6018 1.66675 9.99996 1.66675C5.39813 1.66675 1.66663 5.23639 1.66663 9.639Z"
                      fill="#333333"
                      stroke="#333333"
                      stroke-width="1.25"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="w-6 h-6 flex justify-center items-center bg-opacity-10 bg-zinc-400 rounded-full text-center text-zinc-800 text-[15px] font-bold font-openSans leading-tight">6</div>
                </div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 10.8333C10.4603 10.8333 10.8334 10.4602 10.8334 9.99992C10.8334 9.53968 10.4603 9.16659 10 9.16659C9.53978 9.16659 9.16669 9.53968 9.16669 9.99992C9.16669 10.4602 9.53978 10.8333 10 10.8333Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 4.99992C10.4603 4.99992 10.8334 4.62682 10.8334 4.16659C10.8334 3.70635 10.4603 3.33325 10 3.33325C9.53978 3.33325 9.16669 3.70635 9.16669 4.16659C9.16669 4.62682 9.53978 4.99992 10 4.99992Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 16.6666C10.4603 16.6666 10.8334 16.2935 10.8334 15.8333C10.8334 15.373 10.4603 14.9999 10 14.9999C9.53978 14.9999 9.16669 15.373 9.16669 15.8333C9.16669 16.2935 9.53978 16.6666 10 16.6666Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* Content 2 */}
          <div className="w-full h-fit gap-[22px] pb-[40px] px-[40px] flex flex-col  ">
            <section className=" flex h-[44px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[16px] text-[#333] ">Events & Activities Forum</h4>
            </section>
            <div className="py-[24px] px-[20px] gap-[20px] w-full rounded-[10px] shadow border border-neutral-200 justify-start flex items-center">
              <img src={events} alt="" />
              <div className=" flex flex-col items-start w-full justify-start p-2.5">
                <h3 className="text-zinc-800 text-2xl font-semibold font-['Open Sans'] leading-loose">Best places to deliver food around the estate?</h3>
                <p className="self-stretch text-neutral-500 text-base font-normal font-['Open Sans'] leading-[30px]">
                  Lorem ipsum dolor sit amet consectetur. Tincidunt nascetur eu congue bibendum. Odio amet hendrerit commodo risus laoreet ac vulputate orci quam. At ultricies neque maecenas vitae quis turpis.{" "}
                </p>
              </div>
              {/* Images and Icons */}
              <div className="w-fit h-6 justify-start items-center gap-[30px] inline-flex">
                <div className="w-[104px]  h-6 justify-start items-start inline-flex">
                  <img src={avartar1} alt="" />
                  <img className="ml-[-7px]" src={avartar2} alt="" />
                  <img className="ml-[-7px]" src={avartar3} alt="" />
                  <img className="ml-[-7px]" src={avartar4} alt="" />
                  <div className="w-6 ml-[-7px] h-6 pt-1 pb-0.5 bg-rose-50 rounded-[200px] border-2 border-white justify-center items-center inline-flex">
                    <div className="w-6 text-center text-orange-600 text-xs font-medium font-openSans leading-[18px]">+5</div>
                  </div>
                </div>
                <div className="w-[50px] h-6 justify-start items-center gap-1.5 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.66663 9.639C1.66663 14.0417 5.39813 17.6112 9.99996 17.6112C10.5411 17.612 11.0806 17.5619 11.6121 17.4622C11.9947 17.3902 12.186 17.3543 12.3195 17.3747C12.4531 17.3952 12.6423 17.4957 13.0208 17.6971C14.0915 18.2665 15.34 18.4676 16.5407 18.2442C16.0844 17.6829 15.7727 17.0094 15.6352 16.2874C15.5518 15.8457 15.7583 15.4167 16.0676 15.1027C17.4722 13.6763 18.3333 11.7543 18.3333 9.639C18.3333 5.23639 14.6018 1.66675 9.99996 1.66675C5.39813 1.66675 1.66663 5.23639 1.66663 9.639Z"
                      fill="#333333"
                      stroke="#333333"
                      stroke-width="1.25"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="w-6 h-6 flex justify-center items-center bg-opacity-10 bg-zinc-400 rounded-full text-center text-zinc-800 text-[15px] font-bold font-openSans leading-tight">6</div>
                </div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 10.8333C10.4603 10.8333 10.8334 10.4602 10.8334 9.99992C10.8334 9.53968 10.4603 9.16659 10 9.16659C9.53978 9.16659 9.16669 9.53968 9.16669 9.99992C9.16669 10.4602 9.53978 10.8333 10 10.8333Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 4.99992C10.4603 4.99992 10.8334 4.62682 10.8334 4.16659C10.8334 3.70635 10.4603 3.33325 10 3.33325C9.53978 3.33325 9.16669 3.70635 9.16669 4.16659C9.16669 4.62682 9.53978 4.99992 10 4.99992Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 16.6666C10.4603 16.6666 10.8334 16.2935 10.8334 15.8333C10.8334 15.373 10.4603 14.9999 10 14.9999C9.53978 14.9999 9.16669 15.373 9.16669 15.8333C9.16669 16.2935 9.53978 16.6666 10 16.6666Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* Content 3 */}
          <div className="w-full h-fit gap-[22px] pb-[40px] px-[40px] flex flex-col  ">
            <section className=" flex h-[44px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[16px] text-[#333] ">Amenities & Services Forum</h4>
            </section>
            <div className="py-[24px] px-[20px] gap-[20px] w-full rounded-[10px] shadow border border-neutral-200 justify-start flex items-center">
              <img src={amenities} alt="" />
              <div className=" flex flex-col items-start w-full justify-start p-2.5">
                <h3 className="text-zinc-800 text-2xl font-semibold font-['Open Sans'] leading-loose">Best places to deliver food around the estate?</h3>
                <p className="self-stretch text-neutral-500 text-base font-normal font-['Open Sans'] leading-[30px]">
                  Lorem ipsum dolor sit amet consectetur. Tincidunt nascetur eu congue bibendum. Odio amet hendrerit commodo risus laoreet ac vulputate orci quam. At ultricies neque maecenas vitae quis turpis.{" "}
                </p>
              </div>
              {/* Images and Icons */}
              <div className="w-fit h-6 justify-start items-center gap-[30px] inline-flex">
                <div className="w-[104px]  h-6 justify-start items-start inline-flex">
                  <img src={avartar1} alt="" />
                  <img className="ml-[-7px]" src={avartar2} alt="" />
                  <img className="ml-[-7px]" src={avartar3} alt="" />
                  <img className="ml-[-7px]" src={avartar4} alt="" />
                  <div className="w-6 ml-[-7px] h-6 pt-1 pb-0.5 bg-rose-50 rounded-[200px] border-2 border-white justify-center items-center inline-flex">
                    <div className="w-6 text-center text-orange-600 text-xs font-medium font-openSans leading-[18px]">+5</div>
                  </div>
                </div>
                <div className="w-[50px] h-6 justify-start items-center gap-1.5 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.66663 9.639C1.66663 14.0417 5.39813 17.6112 9.99996 17.6112C10.5411 17.612 11.0806 17.5619 11.6121 17.4622C11.9947 17.3902 12.186 17.3543 12.3195 17.3747C12.4531 17.3952 12.6423 17.4957 13.0208 17.6971C14.0915 18.2665 15.34 18.4676 16.5407 18.2442C16.0844 17.6829 15.7727 17.0094 15.6352 16.2874C15.5518 15.8457 15.7583 15.4167 16.0676 15.1027C17.4722 13.6763 18.3333 11.7543 18.3333 9.639C18.3333 5.23639 14.6018 1.66675 9.99996 1.66675C5.39813 1.66675 1.66663 5.23639 1.66663 9.639Z"
                      fill="#333333"
                      stroke="#333333"
                      stroke-width="1.25"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="w-6 h-6 flex justify-center items-center bg-opacity-10 bg-zinc-400 rounded-full text-center text-zinc-800 text-[15px] font-bold font-openSans leading-tight">6</div>
                </div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 10.8333C10.4603 10.8333 10.8334 10.4602 10.8334 9.99992C10.8334 9.53968 10.4603 9.16659 10 9.16659C9.53978 9.16659 9.16669 9.53968 9.16669 9.99992C9.16669 10.4602 9.53978 10.8333 10 10.8333Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 4.99992C10.4603 4.99992 10.8334 4.62682 10.8334 4.16659C10.8334 3.70635 10.4603 3.33325 10 3.33325C9.53978 3.33325 9.16669 3.70635 9.16669 4.16659C9.16669 4.62682 9.53978 4.99992 10 4.99992Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 16.6666C10.4603 16.6666 10.8334 16.2935 10.8334 15.8333C10.8334 15.373 10.4603 14.9999 10 14.9999C9.53978 14.9999 9.16669 15.373 9.16669 15.8333C9.16669 16.2935 9.53978 16.6666 10 16.6666Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        {/* Small Screens */}
        <div className=" 100:flex xl:hidden w-full pr-[25px] pb-[50px] flex-col justify-between gap-[22px] h-fit pl-[130px]">
          <HeaderExco mini={mini} name={activeItem ? activeItem.name : null} />
          {/* Button */}
          <div className="flex px-[20px] justify-end items-center">
            <button onClick={() => setCreateAnnouncement(true)}>
              <div className="w-fit h-[33px] px-[20px] py-[7px] bg-[#FF5722] rounded text-white text-[14px] font-semibold font-monteserrat tracking-tight justify-center items-center  inline-flex">Create Announcement</div>
            </button>
          </div>
          {/* Content 1 */}
          <div className="w-full mt-[40px] h-fit gap-[22px] pb-[40px] px-[20px] flex flex-col  ">
            <section className=" flex 100:pb-[20px] phone:h-[44px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[16px] text-[#333] ">General Discussions Forum</h4>
            </section>
            <div className="py-[24px] flex-col px-[20px] gap-[20px] w-full rounded-[10px] shadow border border-neutral-200 justify-start flex items-start">
              <img className="w-full" src={general} alt="" />
              <div className=" flex flex-col items-start w-full justify-start p-2.5">
                <h3 className="text-zinc-800 text-2xl font-semibold font-['Open Sans'] leading-loose">Best places to deliver food around the estate?</h3>
                <p className="self-stretch text-neutral-500 text-base font-normal font-['Open Sans'] leading-[30px]">
                  Lorem ipsum dolor sit amet consectetur. Tincidunt nascetur eu congue bibendum. Odio amet hendrerit commodo risus laoreet ac vulputate orci quam. At ultricies neque maecenas vitae quis turpis.{" "}
                </p>
              </div>
              {/* Images and Icons */}
              <div className="w-fit 100:flex-col phone:flex-row h-fit justify-start 100:items-start phone:items-center gap-[30px] inline-flex">
                <div className="w-[104px]  h-6 justify-start items-start inline-flex">
                  <img src={avartar1} alt="" />
                  <img className="ml-[-7px]" src={avartar2} alt="" />
                  <img className="ml-[-7px]" src={avartar3} alt="" />
                  <img className="ml-[-7px]" src={avartar4} alt="" />
                  <div className="w-6 ml-[-7px] h-6 pt-1 pb-0.5 bg-rose-50 rounded-[200px] border-2 border-white justify-center items-center inline-flex">
                    <div className="w-6 text-center text-orange-600 text-xs font-medium font-openSans leading-[18px]">+5</div>
                  </div>
                </div>
                <div className="w-[50px] h-6 justify-start items-center gap-1.5 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.66663 9.639C1.66663 14.0417 5.39813 17.6112 9.99996 17.6112C10.5411 17.612 11.0806 17.5619 11.6121 17.4622C11.9947 17.3902 12.186 17.3543 12.3195 17.3747C12.4531 17.3952 12.6423 17.4957 13.0208 17.6971C14.0915 18.2665 15.34 18.4676 16.5407 18.2442C16.0844 17.6829 15.7727 17.0094 15.6352 16.2874C15.5518 15.8457 15.7583 15.4167 16.0676 15.1027C17.4722 13.6763 18.3333 11.7543 18.3333 9.639C18.3333 5.23639 14.6018 1.66675 9.99996 1.66675C5.39813 1.66675 1.66663 5.23639 1.66663 9.639Z"
                      fill="#333333"
                      stroke="#333333"
                      stroke-width="1.25"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="w-6 h-6 flex justify-center items-center bg-opacity-10 bg-zinc-400 rounded-full text-center text-zinc-800 text-[15px] font-bold font-openSans leading-tight">6</div>
                </div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 10.8333C10.4603 10.8333 10.8334 10.4602 10.8334 9.99992C10.8334 9.53968 10.4603 9.16659 10 9.16659C9.53978 9.16659 9.16669 9.53968 9.16669 9.99992C9.16669 10.4602 9.53978 10.8333 10 10.8333Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 4.99992C10.4603 4.99992 10.8334 4.62682 10.8334 4.16659C10.8334 3.70635 10.4603 3.33325 10 3.33325C9.53978 3.33325 9.16669 3.70635 9.16669 4.16659C9.16669 4.62682 9.53978 4.99992 10 4.99992Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 16.6666C10.4603 16.6666 10.8334 16.2935 10.8334 15.8333C10.8334 15.373 10.4603 14.9999 10 14.9999C9.53978 14.9999 9.16669 15.373 9.16669 15.8333C9.16669 16.2935 9.53978 16.6666 10 16.6666Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* Content 2 */}
          <div className="w-full h-fit gap-[22px] pb-[40px] px-[20px] flex flex-col  ">
            <section className=" flex 100:pb-[20px] phone:h-[44px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[16px] text-[#333] ">Events & Activities Forum</h4>
            </section>
            <div className="py-[24px] flex-col px-[20px] gap-[20px] w-full rounded-[10px] shadow border border-neutral-200 justify-start flex items-start">
              <img className="w-full" src={events} alt="" />
              <div className=" flex flex-col items-start w-full justify-start p-2.5">
                <h3 className="text-zinc-800 text-2xl font-semibold font-['Open Sans'] leading-loose">Best places to deliver food around the estate?</h3>
                <p className="self-stretch text-neutral-500 text-base font-normal font-['Open Sans'] leading-[30px]">
                  Lorem ipsum dolor sit amet consectetur. Tincidunt nascetur eu congue bibendum. Odio amet hendrerit commodo risus laoreet ac vulputate orci quam. At ultricies neque maecenas vitae quis turpis.{" "}
                </p>
              </div>
              {/* Images and Icons */}
              <div className="w-fit 100:flex-col phone:flex-row h-fit justify-start 100:items-start phone:items-center gap-[30px] inline-flex">
                <div className="w-[104px]  h-6 justify-start items-start inline-flex">
                  <img src={avartar1} alt="" />
                  <img className="ml-[-7px]" src={avartar2} alt="" />
                  <img className="ml-[-7px]" src={avartar3} alt="" />
                  <img className="ml-[-7px]" src={avartar4} alt="" />
                  <div className="w-6 ml-[-7px] h-6 pt-1 pb-0.5 bg-rose-50 rounded-[200px] border-2 border-white justify-center items-center inline-flex">
                    <div className="w-6 text-center text-orange-600 text-xs font-medium font-openSans leading-[18px]">+5</div>
                  </div>
                </div>
                <div className="w-[50px] h-6 justify-start items-center gap-1.5 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.66663 9.639C1.66663 14.0417 5.39813 17.6112 9.99996 17.6112C10.5411 17.612 11.0806 17.5619 11.6121 17.4622C11.9947 17.3902 12.186 17.3543 12.3195 17.3747C12.4531 17.3952 12.6423 17.4957 13.0208 17.6971C14.0915 18.2665 15.34 18.4676 16.5407 18.2442C16.0844 17.6829 15.7727 17.0094 15.6352 16.2874C15.5518 15.8457 15.7583 15.4167 16.0676 15.1027C17.4722 13.6763 18.3333 11.7543 18.3333 9.639C18.3333 5.23639 14.6018 1.66675 9.99996 1.66675C5.39813 1.66675 1.66663 5.23639 1.66663 9.639Z"
                      fill="#333333"
                      stroke="#333333"
                      stroke-width="1.25"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="w-6 h-6 flex justify-center items-center bg-opacity-10 bg-zinc-400 rounded-full text-center text-zinc-800 text-[15px] font-bold font-openSans leading-tight">6</div>
                </div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 10.8333C10.4603 10.8333 10.8334 10.4602 10.8334 9.99992C10.8334 9.53968 10.4603 9.16659 10 9.16659C9.53978 9.16659 9.16669 9.53968 9.16669 9.99992C9.16669 10.4602 9.53978 10.8333 10 10.8333Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 4.99992C10.4603 4.99992 10.8334 4.62682 10.8334 4.16659C10.8334 3.70635 10.4603 3.33325 10 3.33325C9.53978 3.33325 9.16669 3.70635 9.16669 4.16659C9.16669 4.62682 9.53978 4.99992 10 4.99992Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 16.6666C10.4603 16.6666 10.8334 16.2935 10.8334 15.8333C10.8334 15.373 10.4603 14.9999 10 14.9999C9.53978 14.9999 9.16669 15.373 9.16669 15.8333C9.16669 16.2935 9.53978 16.6666 10 16.6666Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          {/* Content 3 */}
          <div className="w-full h-fit gap-[22px] pb-[40px] px-[20px] flex flex-col  ">
            <section className=" flex 100:pb-[20px] phone:h-[44px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[16px] text-[#333] ">Amenities & Services Forum</h4>
            </section>
            <div className="py-[24px] flex-col px-[20px] gap-[20px] w-full rounded-[10px] shadow border border-neutral-200 justify-start flex items-start">
              <img className="w-full" src={amenities} alt="" />
              <div className=" flex flex-col items-start w-full justify-start p-2.5">
                <h3 className="text-zinc-800 text-2xl font-semibold font-['Open Sans'] leading-loose">Best places to deliver food around the estate?</h3>
                <p className="self-stretch text-neutral-500 text-base font-normal font-['Open Sans'] leading-[30px]">
                  Lorem ipsum dolor sit amet consectetur. Tincidunt nascetur eu congue bibendum. Odio amet hendrerit commodo risus laoreet ac vulputate orci quam. At ultricies neque maecenas vitae quis turpis.{" "}
                </p>
              </div>
              {/* Images and Icons */}
              <div className="w-fit 100:flex-col phone:flex-row h-fit justify-start 100:items-start phone:items-center gap-[30px] inline-flex">
                <div className="w-[104px]  h-6 justify-start items-start inline-flex">
                  <img src={avartar1} alt="" />
                  <img className="ml-[-7px]" src={avartar2} alt="" />
                  <img className="ml-[-7px]" src={avartar3} alt="" />
                  <img className="ml-[-7px]" src={avartar4} alt="" />
                  <div className="w-6 ml-[-7px] h-6 pt-1 pb-0.5 bg-rose-50 rounded-[200px] border-2 border-white justify-center items-center inline-flex">
                    <div className="w-6 text-center text-orange-600 text-xs font-medium font-openSans leading-[18px]">+5</div>
                  </div>
                </div>
                <div className="w-[50px] h-6 justify-start items-center gap-1.5 inline-flex">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.66663 9.639C1.66663 14.0417 5.39813 17.6112 9.99996 17.6112C10.5411 17.612 11.0806 17.5619 11.6121 17.4622C11.9947 17.3902 12.186 17.3543 12.3195 17.3747C12.4531 17.3952 12.6423 17.4957 13.0208 17.6971C14.0915 18.2665 15.34 18.4676 16.5407 18.2442C16.0844 17.6829 15.7727 17.0094 15.6352 16.2874C15.5518 15.8457 15.7583 15.4167 16.0676 15.1027C17.4722 13.6763 18.3333 11.7543 18.3333 9.639C18.3333 5.23639 14.6018 1.66675 9.99996 1.66675C5.39813 1.66675 1.66663 5.23639 1.66663 9.639Z"
                      fill="#333333"
                      stroke="#333333"
                      stroke-width="1.25"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div className="w-6 h-6 flex justify-center items-center bg-opacity-10 bg-zinc-400 rounded-full text-center text-zinc-800 text-[15px] font-bold font-openSans leading-tight">6</div>
                </div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 10.8333C10.4603 10.8333 10.8334 10.4602 10.8334 9.99992C10.8334 9.53968 10.4603 9.16659 10 9.16659C9.53978 9.16659 9.16669 9.53968 9.16669 9.99992C9.16669 10.4602 9.53978 10.8333 10 10.8333Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 4.99992C10.4603 4.99992 10.8334 4.62682 10.8334 4.16659C10.8334 3.70635 10.4603 3.33325 10 3.33325C9.53978 3.33325 9.16669 3.70635 9.16669 4.16659C9.16669 4.62682 9.53978 4.99992 10 4.99992Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 16.6666C10.4603 16.6666 10.8334 16.2935 10.8334 15.8333C10.8334 15.373 10.4603 14.9999 10 14.9999C9.53978 14.9999 9.16669 15.373 9.16669 15.8333C9.16669 16.2935 9.53978 16.6666 10 16.6666Z"
                    stroke="#9E9E9E"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {createAnnouncement && (
        <>
          <div className=" w-full bg-black bg-opacity-40 flex justify-center items-center h-screen fixed top-0 left-0">
            <div className=" md:w-fit 100:w-[85%] overflow-y-scroll  p-[10px]  h-[400px] gap-[10px] items-start flex flex-col rounded-[20px] bg-[#F5F5F5] ">
              <div className=" w-full bg-[#FFE2D9] h-[80px] py-[20px] px-[30px] rounded-[20px] flex justify-between items-center ">
                {save ? <div></div> : <h3 className=" font-openSans tracking-[-0.4px] text-[#333] font-semibold text-[20px]">Announcement</h3>}
                <div onClick={() => setCreateAnnouncement(false)} className=" bg-[#B8A9A9] rounded-full cursor-pointer w-fit p-[10px]">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 4.16675L4.1665 15.8334M4.1665 4.16675L15.8332 15.8334" stroke="#F5F5F5" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="w-full h-fit px-[30px] py-[20px] bg-white rounded-[20px] flex-col justify-start items-start gap-5 inline-flex">
                {save ? (
                  <>
                    <div className="flex gap-[40px] sm:w-[527px] 100:w-full flex-col justify-between items-center">
                      <div className="w-20 h-20 p-5 bg-emerald-100 rounded-[100px] border-8 border-green-50 justify-center items-center gap-2.5 inline-flex">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.33325 23.333L14.1666 29.1663L31.6666 10.833" stroke="#4CAF50" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </div>
                      <div className=" text-center text-zinc-800 text-sm font-semibold font-openSans leading-[23px] tracking-tight">Announcement added successfully</div>
                    </div>
                  </>
                ) : (
                  <form className="sm:w-[527px] 100:w-full h-fit flex-col justify-start items-start gap-[20px] inline-flex">
                    <div className="self-stretch h-[61px] flex-col justify-start items-start gap-[8px] flex">
                      <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Title</h3>
                      <input
                        onChange={handleChange}
                        value={inputValue.title}
                        placeholder="Enter Title"
                        type="text"
                        name="title"
                        className=" w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-['Montserrat'] tracking-tight rounded border-[0.5px] border-neutral-500 "
                      />
                    </div>

                    <div className="self-stretch h-[124px] flex-col justify-start items-start gap-[8px] flex">
                      <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Description</h3>
                      <textarea
                        onChange={handleChange}
                        value={inputValue.description}
                        name="description"
                        placeholder="Enter Description....."
                        className=" w-full h-[100px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-['Montserrat'] tracking-tight rounded border-[0.5px] border-neutral-500 "
                      />
                    </div>
                    <div className="sm:w-[527px] 100:flex-col phone:flex-row 100:w-full phone:h-[126px] 100:h-fit 100:justify-between 100:items-center phone:justify-start phone:items-start gap-5 inline-flex">
                      <img
                        className="w-16 h-16 relative rounded-[200px]"
                        name="image"
                        src={selectedImage ? `data:image/png;base64,${selectedImage}` : inputValue.image === "" ? illustration : `data:image/png;base64,${inputValue.image}`}
                        alt={inputValue.title}
                      />
                      <div className=" w-full file-input-container">
                        <input onChange={handleFileChange} type="file" id="file-input" className="hidden" />
                        <label className=" w-full" htmlFor="file-input">
                          <div className="self-stretch w-full h-[126px] px-6 py-4 rounded-lg border-[0.5px] border-neutral-500 flex-col justify-start items-center gap-1 flex grow shrink basis-0 bg-zinc-100 ">
                            <div className="self-stretch h-[94px] flex-col justify-center items-center gap-3 flex">
                              <div className="w-10 h-10 p-2.5 bg-gray-100 rounded-[28px] border-4 border-gray-50 justify-center items-center inline-flex">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1694_1007)">
                                    <path
                                      d="M13.3335 13.8332L10.0002 10.4999M10.0002 10.4999L6.66688 13.8332M10.0002 10.4999V17.9999M16.9919 15.8249C17.8047 15.3818 18.4467 14.6806 18.8168 13.8321C19.1868 12.9835 19.2637 12.0359 19.0354 11.1388C18.807 10.2417 18.2865 9.44616 17.5558 8.87778C16.8251 8.30939 15.9259 8.00052 15.0002 7.9999H13.9502C13.698 7.02427 13.2278 6.11852 12.5752 5.35073C11.9225 4.58295 11.1042 3.97311 10.182 3.56708C9.25967 3.16104 8.25734 2.96937 7.25031 3.00647C6.24328 3.04358 5.25777 3.30849 4.36786 3.78129C3.47795 4.2541 2.7068 4.92249 2.1124 5.73622C1.51799 6.54996 1.11579 7.48785 0.936028 8.4794C0.756269 9.47095 0.803632 10.4903 1.07456 11.461C1.34548 12.4316 1.83291 13.3281 2.50021 14.0832"
                                      stroke="#777777"
                                      stroke-width="1.66667"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1694_1007">
                                      <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="self-stretch justify-center items-start gap-1 flex-col flex">
                                <div className="justify-center w-full flex-col items-center gap-2 flex">
                                  <div className="text-orange-600 text-sm font-normal font-['Open Sans'] leading-tight">
                                    Click to upload <span className="text-neutral-500 text-sm font-normal font-['Open Sans'] leading-tight">or drag and drop</span>
                                  </div>
                                  <div className="self-stretch text-center text-neutral-500 text-xs font-normal font-['Open Sans'] leading-[18px]">SVG, PNG, JPG or GIF (max. 800x400px)</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="flex w-full p-[10px] justify-center items-center">
                      <button
                        onClick={(e) => {
                          handleSub(e);
                          // handleSubmitPassword(e);
                          setSave(true);
                        }}
                        type="submit"
                        className="w-[180px] h-[38px]  rounded  bg-orange-600 text-center text-white text-base font-semibold font-['Open Sans'] leading-normal tracking-wide"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default CommunicationExco;
