import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import SideBar from "./SideBarExco";
import Footer from "../UserAdmin/Footer";
import Select from "react-select";
import HeaderExco from "./HeaderExco";
import avatar from "../../images/avatar.png";
import SideBarExco from "./SideBarExco";
import axios from "axios";

const category = [
  { value: "Estate Dues", label: "Estate Dues" },
  { value: "Security", label: "Security" },
  { value: "Electricity", label: "Electricity" },
  { value: "Lawma", label: "Lawma" },
  { value: "Others", label: "Others" },
];
const date = [
  { value: "Daily", label: "Daily" },
  { value: "Monthly", label: "Monthly" },
  { value: "Quarterly", label: "Quarterly" },
  { value: "Yearly", label: "Yearly" },
];
function DashboardExco() {
  const [mini, setMini] = useState(false);
  const location = useLocation();
  const [modal, setModal] = useState(false);
  const [categoryOption, setCategoryOption] = useState(null);
  const [save, setSave] = useState(false);
  const [inputField, setInputField] = useState(false);
  const [view, setView] = useState(false);
  const [dateOption, setDateOption] = useState(null);
  const [debt, setDebt] = useState(true);
  const [dropDrown, setDropDrown] = useState(false);
  const [data, setData] = useState([]);
  const [accessCode, setAccessCode] = useState([]);
  const [expiredAccessCode, setExpiredAccessCode] = useState([]);
  const [unUsedAccessCode, setUnUsedAccessCode] = useState([]);
  const [usedAccessCode, setUsedAccessCode] = useState([]);
  const [reciept, setReciept] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(1);

  // data information
  useEffect(() => {
    const fetchData = async () => {
      const dataSend = {
        page: page,
        id: "admin",
      };
      try {
        const res = await axios.post("https://www.myhood.ng/mobile_back_end/api/get_issues.php", dataSend);
        console.log("get issues ", res, dataSend);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [page]);

  // for expired access code
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("https://www.myhood.ng/mobile_back_end/api/list_tokens.php", { status: "expired", rows: "100000", page: "3" }, accessCode);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // for live access code
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("https://www.myhood.ng/mobile_back_end/api/list_tokens.php", { status: "live", rows: "100000", page: "3" }, accessCode);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // for used access code
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("https://www.myhood.ng/mobile_back_end/api/list_tokens.php", { status: "utilized", rows: "100000", page: "3" }, accessCode, { headers: { "Content-Type": "application/json" } });
        console.log(res);
        // setUsedAccessCode(res.data);
        // setAccessCode(res.accessCode);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const menu = [
    {
      id: 1,
      path: "/dashboardexco",
      name: "Dashboard",
      title: "Dashboard",
      icon1: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83334 3.17332 9.83334 4.73812C9.83334 6.30293 11.1018 7.57146 12.6666 7.57146Z" stroke="#333333" />
          <path d="M5 7.57146C6.5648 7.57146 7.83333 6.30293 7.83333 4.73812C7.83333 3.17332 6.5648 1.90479 5 1.90479C3.43519 1.90479 2.16666 3.17332 2.16666 4.73812C2.16666 6.30293 3.43519 7.57146 5 7.57146Z" stroke="#333333" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83334 10.8398 9.83334 12.4046C9.83334 13.9694 11.1018 15.238 12.6666 15.238Z" stroke="#333333" />
          <path d="M5 15.238C6.5648 15.238 7.83333 13.9694 7.83333 12.4046C7.83333 10.8398 6.5648 9.57129 5 9.57129C3.43519 9.57129 2.16666 10.8398 2.16666 12.4046C2.16666 13.9694 3.43519 15.238 5 15.238Z" stroke="#333333" />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83331 3.17332 9.83331 4.73812C9.83331 6.30293 11.1018 7.57146 12.6666 7.57146Z" fill="#F5F5F5" />
          <path d="M4.99997 7.57146C6.56477 7.57146 7.8333 6.30293 7.8333 4.73812C7.8333 3.17332 6.56477 1.90479 4.99997 1.90479C3.43516 1.90479 2.16663 3.17332 2.16663 4.73812C2.16663 6.30293 3.43516 7.57146 4.99997 7.57146Z" fill="#F5F5F5" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83331 10.8398 9.83331 12.4046C9.83331 13.9694 11.1018 15.238 12.6666 15.238Z" fill="#F5F5F5" />
          <path d="M4.99997 15.238C6.56477 15.238 7.8333 13.9694 7.8333 12.4046C7.8333 10.8398 6.56477 9.57129 4.99997 9.57129C3.43516 9.57129 2.16663 10.8398 2.16663 12.4046C2.16663 13.9694 3.43516 15.238 4.99997 15.238Z" fill="#F5F5F5" />
        </svg>
      ),
    },
    {
      id: 2,
      path: "/estatemanagementexco",
      name: "Estate Management",
      title: "Estate Management",
      icon1: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.66665 2.11902L1.33331 5.45235" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8 2.78564V15.4523H4.66667C3.40959 15.4523 2.78105 15.4523 2.39053 15.0618C2 14.6712 2 14.0427 2 12.7856V5.45231" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8 5.45227L14.6667 8.7856" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6.66669 15.4521H11.3334C12.5904 15.4521 13.219 15.4521 13.6095 15.0615C14 14.671 14 14.0425 14 12.7854V8.45227" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12 7.45227V5.45227" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.66669 8.11902H5.33335M4.66669 10.7857H5.33335" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.6667 10.119H11.3334" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11 15.4523V12.7856" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
      icon2: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.66668 2.40466L1.33334 5.73799" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8 3.07129V15.7379H4.66667C3.40959 15.7379 2.78105 15.7379 2.39053 15.3474C2 14.9568 2 14.3283 2 13.0712V5.73796" fill="#F5F5F5" />
          <path d="M8 3.07129V15.7379H4.66667C3.40959 15.7379 2.78105 15.7379 2.39053 15.3474C2 14.9568 2 14.3283 2 13.0712V5.73796" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8 5.73792L14.6667 9.07124" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6.66666 15.7377H11.3333C12.5904 15.7377 13.2189 15.7377 13.6095 15.3471C14 14.9566 14 14.3281 14 13.071V8.73792" fill="#F5F5F5" />
          <path d="M6.66666 15.7377H11.3333C12.5904 15.7377 13.2189 15.7377 13.6095 15.3471C14 14.9566 14 14.3281 14 13.071V8.73792" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12 7.73792V5.73792" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M4.66666 8.40466H5.33332M4.66666 11.0713H5.33332" stroke="#FF5722" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.6667 10.4047H11.3333" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M11 15.738V13.0713V15.738Z" fill="#FF5722" />
          <path d="M11 15.738V13.0713" stroke="#FF5722" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
    },
    {
      id: 3,
      path: "/communicationexco",
      name: "Communication",
      title: "Communication",
      icon1: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 6.78568C12.8032 4.12872 10.3418 2.11902 7.38618 2.11902C4.04364 2.11902 1.33331 4.68916 1.33331 7.85902C1.33331 9.38208 1.95877 10.766 2.97901 11.7929C3.20364 12.019 3.35361 12.328 3.29309 12.6459C3.19319 13.1658 2.96682 13.6507 2.63535 14.0548C3.50747 14.2156 4.41429 14.0708 5.19199 13.6608C5.46691 13.516 5.60436 13.4435 5.70136 13.4288C5.76926 13.4185 5.85771 13.4281 5.99998 13.4525"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.33331 11.6268C7.33331 13.564 8.97518 15.1346 11 15.1346C11.238 15.1349 11.4754 15.1129 11.7093 15.069C11.8776 15.0374 11.9618 15.0216 12.0206 15.0306C12.0793 15.0395 12.1626 15.0838 12.3292 15.1724C12.8002 15.4229 13.3496 15.5114 13.8779 15.4131C13.6771 15.1662 13.54 14.8698 13.4794 14.5521C13.4428 14.3578 13.5336 14.169 13.6697 14.0308C14.2878 13.4032 14.6666 12.5576 14.6666 11.6268C14.6666 9.68968 13.0248 8.11902 11 8.11902C8.97518 8.11902 7.33331 9.68968 7.33331 11.6268Z"
            stroke="#333333"
            strokeLinejoin="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.3333 6.78568C12.8032 4.12872 10.3419 2.11902 7.3862 2.11902C4.04366 2.11902 1.33334 4.68916 1.33334 7.85902C1.33334 9.38208 1.9588 10.766 2.97904 11.7929C3.20366 12.019 3.35364 12.328 3.29311 12.6459C3.19322 13.1658 2.96684 13.6507 2.63537 14.0548C3.50749 14.2156 4.41432 14.0708 5.19201 13.6608C5.46693 13.516 5.60438 13.4435 5.70138 13.4288C5.76928 13.4185 5.85773 13.4281 6 13.4525"
            stroke="#F5F5F5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.33334 11.6268C7.33334 13.564 8.9752 15.1346 11 15.1346C11.2381 15.1349 11.4755 15.1129 11.7093 15.069C11.8777 15.0374 11.9619 15.0216 12.0206 15.0306C12.0793 15.0395 12.1627 15.0838 12.3292 15.1724C12.8003 15.4229 13.3496 15.5114 13.8779 15.4131C13.6771 15.1662 13.54 14.8698 13.4795 14.5521C13.4428 14.3578 13.5337 14.169 13.6697 14.0308C14.2878 13.4032 14.6667 12.5576 14.6667 11.6268C14.6667 9.68968 13.0248 8.11902 11 8.11902C8.9752 8.11902 7.33334 9.68968 7.33334 11.6268Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 4,
      path: "/issuetrackingexco",
      name: "Issue Tracking",
      title: "Issue Tracking",
      icon1: (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.38115 6.74101C5.99027 3.89372 6.79482 2.47006 7.89887 2.1036C8.50621 1.90199 9.16048 1.90199 9.76781 2.1036C10.8719 2.47006 11.6764 3.89372 13.2855 6.74101C14.8947 9.58832 15.6992 11.0119 15.4579 12.1719C15.3251 12.8101 14.9979 13.389 14.5233 13.8255C13.6607 14.6191 12.0516 14.6191 8.83334 14.6191C5.61511 14.6191 4.00599 14.6191 3.14331 13.8255C2.66873 13.389 2.3416 12.8101 2.20882 12.1719C1.96747 11.0119 2.77203 9.58832 4.38115 6.74101Z"
            stroke="#333333"
          />
          <path d="M8.99481 11.619V8.95231C8.99481 8.63804 8.99481 8.48091 8.89714 8.38324C8.79954 8.28564 8.64241 8.28564 8.32814 8.28564" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8.828 6.28564H8.8355" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
      icon2: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.54785 7.52665C5.15697 4.67936 5.96152 3.2557 7.06558 2.88924C7.67291 2.68763 8.32718 2.68763 8.93451 2.88924C10.0386 3.2557 10.8431 4.67936 12.4522 7.52665C14.0614 10.374 14.8659 11.7976 14.6246 12.9576C14.4918 13.5958 14.1646 14.1747 13.69 14.6112C12.8274 15.4047 11.2183 15.4047 8.00004 15.4047C4.78181 15.4047 3.17269 15.4047 2.31002 14.6112C1.83543 14.1747 1.5083 13.5958 1.37552 12.9576C1.13417 11.7976 1.93873 10.374 3.54785 7.52665Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
          />
          <path d="M8.16142 12.4046V9.73796C8.16142 9.42369 8.16142 9.26656 8.06375 9.16889C7.96615 9.07129 7.80902 9.07129 7.49475 9.07129" stroke="#FF5722" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.99463 7.07129H8.00213" stroke="#FF5722" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
    },
  ];
  const activeItem = menu.find((item) => item.path === location.pathname);

  const cards = [
    {
      id: 1,
      text: "Total Issues",
      figure: expiredAccessCode.length + unUsedAccessCode.length + usedAccessCode.length,

      backgroundColor: "#EDF0FF",
      icon: (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.10147 8.51869C7.11287 5.16894 8.11855 3.49405 9.49863 3.06292C10.2578 2.82573 11.0756 2.82573 11.8348 3.06292C13.2149 3.49405 14.2205 5.16894 16.2319 8.51869C18.2434 11.8685 19.249 13.5433 18.9474 14.908C18.7814 15.6588 18.3724 16.3399 17.7792 16.8534C16.7009 17.7869 14.6895 17.7869 10.6667 17.7869C6.64392 17.7869 4.63252 17.7869 3.55418 16.8534C2.96094 16.3399 2.55203 15.6588 2.38606 14.908C2.08437 13.5433 3.09007 11.8685 5.10147 8.51869Z"
            stroke="#333333"
            strokeWidth="1.5"
          />
          <path d="M10.8684 14.2576V11.1204C10.8684 10.7506 10.8684 10.5658 10.7463 10.4509C10.6243 10.336 10.4279 10.336 10.0351 10.336" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M10.6599 7.98311H10.6693" stroke="#333333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
    },
    {
      id: 2,
      text: "Number of Debtors",
      figure: usedAccessCode.length,
      backgroundColor: "#F0FFF1",
      icon: (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.81465 12.9013C4.63568 13.6033 1.54448 15.0367 3.42723 16.8305C4.34694 17.7067 5.37125 18.3333 6.65907 18.3333H14.0076C15.2954 18.3333 16.3198 17.7067 17.2394 16.8305C19.1222 15.0367 16.031 13.6033 14.852 12.9013C12.0873 11.2551 8.57934 11.2551 5.81465 12.9013Z"
            stroke="#333333"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.0833 5.41666C14.0833 7.48773 12.4044 9.16666 10.3333 9.16666C8.26228 9.16666 6.58334 7.48773 6.58334 5.41666C6.58334 3.3456 8.26228 1.66666 10.3333 1.66666C12.4044 1.66666 14.0833 3.3456 14.0833 5.41666Z"
            stroke="#333333"
            strokeWidth="1.5"
          />
        </svg>
      ),
    },
    {
      id: 3,
      text: "Debt Value",
      figure: expiredAccessCode.length,
      backgroundColor: "#FFF3E5",
      icon: (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.4523 14.0295C16.9054 10.7388 15.2027 8.28844 13.7226 6.84917C13.2918 6.43035 13.0765 6.22094 12.6007 6.02714C12.125 5.83334 11.7161 5.83334 10.8982 5.83334H9.10189C8.28407 5.83334 7.87514 5.83334 7.39941 6.02714C6.92368 6.22094 6.70832 6.43035 6.27759 6.84917C4.79741 8.28844 3.09473 10.7388 2.54778 14.0295C2.14083 16.4778 4.39945 18.3333 6.92366 18.3333H13.0765C15.6006 18.3333 17.8593 16.4778 17.4523 14.0295Z"
            stroke="#333333"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.04722 3.70239C5.8753 3.45215 5.62611 3.11249 6.14087 3.03504C6.66997 2.95542 7.21938 3.31761 7.75717 3.31017C8.24368 3.30344 8.49154 3.08766 8.75746 2.77956C9.03746 2.45514 9.47104 1.66666 10 1.66666C10.529 1.66666 10.9626 2.45514 11.2426 2.77956C11.5085 3.08766 11.7564 3.30344 12.2429 3.31017C12.7807 3.31761 13.3301 2.95542 13.8592 3.03504C14.374 3.11249 14.1248 3.45215 13.9529 3.70239L13.1755 4.83386C12.843 5.31788 12.6767 5.55989 12.3287 5.69661C11.9808 5.83333 11.5311 5.83333 10.6319 5.83333H9.36821C8.46896 5.83333 8.01929 5.83333 7.67134 5.69661C7.32339 5.55989 7.15712 5.31788 6.82458 4.83386L6.04722 3.70239Z"
            stroke="#333333"
            strokeWidth="1.25"
            strokeLinejoin="round"
          />
          <path
            d="M11.9581 12.75H11.4661V13.14H11.9581V13.464H11.4661V15H10.8841L9.84006 13.464H8.68806V15H8.10606V13.464H7.60206V13.14H8.10606V12.75H7.60206V12.432H8.10606V10.8H8.68806L9.79806 12.432H10.8961V10.8H11.4661V12.432H11.9581V12.75ZM9.13806 12.432L8.68806 11.772V12.432H9.13806ZM8.68806 13.14H9.61806L9.35406 12.75H8.68806V13.14ZM10.8961 12.75H10.0141L10.2841 13.14H10.8961V12.75ZM10.5001 13.464L10.8961 14.04V13.464H10.5001Z"
            fill="#333333"
          />
        </svg>
      ),
    },
  ];

  return (
    <>
      <div className="flex justify-between items-start">
        <SideBarExco mini={mini} setMini={setMini} menu={menu} />
        {/* LARGE SCREENS */}
        <div className={` 100:hidden xl:flex w-full pr-[25px] pb-[50px] flex flex-col justify-between gap-[22px] h-fit ${mini ? "pl-[130px]" : "pl-[280px]"}`}>
          <HeaderExco mini={mini} name={activeItem ? activeItem.name : null} />
          {/* Button */}
          <div className="flex px-[40px] justify-end items-center">
            <button onClick={() => setModal(true)}>
              <div className="w-fit h-[33px] px-[20px] py-[7px] bg-[#FF5722] rounded text-white text-[14px] font-semibold font-monteserrat tracking-tight justify-center items-center  inline-flex">Add Dues</div>
            </button>
          </div>
          {/* Cards */}
          <div className=" py-[25px]  gap-[10px] flex justify-between items-center ">
            {cards.map((item) => (
              <div title={item.text} className="w-full h-[102px] px-5 py-[21px] rounded-[10px] shadow border border-neutral-200 justify-between items-center inline-flex">
                <div className="py-[5px] flex-col justify-start items-start gap-5 inline-flex">
                  <h4 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[15px] tracking-tight">{item.text}</h4>
                  <h3 className="text-zinc-800 text-xl font-normal font-['Montserrat'] leading-[15px] tracking-tight">{item.figure}</h3>
                </div>
                <div style={{ backgroundColor: item.backgroundColor }} className={`p-2.5 rounded-[100px] justify-center items-center flex `}>
                  {item.icon}
                </div>
              </div>
            ))}
          </div>
          <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <div className=" flex gap-[16px] justify-between items-center w-full">
                <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">User Overview</h4>
                <div className=" rounded-[20px] border-[0.6px] border-solid h-[38px] px-[23px] py-[10px] border-[#9E9E9E] flex justify-between items-center gap-[10px] w-[321px]">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.125 13.125L16.5 16.5" stroke="#777777" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15 8.25C15 4.52208 11.978 1.5 8.25 1.5C4.52208 1.5 1.5 4.52208 1.5 8.25C1.5 11.978 4.52208 15 8.25 15C11.978 15 15 11.978 15 8.25Z" stroke="#777777" strokeWidth="1.125" strokeLinejoin="round" />
                  </svg>
                  <input
                    placeholder="Search user"
                    className=" text-[16px] font-monteserrat font-medium text-[#333] placeholder:text-[14px] placeholder:font-monteserrat placeholder:font-normal w-full h-full bg-transparent outline-none border-none"
                    type="text"
                  />
                </div>
                <div className="w-[75px] h-[38px] px-[5px] bg-neutral-100 rounded-[10px] shadow justify-start items-center gap-[5px] inline-flex">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 11.5H14.5001" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.5 15H13.5" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.5 8H15.5" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>

                  <div className="text-neutral-500 text-sm font-normal font-['Montserrat'] tracking-tight">Filter</div>
                </div>
              </div>
            </section>
            {/* Table Starts here */}
            <div className=" flex justify-between w-full items-center">
              <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">User Name </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu Araromi </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Chief Aromire Peace </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Chief Aromire Peace </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Chief Aromire Peace </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Emmanuel Adelateef</h2>
                </div>
              </div>
              <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Unit Number </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">#1234567890 </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">#1234567890 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">#1234567890 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">#1234567890 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">#1234567890 </h2>
                </div>
              </div>
              <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Contact Information </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">234 819 144 2340 </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">234 819 144 2340 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">234 819 144 2340 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">234 819 144 2340 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">234 819 144 2340 </h2>
                </div>
              </div>
              <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Account Status </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-[#E52C2C] text-xs font-normal w-fit rounded-[16px] h-[22px] bg-[#FFECEC] flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]">
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Inactive
                  </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-[#4CAF50] text-xs font-normal w-fit rounded-[16px] h-[22px] bg-[#E6FFE7] flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]">
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Active
                  </h2>{" "}
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-[#E52C2C] text-xs font-normal w-fit rounded-[16px] h-[22px] bg-[#FFECEC] flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]">
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Inactive
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-[#4CAF50] text-xs font-normal w-fit rounded-[16px] h-[22px] bg-[#E6FFE7] flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]">
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Active
                  </h2>{" "}
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-[#4CAF50] text-xs font-normal w-fit rounded-[16px] h-[22px] bg-[#E6FFE7] flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]">
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Active
                  </h2>{" "}
                </div>{" "}
              </div>
              <div className="w-[250px] cursor-pointer h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Action </h2>
                </div>
                <div onClick={() => setView(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                      stroke="#333333"
                      strokeWidth="0.875"
                    />
                    <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" strokeWidth="0.875" />
                  </svg>
                  View
                </div>
                <div onClick={() => setView(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                      stroke="#333333"
                      strokeWidth="0.875"
                    />
                    <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" strokeWidth="0.875" />
                  </svg>
                  View
                </div>
                <div onClick={() => setView(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                      stroke="#333333"
                      strokeWidth="0.875"
                    />
                    <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" strokeWidth="0.875" />
                  </svg>
                  View
                </div>
                <div onClick={() => setView(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                      stroke="#333333"
                      strokeWidth="0.875"
                    />
                    <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" strokeWidth="0.875" />
                  </svg>
                  View
                </div>
                <div onClick={() => setView(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                      stroke="#333333"
                      strokeWidth="0.875"
                    />
                    <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" strokeWidth="0.875" />
                  </svg>
                  View
                </div>
              </div>{" "}
            </div>
          </div>
          <Footer />
        </div>
        {/* SMALL SCREENS */}
        <div className=" 100:flex xl:hidden w-full pr-[25px] pb-[50px] flex-col justify-between gap-[22px] h-fit pl-[130px]">
          <HeaderExco mini={mini} name={activeItem ? activeItem.name : null} />
          {/* Button */}
          <div className="flex  justify-end items-center">
            <button onClick={() => setModal(true)}>
              <div className="w-fit h-[33px] px-[20px] py-[7px] bg-[#FF5722] rounded text-white text-[14px] font-semibold font-monteserrat tracking-tight justify-center items-center  inline-flex">Add Dues</div>
            </button>
          </div>
          {/* Cards */}
          <div className=" py-[25px]  gap-[10px] flex flex-col justify-between items-center ">
            {cards.map((item) => (
              <div title={item.text} className="w-full h-[102px] px-5 py-[21px] rounded-[10px] shadow border border-neutral-200 justify-between items-center inline-flex">
                <div className="py-[5px] flex-col justify-start items-start gap-5 inline-flex">
                  <h4 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[15px] tracking-tight">{item.text}</h4>
                  <h3 className="text-zinc-800 text-xl font-normal font-['Montserrat'] leading-[15px] tracking-tight">{item.figure}</h3>
                </div>
                <div style={{ backgroundColor: item.backgroundColor }} className={`p-2.5 rounded-[100px] justify-center items-center flex `}>
                  {item.icon}
                </div>
              </div>
            ))}
          </div>
          <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <div className=" flex gap-[16px] justify-between items-center w-full">
                <h4 className={`tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ${inputField ? " hidden" : " block"}`}>User Overview</h4>
                <div className={`rounded-[20px] border-[0.6px] border-solid h-[38px] px-[10px] py-[10px] border-[#9E9E9E] flex justify-between items-center gap-[10px] ${inputField ? "w-full" : "w-fit"} `}>
                  <svg onClick={() => setInputField(!inputField)} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.125 13.125L16.5 16.5" stroke="#777777" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15 8.25C15 4.52208 11.978 1.5 8.25 1.5C4.52208 1.5 1.5 4.52208 1.5 8.25C1.5 11.978 4.52208 15 8.25 15C11.978 15 15 11.978 15 8.25Z" stroke="#777777" strokeWidth="1.125" strokeLinejoin="round" />
                  </svg>
                  {inputField && (
                    <input
                      placeholder="Search user"
                      className=" text-[16px] font-monteserrat font-medium text-[#333] placeholder:text-[14px] placeholder:font-monteserrat placeholder:font-normal w-full h-full bg-transparent outline-none border-none"
                      type="text"
                    />
                  )}
                </div>
                <div className="w-fit h-[38px] px-[10px] bg-neutral-100 rounded-[10px] shadow justify-start items-center gap-[5px] inline-flex">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 11.5H14.5001" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.5 15H13.5" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.5 8H15.5" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
            </section>
            {/* Table Starts here */}
            <div className=" flex overflow-x-scroll whitespace-nowrap justify-between w-full items-center">
              <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">User Name </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu Araromi </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Chief Aromire Peace </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Chief Aromire Peace </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Chief Aromire Peace </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Emmanuel Adelateef</h2>
                </div>
              </div>
              <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Unit Number </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">#1234567890 </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">#1234567890 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">#1234567890 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">#1234567890 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">#1234567890 </h2>
                </div>
              </div>
              <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Contact Information </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">234 819 144 2340 </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">234 819 144 2340 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">234 819 144 2340 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">234 819 144 2340 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">234 819 144 2340 </h2>
                </div>
              </div>
              <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Account Status </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-[#E52C2C] text-xs font-normal w-fit rounded-[16px] h-[22px] bg-[#FFECEC] flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]">
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Inactive
                  </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-[#4CAF50] text-xs font-normal w-fit rounded-[16px] h-[22px] bg-[#E6FFE7] flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]">
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Active
                  </h2>{" "}
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-[#E52C2C] text-xs font-normal w-fit rounded-[16px] h-[22px] bg-[#FFECEC] flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]">
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Inactive
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-[#4CAF50] text-xs font-normal w-fit rounded-[16px] h-[22px] bg-[#E6FFE7] flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]">
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Active
                  </h2>{" "}
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-[#4CAF50] text-xs font-normal w-fit rounded-[16px] h-[22px] bg-[#E6FFE7] flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]">
                    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    Active
                  </h2>{" "}
                </div>{" "}
              </div>
              <div className="w-[250px] cursor-pointer h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Action </h2>
                </div>
                <div onClick={() => setView(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                      stroke="#333333"
                      strokeWidth="0.875"
                    />
                    <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" strokeWidth="0.875" />
                  </svg>
                  View
                </div>
                <div onClick={() => setView(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                      stroke="#333333"
                      strokeWidth="0.875"
                    />
                    <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" strokeWidth="0.875" />
                  </svg>
                  View
                </div>
                <div onClick={() => setView(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                      stroke="#333333"
                      strokeWidth="0.875"
                    />
                    <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" strokeWidth="0.875" />
                  </svg>
                  View
                </div>
                <div onClick={() => setView(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                      stroke="#333333"
                      strokeWidth="0.875"
                    />
                    <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" strokeWidth="0.875" />
                  </svg>
                  View
                </div>
                <div onClick={() => setView(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                      stroke="#333333"
                      strokeWidth="0.875"
                    />
                    <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" strokeWidth="0.875" />
                  </svg>
                  View
                </div>
              </div>{" "}
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {modal && (
        <>
          <div className=" w-full bg-black bg-opacity-40 flex justify-center items-center h-screen fixed top-0 left-0">
            <div className=" md:w-fit 100:w-[85%]  p-[10px]  h-fit gap-[10px] items-start flex flex-col rounded-[20px] bg-[#F5F5F5] ">
              <div className=" w-full bg-[#FFE2D9] h-[80px] py-[20px] px-[30px] rounded-[20px] flex justify-between items-center ">
                {save ? <div></div> : <h3 className=" font-openSans tracking-[-0.4px] text-[#333] font-semibold text-[20px]">Add Dues</h3>}{" "}
                <div onClick={() => setModal(false)} className=" bg-[#B8A9A9] rounded-full  cursor-pointer w-fit p-[10px]">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 4.16675L4.1665 15.8334M4.1665 4.16675L15.8332 15.8334" stroke="#F5F5F5" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="md:w-[728px] 100:w-full h-fit px-[30px] py-[30px] bg-white rounded-[20px] flex-col justify-between items-center gap-[40px] inline-flex">
                {save ? (
                  <>
                    <div className="flex gap-[40px] flex-col justify-between items-center">
                      <div className="w-20 h-20 p-5 bg-emerald-100 rounded-[100px] border-8 border-green-50 justify-center items-center gap-2.5 inline-flex">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.33325 23.333L14.1666 29.1663L31.6666 10.833" stroke="#4CAF50" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                      <div className=" text-center text-zinc-800 text-sm font-semibold font-openSans leading-[23px] tracking-tight">Dues added successfully</div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex-col w-full justify-start items-center gap-[30px] flex">
                      <form className="sm:w-[527px] 100:w-full h-fit flex-col justify-start items-start gap-[20px] inline-flex">
                        <div className=" h-[61px] flex-col w-full justify-start items-start gap-[8px] flex">
                          <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Name of Due</h3>
                          <input
                            placeholder="Enter Name of Due"
                            type="text"
                            name="category"
                            className=" w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-['Montserrat'] tracking-tight rounded border-[0.5px] border-neutral-500 "
                          />
                        </div>
                        <div className=" h-[61px] flex-col w-full justify-start items-start gap-[8px] flex">
                          <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Amount Due</h3>
                          <input
                            placeholder="Enter Amount Due"
                            type="text"
                            name="category"
                            className=" w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-['Montserrat'] tracking-tight rounded border-[0.5px] border-neutral-500 "
                          />
                        </div>
                        <div className=" h-[61px] flex-col w-full justify-start items-start gap-[8px] flex">
                          <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Category</h3>
                          <Select
                            placeholder="Select Category"
                            className="w-full"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? "#FF5722" : "grey",

                                height: "37px",
                                color: "#333",
                                paddingLeft: "10px",
                                borderRadius: "4px",
                                text: "14px",
                                borderBlockWidth: "0.5px",
                                background: "#EEEEEE",
                                placeholder: "#9E9E9E",
                                fontFamily: "open sans",
                                outline: "#FF5722",
                                fontWeight: "semibold",
                              }),
                            }}
                            defaultValue={categoryOption}
                            onChange={setCategoryOption}
                            options={category}
                          />
                        </div>
                        <div className=" h-[61px] flex-col w-full justify-start items-start gap-[8px] flex">
                          <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Effective Date</h3>
                          <Select
                            placeholder="Select Effective Date"
                            className="w-full"
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? "#FF5722" : "grey",

                                height: "37px",
                                color: "#333",
                                paddingLeft: "10px",
                                borderRadius: "4px",
                                text: "14px",
                                borderBlockWidth: "0.5px",
                                background: "#EEEEEE",
                                placeholder: "#9E9E9E",
                                fontFamily: "open sans",
                                outline: "#FF5722",
                                fontWeight: "semibold",
                              }),
                            }}
                            defaultValue={dateOption}
                            onChange={setDateOption}
                            options={date}
                          />
                        </div>
                      </form>
                    </div>
                    <div className="flex w-full py-[10px] justify-center items-center">
                      <button onClick={() => setSave(true)}>
                        <div className="w-fit h-[33px] px-[57px] py-[7px] bg-[#FF5722] rounded text-white text-[14px] font-semibold font-monteserrat tracking-tight justify-center items-center  inline-flex">Confrim</div>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {view && (
        <>
          <div className=" w-full bg-black bg-opacity-40 flex justify-center items-center h-screen fixed top-0 left-0">
            <div className=" md:w-fit  100:w-[85%] p-[10px]  h-fit gap-[10px] items-start justify-center flex flex-col rounded-[20px] bg-[#F5F5F5] ">
              <div className=" w-full bg-[#FFE2D9] h-[80px] py-[20px] px-[30px] rounded-[20px] flex justify-between items-center ">
                <h3 className=" font-openSans tracking-[-0.4px] text-[#333] font-semibold text-[20px]">User Profile</h3>
                <div onClick={() => setView(false)} className=" bg-[#B8A9A9] rounded-full  cursor-pointer w-fit p-[10px]">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 4.16675L4.1665 15.8334M4.1665 4.16675L15.8332 15.8334" stroke="#F5F5F5" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <div className="flex gap-[0px] 100:flex-col md:flex-row  w-full h-fit justify-between items-center">
                <div className="w-full md:h-[365px]">
                  <img src={avatar} className="w-full h-full" alt="User" />
                </div>
                <div className=" w-full h-full px-[30px] py-[20px] bg-white rounded-[20px] flex-col justify-between items-center gap-[20px] inline-flex">
                  <div className=" w-full flex  justify-end items-end">
                    <div className="w-[85px] h-[37px] p-2.5 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-[5px] inline-flex">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M2.51833 7.96152C1.88632 6.85945 1.58115 5.95957 1.39714 5.04739C1.125 3.69831 1.74779 2.38046 2.7795 1.53957C3.21555 1.18417 3.7154 1.3056 3.97325 1.76819L4.55537 2.81253C5.01678 3.6403 5.24748 4.05418 5.20172 4.49298C5.15596 4.93178 4.84483 5.28916 4.22256 6.00393L2.51833 7.96152ZM2.51833 7.96152C3.79759 10.1921 5.80514 12.2008 8.03832 13.4815M8.03832 13.4815C9.14038 14.1135 10.0403 14.4187 10.9525 14.6027C12.3015 14.8749 13.6194 14.2521 14.4603 13.2203C14.8157 12.7843 14.6943 12.2845 14.2317 12.0266L13.1873 11.4445C12.3595 10.9831 11.9457 10.7524 11.5069 10.7981C11.0681 10.8439 10.7107 11.155 9.99592 11.7773L8.03832 13.4815Z"
                          stroke="#667085"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <h3 className="text-gray-500 text-xs font-normal font-['Open Sans'] leading-[17.40px]">Contact</h3>
                    </div>
                  </div>
                  <div className=" w-full whitespace-nowrap 100:flex-col  md:flex-row justify-between p-[10px] 100:items-start md:items-center gap-[30px] inline-flex">
                    <div className=" flex flex-col justify-between gap-[50px] items-start">
                      <div className="pr-5 pt-px flex-col justify-center items-start gap-[7px] inline-flex">
                        <h4 className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Full Name:</h4>
                        <h3 className="text-zinc-800 text-sm font-semibold font-['Open Sans']">Adewale Ayuba</h3>
                      </div>
                      <div className="pr-5 pt-px flex-col justify-center items-start gap-[7px] inline-flex">
                        <h4 className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Unit Number:</h4>
                        <h3 className="text-zinc-800 text-sm font-semibold font-['Open Sans']">ABC12345678</h3>
                      </div>
                      <div className="pr-5 pt-px flex-col justify-center items-start gap-[7px] inline-flex">
                        <h4 className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Registered Vehicle:</h4>
                        <h3 className="text-zinc-800 text-sm font-semibold font-['Open Sans']">Toyota Camry 2020</h3>
                      </div>
                    </div>
                    <div className=" flex flex-col justify-between gap-[50px] items-start">
                      <div className="pr-5 pt-px flex-col justify-center items-start gap-[7px] inline-flex">
                        <h4 className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Contact Information:</h4>
                        <h3 className="text-zinc-800 text-sm font-semibold font-['Open Sans']">+234 814 299 4440</h3>
                      </div>

                      <div className="pr-5 pt-px flex-col justify-center items-start gap-[7px] inline-flex">
                        <h4 className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Status:</h4>
                        <h2 className="text-[#4CAF50] text-xs font-normal w-fit rounded-[16px] h-[22px] bg-[#E6FFE7] flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]">
                          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                          Active
                        </h2>{" "}
                      </div>
                      <div className="pr-5 pt-px flex-col justify-center items-start gap-[7px] inline-flex">
                        <h4 className="text-neutral-500 text-xs font-semibold font-['Open Sans']">License Plate Number:</h4>
                        <h3 className="text-zinc-800 text-sm font-semibold font-['Open Sans']">ABC 123 DEF</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DashboardExco;
