function FAQs() {
  return (
    <>
      {/* BIG SCREENS */}
      <div className="w-full xl:flex 100:hidden  justify-center items-center">
        <div className="w-[85%] flex flex-col gap-[50px] justify-between items-center">
          <h3 className="text-zinc-800 text-[40px] font-semibold font-openSans tracking-tight">Explore Common Queries</h3>
          <div className="flex  gap-[30px] justify-evenly flex-wrap w-[1230px]">
            <div className="w-[600px] h-[210px] p-[25px] bg-green-500 bg-opacity-10 rounded-[10px] flex-col justify-center items-start gap-2.5 inline-flex">
              <h4 className="text-zinc-800 text-2xl font-medium font-['Montserrat'] leading-[45px] tracking-tight">What is myHood?</h4>
              <p className="text-neutral-500 text-base font-normal font-monteserrat leading-[35px] tracking-tight">
                <span className="text-zinc-800">Answer:</span> myHood empowers communities by offering a centralized space for residents to connect, share information, and actively engage in local happenings.
              </p>
            </div>
            <div className="w-[600px] h-[210px] p-[25px] bg-green-500 bg-opacity-10 rounded-[10px] flex-col justify-center items-start gap-2.5 inline-flex">
              <h4 className="text-zinc-800 text-2xl font-medium font-['Montserrat'] leading-[45px] tracking-tight">How can i join?</h4>
              <p className="text-neutral-500 text-base font-normal font-monteserrat leading-[35px] tracking-tight">
                <span className="text-zinc-800">Answer:</span> Joining myHood is easy! Simply download the app, create an account, and start connecting with your neighbors.
              </p>
            </div>
            <div className="w-[600px] h-[210px] p-[25px] bg-green-500 bg-opacity-10 rounded-[10px] flex-col justify-center items-start gap-2.5 inline-flex">
              <h4 className="text-zinc-800 text-2xl font-medium font-['Montserrat'] leading-[45px] tracking-tight">Is myHood Available on Both iOS and Android?</h4>
              <p className="text-neutral-500 text-base font-normal font-monteserrat leading-[35px] tracking-tight">
                <span className="text-zinc-800">Answer:</span> Yes, myHood is available on both iOS and Android platforms, ensuring accessibility for a wide range of users.
              </p>
            </div>
            <div className="w-[600px] h-[210px] p-[25px] bg-green-500 bg-opacity-10 rounded-[10px] flex-col justify-center items-start gap-2.5 inline-flex">
              <h4 className="text-zinc-800 text-2xl font-medium font-['Montserrat'] leading-[45px] tracking-tight">How Can I Contact myHood Support?</h4>
              <p className="text-neutral-500 text-base font-normal font-monteserrat leading-[35px] tracking-tight">
                <span className="text-zinc-800">Answer:</span> myHood offers a range of features, including community updates, event notifications, safety alerts, and more. Explore the app to discover the full suite of functionalities.
              </p>
            </div>
            <div className="w-[600px] h-[210px] p-[25px] bg-green-500 bg-opacity-10 rounded-[10px] flex-col justify-center items-start gap-2.5 inline-flex">
              <h4 className="text-zinc-800 text-2xl font-medium font-['Montserrat'] leading-[45px] tracking-tight">Is my Information Secure on myHood?</h4>
              <p className="text-neutral-500 text-base font-normal font-monteserrat leading-[35px] tracking-tight">
                <span className="text-zinc-800">Answer:</span>Yes, we take the security and privacy of your information seriously. myHood employs robust security measures to protect user data.
              </p>
            </div>
            <div className="w-[600px] h-[210px] p-[25px] bg-green-500 bg-opacity-10 rounded-[10px] flex-col justify-center items-start gap-2.5 inline-flex">
              <h4 className="text-zinc-800 text-2xl font-medium font-['Montserrat'] leading-[45px] tracking-tight">How Can I Contact myHood Support?</h4>
              <p className="text-neutral-500 text-base font-normal font-monteserrat leading-[35px] tracking-tight">
                <span className="text-zinc-800">Answer:</span> For support or inquiries, you can reach out to our customer support team through the "Contact Us" section in the app.
              </p>
            </div>{" "}
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM MOBILE TO 1023PX */}
      <div className="w-full 100:flex mt-[89px] xl:hidden justify-center items-center">
        <div className="100:w-[90%]   flex flex-col gap-[50px] justify-between items-center">
          <h3 className="text-zinc-800 text-[30px] font-semibold font-openSans tracking-tight">Explore Common Queries</h3>
          <div className="100:w-full  items-center flex-wrap flex justify-between gap-[30px]">
            <div className="w-[300px] flex-grow  h-[350px] p-[20px] bg-green-500 bg-opacity-10 rounded-[10px] flex-col justify-center items-start gap-2.5 inline-flex">
              <h4 className="text-zinc-800 text-[20px] font-medium font-['Montserrat'] leading-[45px] tracking-tight">What is myHood?</h4>
              <p className="text-neutral-500 text-base font-normal font-monteserrat leading-[35px] tracking-tight">
                <span className="text-zinc-800">Answer:</span> myHood empowers communities by offering a centralized space for residents to connect, share information, and actively engage in local happenings.
              </p>
            </div>
            <div className="w-[300px] flex-grow  h-[350px] p-[20px] bg-green-500 bg-opacity-10 rounded-[10px] flex-col justify-center items-start gap-2.5 inline-flex">
              <h4 className="text-zinc-800 text-[20px] font-medium font-['Montserrat'] leading-[45px] tracking-tight">How can i join?</h4>
              <p className="text-neutral-500 text-base font-normal font-monteserrat leading-[35px] tracking-tight">
                <span className="text-zinc-800">Answer:</span> Joining myHood is easy! Simply download the app, create an account, and start connecting with your neighbors.
              </p>
            </div>
            <div className="w-[300px] flex-grow  h-[350px] p-[20px] bg-green-500 bg-opacity-10 rounded-[10px] flex-col justify-center items-start gap-2.5 inline-flex">
              <h4 className="text-zinc-800 text-[20px] font-medium font-['Montserrat'] leading-[45px] tracking-tight">Is myHood Available on Both iOS and Android?</h4>
              <p className="text-neutral-500 text-base font-normal font-monteserrat leading-[35px] tracking-tight">
                <span className="text-zinc-800">Answer:</span> Yes, myHood is available on both iOS and Android platforms, ensuring accessibility for a wide range of users.
              </p>
            </div>
            <div className="w-[300px] flex-grow  h-[350px] p-[20px] bg-green-500 bg-opacity-10 rounded-[10px] flex-col justify-center items-start gap-2.5 inline-flex">
              <h4 className="text-zinc-800 text-[20px] font-medium font-['Montserrat'] leading-[45px] tracking-tight">How Can I Contact myHood Support?</h4>
              <p className="text-neutral-500 text-base font-normal font-monteserrat leading-[35px] tracking-tight">
                <span className="text-zinc-800">Answer:</span> myHood offers a range of features, including community updates, event notifications, safety alerts, and more. Explore the app to discover the full suite of functionalities.
              </p>
            </div>
            <div className="w-[300px] flex-grow  h-[350px] p-[20px] bg-green-500 bg-opacity-10 rounded-[10px] flex-col justify-center items-start gap-2.5 inline-flex">
              <h4 className="text-zinc-800 text-[20px] font-medium font-['Montserrat'] leading-[45px] tracking-tight">Is my Information Secure on myHood?</h4>
              <p className="text-neutral-500 text-base font-normal font-monteserrat leading-[35px] tracking-tight">
                <span className="text-zinc-800">Answer:</span>Yes, we take the security and privacy of your information seriously. myHood employs robust security measures to protect user data.
              </p>
            </div>
            <div className="w-[300px] flex-grow  h-[350px] p-[20px] bg-green-500 bg-opacity-10 rounded-[10px] flex-col justify-center items-start gap-2.5 inline-flex">
              <h4 className="text-zinc-800 text-[20px] font-medium font-['Montserrat'] leading-[45px] tracking-tight">How Can I Contact myHood Support?</h4>
              <p className="text-neutral-500 text-base font-normal font-monteserrat leading-[35px] tracking-tight">
                <span className="text-zinc-800">Answer:</span> For support or inquiries, you can reach out to our customer support team through the "Contact Us" section in the app.
              </p>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default FAQs;
