import { Link } from "react-router-dom";
import mobile from "../images/myhood mobile.png";
import About from "./About";
import Features from "./Features";
import JoinUs from "./JoinUs";
import Testimonial from "./Testimonial";
import { useRef } from "react";
import Download from "./Download";
import FAQs from "./FAQs";
import Footer from "./Footer";
import Header from "./Header";

function HeroSection() {
  const aboutUs = useRef(null);
  const faQs = useRef(null);
  const contact = useRef(null);
  const features = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Header scrollToSection={scrollToSection} aboutUs={aboutUs} faQs={faQs} contact={contact} features={features} />
      {/* BIG SCREENS */}
      <div className=" mobile:hidden lg:flex w-[100%] 100:hidden  justify-center flex items-center ">
        <div className="w-[670px] flex flex-col items-center gap-[15px] text-zinc-800 lg:mt-[150px] xl:mt-[200px]  text-center">
          <h1 className="text-[52px] text-[#333333] font-bold font-openSans leading-[79px] tracking-wide ">
            <span className="text-[#4CAF50]">my</span>
            <span className="text-[#FF5722]">Hood</span>: Empowering Communities Together!
          </h1>
          <p className="text-neutral-500 text-2xl w-[600px] font-normal font-monteserrat leading-[42px] tracking-tight">Welcome to MyHood – your digital haven for community empowerment. Join us and redefine your community experience!</p>
          <button>
            <Link to="">
              <div className="w-[248px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-xl font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex">Download for Free</div>
            </Link>
          </button>
          <img src={mobile} alt="mobile" />
        </div>
      </div>
      {/* SMALL SCREENS FROM MOBILE TO 1023PX */}
      <div className="mobile:flex lg:hidden w-full justify-center items-center ">
        <div className="flex flex-col text-center mobile:w-[92%] md:max-w-[70%] justify-between items-center mt-[150px] gap-[20px] ">
          <h1 className="text-[32px] font-bold font-openSans leading-[60px] tracking-wide ">
            <span className="text-[#4CAF50]">my</span>
            <span className="text-[#FF5722]">Hood</span>: Empowering Communities Together!
          </h1>
          <p className="text-neutral-500 text-[17px] font-normal font-monteserrat leading-[38px] tracking-tight">Welcome to MyHood – your digital haven for community empowerment. Join us and redefine your community experience!</p>
          <button>
            <Link to="">
              <div className="w-[248px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[18px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex">Download for Free</div>
            </Link>
          </button>
          <img src={mobile} alt="mobile" />
        </div>
      </div>
      <div ref={aboutUs}>
        <About />
      </div>
      <div ref={features}>
        <Features />
      </div>

      <JoinUs />
      <Testimonial />
      <Download />
      <div ref={faQs}>
        <FAQs />
      </div>
      <div ref={contact}>
        <Footer />
      </div>
    </>
  );
}

export default HeroSection;
