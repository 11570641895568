import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import SideBar from "./SideBar";
import illustration from "../../images/Empty State Illustration.png";
import Footer from "./Footer";
import footware from "../../images/footware.png";
// import { axiosInstance } from "../../Utils";
import InputFiles from "react-input-files";
import moment from "moment";
import axios from "axios";

function AdvertManagement() {
  const initialData = {
    image: footware, // Initial image
    category: "Clothing", // Initial category
    status: "Pending", // Initial status
    duration: "14 December 2024", // Initial duration
  };
  const [mini, setMini] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [modal, setModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState(initialData);
  const [inputField, setInputField] = useState(false);
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [page, setPage] = useState(1);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const dataSend = {
        title: "ade",
        description: "kk",
        image: "k",
        start_date: "12-12-2012",
        contact_phone: "1",
        contact_email: "aaa",
        user_id: 11,
        number_of_days: 10,
        actionid: 2,
      };
      try {
        const res = await axios.post("https://www.myhood.ng/mobile_back_end/api/adverts.php", dataSend);
        console.log("get advert ", res.data, dataSend);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [page]);
  const menu = [
    {
      id: 1,
      path: "/dashboarduser",
      name: "Dashboard",
      title: "Dashboard",
      icon1: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83334 3.17332 9.83334 4.73812C9.83334 6.30293 11.1018 7.57146 12.6666 7.57146Z" stroke="#333333" />
          <path d="M5 7.57146C6.5648 7.57146 7.83333 6.30293 7.83333 4.73812C7.83333 3.17332 6.5648 1.90479 5 1.90479C3.43519 1.90479 2.16666 3.17332 2.16666 4.73812C2.16666 6.30293 3.43519 7.57146 5 7.57146Z" stroke="#333333" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83334 10.8398 9.83334 12.4046C9.83334 13.9694 11.1018 15.238 12.6666 15.238Z" stroke="#333333" />
          <path d="M5 15.238C6.5648 15.238 7.83333 13.9694 7.83333 12.4046C7.83333 10.8398 6.5648 9.57129 5 9.57129C3.43519 9.57129 2.16666 10.8398 2.16666 12.4046C2.16666 13.9694 3.43519 15.238 5 15.238Z" stroke="#333333" />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83331 3.17332 9.83331 4.73812C9.83331 6.30293 11.1018 7.57146 12.6666 7.57146Z" fill="#F5F5F5" />
          <path d="M4.99997 7.57146C6.56477 7.57146 7.8333 6.30293 7.8333 4.73812C7.8333 3.17332 6.56477 1.90479 4.99997 1.90479C3.43516 1.90479 2.16663 3.17332 2.16663 4.73812C2.16663 6.30293 3.43516 7.57146 4.99997 7.57146Z" fill="#F5F5F5" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83331 10.8398 9.83331 12.4046C9.83331 13.9694 11.1018 15.238 12.6666 15.238Z" fill="#F5F5F5" />
          <path d="M4.99997 15.238C6.56477 15.238 7.8333 13.9694 7.8333 12.4046C7.8333 10.8398 6.56477 9.57129 4.99997 9.57129C3.43516 9.57129 2.16663 10.8398 2.16663 12.4046C2.16663 13.9694 3.43516 15.238 4.99997 15.238Z" fill="#F5F5F5" />
        </svg>
      ),
    },
    {
      id: 2,
      path: "/paymentuser",
      name: "Payment",
      title: "Payment",
      icon1: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_1356_2637" maskUnits="userSpaceOnUse" x="0" y="1" width="16" height="17">
            <path d="M16 1.07129H0V17.0713H16V1.07129Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1356_2637)">
            <path
              d="M9.33333 13.0712C12.2789 13.0712 14.6667 10.6833 14.6667 7.73787C14.6667 4.79235 12.2789 2.40454 9.33333 2.40454C6.38781 2.40454 4 4.79235 4 7.73787C4 10.6833 6.38781 13.0712 9.33333 13.0712Z"
              stroke="#333333"
              stroke-linecap="round"
            />
            <path d="M2.10442 8.40454C1.61686 9.14958 1.33337 10.0402 1.33337 10.9971C1.33337 13.6153 3.45589 15.7378 6.07415 15.7378C7.03102 15.7378 7.92167 15.4543 8.66671 14.9668" stroke="#333333" stroke-linecap="round" />
            <path
              d="M11.9727 7.83441H11.3567V8.23341H11.9727V8.66741H11.3567V10.4454H10.4817L8.24874 7.12041V10.4454H7.33874V8.66741H6.72974V8.23341H7.33874V7.83441H6.72974V7.40041H7.33874V5.54541H8.21374L10.4607 8.89141V5.54541H11.3567V7.40041H11.9727V7.83441Z"
              fill="#333333"
            />
          </g>
        </svg>
      ),
      icon2: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_1388_7257" maskUnits="userSpaceOnUse" x="0" y="1" width="16" height="17">
            <path d="M16 1.07129H0V17.0713H16V1.07129Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1388_7257)">
            <path
              d="M9.33333 13.0712C12.2789 13.0712 14.6667 10.6833 14.6667 7.73787C14.6667 4.79235 12.2789 2.40454 9.33333 2.40454C6.38781 2.40454 4 4.79235 4 7.73787C4 10.6833 6.38781 13.0712 9.33333 13.0712Z"
              fill="#F5F5F5"
              stroke="#F5F5F5"
              stroke-linecap="round"
            />
            <path d="M2.10442 8.40454C1.61686 9.14958 1.33337 10.0402 1.33337 10.9971C1.33337 13.6153 3.45589 15.7378 6.07415 15.7378C7.03102 15.7378 7.92167 15.4543 8.66671 14.9668" stroke="#F5F5F5" stroke-linecap="round" />
            <path
              d="M11.9727 7.83441H11.3567V8.23341H11.9727V8.66741H11.3567V10.4454H10.4817L8.24874 7.12041V10.4454H7.33874V8.66741H6.72974V8.23341H7.33874V7.83441H6.72974V7.40041H7.33874V5.54541H8.21374L10.4607 8.89141V5.54541H11.3567V7.40041H11.9727V7.83441Z"
              fill="#FF5722"
            />
          </g>
        </svg>
      ),
    },
    {
      id: 3,
      path: "/advertmanagementuser",
      name: "Advert Management",
      title: "Advert Management",
      icon1: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.67053 11.7323L5.35427 7.09046C5.66045 6.44611 6.12866 5.9132 6.66514 7.19129C7.16036 8.3711 7.89923 10.5314 8.33536 11.7348M4.43555 9.7393H7.54763" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M2.30969 3.94997C1.33337 4.82865 1.33337 6.24287 1.33337 9.07129C1.33337 11.8996 1.33337 13.3139 2.30969 14.1926C3.28599 15.0712 4.85735 15.0712 8.00004 15.0712C11.1427 15.0712 12.7141 15.0712 13.6904 14.1926C14.6667 13.3139 14.6667 11.8996 14.6667 9.07129C14.6667 6.24287 14.6667 4.82865 13.6904 3.94997C12.7141 3.07129 11.1427 3.07129 8.00004 3.07129C4.85735 3.07129 3.28599 3.07129 2.30969 3.94997Z"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.3228 7.0625V9.05895M12.3228 9.05895V11.688M12.3228 9.05895H10.9773C10.8175 9.05895 10.659 9.08868 10.5096 9.14662C9.38042 9.58475 9.38042 11.2128 10.5096 11.651C10.659 11.7089 10.8175 11.7386 10.9773 11.7386H12.3228"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.14304 3.94997C2.16672 4.82865 2.16672 6.24287 2.16672 9.07129C2.16672 11.8996 2.16672 13.3139 3.14304 14.1926C4.11934 15.0712 5.6907 15.0712 8.83339 15.0712C11.976 15.0712 13.5474 15.0712 14.5237 14.1926C15.5 13.3139 15.5 11.8996 15.5 9.07129C15.5 6.24287 15.5 4.82865 14.5237 3.94997C13.5474 3.07129 11.976 3.07129 8.83339 3.07129C5.6907 3.07129 4.11934 3.07129 3.14304 3.94997Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M4.50388 11.7323L6.18762 7.09046C6.4938 6.44611 6.96201 5.9132 7.49849 7.19129C7.99371 8.3711 8.73257 10.5314 9.1687 11.7348M5.2689 9.7393H8.38098" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M13.1561 7.0625V9.05895M13.1561 9.05895V11.688M13.1561 9.05895H11.8106C11.6508 9.05895 11.4923 9.08868 11.3429 9.14662C10.2138 9.58475 10.2138 11.2128 11.3429 11.651C11.4923 11.7089 11.6508 11.7386 11.8106 11.7386H13.1561"
            stroke="#FF5722"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 4,
      path: "/issuetrackinguser",
      name: "Issue Tracking",
      title: "Issue Tracking",
      icon1: (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.38115 6.74101C5.99027 3.89372 6.79482 2.47006 7.89887 2.1036C8.50621 1.90199 9.16048 1.90199 9.76781 2.1036C10.8719 2.47006 11.6764 3.89372 13.2855 6.74101C14.8947 9.58832 15.6992 11.0119 15.4579 12.1719C15.3251 12.8101 14.9979 13.389 14.5233 13.8255C13.6607 14.6191 12.0516 14.6191 8.83334 14.6191C5.61511 14.6191 4.00599 14.6191 3.14331 13.8255C2.66873 13.389 2.3416 12.8101 2.20882 12.1719C1.96747 11.0119 2.77203 9.58832 4.38115 6.74101Z"
            stroke="#333333"
          />
          <path d="M8.99481 11.619V8.95231C8.99481 8.63804 8.99481 8.48091 8.89714 8.38324C8.79954 8.28564 8.64241 8.28564 8.32814 8.28564" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8.828 6.28564H8.8355" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
      icon2: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.54785 7.52665C5.15697 4.67936 5.96152 3.2557 7.06558 2.88924C7.67291 2.68763 8.32718 2.68763 8.93451 2.88924C10.0386 3.2557 10.8431 4.67936 12.4522 7.52665C14.0614 10.374 14.8659 11.7976 14.6246 12.9576C14.4918 13.5958 14.1646 14.1747 13.69 14.6112C12.8274 15.4047 11.2183 15.4047 8.00004 15.4047C4.78181 15.4047 3.17269 15.4047 2.31002 14.6112C1.83543 14.1747 1.5083 13.5958 1.37552 12.9576C1.13417 11.7976 1.93873 10.374 3.54785 7.52665Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
          />
          <path d="M8.16142 12.4046V9.73796C8.16142 9.42369 8.16142 9.26656 8.06375 9.16889C7.96615 9.07129 7.80902 9.07129 7.49475 9.07129" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.99463 7.07129H8.00213" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
    },
  ];

  const handleFileChange = (files) => {
    const file = files[0];
    setSelectedImage(file);
  };
  const extractTime = (dateString) => {
    return moment(dateString, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss");
  };

  // Iterate through the array and extract time from the datecreated property of each object
  const times = data?.map((obj) => extractTime(obj.datecreated));

  const activeItem = menu.find((item) => item.path === location.pathname);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Save logic, for example, update state or call an API to save changes
    console.log("Saving changes:", editedData);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setEditedData(initialData);
    setIsEditing(false);
  };

  return (
    <>
      <div className="flex  justify-between items-start">
        <SideBar mini={mini} setMini={setMini} menu={menu} />
        {/* LARGE SCREENS */}
        <div className={` 100:hidden xl:flex  w-full pr-[25px] flex flex-col  gap-[22px] h-fit ${mini ? "pl-[130px]" : "pl-[280px]"}`}>
          <Header mini={mini} name={activeItem ? activeItem.name : null} />
          {empty ? (
            <>
              <div className=" w-full mt-[78px] flex justify-center items-center">
                <div className=" flex flex-col gap-[16px] justify-center items-center">
                  <img onClick={() => setEmpty(true)} src={illustration} alt="" />
                  <div className="w-[356px] h-12 flex-col justify-start items-center gap-1 inline-flex">
                    <div className="self-stretch text-center text-zinc-800 text-base font-semibold font-['Open Sans'] leading-normal">No Active Advert</div>
                    <div className="w-[356px] text-center text-neutral-500 text-sm font-normal font-monteserrat leading-tight">No active advert . Please try again.</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
                <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
                  <div className=" flex gap-[16px] justify-between items-center w-full">
                    <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Active Advert</h4>
                    <div className=" rounded-[20px] border-[0.6px] border-solid h-[38px] px-[23px] py-[10px] border-[#9E9E9E] flex justify-between items-center gap-[10px] w-[321px]">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.125 13.125L16.5 16.5" stroke="#777777" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15 8.25C15 4.52208 11.978 1.5 8.25 1.5C4.52208 1.5 1.5 4.52208 1.5 8.25C1.5 11.978 4.52208 15 8.25 15C11.978 15 15 11.978 15 8.25Z" stroke="#777777" stroke-width="1.125" stroke-linejoin="round" />
                      </svg>
                      <input
                        placeholder="Search advert"
                        className=" text-[16px] font-monteserrat font-medium text-[#333] placeholder:text-[14px] placeholder:font-monteserrat placeholder:font-normal w-full h-full bg-transparent outline-none border-none"
                        type="text"
                      />
                    </div>
                    <div className="w-[75px] h-[38px] px-[5px] bg-neutral-100 rounded-[10px] shadow justify-start items-center gap-[5px] inline-flex">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.5 11.5H14.5001" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9.5 15H13.5" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.5 8H15.5" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>

                      <div className="text-neutral-500 text-sm font-normal font-['Montserrat'] tracking-tight">Filter</div>
                    </div>
                  </div>
                </section>

                <div className=" flex justify-between w-full items-center">
                  <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Advert Title </h2>
                    </div>
                    {data?.map((item) => (
                      <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                        <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">{item.title} </h2>
                      </div>
                    ))}
                  </div>
                  <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Description</h2>
                    </div>
                    {data?.map((item) => (
                      <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                        <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">{item.description} </h2>
                      </div>
                    ))}
                  </div>
                  <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Payment Status </h2>
                    </div>
                    {data?.map((item) => (
                      <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                        <h2
                          className={`${
                            item.status === "expired" ? "text-[#E52C2C] bg-[#FFECEC]" : item.status === "active" ? "bg-[#E6FFE7] text-[#4CAF50]" : "text-[#E37A00] border-b-neutral-200"
                          } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                        >
                          {item.status === "expired" ? (
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          ) : item.status === "active" ? (
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          ) : (
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_1356_3235)">
                                <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                  d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                                  stroke="#E37A00"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1356_3235">
                                  <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                          {item.status}
                        </h2>
                      </div>
                    ))}
                  </div>
                  <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Expirey Date </h2>
                    </div>
                    {data?.map((item) => {
                      const formattedDate = moment(item.expireydate).format("Do MMMM YYYY");
                      console.log(formattedDate);
                      return (
                        <>
                          <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                            <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">{formattedDate} </h2>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="w-[250px] cursor-pointer h-fit flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Action </h2>
                    </div>
                    {data?.map(() => (
                      <div onClick={() => setModal(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                            stroke="#333333"
                            stroke-width="0.875"
                          />
                          <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" stroke-width="0.875" />
                        </svg>
                        View
                      </div>
                    ))}
                  </div>{" "}
                </div>
              </div>
              {/* Table 2 Starts here
              <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
                <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
                  <div className=" flex gap-[16px] justify-between items-center w-full">
                    <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Pending Approval</h4>
                  </div>
                </section>

                <div className=" flex justify-between w-full items-center">
                  <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Advert Title </h2>
                    </div>
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                    </div>
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Aromire Peace</h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Peace Court </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Peace Court </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Peace Court </h2>
                    </div>
                  </div>
                  <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Category</h2>
                    </div>
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Clothing </h2>
                    </div>
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Electronics </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Furniture </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Food </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Clothing </h2>
                    </div>
                  </div>
                  <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Submitted Date </h2>
                    </div>
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                    </div>
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                    </div>
                  </div>
                  <div className="w-[250px] cursor-pointer h-[410px] flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Action </h2>
                    </div>
                    <div onClick={() => setModal(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                          stroke="#333333"
                          stroke-width="0.875"
                        />
                        <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" stroke-width="0.875" />
                      </svg>
                      View
                    </div>
                    <div onClick={() => setModal(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                          stroke="#333333"
                          stroke-width="0.875"
                        />
                        <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" stroke-width="0.875" />
                      </svg>
                      View
                    </div>
                    <div onClick={() => setModal(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                          stroke="#333333"
                          stroke-width="0.875"
                        />
                        <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" stroke-width="0.875" />
                      </svg>
                      View
                    </div>
                    <div onClick={() => setModal(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                          stroke="#333333"
                          stroke-width="0.875"
                        />
                        <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" stroke-width="0.875" />
                      </svg>
                      View
                    </div>
                    <div onClick={() => setModal(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                          stroke="#333333"
                          stroke-width="0.875"
                        />
                        <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" stroke-width="0.875" />
                      </svg>
                      View
                    </div>
                  </div>{" "}
                </div>
              </div> */}
              <Footer setPage={setPage} page={page} />
            </>
          )}
        </div>
        {/* SMALL SCREENS */}
        <div className=" 100:flex xl:hidden w-full pr-[25px] pb-[50px] flex-col justify-between gap-[22px] h-fit pl-[130px]">
          <Header mini={mini} name={activeItem ? activeItem.name : null} />
          {empty ? (
            <>
              <div className=" w-full mt-[78px] flex justify-center items-center">
                <div className=" flex flex-col gap-[16px] justify-center items-center">
                  <img onClick={() => setEmpty(true)} src={illustration} alt="" />
                  <div className="w-[356px] h-12 flex-col justify-start items-center gap-1 inline-flex">
                    <div className="self-stretch text-center text-zinc-800 text-base font-semibold font-['Open Sans'] leading-normal">No Active Advert</div>
                    <div className="w-[356px] text-center text-neutral-500 text-sm font-normal font-monteserrat leading-tight">No active advert . Please try again.</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
                <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
                  <div className=" flex gap-[16px] justify-between items-center w-full">
                    <h4 className={`tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ${inputField ? " hidden" : " block"}`}>Active Advert</h4>
                    <div className={`rounded-[20px] border-[0.6px] border-solid h-[38px] px-[10px] py-[10px] border-[#9E9E9E] flex justify-between items-center gap-[10px] ${inputField ? "w-full" : "w-fit"} `}>
                      <svg onClick={() => setInputField(!inputField)} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.125 13.125L16.5 16.5" stroke="#777777" stroke-width="1.125" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15 8.25C15 4.52208 11.978 1.5 8.25 1.5C4.52208 1.5 1.5 4.52208 1.5 8.25C1.5 11.978 4.52208 15 8.25 15C11.978 15 15 11.978 15 8.25Z" stroke="#777777" stroke-width="1.125" stroke-linejoin="round" />
                      </svg>
                      {inputField && (
                        <input
                          placeholder="Search active advert"
                          className=" text-[16px] font-monteserrat font-medium text-[#333] placeholder:text-[14px] placeholder:font-monteserrat placeholder:font-normal w-full h-full bg-transparent outline-none border-none"
                          type="text"
                        />
                      )}
                    </div>
                    <div className="w-fit h-[38px] px-[10px] bg-neutral-100 rounded-[10px] shadow justify-start items-center gap-[5px] inline-flex">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.5 11.5H14.5001" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M9.5 15H13.5" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M7.5 8H15.5" stroke="#777777" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </div>
                  </div>
                </section>

                <div className=" overflow-x-scroll whitespace-nowrap flex justify-between w-full items-center">
                  <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Advert Title </h2>
                    </div>
                    {data?.map((item) => (
                      <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                        <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">{item.title} </h2>
                      </div>
                    ))}
                  </div>
                  <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Description</h2>
                    </div>
                    {data?.map((item) => (
                      <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                        <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">{item.description} </h2>
                      </div>
                    ))}
                  </div>
                  <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Payment Status </h2>
                    </div>
                    {data?.map((item) => (
                      <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                        <h2
                          className={`${
                            item.status === "expired" ? "text-[#E52C2C] bg-[#FFECEC]" : item.status === "active" ? "bg-[#E6FFE7] text-[#4CAF50]" : "text-[#E37A00] border-b-neutral-200"
                          } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                        >
                          {item.status === "expired" ? (
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          ) : item.status === "active" ? (
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                          ) : (
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_1356_3235)">
                                <path d="M6.25 11C3.48857 11 1.25002 8.76142 1.25002 6C1.25002 3.23858 3.48859 1 6.25002 1C8.48884 1 10.3629 2.47145 11 4.5H9.75" stroke="#E37A00" stroke-linecap="round" stroke-linejoin="round" />
                                <path
                                  d="M11.2275 6.5C11.2424 6.33543 11.25 6.16865 11.25 6M7.75 11C7.9208 10.9438 8.08765 10.8782 8.25 10.8039M10.6453 8.5C10.7417 8.31421 10.8277 8.12163 10.9025 7.92311M9.34623 10.1146C9.51847 9.97207 9.68155 9.81791 9.83439 9.65324"
                                  stroke="#E37A00"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_1356_3235">
                                  <rect width="12" height="12" fill="white" transform="translate(0.25)" />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                          {item.status}
                        </h2>
                      </div>
                    ))}
                  </div>
                  <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Expirey Date </h2>
                    </div>
                    {data?.map((item) => {
                      const formattedDate = moment(item.expireydate).format("Do MMMM YYYY");
                      console.log(formattedDate);
                      return (
                        <>
                          <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                            <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">{formattedDate} </h2>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="w-[250px] cursor-pointer h-fit flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Action </h2>
                    </div>
                    {data?.map(() => (
                      <div onClick={() => setModal(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                            stroke="#333333"
                            stroke-width="0.875"
                          />
                          <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" stroke-width="0.875" />
                        </svg>
                        View
                      </div>
                    ))}
                  </div>{" "}
                </div>
              </div>
              {/* Table 2 Starts here
              <div className=" whitespace-nowrap overflow-x-scroll w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
                <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
                  <div className=" flex gap-[16px] justify-between items-center w-full">
                    <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Pending Approval</h4>
                  </div>
                </section>

                <div className=" flex justify-between w-full items-center">
                  <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Advert Title </h2>
                    </div>
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Prince Ojukwu </h2>
                    </div>
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Aromire Peace</h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Peace Court </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Peace Court </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Peace Court </h2>
                    </div>
                  </div>
                  <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Category</h2>
                    </div>
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Clothing </h2>
                    </div>
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Electronics </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Furniture </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Food </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">Clothing </h2>
                    </div>
                  </div>
                  <div className="w-full h-[410px] flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Submitted Date </h2>
                    </div>
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                    </div>
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                    </div>{" "}
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                    </div>
                  </div>
                  <div className="w-[250px] cursor-pointer h-[410px] flex-col justify-start items-start inline-flex">
                    <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Action </h2>
                    </div>
                    <div onClick={() => setModal(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                          stroke="#333333"
                          stroke-width="0.875"
                        />
                        <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" stroke-width="0.875" />
                      </svg>
                      View
                    </div>
                    <div onClick={() => setModal(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                          stroke="#333333"
                          stroke-width="0.875"
                        />
                        <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" stroke-width="0.875" />
                      </svg>
                      View
                    </div>
                    <div onClick={() => setModal(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                          stroke="#333333"
                          stroke-width="0.875"
                        />
                        <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" stroke-width="0.875" />
                      </svg>
                      View
                    </div>
                    <div onClick={() => setModal(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                          stroke="#333333"
                          stroke-width="0.875"
                        />
                        <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" stroke-width="0.875" />
                      </svg>
                      View
                    </div>
                    <div onClick={() => setModal(true)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 text-[12px] text-[#333] font-openSans px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.772 5.5225C10.924 5.73565 11 5.84225 11 6C11 6.15775 10.924 6.26435 10.772 6.4775C10.089 7.4353 8.3446 9.5 6 9.5C3.65539 9.5 1.91105 7.4353 1.22802 6.4775C1.076 6.26435 1 6.15775 1 6C1 5.84225 1.076 5.73565 1.22802 5.5225C1.91105 4.56472 3.65539 2.5 6 2.5C8.3446 2.5 10.089 4.56472 10.772 5.5225Z"
                          stroke="#333333"
                          stroke-width="0.875"
                        />
                        <path d="M7.5 6C7.5 5.17155 6.82845 4.5 6 4.5C5.17155 4.5 4.5 5.17155 4.5 6C4.5 6.82845 5.17155 7.5 6 7.5C6.82845 7.5 7.5 6.82845 7.5 6Z" stroke="#333333" stroke-width="0.875" />
                      </svg>
                      View
                    </div>
                  </div>{" "}
                </div>
              </div> */}
              <Footer setPage={setPage} page={page} />
            </>
          )}
        </div>
      </div>
      {modal && (
        <>
          <div className=" w-full bg-black bg-opacity-40 flex justify-center items-center h-screen fixed top-0 left-0">
            <div className=" w-fit  p-[10px]  h-fit gap-[10px] items-start flex flex-col rounded-[20px] bg-[#F5F5F5] ">
              <div className=" w-full bg-[#FFE2D9] h-[80px] px-[30px] rounded-[20px] flex justify-between items-center ">
                <h3 className=" font-openSans tracking-[-0.4px] text-[#333] font-semibold text-[20px]">Pending Advert</h3>
                <div onClick={() => setModal(false)} className=" bg-[#B8A9A9] rounded-full cursor-pointer w-fit p-[10px]">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 4.16675L4.1665 15.8334M4.1665 4.16675L15.8332 15.8334" stroke="#F5F5F5" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
              <div className=" w-full 100:flex-col md:flex-row flex justify-between items-center gap-[10px] ">
                {isEditing ? null : <img className="w-[400px]  h-[376px] rounded-[20px] " src={selectedImage ? URL.createObjectURL(selectedImage) : editedData.image} alt="footware" />}
                <div className="w-full h-fit px-[30px] py-[20px] bg-white rounded-[20px] flex-col justify-start items-start gap-5 inline-flex">
                  <div className=" w-full mt-[5px] flex justify-end ">
                    {!isEditing && (
                      <button onClick={handleEditClick}>
                        <div className="p-2.5 w-[63px] bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-[5px] flex">
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M10.1428 3.9881L11.0773 3.05359C11.5934 2.53748 12.4302 2.53748 12.9463 3.05359C13.4624 3.5697 13.4624 4.40649 12.9463 4.9226L12.0118 5.85711M10.1428 3.9881L4.65357 9.47737C3.9567 10.1742 3.60825 10.5226 3.37099 10.9472C3.13373 11.3718 2.89501 12.3744 2.66675 13.3332C3.62547 13.1049 4.62807 12.8662 5.05267 12.6289C5.47727 12.3916 5.82571 12.0432 6.52258 11.3464L12.0118 5.85711M10.1428 3.9881L12.0118 5.85711"
                              stroke="#667085"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <div className="text-gray-500 text-xs font-normal font-['Open Sans'] leading-[17.40px]">Edit</div>
                        </div>
                      </button>
                    )}
                  </div>
                  {!isEditing && (
                    <div className=" justify-between items-center  flex">
                      <div className="flex-col whitespace-nowrap justify-start items-start w-full gap-[30px] flex">
                        <div className="pr-[69px] pt-px flex-col justify-center items-start gap-[7px] inline-flex">
                          <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Category:</div>
                          <div className="text-zinc-800 w-[200px] whitespace-pre-line text-sm font-semibold font-['Open Sans']">{data[0].description}</div>
                        </div>
                        <div className="pr-[69px] pt-px flex-col justify-center items-start gap-[7px] inline-flex">
                          <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Submitted Date:</div>
                          <div className="text-zinc-800 text-sm font-semibold font-['Open Sans']">{moment(data.datecreated).format("Do MMMM YYYY")}</div>
                        </div>
                        <div className="pr-[69px] pt-px flex-col justify-center items-start gap-[7px] inline-flex">
                          <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Start Date:</div>
                          <div className="text-zinc-800 text-sm font-semibold font-['Open Sans']">{moment(data.dateapproved).format("Do MMMM YYYY")}</div>
                        </div>
                        <div className="pr-[31px] pt-px flex-col justify-center items-start gap-[7px] inline-flex">
                          <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Advert ID:</div>
                          <div className="text-zinc-800 text-sm font-semibold font-['Open Sans']">{data[0].id}</div>
                        </div>
                      </div>
                      <div className=" flex-col whitespace-nowrap justify-start items-start w-full gap-[30px] flex">
                        <div className="flex-col justify-start items-start gap-[5px] inline-flex">
                          <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Item Price:</div>
                          <div className="text-zinc-800 text-sm whitespace-nowrap font-semibold font-['Open Sans']">{data[0].ispaid}</div>
                        </div>
                        <div className="flex-col justify-start items-start gap-[5px] inline-flex">
                          <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Time:</div>
                          <div className="text-zinc-800 text-sm whitespace-nowrap font-semibold font-['Open Sans']">{times}</div>
                        </div>
                        <div className="flex-col justify-start items-start gap-[5px] inline-flex">
                          <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">End Date:</div>
                          <div className="text-zinc-800 text-sm whitespace-nowrap font-semibold font-['Open Sans']">{moment(data.expireydate).format("Do MMMM YYYY")}</div>
                        </div>
                        <div className="flex-col justify-start items-start gap-[5px] inline-flex">
                          <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Status</div>
                          <h2
                            className={`${
                              data.status === "expired" ? "text-[#E52C2C] bg-[#FFECEC]" : "bg-[#E6FFE7] text-[#4CAF50]"
                            } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                          >
                            {data.status === "expired" ? (
                              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            ) : (
                              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            )}
                            {data.status}
                          </h2>
                        </div>
                      </div>
                    </div>
                  )}
                  {isEditing && (
                    <form className="sm:w-[527px] 100:w-full h-fit flex-col justify-start items-start gap-[20px] inline-flex" onSubmit={handleFormSubmit}>
                      <div className="self-stretch h-[61px] flex-col justify-start items-start gap-[8px] flex">
                        <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Advert Title</h3>
                        <input
                          value={editedData.category}
                          onChange={handleInputChange}
                          placeholder="Enter Advert Title"
                          type="text"
                          name="category"
                          className=" w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-['Montserrat'] tracking-tight rounded border-[0.5px] border-neutral-500 "
                        />
                      </div>
                      <div className="self-stretch h-[61px] flex-col justify-start items-start gap-[8px] flex">
                        <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Advert Duration</h3>

                        <input
                          value={editedData.duration}
                          onChange={handleInputChange}
                          placeholder="Select Advert Duration"
                          type="text"
                          name="duration"
                          className=" w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-['Montserrat'] tracking-tight rounded border-[0.5px] border-neutral-500 "
                        />
                      </div>
                      <div className="self-stretch h-[61px] flex-col justify-start items-start gap-[8px] flex">
                        <h3 className=" text-zinc-800 text-sm font-semibold font-['Open Sans'] leading-tight">Advert Status</h3>
                        <input
                          value={editedData.status}
                          onChange={handleInputChange}
                          placeholder="Select Advert Status"
                          type="text"
                          name="status"
                          className=" w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-['Montserrat'] tracking-tight rounded border-[0.5px] border-neutral-500 "
                        />
                      </div>
                      <div className="sm:w-[527px] 100:w-full h-[126px] justify-start items-start gap-5 inline-flex">
                        <img alt="" className="w-16 h-16 relative rounded-[200px]" name="image" value={editedData.image} onChange={handleInputChange} src={selectedImage ? URL.createObjectURL(selectedImage) : editedData.image} />
                        <InputFiles style={{ width: "100%" }} onChange={handleFileChange}>
                          <div className="self-stretch w-full h-[126px] px-6 py-4 rounded-lg border-[0.5px] border-neutral-500 flex-col justify-start items-center gap-1 flex grow shrink basis-0 bg-zinc-100 ">
                            <div className="self-stretch h-[94px] flex-col justify-center items-center gap-3 flex">
                              <div className="w-10 h-10 p-2.5 bg-gray-100 rounded-[28px] border-4 border-gray-50 justify-center items-center inline-flex">
                                <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <g clip-path="url(#clip0_1694_1007)">
                                    <path
                                      d="M13.3335 13.8332L10.0002 10.4999M10.0002 10.4999L6.66688 13.8332M10.0002 10.4999V17.9999M16.9919 15.8249C17.8047 15.3818 18.4467 14.6806 18.8168 13.8321C19.1868 12.9835 19.2637 12.0359 19.0354 11.1388C18.807 10.2417 18.2865 9.44616 17.5558 8.87778C16.8251 8.30939 15.9259 8.00052 15.0002 7.9999H13.9502C13.698 7.02427 13.2278 6.11852 12.5752 5.35073C11.9225 4.58295 11.1042 3.97311 10.182 3.56708C9.25967 3.16104 8.25734 2.96937 7.25031 3.00647C6.24328 3.04358 5.25777 3.30849 4.36786 3.78129C3.47795 4.2541 2.7068 4.92249 2.1124 5.73622C1.51799 6.54996 1.11579 7.48785 0.936028 8.4794C0.756269 9.47095 0.803632 10.4903 1.07456 11.461C1.34548 12.4316 1.83291 13.3281 2.50021 14.0832"
                                      stroke="#777777"
                                      stroke-width="1.66667"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1694_1007">
                                      <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                              <div className="self-stretch justify-center items-start gap-1 flex-col flex">
                                <div className="justify-center w-full flex-col items-center gap-2 flex">
                                  <div className="text-orange-600 text-sm font-normal font-['Open Sans'] leading-tight">
                                    Click to upload <span className="text-neutral-500 text-sm font-normal font-['Open Sans'] leading-tight">or drag and drop</span>
                                  </div>
                                  <div className="self-stretch text-center text-neutral-500 text-xs font-normal font-['Open Sans'] leading-[18px]">SVG, PNG, JPG or GIF (max. 800x400px)</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </InputFiles>
                      </div>
                      <div className="flex 100:flex-col 100:gap-[10px] md:gap-0 md:flex-row w-full p-[10px] justify-between items-center">
                        <button onClick={handleCancelClick} className="w-[180px] h-[38px]  rounded border border-orange-600 text-center text-zinc-800 text-base font-semibold font-['Open Sans'] leading-normal tracking-wide" type="button">
                          Cancel
                        </button>
                        <button className="w-[180px] h-[38px]  rounded  bg-orange-600 text-center text-white text-base font-semibold font-['Open Sans'] leading-normal tracking-wide" type="submit">
                          Save
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default AdvertManagement;
