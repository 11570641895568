import { Link, useNavigate } from "react-router-dom";
import sideImg from "../images/loginmyhood.png";
import logo from "../images/forgotPassword.png";
import { useState } from "react";
import axios from "axios";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  // const [userData, setUserData] = useState([]);
  const navigate = useNavigate();

  const handleSub = async () => {
    try {
      const response = await axios.post("https://www.myhood.ng/mobile_back_end/api/initiate_password_resset.php", {
        user_id: email,
      });

      if (response.data.responsecode === "200") {
        console.log(response, email);
        setEmail("");
        navigate("/otp", { state: { email } });
        // setCurrentPage((prevPage) => prevPage + 1);
      } else {
        // Handle error response
        setError("Error", response.data.responsemessage);
      }
    } catch (err) {
      console.error("Error:", err);
      setError("Error", "An error occurred while sending email");
    }
  };
  return (
    <>
      {/* BIG SCREENS */}
      <div className="flex w-full 100:hidden lg:flex h-screen items-start justify-between">
        <img className="fixed   h-full w-[50%]  " src={sideImg} alt="housing" />
        <div className=" ml-[52%] px-[48px] gap-[50px] flex flex-col justify-start items-center pb-[52px] w-[50%] h-full ">
          <div className="w-full top-[60px]  relative float-left ">
            <Link className="" to="/login">
              <h3 className=" text-[16px]  w-[36px] font-normal font-openSans text-[#FF5722] tracking-[0.9px] ">Back</h3>
            </Link>
          </div>
          <div className="flex mt-[100px] flex-col justify-between gap-[50px] items-center">
            <div className="flex flex-col text-center justify-between items-center gap-[40px]">
              <img src={logo} alt="forgot password" />
              <div className=" flex flex-col items-center justify-between gap-[15px]">
                <h1 className="text-[32px] text-[#333333] font-semibold font-openSans leading-[35px] tracking-wide ">Forgot Your Password? </h1>
                <p className="text-[#777777] text-[20px] w-[450px] font-normal font-monteserrat leading-[35px] tracking-tight">Enter your email address, and we'll send you a link to reset your password.</p>
              </div>
            </div>
            <form onSubmit={handleSub} className=" flex flex-col justify-between gap-[20px]" action="/" method="get">
              <div className="w-[400px] h-[37px] flex justify-between items-center px-[15px]  bg-[#eee] border-[0.7px] border-[#777] rounded-[4px] ">
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                  type="email"
                  placeholder="Enter Your Email"
                />
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.16669 3.71429L5.19928 5.99919C6.68595 6.84154 7.31409 6.84154 8.80077 5.99919L12.8334 3.71429" stroke="#777777" strokeWidth="0.875" strokeLinejoin="round" />
                  <path
                    d="M1.17589 8.07506C1.21402 9.86333 1.23309 10.7574 1.89291 11.4198C2.55273 12.0821 3.47105 12.1051 5.30767 12.1513C6.43961 12.1797 7.56043 12.1797 8.69239 12.1513C10.529 12.1051 11.4473 12.0821 12.1072 11.4198C12.767 10.7574 12.786 9.86333 12.8241 8.07506C12.8364 7.50007 12.8364 6.92852 12.8241 6.35353C12.786 4.56529 12.767 3.67118 12.1072 3.00884C11.4473 2.34651 10.529 2.32344 8.69239 2.27729C7.56043 2.24885 6.43961 2.24885 5.30767 2.27729C3.47105 2.32343 2.55273 2.3465 1.89291 3.00884C1.23308 3.67117 1.21402 4.56529 1.17588 6.35353C1.16362 6.92852 1.16362 7.50007 1.17589 8.07506Z"
                    stroke="#777777"
                    strokeWidth="0.875"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {error && <p className=" text-[16px] font-openSans text-[#EB0000] ">{error}</p>}
            </form>
            {/* <Link to="/otp"> */}
            <button
              onClick={(e) => {
                handleSub(e);
                // handleSubmitPassword(e);
              }}
              type="submit"
              className="w-[243px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[16px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex"
            >
              Send OTP
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1024PX */}
      <div className="flex flex-col w-full overflow-x-hidden 100:flex gap-[70px] lg:hidden h-fit py-[50px] items-center justify-center">
        <div className="w-full  left-[50px] relative float-left ">
          <Link className="" to="/login">
            <h3 className=" text-[16px]  w-[36px] font-normal font-openSans text-[#FF5722] tracking-[0.9px] ">Back</h3>
          </Link>
        </div>
        <div className=" phone:max-w-[450px]  px-[48px]  flex flex-col justify-center items-center pb-[52px] w-[90%] ">
          <div className="flex flex-col justify-between gap-[50px] items-center">
            <div className="flex flex-col text-center justify-between items-center gap-[40px]">
              <img src={logo} alt="forgot password" />
              <div className=" flex flex-col items-center justify-between gap-[15px]">
                <h1 className="phone:text-[32px] 100:text-[28px] text-[#333333] whitespace-nowrap font-semibold font-openSans leading-[35px] tracking-wide ">Forgot Your Password? </h1>
                <p className="text-[#777777] 100:text-[18px] phone:text-[20px] w-full phone:w-[450px] font-normal font-monteserrat leading-[35px] tracking-tight">Enter your email address, and we'll send you a link to reset your password.</p>
              </div>
            </div>

            <form onSubmit={handleSub} className=" flex flex-col justify-between gap-[20px]" action="/" method="get">
              <div className="w-[300px] phone:w-[450px] flex-grow h-[37px] flex justify-between items-center px-[15px]  bg-[#eee] border-[0.7px] border-[#777] rounded-[4px] ">
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                  className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                  type="email"
                  placeholder="Enter Your Email"
                />
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.16669 3.71429L5.19928 5.99919C6.68595 6.84154 7.31409 6.84154 8.80077 5.99919L12.8334 3.71429" stroke="#777777" strokeWidth="0.875" strokeLinejoin="round" />
                  <path
                    d="M1.17589 8.07506C1.21402 9.86333 1.23309 10.7574 1.89291 11.4198C2.55273 12.0821 3.47105 12.1051 5.30767 12.1513C6.43961 12.1797 7.56043 12.1797 8.69239 12.1513C10.529 12.1051 11.4473 12.0821 12.1072 11.4198C12.767 10.7574 12.786 9.86333 12.8241 8.07506C12.8364 7.50007 12.8364 6.92852 12.8241 6.35353C12.786 4.56529 12.767 3.67118 12.1072 3.00884C11.4473 2.34651 10.529 2.32344 8.69239 2.27729C7.56043 2.24885 6.43961 2.24885 5.30767 2.27729C3.47105 2.32343 2.55273 2.3465 1.89291 3.00884C1.23308 3.67117 1.21402 4.56529 1.17588 6.35353C1.16362 6.92852 1.16362 7.50007 1.17589 8.07506Z"
                    stroke="#777777"
                    strokeWidth="0.875"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              {error && <p className=" text-[16px] font-openSans text-[#EB0000] ">{error}</p>}
            </form>
            {/* <Link to="/otp"> */}
            <button
              onClick={(e) => {
                handleSub(e);
                // handleSubmitPassword(e);
              }}
              type="submit"
              className="w-[243px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[16px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex"
            >
              Send OTP
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
