import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../images/Logo.svg";
import user from "../../images/user.png";
import UserImg from "../../images/ava.png";
import { axiosInstance } from "../../Utils";
import axios from "axios";

function SideBar({ children, menu, setMini, mini }) {
  const [smallScreens, setSmallScreens] = useState(false);
  const location = useLocation();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const res = await axios.post("http://www.myhood.ng/mobile_back_end/api/get_user.php?id=1", {
        //   data,
        // });
        // console.log(res);

        const revData = localStorage.getItem("myHoodData");
        setData(JSON.parse(revData));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const path = "/profileuser";

  return (
    <>
      {/* LARGE SCREENS */}
      <div className={` 100:hidden xl:flex  fixed  h-screen px-[20px] py-[49px] gap-[70px] flex flex-col justify-start items-start ${mini ? "w-[111.67px]" : "w-fit"}  bg-[#FFEEE9] `}>
        <section className=" w-full flex items-center justify-between">
          <img src={logo} alt="myHood" />
          {mini ? (
            <>
              <svg className=" cursor-pointer" onClick={() => setMini(false)} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.68056 4.47599C8.68056 4.47599 12.8472 7.54467 12.8472 8.64266C12.8472 9.74071 8.68056 12.8093 8.68056 12.8093" stroke="#333333" strokeWidth="1.04167" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.81948 4.47599C3.81948 4.47599 7.98615 7.54467 7.98615 8.64266C7.98615 9.74071 3.81948 12.8093 3.81948 12.8093" stroke="#333333" strokeWidth="1.04167" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </>
          ) : (
            <>
              <svg className=" cursor-pointer" onClick={() => setMini(true)} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.31944 12.8095C8.31944 12.8095 4.15278 9.74085 4.15277 8.64286C4.15276 7.54481 8.31944 4.4762 8.31944 4.4762" stroke="#333333" strokeWidth="1.04167" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.1805 12.8095C13.1805 12.8095 9.01385 9.74085 9.01385 8.64286C9.01385 7.54481 13.1805 4.4762 13.1805 4.4762" stroke="#333333" strokeWidth="1.04167" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </>
          )}
        </section>
        <section className=" gap-[20px] w-full flex-col flex items-start justify-between">
          {menu.map((item) => (
            <NavLink
              onClick={() => window.scrollTo(0, 0)}
              className={` text-[14px] whitespace-nowrap ${mini ? " justify-center items-center" : " items-center justify-start"} font-openSans font-normal tracking-[0.9px]  rounded-[20px]  px-[20px] h-[39px] py-[10px] w-full flex  gap-[10px] ${
                location.pathname === item.path ? "text-[#F5F5F5] bg-[#FF5722]" : " bg-none text-[#333]"
              } `}
              key={item.id}
              title={item.title}
              to={item.path}
            >
              {location.pathname === item.path ? item.icon2 : item.icon1}
              {mini ? null : <>{item.name}</>}
            </NavLink>
          ))}
        </section>
        <section className="w-full">
          <NavLink
            className={` text-[14px] whitespace-nowrap ${mini ? " justify-center items-center" : " items-center justify-start"} font-openSans font-normal tracking-[0.9px]  rounded-[20px]  px-[20px] h-[39px] py-[10px] w-full flex  gap-[10px] ${
              location.pathname === path ? "text-[#F5F5F5] bg-[#FF5722]" : " bg-none text-[#333]"
            } `}
            to={path}
          >
            <img className=" w-[30px] rounded-full" src={data.profile_pic === "" ? UserImg : `data:image/png;base64,${data.profile_pic}`} alt={data.first_name + " " + data.last_name} title={data.first_name + " " + data.last_name} />{" "}
            {mini ? null : <>Profile</>}
          </NavLink>
        </section>
      </div>
      {/* SMALL SCREENS */}
      <div className={` 100:flex xl:hidden  `}>
        {smallScreens ? (
          <>
            <div className=" w-full z-30 bg-black bg-opacity-40  h-screen fixed top-0 left-0">
              <div className=" h-screen px-[20px] py-[49px] gap-[70px] flex flex-col justify-start items-start w-[255px]  bg-[#FFEEE9]">
                <section className=" w-full flex items-center justify-between">
                  <img src={logo} alt="myHood" />

                  <>
                    <svg className=" cursor-pointer" onClick={() => setSmallScreens(false)} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.31944 12.8095C8.31944 12.8095 4.15278 9.74085 4.15277 8.64286C4.15276 7.54481 8.31944 4.4762 8.31944 4.4762" stroke="#333333" strokeWidth="1.04167" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M13.1805 12.8095C13.1805 12.8095 9.01385 9.74085 9.01385 8.64286C9.01385 7.54481 13.1805 4.4762 13.1805 4.4762" stroke="#333333" strokeWidth="1.04167" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </>
                </section>
                <section className=" gap-[20px] w-full flex-col flex items-start justify-between">
                  {menu.map((item) => (
                    <NavLink
                      onClick={() => window.scrollTo(0, 0)}
                      className={` text-[14px] whitespace-nowrap 
                         items-center justify-start
                      font-openSans font-normal tracking-[0.9px]  rounded-[20px]  px-[20px] h-[39px] py-[10px] w-full flex  gap-[10px] ${location.pathname === item.path ? "text-[#F5F5F5] bg-[#FF5722]" : " bg-none text-[#333]"} `}
                      key={item.id}
                      title={item.title}
                      to={item.path}
                    >
                      {location.pathname === item.path ? item.icon2 : item.icon1}
                      {item.name}
                    </NavLink>
                  ))}
                </section>
                <section className="w-full mt-[250px]">
                  <NavLink
                    className={` text-[14px] whitespace-nowrap ${
                      mini ? " justify-center items-center" : " items-center justify-start"
                    } font-openSans font-normal tracking-[0.9px]  rounded-[20px]  px-[20px] h-[39px] py-[10px] w-full flex  gap-[10px] ${location.pathname === path ? "text-[#F5F5F5] bg-[#FF5722]" : " bg-none text-[#333]"} `}
                    to={path}
                  >
                    <img className=" w-[30px] rounded-full" src={data.profile_pic === "" ? UserImg : `data:image/png;base64,${data.profile_pic}`} alt={data.first_name + " " + data.last_name} title={data.first_name + " " + data.last_name} />{" "}
                    <>Profile</>
                  </NavLink>
                </section>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-[111.67px] h-full fixed flex bg-[#FFEEE9] px-[20px] py-[49px] gap-[70px]  flex-col justify-start items-start">
              <section className=" w-full flex items-center justify-between">
                <img src={logo} alt="myHood" />

                <svg className=" cursor-pointer" onClick={() => setSmallScreens(true)} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.68056 4.47599C8.68056 4.47599 12.8472 7.54467 12.8472 8.64266C12.8472 9.74071 8.68056 12.8093 8.68056 12.8093" stroke="#333333" strokeWidth="1.04167" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M3.81948 4.47599C3.81948 4.47599 7.98615 7.54467 7.98615 8.64266C7.98615 9.74071 3.81948 12.8093 3.81948 12.8093" stroke="#333333" strokeWidth="1.04167" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </section>
              <section className=" gap-[20px] w-full flex-col flex items-start justify-between">
                {menu.map((item) => (
                  <NavLink
                    className={` text-[14px] whitespace-nowrap justify-center items-center font-openSans font-normal tracking-[0.9px]  rounded-[20px]  px-[20px] h-[39px] py-[10px] w-full flex  gap-[10px] ${
                      location.pathname === item.path ? "text-[#F5F5F5] bg-[#FF5722]" : " bg-none text-[#333]"
                    } `}
                    key={item.id}
                    title={item.title}
                    to={item.path}
                  >
                    {location.pathname === item.path ? item.icon2 : item.icon1}
                  </NavLink>
                ))}
              </section>
              <section className="w-full ">
                <NavLink
                  className={` text-[14px] whitespace-nowrap justify-center items-center font-openSans font-normal tracking-[0.9px]  rounded-[20px]  px-[20px] h-[39px] py-[10px] w-full flex  gap-[10px] ${
                    location.pathname === path ? "text-[#F5F5F5] bg-[#FF5722]" : " bg-none text-[#333]"
                  } `}
                  to={path}
                >
                  <img className=" w-[30px] rounded-full" src={data.profile_pic === "" ? UserImg : `data:image/png;base64,${data.profile_pic}`} alt={data.first_name + " " + data.last_name} title={data.first_name + " " + data.last_name} />
                </NavLink>
              </section>
            </div>
          </>
        )}
      </div>
      <main>{children}</main>
    </>
  );
}

export default SideBar;
