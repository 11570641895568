import { useCallback, useEffect, useState } from "react";
// import InputFiles from "react-input-files";
import UserImg from "../../../images/placeholder.png";
import axios from "axios";
// import { axiosInstance } from "../../../Utils";
import authRequest from "../../../Utils/requests";

function ProfileManagement({ setModal }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    userImage: "",
  });

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    },
    [setFormData]
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData);
    const revData = localStorage.getItem("myHoodData");
    setData(JSON.parse(revData));
    const payload = {
      id: data.id,
      // id: 11,
      first_name: formData.firstName || data.first_name,
      last_name: formData.lastName || data.last_name,
      email: formData.email || data.email,
      phone_number: formData.phoneNumber || data.phone_number,
      profile_pic: selectedImage || data.profile_pic,
    };

    console.log(payload);

    setModal(true);
    try {
      const res = await axios.post("https://www.myhood.ng/mobile_back_end/api/update_user.php", payload);
      console.log(res.data); // Handle successful response from the backend

      await authRequest.getUserInfo(data.id).then(async (response) => {
        let dataSave = {
          address: response.data.address,
          caegory: response.data.category,
          dob: response.data.dob,
          email: response.data.email,
          emergency_email: response.data.emergency_email,
          emergency_message: response.data.emergency_message,
          emergency_number: response.data.emergency_number,
          estate_id: response.data.estate_id,
          first_name: response.data.first_name,
          flat_id: response.data.flat_id,
          id: response.data.id,
          last_name: response.data.last_name,
          middle_name: response.data.middle_name,
          nin: response.data.nin,
          // password: response.data.password
          phone_number: response.data.phone_number,
          plot_number: response.data.plot_number,
          primary_vendor: response.data.primary_vendor,
          profile_pic: response.data.profile_pic,
          registration_code: response.data.registration_code,
          role: response.data.role,
          status: response.data.status,
          street_name: response.data.street_name,
          type: response.data.type,
          visible: response.data.visible,

          // hood_name: response.data.name,

          // hood_user_token: response.data.token,
        };
        localStorage.setItem("myHoodData", JSON.stringify(dataSave));
        window.location.reload();
      });
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const revData = localStorage.getItem("myHoodData");
        setData(JSON.parse(revData));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // setFormData({
    //   firstName: data.first_name,
    //   lastName: data.last_name,
    //   email: data.email,
    //   phoneNumber: data.phone_number,
    //   userImage: data.profile_pic, // You might want to set this to the initial user image if available
    // });
  }, []);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1]; // Remove the prefix
        setSelectedImage(base64String);
        // console.log("Base64 String:", base64String);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {/* LARGE SCREENS */}
      <div className={` 100:hidden xl:block  w-full `}>
        {/* Banner & Profile Picture, name */}
        <div className="flex  w-full flex-col">
          <div className="w-full h-[200px] relative bg-gradient-to-r from-red-200 to-green-200" />
          <div className=" w-full z-10 px-[32px] mt-[-50px] items-center flex justify-between">
            <section className=" w-full flex gap-[24px] justify-between items-center">
              <img
                className="shadow border-solid border-[#fff] border-[4px] rounded-full h-[160px] w-[160px]"
                src={selectedImage ? `data:image/png;base64,${selectedImage}` : data.profile_pic === "" ? UserImg : `data:image/png;base64,${data.profile_pic}`}
                alt={formData.firstName + " " + formData.lastName}
              />

              {/* <img className="  shadow border-solid border-[#fff] border-[4px]  rounded-full h-[160px] w-[160px] " src={selectedImage ? URL.createObjectURL(selectedImage) : UserImg} alt={data.first_name + " " + data.last_name} /> */}
              {/* <InputFiles onChange={handleFileChange}>
                <div className=" bg-[#FF5722] ml-[-45px] mt-[10px]   w-fit h-fit p-[10px] rounded-full border-[1.5px] flex justify-center items-center border-[#fff]  ">
                  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.49976 3.99902C4.68676 3.99902 3.99976 4.68602 3.99976 5.49902C3.99976 6.31202 4.68676 6.99902 5.49976 6.99902C6.31276 6.99902 6.99976 6.31202 6.99976 5.49902C6.99976 4.68602 6.31276 3.99902 5.49976 3.99902Z"
                      fill="white"
                    />
                    <path
                      d="M9.5 2H8.207L6.8535 0.646501C6.80715 0.599979 6.75205 0.563085 6.69138 0.537943C6.63071 0.5128 6.56567 0.499905 6.5 0.500001H4.5C4.43433 0.499905 4.36929 0.5128 4.30862 0.537943C4.24795 0.563085 4.19285 0.599979 4.1465 0.646501L2.793 2H1.5C0.9485 2 0.5 2.4485 0.5 3V8.5C0.5 9.0515 0.9485 9.5 1.5 9.5H9.5C10.0515 9.5 10.5 9.0515 10.5 8.5V3C10.5 2.4485 10.0515 2 9.5 2ZM5.5 8C4.145 8 3 6.855 3 5.5C3 4.145 4.145 3 5.5 3C6.855 3 8 4.145 8 5.5C8 6.855 6.855 8 5.5 8Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </InputFiles> */}

              <div className="file-input-container">
                <input onChange={handleFileChange} type="file" id="file-input" className="hidden" />
                <label htmlFor="file-input" className="bg-[#FF5722] ml-[-35px] mt-[10px] w-fit h-fit p-[10px] rounded-full border-[1.5px] flex justify-center items-center border-[#fff]">
                  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.49976 3.99902C4.68676 3.99902 3.99976 4.68602 3.99976 5.49902C3.99976 6.31202 4.68676 6.99902 5.49976 6.99902C6.31276 6.99902 6.99976 6.31202 6.99976 5.49902C6.99976 4.68602 6.31276 3.99902 5.49976 3.99902Z"
                      fill="white"
                    />
                    <path
                      d="M9.5 2H8.207L6.8535 0.646501C6.80715 0.599979 6.75205 0.563085 6.69138 0.537943C6.63071 0.5128 6.56567 0.499905 6.5 0.500001H4.5C4.43433 0.499905 4.36929 0.5128 4.30862 0.537943C4.24795 0.563085 4.19285 0.599979 4.1465 0.646501L2.793 2H1.5C0.9485 2 0.5 2.4485 0.5 3V8.5C0.5 9.0515 0.9485 9.5 1.5 9.5H9.5C10.0515 9.5 10.5 9.0515 10.5 8.5V3C10.5 2.4485 10.0515 2 9.5 2ZM5.5 8C4.145 8 3 6.855 3 5.5C3 4.145 4.145 3 5.5 3C6.855 3 8 4.145 8 5.5C8 6.855 6.855 8 5.5 8Z"
                      fill="white"
                    />
                  </svg>
                </label>
              </div>

              <section className="flex  items-center justify-between  w-full mt-[60px]">
                <div className="w-fit h-[69px]  flex-col justify-start items-start gap-1 inline-flex">
                  <h3 className="self-stretch text-zinc-800 text-3xl font-semibold font-openSans tracking-tight">Profile</h3>
                  <h4 className="self-stretch text-neutral-500 text-base font-normal font-monteserrat leading-normal">Update your photo and personal details</h4>
                </div>
                <div className=" flex gap-[10px] justify-between items-center">
                  <button
                    type="submit"
                    className="  text-zinc-800 text-xs font-semibold font-openSans tracking-tight  flex justify-center items-center gap-[10px] w-fit hit-fit py-[5px] px-[20px] rounded-[5px] border-solid border-[1px] border-[#FF5722] "
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className=" cursor-pointer text-white text-xs font-semibold font-openSans tracking-tight  flex justify-center items-center gap-[10px] w-fit hit-fit py-[5px] px-[20px] rounded-[5px]  bg-[#FF5722] "
                  >
                    Save
                  </button>
                </div>
              </section>
            </section>
          </div>
        </div>

        <div className="w-full gap-[20px] px-[20px] flex-col flex justify-start items-start mt-[50px] pb-[20px]">
          <h3 className="w-full border-b-[1px] h-[44px] border-b-[#E7E7E7] text-zinc-800 text-lg font-semibold font-openSans leading-relaxed">Personal Information</h3>
          <form className=" flex flex-col justify-between gap-[30px] items-center">
            <div className=" flex items-center justify-between gap-[24px]  ">
              <div className="w-[284px] h-[61px] flex-col justify-start items-start gap-[8px] flex">
                <label htmlFor="first name" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                  First Name
                </label>
                <input
                  value={formData.firstName}
                  onChange={handleChange}
                  name="firstName"
                  id="firstName"
                  placeholder={data.first_name}
                  type="text"
                  className=" outline-[#FF5722] w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100  text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 "
                />
              </div>
              <div className="w-[284px] h-[61px] flex-col justify-start items-start gap-[8px] flex">
                <label htmlFor="last name" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                  Last Name
                </label>
                <input
                  value={formData.lastName}
                  onChange={handleChange}
                  name="lastName"
                  placeholder={data.last_name}
                  type="text"
                  className=" outline-[#FF5722] w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100  text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 "
                />
              </div>
            </div>
            <div className=" flex items-center justify-between gap-[24px]  ">
              <div className="w-[284px] h-[61px] flex-col justify-start items-start gap-[8px] flex">
                <label htmlFor="email" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                  Email Address
                </label>
                <input
                  value={formData.email}
                  onChange={handleChange}
                  name="email"
                  placeholder={data.email}
                  type="email"
                  className=" outline-[#FF5722] w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100  text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 "
                />
              </div>
              <div className="w-[284px] h-[61px] flex-col justify-start items-start gap-[8px] flex">
                <label htmlFor="phone number" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                  Phone Number
                </label>
                <input
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  name="phoneNumber"
                  placeholder={data.phone_number}
                  type="tel"
                  className=" outline-[#FF5722] w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100  text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 "
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* SMALL SCREENS */}
      <div className=" 100:flex xl:hidden w-full  pb-[50px] flex-col justify-between gap-[22px] h-fit ">
        {/* Banner & Profile Picture, name */}
        <div className="flex w-full justify- flex-col">
          <div className="w-full h-[200px] relative bg-gradient-to-r from-red-200 to-green-200" />
          <div className=" w-full z-10 px-[10px] mt-[-50px] items-center flex justify-between">
            <section className=" w-full flex-col flex gap-[10px] justify-start items-start">
              <img
                className="  shadow border-solid border-[#fff] border-[4px]  rounded-full h-[140px] w-[140px] "
                src={selectedImage ? `data:image/png;base64,${selectedImage}` : data.profile_pic === "" ? UserImg : `data:image/png;base64,${data.profile_pic}`}
                alt="User"
              />

              <div className="file-input-container">
                <input onChange={handleFileChange} type="file" id="file-input" className="hidden" />
                <label htmlFor="file-input" className=" bg-[#FF5722] mt-[-85px] ml-[120px] w-fit h-fit p-[10px] rounded-full border-[1.5px] flex justify-center items-center border-[#fff]  ">
                  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.49976 3.99902C4.68676 3.99902 3.99976 4.68602 3.99976 5.49902C3.99976 6.31202 4.68676 6.99902 5.49976 6.99902C6.31276 6.99902 6.99976 6.31202 6.99976 5.49902C6.99976 4.68602 6.31276 3.99902 5.49976 3.99902Z"
                      fill="white"
                    />
                    <path
                      d="M9.5 2H8.207L6.8535 0.646501C6.80715 0.599979 6.75205 0.563085 6.69138 0.537943C6.63071 0.5128 6.56567 0.499905 6.5 0.500001H4.5C4.43433 0.499905 4.36929 0.5128 4.30862 0.537943C4.24795 0.563085 4.19285 0.599979 4.1465 0.646501L2.793 2H1.5C0.9485 2 0.5 2.4485 0.5 3V8.5C0.5 9.0515 0.9485 9.5 1.5 9.5H9.5C10.0515 9.5 10.5 9.0515 10.5 8.5V3C10.5 2.4485 10.0515 2 9.5 2ZM5.5 8C4.145 8 3 6.855 3 5.5C3 4.145 4.145 3 5.5 3C6.855 3 8 4.145 8 5.5C8 6.855 6.855 8 5.5 8Z"
                      fill="white"
                    />
                  </svg>
                </label>
              </div>

              <section className="flex   justify-between flex-col items-end gap-[20px] w-full ">
                <div className="w-full 100:h-[100px] sm:h-[69px]  flex-col justify-start items-start gap-1 inline-flex">
                  <h3 className="self-stretch text-zinc-800 text-[28px] font-semibold font-openSans tracking-tight">Profile</h3>
                  <h4 className="self-stretch text-neutral-500 text-[14px] font-normal font-monteserrat leading-normal">Update your photo and personal details</h4>
                </div>
                <div className=" flex gap-[10px] justify-between items-center">
                  <div className=" cursor-pointer text-zinc-800 text-xs font-semibold font-openSans tracking-tight  flex justify-center items-center gap-[10px] w-fit hit-fit py-[5px] px-[20px] rounded-[5px] border-solid border-[1px] border-[#FF5722] ">
                    Cancel
                  </div>
                  <div onClick={handleSubmit} className=" cursor-pointer text-white text-xs font-semibold font-openSans tracking-tight  flex justify-center items-center gap-[10px] w-fit hit-fit py-[5px] px-[20px] rounded-[5px]  bg-[#FF5722] ">
                    Save
                  </div>
                </div>
              </section>
            </section>
          </div>
        </div>
        <div className="w-full md:w-[450px] gap-[20px] flex-col flex justify-start items-start pb-[20px]">
          <h3 className="w-full border-b-[1px] h-[44px] border-b-[#E7E7E7] text-zinc-800 text-lg font-semibold font-openSans leading-relaxed">Personal Information</h3>
          <form className=" flex flex-col w-full justify-between gap-[30px] items-center">
            <div className="w-full h-[61px]  flex-col justify-start items-start gap-[8px] flex">
              <label htmlFor="first name" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                First Name
              </label>
              <input
                value={formData.firstName}
                onChange={handleChange}
                name="firstName"
                id="firstName"
                placeholder={data.first_name}
                type="text"
                className=" outline-[#FF5722] w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 "
              />
            </div>
            <div className="w-full h-[61px]  flex-col justify-start items-start gap-[8px] flex">
              <label htmlFor="last name" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                Last Name
              </label>
              <input
                value={formData.lastName}
                onChange={handleChange}
                name="lastName"
                placeholder={data.last_name}
                type="text"
                className=" outline-[#FF5722] w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 "
              />
            </div>
            <div className="w-full h-[61px]  flex-col justify-start items-start gap-[8px] flex">
              <label htmlFor="email" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                Email Address
              </label>
              <input
                value={formData.email}
                onChange={handleChange}
                name="email"
                placeholder={data.email}
                type="email"
                className=" outline-[#FF5722] w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 "
              />
            </div>
            <div className="w-full h-[61px]  flex-col justify-start items-start gap-[8px] flex">
              <label htmlFor="phone number" className=" text-zinc-800 text-sm font-semibold font-openSans leading-tight">
                Phone Number
              </label>
              <input
                value={formData.phoneNumber}
                onChange={handleChange}
                name="phoneNumber"
                placeholder={data.phone_number}
                type="tel"
                className=" outline-[#FF5722] w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 "
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ProfileManagement;
