import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "./Header";
import SideBar from "./SideBar";
import UserImg from "../../images/ava.png";
// import { axiosInstance } from "../../Utils";
import axios from "axios";

function ProfileUser() {
  const [mini, setMini] = useState(false);
  const location = useLocation();
  const [data, setData] = useState([]);

  useEffect(() => {
    // const fetchData = async () => {
    //   // try {
    //   //   const res = await axios.post("http://www.myhood.ng/mobile_back_end/api/get_user.php?id=1", {
    //   //     data,
    //   //   });
    //   //   // axiosInstance.post("/get_user.php", data, { headers: { "Content-Type": "application/json" } });
    //   //   console.log(res);

    //   //   setData(res.data);
    //   // } catch (error) {
    //   //   console.log(error);
    //   // }
    //   const revData = localStorage.getItem("myHoodData");

    //   console.log(revData);
    //   setData(revData);
    // };

    // fetchData();

    const revData = localStorage.getItem("myHoodData");

    console.log(revData.first_name);
    setData(JSON.parse(revData));
  }, []);

  console.log(data.first_name);
  const menu = [
    {
      id: 1,
      path: "/dashboarduser",
      name: "Dashboard",
      title: "Dashboard",
      icon1: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83334 3.17332 9.83334 4.73812C9.83334 6.30293 11.1018 7.57146 12.6666 7.57146Z" stroke="#333333" />
          <path d="M5 7.57146C6.5648 7.57146 7.83333 6.30293 7.83333 4.73812C7.83333 3.17332 6.5648 1.90479 5 1.90479C3.43519 1.90479 2.16666 3.17332 2.16666 4.73812C2.16666 6.30293 3.43519 7.57146 5 7.57146Z" stroke="#333333" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83334 10.8398 9.83334 12.4046C9.83334 13.9694 11.1018 15.238 12.6666 15.238Z" stroke="#333333" />
          <path d="M5 15.238C6.5648 15.238 7.83333 13.9694 7.83333 12.4046C7.83333 10.8398 6.5648 9.57129 5 9.57129C3.43519 9.57129 2.16666 10.8398 2.16666 12.4046C2.16666 13.9694 3.43519 15.238 5 15.238Z" stroke="#333333" />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83331 3.17332 9.83331 4.73812C9.83331 6.30293 11.1018 7.57146 12.6666 7.57146Z" fill="#F5F5F5" />
          <path d="M4.99997 7.57146C6.56477 7.57146 7.8333 6.30293 7.8333 4.73812C7.8333 3.17332 6.56477 1.90479 4.99997 1.90479C3.43516 1.90479 2.16663 3.17332 2.16663 4.73812C2.16663 6.30293 3.43516 7.57146 4.99997 7.57146Z" fill="#F5F5F5" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83331 10.8398 9.83331 12.4046C9.83331 13.9694 11.1018 15.238 12.6666 15.238Z" fill="#F5F5F5" />
          <path d="M4.99997 15.238C6.56477 15.238 7.8333 13.9694 7.8333 12.4046C7.8333 10.8398 6.56477 9.57129 4.99997 9.57129C3.43516 9.57129 2.16663 10.8398 2.16663 12.4046C2.16663 13.9694 3.43516 15.238 4.99997 15.238Z" fill="#F5F5F5" />
        </svg>
      ),
    },
    {
      id: 2,
      path: "/paymentuser",
      name: "Payment",
      title: "Payment",
      icon1: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_1356_2637" maskUnits="userSpaceOnUse" x="0" y="1" width="16" height="17">
            <path d="M16 1.07129H0V17.0713H16V1.07129Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1356_2637)">
            <path
              d="M9.33333 13.0712C12.2789 13.0712 14.6667 10.6833 14.6667 7.73787C14.6667 4.79235 12.2789 2.40454 9.33333 2.40454C6.38781 2.40454 4 4.79235 4 7.73787C4 10.6833 6.38781 13.0712 9.33333 13.0712Z"
              stroke="#333333"
              stroke-linecap="round"
            />
            <path d="M2.10442 8.40454C1.61686 9.14958 1.33337 10.0402 1.33337 10.9971C1.33337 13.6153 3.45589 15.7378 6.07415 15.7378C7.03102 15.7378 7.92167 15.4543 8.66671 14.9668" stroke="#333333" stroke-linecap="round" />
            <path
              d="M11.9727 7.83441H11.3567V8.23341H11.9727V8.66741H11.3567V10.4454H10.4817L8.24874 7.12041V10.4454H7.33874V8.66741H6.72974V8.23341H7.33874V7.83441H6.72974V7.40041H7.33874V5.54541H8.21374L10.4607 8.89141V5.54541H11.3567V7.40041H11.9727V7.83441Z"
              fill="#333333"
            />
          </g>
        </svg>
      ),
      icon2: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_1388_7257" maskUnits="userSpaceOnUse" x="0" y="1" width="16" height="17">
            <path d="M16 1.07129H0V17.0713H16V1.07129Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1388_7257)">
            <path
              d="M9.33333 13.0712C12.2789 13.0712 14.6667 10.6833 14.6667 7.73787C14.6667 4.79235 12.2789 2.40454 9.33333 2.40454C6.38781 2.40454 4 4.79235 4 7.73787C4 10.6833 6.38781 13.0712 9.33333 13.0712Z"
              fill="#F5F5F5"
              stroke="#F5F5F5"
              stroke-linecap="round"
            />
            <path d="M2.10442 8.40454C1.61686 9.14958 1.33337 10.0402 1.33337 10.9971C1.33337 13.6153 3.45589 15.7378 6.07415 15.7378C7.03102 15.7378 7.92167 15.4543 8.66671 14.9668" stroke="#F5F5F5" stroke-linecap="round" />
            <path
              d="M11.9727 7.83441H11.3567V8.23341H11.9727V8.66741H11.3567V10.4454H10.4817L8.24874 7.12041V10.4454H7.33874V8.66741H6.72974V8.23341H7.33874V7.83441H6.72974V7.40041H7.33874V5.54541H8.21374L10.4607 8.89141V5.54541H11.3567V7.40041H11.9727V7.83441Z"
              fill="#FF5722"
            />
          </g>
        </svg>
      ),
    },
    {
      id: 3,
      path: "/advertmanagementuser",
      name: "Advert Management",
      title: "Advert Management",
      icon1: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.67053 11.7323L5.35427 7.09046C5.66045 6.44611 6.12866 5.9132 6.66514 7.19129C7.16036 8.3711 7.89923 10.5314 8.33536 11.7348M4.43555 9.7393H7.54763" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M2.30969 3.94997C1.33337 4.82865 1.33337 6.24287 1.33337 9.07129C1.33337 11.8996 1.33337 13.3139 2.30969 14.1926C3.28599 15.0712 4.85735 15.0712 8.00004 15.0712C11.1427 15.0712 12.7141 15.0712 13.6904 14.1926C14.6667 13.3139 14.6667 11.8996 14.6667 9.07129C14.6667 6.24287 14.6667 4.82865 13.6904 3.94997C12.7141 3.07129 11.1427 3.07129 8.00004 3.07129C4.85735 3.07129 3.28599 3.07129 2.30969 3.94997Z"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.3228 7.0625V9.05895M12.3228 9.05895V11.688M12.3228 9.05895H10.9773C10.8175 9.05895 10.659 9.08868 10.5096 9.14662C9.38042 9.58475 9.38042 11.2128 10.5096 11.651C10.659 11.7089 10.8175 11.7386 10.9773 11.7386H12.3228"
            stroke="#333333"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.14304 3.94997C2.16672 4.82865 2.16672 6.24287 2.16672 9.07129C2.16672 11.8996 2.16672 13.3139 3.14304 14.1926C4.11934 15.0712 5.6907 15.0712 8.83339 15.0712C11.976 15.0712 13.5474 15.0712 14.5237 14.1926C15.5 13.3139 15.5 11.8996 15.5 9.07129C15.5 6.24287 15.5 4.82865 14.5237 3.94997C13.5474 3.07129 11.976 3.07129 8.83339 3.07129C5.6907 3.07129 4.11934 3.07129 3.14304 3.94997Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M4.50388 11.7323L6.18762 7.09046C6.4938 6.44611 6.96201 5.9132 7.49849 7.19129C7.99371 8.3711 8.73257 10.5314 9.1687 11.7348M5.2689 9.7393H8.38098" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M13.1561 7.0625V9.05895M13.1561 9.05895V11.688M13.1561 9.05895H11.8106C11.6508 9.05895 11.4923 9.08868 11.3429 9.14662C10.2138 9.58475 10.2138 11.2128 11.3429 11.651C11.4923 11.7089 11.6508 11.7386 11.8106 11.7386H13.1561"
            stroke="#FF5722"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 4,
      path: "/issuetrackinguser",
      name: "Issue Tracking",
      title: "Issue Tracking",
      icon1: (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.38115 6.74101C5.99027 3.89372 6.79482 2.47006 7.89887 2.1036C8.50621 1.90199 9.16048 1.90199 9.76781 2.1036C10.8719 2.47006 11.6764 3.89372 13.2855 6.74101C14.8947 9.58832 15.6992 11.0119 15.4579 12.1719C15.3251 12.8101 14.9979 13.389 14.5233 13.8255C13.6607 14.6191 12.0516 14.6191 8.83334 14.6191C5.61511 14.6191 4.00599 14.6191 3.14331 13.8255C2.66873 13.389 2.3416 12.8101 2.20882 12.1719C1.96747 11.0119 2.77203 9.58832 4.38115 6.74101Z"
            stroke="#333333"
          />
          <path d="M8.99481 11.619V8.95231C8.99481 8.63804 8.99481 8.48091 8.89714 8.38324C8.79954 8.28564 8.64241 8.28564 8.32814 8.28564" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8.828 6.28564H8.8355" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
      icon2: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.54785 7.52665C5.15697 4.67936 5.96152 3.2557 7.06558 2.88924C7.67291 2.68763 8.32718 2.68763 8.93451 2.88924C10.0386 3.2557 10.8431 4.67936 12.4522 7.52665C14.0614 10.374 14.8659 11.7976 14.6246 12.9576C14.4918 13.5958 14.1646 14.1747 13.69 14.6112C12.8274 15.4047 11.2183 15.4047 8.00004 15.4047C4.78181 15.4047 3.17269 15.4047 2.31002 14.6112C1.83543 14.1747 1.5083 13.5958 1.37552 12.9576C1.13417 11.7976 1.93873 10.374 3.54785 7.52665Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
          />
          <path d="M8.16142 12.4046V9.73796C8.16142 9.42369 8.16142 9.26656 8.06375 9.16889C7.96615 9.07129 7.80902 9.07129 7.49475 9.07129" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.99463 7.07129H8.00213" stroke="#FF5722" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
    },
  ];
  const activeItem = menu.find((item) => data.path === location.pathname);

  return (
    <>
      <div className="flex  justify-between items-start">
        <SideBar mini={mini} setMini={setMini} menu={menu} />
        {/* LARGE SCREENS */}
        <div className={` 100:hidden xl:flex w-full pr-[25px] pb-[50px] flex flex-col justify-between gap-[22px] h-fit ${mini ? "pl-[130px]" : "pl-[280px]"}`}>
          <Header mini={mini} name={activeItem ? activeItem.name : "Profile"} />
          <div className="w-full h-fit gap-[50px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            {/* Banner & Profile Picture, name */}
            <div className="flex w-full flex-col">
              <div className="w-full h-[200px] relative bg-gradient-to-r from-red-200 to-green-200" />
              <div className=" w-full z-10 px-[32px] mt-[-50px] items-center flex justify-between">
                <section className=" w-full flex gap-[24px] justify-between items-center">
                  {/* {data.map((item) => ( */}
                  <>
                    <div className="image-container ">
                      <img
                        className=" border-[4px]  object-cover  border-[#fff] rounded-full"
                        src={data.profile_pic === "" ? UserImg : `data:image/png;base64,${data.profile_pic}`}
                        alt={data.first_name + " " + data.last_name}
                        title={data.first_name + " " + data.last_name}
                      />
                    </div>
                  </>
                  {/* ))} */}
                  <section className="flex justify-between items-center w-full mt-[50px]">
                    <div className="w-fit h-[69px]  flex-col justify-start items-start gap-1 inline-flex">
                      {/* {data.map((item) => ( */}
                      <>
                        <h3 className="self-stretch text-zinc-800 text-3xl font-semibold font-openSans tracking-tight">
                          {data.first_name} {data.last_name}
                        </h3>
                        <h4 className="self-stretch text-neutral-500 text-base font-normal font-monteserrat leading-normal">{data.email}</h4>
                      </>
                      {/* ))} */}
                    </div>
                    <Link to="/login">
                      <div className="  flex justify-center items-center gap-[10px] w-fit hit-fit p-[10px] rounded-[5px] border-solid border-[1px] border-[#FF5722] ">
                        <h3 className="text-zinc-800 text-xs font-semibold font-openSans tracking-tight">Logout</h3>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.4165 1.75L6.02999 1.88641C4.52573 2.41733 3.77358 2.6828 3.34504 3.2885C2.9165 3.89419 2.9165 4.69181 2.9165 6.28705V7.71295C2.9165 9.30819 2.9165 10.1058 3.34504 10.7115C3.77358 11.3172 4.52573 11.5827 6.02999 12.1136L6.4165 12.25"
                            stroke="#333333"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M12.2498 6.99996H6.4165M12.2498 6.99996C12.2498 6.59151 11.0865 5.82835 10.7915 5.54163M12.2498 6.99996C12.2498 7.40841 11.0865 8.17158 10.7915 8.45829"
                            stroke="#333333"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </Link>
                  </section>
                </section>
              </div>
            </div>
            {/* Personal Details */}
            <div className="w-full gap-[20px] flex-col flex justify-start items-start pb-[20px]">
              <h3 className="w-full text-zinc-800 text-lg font-semibold font-openSans leading-relaxed">Personal Information</h3>
              <div className="flex px-[14px] justify-between gap-[24px] w-fit items-center">
                <div className=" flex justify-between gap-[24px] items-center">
                  <div className=" w-[284px] h-16 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="flex-col justify-start items-start gap-1 flex">
                      <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">First name</h3>
                      {/* {data.map((item) => ( */}
                      <h4 className="text-zinc-800 text-sm font-medium font-monteserrat leading-tight">{data.first_name}</h4>
                      {/* ))} */}
                    </div>
                  </div>
                  <div className=" w-[284px] h-16 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="flex-col justify-start items-start gap-1 flex">
                      <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Last name</h3>
                      {/* {data.map((item) => ( */}
                      <h4 className="text-zinc-800 text-sm font-medium font-monteserrat leading-tight">{data.last_name}</h4>
                      {/* ))} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex px-[14px] justify-between gap-[24px] w-fit items-center">
                <div className=" flex justify-between gap-[24px] items-center">
                  <div className=" w-[284px] h-16 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="flex-col justify-start items-start gap-1 flex">
                      <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Email Address</h3>
                      {/* {data.map((item) => ( */}
                      <h4 className="text-zinc-800 text-sm font-medium font-monteserrat leading-tight">{data.email}</h4>
                      {/* ))} */}
                    </div>
                  </div>
                  <div className=" w-[284px] h-16 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="flex-col justify-start items-start gap-1 flex">
                      <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Phone Number</h3>
                      {/* {data.map((item) => ( */}
                      <h4 className="text-zinc-800 text-sm font-medium font-monteserrat leading-tight">{data.phone_number}</h4>
                      {/* ))} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex px-[14px] justify-between gap-[24px] w-fit items-center">
                <div className=" flex justify-between gap-[24px] items-center">
                  <div className=" w-[284px] h-16 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="flex-col justify-start items-start gap-1 flex">
                      <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Estate</h3>
                      {/* {data.map((item) => ( */}
                      <h4 className="text-zinc-800 text-sm font-medium font-monteserrat leading-tight">{data.street_name}</h4>
                      {/* ))} */}
                    </div>
                  </div>
                  <div className=" w-fit h-16 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="flex-col justify-start items-start gap-1 flex">
                      <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">House Address</h3>
                      {/* {data.map((item) => ( */}
                      <h4 className="text-zinc-800 text-sm font-medium font-monteserrat leading-tight">{data.address}</h4>
                      {/* ))} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SMALL SCREENS */}
        <div className=" 100:flex xl:hidden w-full pr-[25px] pb-[50px] flex-col justify-between gap-[22px] h-fit pl-[130px]">
          <Header mini={mini} name={activeItem ? activeItem.name : "Profile"} />
          <div className="w-full h-fit gap-[50px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            {/* Banner & Profile Picture, name */}
            <div className="flex w-full flex-col">
              <div className="w-full h-[200px] relative bg-gradient-to-r from-red-200 to-green-200" />
              <div className=" w-full z-10 px-[10px] mt-[-50px] items-center flex justify-between">
                <section className=" w-full flex-col flex gap-[10px] justify-start items-start">
                  <div className="image-container ">
                    <img
                      className=" border-[4px]  object-cover  border-[#fff] rounded-full"
                      src={data.profile_pic === "" ? UserImg : `data:image/png;base64,${data.profile_pic}`}
                      alt={data.first_name + " " + data.last_name}
                      title={data.first_name + " " + data.last_name}
                    />
                  </div>
                  <section className="flex justify-between items-center w-full ">
                    <div className="w-fit h-[69px]  flex-col justify-start items-start gap-1 inline-flex">
                      <h3 className="self-stretch text-zinc-800 text-3xl font-semibold font-openSans tracking-tight">
                        {data.first_name} {data.last_name}
                      </h3>
                      <h4 className="self-stretch text-neutral-500 text-base font-normal font-monteserrat leading-normal">{data.email}</h4>
                    </div>
                    <Link title="Logout" to="/login">
                      <div className="  flex justify-center items-center gap-[10px] w-fit hit-fit p-[10px] rounded-[5px] border-solid border-[1px] border-[#FF5722] ">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.4165 1.75L6.02999 1.88641C4.52573 2.41733 3.77358 2.6828 3.34504 3.2885C2.9165 3.89419 2.9165 4.69181 2.9165 6.28705V7.71295C2.9165 9.30819 2.9165 10.1058 3.34504 10.7115C3.77358 11.3172 4.52573 11.5827 6.02999 12.1136L6.4165 12.25"
                            stroke="#333333"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M12.2498 6.99996H6.4165M12.2498 6.99996C12.2498 6.59151 11.0865 5.82835 10.7915 5.54163M12.2498 6.99996C12.2498 7.40841 11.0865 8.17158 10.7915 8.45829"
                            stroke="#333333"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </Link>
                  </section>
                </section>
              </div>
            </div>
            {/* Personal Details */}
            <div className="w-full gap-[20px] flex-col flex justify-start items-start pb-[20px]">
              <h3 className="w-full text-zinc-800 text-lg font-semibold font-openSans leading-relaxed">Personal Information</h3>
              <div className="flex flex-col justify-between gap-[24px] w-fit items-start">
                <div className="flex-col justify-start items-start gap-1 flex">
                  <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">First name</h3>

                  <h4 className="text-zinc-800 text-sm font-medium font-monteserrat leading-tight">{data.first_name}</h4>
                </div>

                <div className="flex-col justify-start items-start gap-1 flex">
                  <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Last name</h3>

                  <h4 className="text-zinc-800 text-sm font-medium font-monteserrat leading-tight">{data.last_name}</h4>
                </div>
                <div className="flex-col justify-start items-start gap-1 flex">
                  <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Email Address</h3>

                  <h4 className="text-zinc-800 text-sm font-medium font-monteserrat leading-tight">{data.email}</h4>
                </div>
                <div className="flex-col justify-start items-start gap-1 flex">
                  <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Phone Number</h3>

                  <h4 className="text-zinc-800 text-sm font-medium font-monteserrat leading-tight">{data.phone_number}</h4>
                </div>
                <div className="flex-col justify-start items-start gap-1 flex">
                  <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">Estate</h3>

                  <h4 className="text-zinc-800 text-sm font-medium font-monteserrat leading-tight">{data.street_name}</h4>
                </div>
                <div className="flex-col justify-start items-start gap-1 flex">
                  <h3 className="self-stretch text-neutral-400 text-sm font-normal font-openSans leading-tight">House Address</h3>

                  <h4 className="text-zinc-800 text-sm font-medium font-monteserrat leading-tight">{data.address}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileUser;
