import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import sideImg from "../images/loginmyhood.png";
import signup from "../images/Signup img.png";
import "react-datepicker/dist/react-datepicker.css";
// import { axiosInstance } from "../Utils";
import axios from "axios";

function SignUp() {
  const [border, setBorder] = useState(false);
  const [border2, setBorder2] = useState(false);
  const [border3, setBorder3] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [page, setPage] = useState(1);
  const [page1Fields, setPage1Fields] = useState({});
  const [page2Fields, setPage2Fields] = useState({});
  const [page3Fields, setPage3Fields] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [inputType2, setInputType2] = useState("password");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (page > 1) {
      setPage(page - 1);
    } else {
      navigate("/homepage");
    }
  };

  // value
  const [inputValue, setInputValue] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
    input7: "",
    input8: "",
    input9: "",
  });

  const handleSub = async (event) => {
    event.preventDefault();

    console.log("Input 1:", inputValue.input1);
    console.log("Input 2:", inputValue.input2);
    console.log("Input 3:", inputValue.input3);
    console.log("Input 4:", inputValue.input4);
    console.log("Input 5:", inputValue.input5);
    console.log("Input 6:", inputValue.input6);
    console.log("Input 7:", inputValue.input7);
    console.log("Input 8:", inputValue.input8);
    console.log("Input 9:", inputValue.input9);
    // e.preventDefault();

    const data = {
      password: inputValue.input7,
      phone_number: inputValue.input4,
      email: inputValue.input3,
      // middle_name: "kk",
      first_name: inputValue.input1,
      last_name: inputValue.input2,
      flat_id: inputValue.input5,
    };
    try {
      const response = await axios.post("https://myhood.ng/mobile_back_end/api/add_users.php", data);

      // console.log(response.data.responsecode);
      if (response.data.responsecode === "200") {
        navigate("/login");
      } else {
        // display error
      }
      return response.data;
    } catch (error) {
      console.error("Error registering user:", error);
      // Rethrow the error for the caller to handle
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setInputType(showPassword ? "password" : "text");
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
    setInputType2(showPassword2 ? "password" : "text");
  };
  const handleSvgClick = () => {
    setIsOpen(!isOpen);
  };
  const handleFieldChange = (page, fieldName, value) => {
    switch (page) {
      case 1:
        setPage1Fields({ ...page1Fields, [fieldName]: value });
        break;
      case 2:
        setPage2Fields({ ...page2Fields, [fieldName]: value });
        break;
      case 3:
        setPage3Fields({ ...page3Fields, [fieldName]: value });
        break;
      default:
        break;
    }
  };

  const isPageCompleted = (page, checkboxState) => {
    switch (page) {
      case 1:
        return Object.keys(page1Fields).length === 3 && Object.values(page1Fields).every(Boolean);

      case 2:
        return Object.keys(page2Fields).length === 3 && Object.values(page2Fields).every(Boolean);
      case 3:
        return Object.keys(page3Fields).length === 2 && Object.values(page3Fields).every(Boolean);
      // return Object.keys(page3Fields).length === 2 && Object.values(page3Fields).every(Boolean)  && checkboxState;

      default:
        return false;
    }
  };
  const handleSubmitPassword = (e) => {
    e.preventDefault();
    if (inputValue.input7 === inputValue.input8) {
      // navigate("/login");
      handleSub(e);
    } else {
      setError("Passwords do not match");
      // Proceed with form submission
      // return nav;
    }
  };

  return (
    <>
      {/* BIG SCREENS */}
      <div className="flex  100:hidden lg:flex w-full h-screen items-start  justify-between">
        <img className="fixed h-full w-[50%]  " src={sideImg} alt="housing" />
        <div className=" ml-[52%] px-[48px] gap-[50px] flex flex-col justify-between items-center py-[52px] w-[50%] h-fit ">
          <div className="w-full float-left ">
            <nav className=" cursor-pointer" onClick={handleNavigation}>
              <h3 className=" text-[16px]  w-[36px] font-normal font-openSans text-[#FF5722] tracking-[0.9px] ">Back</h3>
            </nav>
          </div>
          <div className="flex flex-col justify-between gap-[50px] items-center">
            <div className="flex flex-col text-center justify-between items-center gap-[40px]">
              <img src={signup} alt="myHood" />
              <div className=" flex flex-col items-center justify-between gap-[15px]">
                <h1 className="text-[32px] text-[#333333] font-semibold font-openSans leading-[35px] tracking-wide ">
                  Welcome to <span className="text-[#4CAF50]">my</span>
                  <span className="text-[#FF5722]">Hood</span>
                </h1>
                <p className="text-[#777777] text-[20px] w-[450px] font-normal font-monteserrat leading-[35px] tracking-tight">Let's get started on creating a connected and vibrant community experience for you.</p>
              </div>
            </div>
            {/* Progress bar */}
            <div className="flex justify-between items-center">
              <div
                className={`w-[15px] h-[15px] rounded-[100px] ${
                  isPageCompleted(1) ? "border-[#4CAF50] bg-opacity-70" : "border-[#eeeeee]"
                }  border-[2px] bg-[#E6E6E6] text-[10px] text-[#9E9E9E] flex justify-center items-center font-monteserrat font-normal `}
              >
                1
              </div>
              <div className={`w-[113px] h-[3px]  ${isPageCompleted(1) ? "bg-[#4CAF50] bg-opacity-70" : "bg-[#eee]"}     `}>{/* intentional break*/}</div>
              <div
                className={`w-[15px] h-[15px] rounded-[100px] ${
                  isPageCompleted(2) ? "border-[#4CAF50] bg-opacity-70" : "border-[#eeeeee]"
                }  border-[2px] bg-[#E6E6E6] text-[10px] text-[#9E9E9E] flex justify-center items-center font-monteserrat font-normal `}
              >
                2
              </div>
              <div className={`w-[113px] h-[3px]  ${isPageCompleted(2) ? "bg-[#4CAF50] bg-opacity-70" : "bg-[#eee]"}     `}>{/* intentional break*/}</div>
              <div
                className={`w-[15px] h-[15px] rounded-[100px] ${
                  isPageCompleted(3) ? "border-[#4CAF50] bg-opacity-70" : "border-[#eeeeee]"
                }  border-[2px] bg-[#E6E6E6] text-[10px] text-[#9E9E9E] flex justify-center items-center font-monteserrat font-normal `}
              >
                3
              </div>
            </div>

            <form onSubmit={handleSub} className=" flex flex-col justify-between gap-[20px]" action="/" method="get">
              {page === 1 && (
                <>
                  <div
                    onClick={() => {
                      setBorder(true);
                      setBorder2(false);
                      setBorder3(false);
                    }}
                    className={`w-[400px]  flex-grow h-[37px] ${border ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "firstName", e.target.value);
                        // handleInputChange(e);
                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type="text"
                      name="input1"
                      value={inputValue.input1}
                      placeholder="First Name"
                    />
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.33692 9.53095C3.51164 10.0223 1.3478 11.0257 2.66572 12.2814C3.30952 12.8947 4.02654 13.3333 4.92801 13.3333H10.072C10.9735 13.3333 11.6905 12.8947 12.3343 12.2814C13.6522 11.0257 11.4884 10.0223 10.6631 9.53095C8.7278 8.37857 6.2722 8.37857 4.33692 9.53095Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.125 4.29166C10.125 5.74141 8.94976 6.91666 7.5 6.91666C6.05025 6.91666 4.875 5.74141 4.875 4.29166C4.875 2.84192 6.05025 1.66666 7.5 1.66666C8.94976 1.66666 10.125 2.84192 10.125 4.29166Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                      />
                    </svg>
                  </div>
                  <div
                    onClick={() => {
                      setBorder(false);
                      setBorder2(true);
                      setBorder3(false);
                    }}
                    className={`w-[400px]  flex-grow h-[37px] ${border2 ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "lastName", e.target.value);
                        // handleInputChange();
                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type="text"
                      name="input2"
                      value={inputValue.input2}
                      placeholder="Last Name"
                    />
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.33692 9.53095C3.51164 10.0223 1.3478 11.0257 2.66572 12.2814C3.30952 12.8947 4.02654 13.3333 4.92801 13.3333H10.072C10.9735 13.3333 11.6905 12.8947 12.3343 12.2814C13.6522 11.0257 11.4884 10.0223 10.6631 9.53095C8.7278 8.37857 6.2722 8.37857 4.33692 9.53095Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.125 4.29166C10.125 5.74141 8.94976 6.91666 7.5 6.91666C6.05025 6.91666 4.875 5.74141 4.875 4.29166C4.875 2.84192 6.05025 1.66666 7.5 1.66666C8.94976 1.66666 10.125 2.84192 10.125 4.29166Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                      />
                    </svg>
                  </div>
                  <div
                    onClick={() => {
                      setBorder(false);
                      setBorder2(false);
                      setBorder3(true);
                    }}
                    className={`w-[400px]  flex-grow h-[37px] ${border3 ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "email", e.target.value);
                        // handleInputChange();

                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type="email"
                      name="input3"
                      value={inputValue.input3}
                      placeholder="Email"
                    />
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.16669 3.71429L5.19928 5.99919C6.68595 6.84154 7.31409 6.84154 8.80077 5.99919L12.8334 3.71429" stroke="#777777" strokeWidth="0.875" strokeLinejoin="round" />
                      <path
                        d="M1.17589 8.07506C1.21402 9.86333 1.23309 10.7574 1.89291 11.4198C2.55273 12.0821 3.47105 12.1051 5.30767 12.1513C6.43961 12.1797 7.56043 12.1797 8.69239 12.1513C10.529 12.1051 11.4473 12.0821 12.1072 11.4198C12.767 10.7574 12.786 9.86333 12.8241 8.07506C12.8364 7.50007 12.8364 6.92852 12.8241 6.35353C12.786 4.56529 12.767 3.67118 12.1072 3.00884C11.4473 2.34651 10.529 2.32344 8.69239 2.27729C7.56043 2.24885 6.43961 2.24885 5.30767 2.27729C3.47105 2.32343 2.55273 2.3465 1.89291 3.00884C1.23308 3.67117 1.21402 4.56529 1.17588 6.35353C1.16362 6.92852 1.16362 7.50007 1.17589 8.07506Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              )}
              {page === 2 && (
                <>
                  <div
                    onClick={() => {
                      setBorder(true);
                      setBorder2(false);
                      setBorder3(false);
                    }}
                    className={`w-[400px]  h-[37px] ${border ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "phoneNumber", e.target.value);
                        // handleInputChange();
                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type="tel"
                      name="input4"
                      value={inputValue.input4}
                      placeholder="Phone Number"
                    />
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.41675 5.75C3.41675 3.8251 3.41675 2.86264 4.01474 2.26466C4.61273 1.66666 5.57518 1.66666 7.50008 1.66666C9.42497 1.66666 10.3874 1.66666 10.9854 2.26466C11.5834 2.86264 11.5834 3.8251 11.5834 5.75V9.25C11.5834 11.1749 11.5834 12.1373 10.9854 12.7354C10.3874 13.3333 9.42497 13.3333 7.50008 13.3333C5.57518 13.3333 4.61273 13.3333 4.01474 12.7354C3.41675 12.1373 3.41675 11.1749 3.41675 9.25V5.75Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                        strokeLinecap="round"
                      />
                      <path d="M6.91675 11.5833H8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path
                        d="M5.75 1.66666L5.80192 1.97818C5.91443 2.65325 5.97069 2.99079 6.20219 3.19619C6.4437 3.41045 6.78606 3.41666 7.5 3.41666C8.21394 3.41666 8.5563 3.41045 8.7978 3.19619C9.02932 2.99079 9.08556 2.65325 9.19808 1.97818L9.25 1.66666"
                        stroke="#777777"
                        strokeWidth="0.875"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div
                    onClick={() => {
                      setBorder(false);
                      setBorder2(true);
                      setBorder3(false);
                    }}
                    className={`w-[400px]  h-[37px] ${border2 ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "registrationCode", e.target.value);
                        // handleInputChange();
                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type="number"
                      name="input5"
                      value={inputValue.input5}
                      placeholder="Registration Code"
                    />
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.66239 9.25H8.66659M6.33325 9.25H6.33745" stroke="#777777" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                      <path
                        d="M3.41675 9.25001C3.41675 6.99484 5.24492 5.16667 7.50008 5.16667C9.75525 5.16667 11.5834 6.99484 11.5834 9.25001C11.5834 11.5052 9.75525 13.3333 7.50008 13.3333C5.24492 13.3333 3.41675 11.5052 3.41675 9.25001Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                      />
                      <path d="M10.125 6.04167V4.29167C10.125 2.84193 8.94976 1.66667 7.5 1.66667C6.05025 1.66667 4.875 2.84193 4.875 4.29167V6.04167" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                    </svg>
                  </div>
                  <div
                    onClick={() => {
                      handleSvgClick();
                      setBorder(false);
                      setBorder2(false);
                      setBorder3(true);
                    }}
                    className={`w-[400px]  h-[37px] ${border3 ? "border-[0.7px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        const formattedDate = date.toLocaleDateString("en-GB");
                        setSelectedDate(date);
                        setIsOpen(false);
                        handleFieldChange(page, "date", date);
                        setInputValue({
                          ...inputValue,
                          input6: formattedDate,
                        });
                      }}
                      className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      placeholderText="Date of Birth"
                      dateFormat="MM-dd-yyyy"
                      showYearDropdown
                      scrollableYearDropdown
                      name="input6"
                      value={inputValue.input6}
                      yearDropdownItemNumber={20}
                      open={isOpen}
                    />{" "}
                    <svg onClick={handleSvgClick} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.91675 8.08333H9.83341M5.16675 8.08333H5.17199M8.08341 10.4167H5.16675M9.83341 10.4167H9.82816" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11 1.66667V2.83334M4 1.66667V2.83334" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path
                        d="M1.95825 7.64187C1.95825 5.10013 1.95825 3.82925 2.68866 3.03962C3.41906 2.25 4.59462 2.25 6.94575 2.25H8.05408C10.4052 2.25 11.5808 2.25 12.3112 3.03962C13.0416 3.82925 13.0416 5.10013 13.0416 7.64187V7.94147C13.0416 10.4832 13.0416 11.7541 12.3112 12.5437C11.5808 13.3333 10.4052 13.3333 8.05408 13.3333H6.94575C4.59462 13.3333 3.41906 13.3333 2.68866 12.5437C1.95825 11.7541 1.95825 10.4832 1.95825 7.94147V7.64187Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path d="M2.25 5.16667H12.75" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </>
              )}
              {page === 3 && (
                <>
                  <div
                    onClick={() => {
                      setBorder(true);
                      setBorder2(false);
                    }}
                    className={`w-[400px]  h-[37px] ${error ? "border-[#EB0000] border-[1px]" : "border-[0.7px] border-[#777]"} ${
                      border ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"
                    } flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "createPassword", e.target.value);
                        // handleInputChange();
                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[16px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type={inputType}
                      name="input7"
                      value={inputValue.input7}
                      placeholder="Create Password"
                      required
                    />
                    <span onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                      {showPassword ? (
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                            stroke="#777777"
                            strokeWidth="0.875"
                          />
                          <path
                            d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z"
                            stroke="#777777"
                            strokeWidth="0.875"
                          />
                        </svg>
                      ) : (
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                          <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      )}
                    </span>
                  </div>
                  <p className="text-[13px] w-[400px] font-monteserrat font-normal text-[#777] ">Password should be at least 8 characters long and include a mix of letters, numbers, and symbols.</p>
                  <div
                    onClick={() => {
                      setBorder(false);
                      setBorder2(true);
                    }}
                    className={`w-[400px]  h-[37px] ${error ? "border-[#EB0000] border-[1px]" : "border-[0.7px] border-[#777]"} ${
                      border2 ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"
                    } flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "confirmPasword", e.target.value);
                        // handleInputChange();
                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[16px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type={inputType2}
                      placeholder="Confirm Password"
                      name="input8"
                      value={inputValue.input8}
                      required
                    />
                    <span onClick={togglePasswordVisibility2} style={{ cursor: "pointer" }}>
                      {showPassword2 ? (
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                            stroke="#777777"
                            strokeWidth="0.875"
                          />
                          <path
                            d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z"
                            stroke="#777777"
                            strokeWidth="0.875"
                          />
                        </svg>
                      ) : (
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                          <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      )}
                    </span>
                  </div>
                  {error && <p className=" text-[14px] font-openSans text-[#EB0000] ">{error}</p>}
                  <div className=" flex gap-[10px] items-center text-[14px] tex-[#777] font-monteserrat font-normal ">
                    <input
                      onChange={(e) => {
                        // handleFieldChange(page, "termsOfUse", e.target);

                        const { name, checked } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: checked, // Update the inputValue state with the checked state of the checkbox
                        });
                      }}
                      name="input9"
                      value={inputValue.input9}
                      required
                      className="w-[15px] bg-transparent rounded h-[15px] border-[0.7px] border-[#9E9E9E] "
                      type="checkbox"
                    />{" "}
                    <label htmlFor="guideline">
                      I accept the{" "}
                      <Link to="/termsofuse">
                        <span className="text-[#3F51B5]">terms</span>
                      </Link>{" "}
                      and{" "}
                      <Link to="/privacypolicy">
                        <span className="text-[#3F51B5]">guidelines</span>.
                      </Link>
                    </label>
                  </div>
                </>
              )}
            </form>
            <div className="flex flex-col justify-between items-center gap-[15px]">
              {page !== 3 && (
                <Link
                  onClick={() => {
                    setPage(page + 1);
                  }}
                  to=""
                >
                  <button type="submit" className="w-[243px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[16px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex">
                    Next
                  </button>
                </Link>
              )}
              {page === 3 && (
                <Link to="/login">
                  <button
                    onClick={(e) => {
                      // handleSub(e);
                      handleSubmitPassword(e);
                    }}
                    type="submit"
                    className="w-[243px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[16px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex"
                  >
                    Next
                  </button>
                </Link>
              )}
              <h3 className="text-[#333] h-[38px] text-[16px] font-medium font-monteserrat tracking-tight">
                Already a member?{" "}
                <Link className="text-[#FF5722]" to="/login">
                  Sign in
                </Link>
              </h3>
            </div>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1024PX */}
      <div className="flex flex-col w-full overflow-x-hidden 100:flex gap-[70px] lg:hidden h-fit py-[50px] items-center justify-center">
        <div className="w-full  left-[50px] relative float-left ">
          <nav className=" cursor-pointer" onClick={handleNavigation}>
            <h3 className=" text-[16px]  w-[36px] font-normal font-openSans text-[#FF5722] tracking-[0.9px] ">Back</h3>
          </nav>
        </div>
        <div className=" phone:max-w-[450px]  px-[48px]  flex flex-col justify-center items-center pb-[52px] w-[90%] ">
          <div className="flex flex-col justify-between gap-[50px] items-center">
            <div className="flex flex-col text-center justify-between items-center gap-[40px]">
              <img src={signup} alt="myHood" />
              <div className=" flex flex-col items-center justify-between gap-[15px]">
                <h1 className="phone:text-[32px] 100:text-[28px] text-[#333333] font-semibold font-openSans leading-[35px] tracking-wide ">
                  Welcome to <span className="text-[#4CAF50]">my</span>
                  <span className="text-[#FF5722]">Hood</span>
                </h1>
                <p className="text-[#777777] 100:text-[18px] phone:text-[20px] w-full phone:w-[450px] font-normal font-monteserrat leading-[35px] tracking-tight">Log in to stay connected with your neighborhood community.</p>
              </div>
            </div>
            {/* Progress bar */}
            <div className="flex justify-between items-center">
              <div
                className={`w-[15px] h-[15px] rounded-[100px] ${
                  isPageCompleted(1) ? "border-[#4CAF50] bg-opacity-70" : "border-[#eeeeee]"
                }  border-[2px] bg-[#E6E6E6] text-[10px] text-[#9E9E9E] flex justify-center items-center font-monteserrat font-normal `}
              >
                1
              </div>
              <div className={`w-[113px] h-[3px]  ${isPageCompleted(1) ? "bg-[#4CAF50] bg-opacity-70" : "bg-[#eee]"}     `}>{/* intentional break*/}</div>
              <div
                className={`w-[15px] h-[15px] rounded-[100px] ${
                  isPageCompleted(2) ? "border-[#4CAF50] bg-opacity-70" : "border-[#eeeeee]"
                }  border-[2px] bg-[#E6E6E6] text-[10px] text-[#9E9E9E] flex justify-center items-center font-monteserrat font-normal `}
              >
                2
              </div>
              <div className={`w-[113px] h-[3px]  ${isPageCompleted(2) ? "bg-[#4CAF50] bg-opacity-70" : "bg-[#eee]"}     `}>{/* intentional break*/}</div>
              <div
                className={`w-[15px] h-[15px] rounded-[100px] ${
                  isPageCompleted(3) ? "border-[#4CAF50] bg-opacity-70" : "border-[#eeeeee]"
                }  border-[2px] bg-[#E6E6E6] text-[10px] text-[#9E9E9E] flex justify-center items-center font-monteserrat font-normal `}
              >
                3
              </div>
            </div>
            <form onSubmit={handleSub} className=" flex flex-col justify-between gap-[20px]" action="/" method="get">
              {page === 1 && (
                <>
                  <div
                    onClick={() => {
                      setBorder(true);
                      setBorder2(false);
                      setBorder3(false);
                    }}
                    className={`w-[300px] phone:w-[450px] flex-grow h-[37px] ${border ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "firstName", e.target.value);
                        // handleInputChange(e);
                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type="text"
                      name="input1"
                      value={inputValue.input1}
                      placeholder="First Name"
                    />
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.33692 9.53095C3.51164 10.0223 1.3478 11.0257 2.66572 12.2814C3.30952 12.8947 4.02654 13.3333 4.92801 13.3333H10.072C10.9735 13.3333 11.6905 12.8947 12.3343 12.2814C13.6522 11.0257 11.4884 10.0223 10.6631 9.53095C8.7278 8.37857 6.2722 8.37857 4.33692 9.53095Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.125 4.29166C10.125 5.74141 8.94976 6.91666 7.5 6.91666C6.05025 6.91666 4.875 5.74141 4.875 4.29166C4.875 2.84192 6.05025 1.66666 7.5 1.66666C8.94976 1.66666 10.125 2.84192 10.125 4.29166Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                      />
                    </svg>
                  </div>
                  <div
                    onClick={() => {
                      setBorder(false);
                      setBorder2(true);
                      setBorder3(false);
                    }}
                    className={`w-[300px] phone:w-[450px] flex-grow h-[37px] ${border2 ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "lastName", e.target.value);
                        // handleInputChange();
                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type="text"
                      name="input2"
                      value={inputValue.input2}
                      placeholder="Last Name"
                    />
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M4.33692 9.53095C3.51164 10.0223 1.3478 11.0257 2.66572 12.2814C3.30952 12.8947 4.02654 13.3333 4.92801 13.3333H10.072C10.9735 13.3333 11.6905 12.8947 12.3343 12.2814C13.6522 11.0257 11.4884 10.0223 10.6631 9.53095C8.7278 8.37857 6.2722 8.37857 4.33692 9.53095Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.125 4.29166C10.125 5.74141 8.94976 6.91666 7.5 6.91666C6.05025 6.91666 4.875 5.74141 4.875 4.29166C4.875 2.84192 6.05025 1.66666 7.5 1.66666C8.94976 1.66666 10.125 2.84192 10.125 4.29166Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                      />
                    </svg>
                  </div>
                  <div
                    onClick={() => {
                      setBorder(false);
                      setBorder2(false);
                      setBorder3(true);
                    }}
                    className={`w-[300px] phone:w-[450px] flex-grow h-[37px] ${border3 ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "email", e.target.value);
                        // handleInputChange();

                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type="email"
                      name="input3"
                      value={inputValue.input3}
                      placeholder="Email"
                    />
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.16669 3.71429L5.19928 5.99919C6.68595 6.84154 7.31409 6.84154 8.80077 5.99919L12.8334 3.71429" stroke="#777777" strokeWidth="0.875" strokeLinejoin="round" />
                      <path
                        d="M1.17589 8.07506C1.21402 9.86333 1.23309 10.7574 1.89291 11.4198C2.55273 12.0821 3.47105 12.1051 5.30767 12.1513C6.43961 12.1797 7.56043 12.1797 8.69239 12.1513C10.529 12.1051 11.4473 12.0821 12.1072 11.4198C12.767 10.7574 12.786 9.86333 12.8241 8.07506C12.8364 7.50007 12.8364 6.92852 12.8241 6.35353C12.786 4.56529 12.767 3.67118 12.1072 3.00884C11.4473 2.34651 10.529 2.32344 8.69239 2.27729C7.56043 2.24885 6.43961 2.24885 5.30767 2.27729C3.47105 2.32343 2.55273 2.3465 1.89291 3.00884C1.23308 3.67117 1.21402 4.56529 1.17588 6.35353C1.16362 6.92852 1.16362 7.50007 1.17589 8.07506Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              )}
              {page === 2 && (
                <>
                  <div
                    onClick={() => {
                      setBorder(true);
                      setBorder2(false);
                      setBorder3(false);
                    }}
                    className={`w-[300px] phone:w-[450px] h-[37px] ${border ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "phoneNumber", e.target.value);
                        // handleInputChange();
                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type="tel"
                      name="input4"
                      value={inputValue.input4}
                      placeholder="Phone Number"
                    />
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M3.41675 5.75C3.41675 3.8251 3.41675 2.86264 4.01474 2.26466C4.61273 1.66666 5.57518 1.66666 7.50008 1.66666C9.42497 1.66666 10.3874 1.66666 10.9854 2.26466C11.5834 2.86264 11.5834 3.8251 11.5834 5.75V9.25C11.5834 11.1749 11.5834 12.1373 10.9854 12.7354C10.3874 13.3333 9.42497 13.3333 7.50008 13.3333C5.57518 13.3333 4.61273 13.3333 4.01474 12.7354C3.41675 12.1373 3.41675 11.1749 3.41675 9.25V5.75Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                        strokeLinecap="round"
                      />
                      <path d="M6.91675 11.5833H8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path
                        d="M5.75 1.66666L5.80192 1.97818C5.91443 2.65325 5.97069 2.99079 6.20219 3.19619C6.4437 3.41045 6.78606 3.41666 7.5 3.41666C8.21394 3.41666 8.5563 3.41045 8.7978 3.19619C9.02932 2.99079 9.08556 2.65325 9.19808 1.97818L9.25 1.66666"
                        stroke="#777777"
                        strokeWidth="0.875"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div
                    onClick={() => {
                      setBorder(false);
                      setBorder2(true);
                      setBorder3(false);
                    }}
                    className={`w-[300px] phone:w-[450px] h-[37px] ${border2 ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "registrationCode", e.target.value);
                        // handleInputChange();
                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type="number"
                      name="input5"
                      value={inputValue.input5}
                      placeholder="Registration Code"
                    />
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.66239 9.25H8.66659M6.33325 9.25H6.33745" stroke="#777777" strokeWidth="1.16667" strokeLinecap="round" strokeLinejoin="round" />
                      <path
                        d="M3.41675 9.25001C3.41675 6.99484 5.24492 5.16667 7.50008 5.16667C9.75525 5.16667 11.5834 6.99484 11.5834 9.25001C11.5834 11.5052 9.75525 13.3333 7.50008 13.3333C5.24492 13.3333 3.41675 11.5052 3.41675 9.25001Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                      />
                      <path d="M10.125 6.04167V4.29167C10.125 2.84193 8.94976 1.66667 7.5 1.66667C6.05025 1.66667 4.875 2.84193 4.875 4.29167V6.04167" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                    </svg>
                  </div>
                  <div
                    onClick={() => {
                      handleSvgClick();
                      setBorder(false);
                      setBorder2(false);
                      setBorder3(true);
                    }}
                    className={`w-[300px] phone:w-[450px] h-[37px] ${border3 ? "border-[0.7px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => {
                        const formattedDate = date.toLocaleDateString("en-GB");
                        setSelectedDate(date);
                        setIsOpen(false);
                        handleFieldChange(page, "date", date);
                        setInputValue({
                          ...inputValue,
                          input6: formattedDate, // Assuming input6 corresponds to the date picker value
                        });
                      }}
                      className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      placeholderText="Date of Birth"
                      dateFormat="MM-dd-yyyy"
                      showYearDropdown
                      scrollableYearDropdown
                      name="input6"
                      value={inputValue.input6}
                      yearDropdownItemNumber={20}
                      open={isOpen}
                    />{" "}
                    <svg onClick={handleSvgClick} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.91675 8.08333H9.83341M5.16675 8.08333H5.17199M8.08341 10.4167H5.16675M9.83341 10.4167H9.82816" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11 1.66667V2.83334M4 1.66667V2.83334" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path
                        d="M1.95825 7.64187C1.95825 5.10013 1.95825 3.82925 2.68866 3.03962C3.41906 2.25 4.59462 2.25 6.94575 2.25H8.05408C10.4052 2.25 11.5808 2.25 12.3112 3.03962C13.0416 3.82925 13.0416 5.10013 13.0416 7.64187V7.94147C13.0416 10.4832 13.0416 11.7541 12.3112 12.5437C11.5808 13.3333 10.4052 13.3333 8.05408 13.3333H6.94575C4.59462 13.3333 3.41906 13.3333 2.68866 12.5437C1.95825 11.7541 1.95825 10.4832 1.95825 7.94147V7.64187Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path d="M2.25 5.16667H12.75" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                </>
              )}

              {page === 3 && (
                <>
                  <div
                    onClick={() => {
                      setBorder(true);
                      setBorder2(false);
                    }}
                    className={`w-[300px] phone:w-[450px]  h-[37px] ${error ? "border-[#EB0000] border-[1px]" : "border-[0.7px] border-[#777]"} ${
                      border ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"
                    } flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "createPassword", e.target.value);
                        // handleInputChange();
                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[16px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type={inputType}
                      name="input7"
                      value={inputValue.input7}
                      placeholder="Create Password"
                      required
                    />
                    <span onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                      {showPassword ? (
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                            stroke="#777777"
                            strokeWidth="0.875"
                          />
                          <path
                            d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z"
                            stroke="#777777"
                            strokeWidth="0.875"
                          />
                        </svg>
                      ) : (
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                          <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      )}
                    </span>
                  </div>
                  <p className="text-[13px] w-full font-monteserrat font-normal text-[#777] ">Password should be at least 8 characters long and include a mix of letters, numbers, and symbols.</p>
                  <div
                    onClick={() => {
                      setBorder(false);
                      setBorder2(true);
                    }}
                    className={`w-[300px] phone:w-[450px]  h-[37px] ${error ? "border-[#EB0000] border-[1px]" : "border-[0.7px] border-[#777]"} ${
                      border2 ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"
                    } flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
                  >
                    <input
                      onChange={(e) => {
                        handleFieldChange(page, "confirmPasword", e.target.value);
                        // handleInputChange();
                        const { name, value } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: value,
                        });
                      }}
                      className="w-full h-full text-[16px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#333] outline-none border-none bg-transparent "
                      type={inputType2}
                      placeholder="Confirm Password"
                      name="input8"
                      value={inputValue.input8}
                      required
                    />
                    <span onClick={togglePasswordVisibility2} style={{ cursor: "pointer" }}>
                      {showPassword2 ? (
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                            stroke="#777777"
                            strokeWidth="0.875"
                          />
                          <path
                            d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z"
                            stroke="#777777"
                            strokeWidth="0.875"
                          />
                        </svg>
                      ) : (
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                          <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      )}
                    </span>
                  </div>
                  {error && <p className=" text-[14px] font-openSans text-[#EB0000] ">{error}</p>}
                  <div className=" flex gap-[10px] items-center text-[14px] tex-[#777] font-monteserrat font-normal ">
                    <input
                      onChange={(e) => {
                        // handleFieldChange(page, "termsOfUse", e.target);

                        const { name, checked } = e.target;
                        setInputValue({
                          ...inputValue,
                          [name]: checked, // Update the inputValue state with the checked state of the checkbox
                        });
                      }}
                      name="input9"
                      value={inputValue.input9}
                      required
                      className="w-[15px] bg-transparent rounded h-[15px] border-[0.7px] border-[#9E9E9E] "
                      type="checkbox"
                    />{" "}
                    <label htmlFor="guideline">
                      I accept the{" "}
                      <Link to="/termsofuse">
                        <span className="text-[#3F51B5]">terms</span>
                      </Link>{" "}
                      and{" "}
                      <Link to="/privacypolicy">
                        <span className="text-[#3F51B5]">guidelines</span>.
                      </Link>
                    </label>
                  </div>
                </>
              )}
            </form>
            <div className="flex flex-col justify-between items-center gap-[15px]">
              {page !== 3 && (
                <Link
                  onClick={() => {
                    setPage(page + 1);
                  }}
                  to=""
                >
                  <button type="submit" className="w-[243px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[16px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex">
                    Next
                  </button>
                </Link>
              )}
              {page === 3 && (
                <Link to="/login">
                  <button
                    onClick={(e) => {
                      // handleSub(e);
                      handleSubmitPassword(e);
                    }}
                    type="submit"
                    className="w-[243px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[16px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex"
                  >
                    Next
                  </button>
                </Link>
              )}
              <h3 className="text-[#333] h-[38px] text-[16px] font-medium font-monteserrat tracking-tight">
                Already a member?{" "}
                <Link className="text-[#FF5722]" to="/login">
                  Sign in
                </Link>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
