import { useState } from "react";
import Select from "react-select";

function AccountManagement({ setModal }) {
  const [banksOption, setBanksOption] = useState(null);

  const nigerianBanks = [
    { label: "Access Bank", value: "Access Bank" },
    { label: "Citibank", value: "Citibank" },
    { label: "Diamond Bank", value: "Diamond Bank" },
    { label: "Ecobank Nigeria", value: "Ecobank Nigeria" },
    { label: "Fidelity Bank Nigeria", value: "Fidelity Bank Nigeria" },
    { label: "First Bank of Nigeria", value: "First Bank of Nigeria" },
    { label: "First City Monument Bank", value: "First City Monument Bank" },
    { label: "Guaranty Trust Bank", value: "Guaranty Trust Bank" },
    { label: "Heritage Bank Plc", value: "Heritage Bank Plc" },
    { label: "Keystone Bank Limited", value: "Keystone Bank Limited" },
    { label: "Polaris Bank", value: "Polaris Bank" },
    { label: "Stanbic IBTC Bank Nigeria Limited", value: "Stanbic IBTC Bank Nigeria Limited" },
    { label: "Standard Chartered Bank", value: "Standard Chartered Bank" },
    { label: "Sterling Bank", value: "Sterling Bank" },
    { label: "Suntrust Bank Nigeria Limited", value: "Suntrust Bank Nigeria Limited" },
    { label: "Union Bank of Nigeria", value: "Union Bank of Nigeria" },
    { label: "United Bank for Africa", value: "United Bank for Africa" },
    { label: "Unity Bank Plc", value: "Unity Bank Plc" },
    { label: "Wema Bank", value: "Wema Bank" },
    { label: "Zenith Bank", value: "Zenith Bank" },
  ];
  return (
    <>
      {/* LARGE SCREENS */}
      <div className={` 100:hidden xl:block  w-full `}>
        <div className="flex  w-full flex-col">
          <section className="flex  items-center justify-between  w-full pt-[64px]">
            <div className="w-fit h-[69px]  flex-col justify-start items-start gap-1 inline-flex">
              <h3 className="self-stretch text-zinc-800 text-3xl font-semibold font-openSans tracking-tight">Bank Information</h3>
              <h4 className="self-stretch text-neutral-500 text-base font-normal font-monteserrat leading-normal">Add bank information</h4>
            </div>
            <div className=" flex gap-[10px] justify-between items-center">
              <div onClick={() => setModal(true)} className=" cursor-pointer text-white text-xs font-semibold font-openSans tracking-tight  flex justify-center items-center gap-[10px] w-fit hit-fit py-[5px] px-[57px] rounded-[5px]  bg-[#FF5722] ">
                Save
              </div>
            </div>
          </section>
          <div className="w-full gap-[20px] px-[20px] flex-col flex justify-start items-start mt-[50px] pb-[20px]">
            <h3 className="w-full border-b-[1px] h-[44px] border-b-[#E7E7E7] text-zinc-800 text-[16px] font-semibold font-openSans leading-relaxed">Bank Details</h3>
            <form className=" flex flex-col justify-between gap-[30px] items-center">
              <div className=" flex items-center justify-between gap-[24px]  ">
                <div className="w-[350px] h-[61px] flex-col justify-start items-start gap-[8px] flex">
                  <label htmlFor="first name" className=" text-[#777] text-sm font-semibold font-openSans leading-tight">
                    Bank Name
                  </label>
                  <Select
                    placeholder="Select Category"
                    className="w-full  text-[#333]  h-[37px] text-sm font-normal font-monteserrat placeholder:text-neutral-400"
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: state.isFocused ? "#FF5722" : "grey",

                        // height: "37px",
                        color: "#333",
                        paddingLeft: "10px",
                        borderRadius: "4px",
                        // text: "14px",
                        borderBlockWidth: "0.5px",
                        backgroundOpacity: "20%",
                        background: "#EEEEEE",
                        placeholder: "#9E9E9E",
                        fontFamily: "open sans",
                        outline: "#FF5722",
                        fontWeight: "semibold",
                      }),
                    }}
                    defaultValue={banksOption}
                    onChange={setBanksOption}
                    options={nigerianBanks}
                  />
                </div>
                <div className="w-[350px] h-[61px] flex-col justify-start items-start gap-[8px] flex">
                  <label htmlFor="last name" className=" text-[#777] text-sm font-semibold font-openSans leading-tight">
                    Account Number
                  </label>
                  <input
                    placeholder="Enter Account Number"
                    type="text"
                    className=" outline-[#FF5722] w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 "
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS */}
      <div className=" 100:flex xl:hidden w-full pt-[34px]  pb-[50px] flex-col justify-between gap-[22px] h-fit ">
        <div className="w-fit h-fit  flex-col justify-start items-start gap-1 inline-flex">
          <h3 className=" text-zinc-800 text-3xl font-semibold font-openSans tracking-tight">Bank Information</h3>
          <h4 className=" text-neutral-500 text-base font-normal font-monteserrat leading-normal">Add bank information</h4>
        </div>
        <div className=" flex gap-[10px] justify-end items-center">
          <div onClick={() => setModal(true)} className=" cursor-pointer text-white text-xs font-semibold font-openSans tracking-tight  flex justify-center items-center gap-[10px] w-fit hit-fit py-[5px] px-[57px] rounded-[5px]  bg-[#FF5722] ">
            Save
          </div>
        </div>
        <form className=" flex 100:w-full sm:max-w-[400px] flex-col justify-between gap-[30px] items-center">
          <div className="w-full h-[61px] flex-col justify-start items-start gap-[8px] flex">
            <label htmlFor="first name" className=" text-[#777] text-sm font-semibold font-openSans leading-tight">
              Bank Name
            </label>
            <Select
              placeholder="Select Category"
              className="w-full  text-[#333]  h-[37px] text-sm font-normal font-monteserrat placeholder:text-neutral-400"
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "#FF5722" : "grey",

                  // height: "37px",
                  color: "#333",
                  paddingLeft: "10px",
                  borderRadius: "4px",
                  // text: "14px",
                  borderBlockWidth: "0.5px",
                  backgroundOpacity: "20%",
                  background: "#EEEEEE",
                  placeholder: "#9E9E9E",
                  fontFamily: "open sans",
                  outline: "#FF5722",
                  fontWeight: "semibold",
                }),
              }}
              defaultValue={banksOption}
              onChange={setBanksOption}
              options={nigerianBanks}
            />
          </div>
          <div className="w-full h-[61px] flex-col justify-start items-start gap-[8px] flex">
            <label htmlFor="last name" className=" text-[#777] text-sm font-semibold font-openSans leading-tight">
              Account Number
            </label>
            <input
              placeholder="Enter Account Number"
              type="text"
              className=" outline-[#FF5722] w-full h-[37px]  text-[#333] px-[15px] py-1 bg-zinc-100 placeholder:text-neutral-400 text-sm font-normal font-monteserrat tracking-tight rounded border-[0.5px] border-neutral-500 "
            />
          </div>
        </form>
      </div>
    </>
  );
}

export default AccountManagement;
