import { BrowserRouter, Route, Routes } from "react-router-dom";
import HeroSection from "./Components/LandingPage/HeroSection";
import SignUp from "./Components/Authentication/SignUp";
import Login from "./Components/Authentication/Login";
import CreatePassword from "./Components/Authentication/CreatePassword";
import ForgotPassword from "./Components/Authentication/ForgotPassword";
import Otp from "./Components/Authentication/Otp";
import DashboardUser from "./Components/Admins/UserAdmin/DashboardUser";
import Payment from "./Components/Admins/UserAdmin/Payment";
import IssueTracking from "./Components/Admins/UserAdmin/IssueTracking";
import AdvertManagement from "./Components/Admins/UserAdmin/AdvertManagement";
import SettingUser from "./Components/Admins/UserAdmin/SettingUser";
import ProfileUser from "./Components/Admins/UserAdmin/ProfileUser";
import TermsOfUse from "./Components/Terms&Privacy/TermsOfUse";
import PrivacyPolicy from "./Components/Terms&Privacy/PrivacyPolicy";
import Footer from "./Components/LandingPage/Footer";
import Disclaimer from "./Components/Terms&Privacy/Disclaimer";
import CookiePolicy from "./Components/Terms&Privacy/CookiePolicy";
import DashboardExco from "./Components/Admins/ExcoAdmin/DashboardExco";
import CommunicationExco from "./Components/Admins/ExcoAdmin/CommunicationExco";
import EstateManagement from "./Components/Admins/ExcoAdmin/EstateManagement";
import IssueTrackingExco from "./Components/Admins/ExcoAdmin/IssueTrackingExco";
import ProfileExco from "./Components/Admins/ExcoAdmin/ProfileExco";
import SettingExco from "./Components/Admins/ExcoAdmin/SettingsExco";
import "./App.css";
import DashboardSuper from "./Components/Admins/SuperAdmin/DashboardSuper";
import AdvertManagementSuper from "./Components/Admins/SuperAdmin/AdvertManagementSuper";
import AccessManagementSuper from "./Components/Admins/SuperAdmin/AccessManagementSuper";
import RevenueTrackingSuper from "./Components/Admins/SuperAdmin/RevenueTrackingSuper";
import SubscriptionManagement from "./Components/Admins/SuperAdmin/SubscriptionManagement";
import ProfileSuper from "./Components/Admins/SuperAdmin/ProfileUser";
import SettingSuper from "./Components/Admins/SuperAdmin/SettingsSuper";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Authentication */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/createpassword" element={<CreatePassword />} />
          {/* LandingPage */}
          <Route path="*" element={<HeroSection />} />
          <Route path="/homepage" element={<HeroSection />} />
          <Route path="/footer" element={<Footer />} />
          {/* UserAdmin */}
          <Route path="/dashboarduser" element={<DashboardUser />} />
          <Route path="/paymentuser" element={<Payment />} />
          <Route path="/issuetrackinguser" element={<IssueTracking />} />
          <Route path="/advertmanagementuser" element={<AdvertManagement />} />
          <Route path="/settingsuser" element={<SettingUser />} />
          <Route path="/profileuser" element={<ProfileUser />} />
          {/* Exco Admin */}
          <Route path="/dashboardexco" element={<DashboardExco />} />
          <Route path="/communicationexco" element={<CommunicationExco />} />
          <Route path="/estatemanagementexco" element={<EstateManagement />} />
          <Route path="/issuetrackingexco" element={<IssueTrackingExco />} />
          <Route path="/profileexco" element={<ProfileExco />} />
          <Route path="/settingsexco" element={<SettingExco />} />
          {/* Super Admin */}
          <Route path="/dashboardsuper" element={<DashboardSuper />} />
          <Route path="/advertmanagementsuper" element={<AdvertManagementSuper />} />
          <Route path="/accessmanagementsuper" element={<AccessManagementSuper />} />
          <Route path="/revenuetracking" element={<RevenueTrackingSuper />} />
          <Route path="/subscriptionmanagement" element={<SubscriptionManagement />} />
          <Route path="/profilesuper" element={<ProfileSuper />} />
          <Route path="/settingsuper" element={<SettingSuper />} />

          {/* Terms and Condition */}
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/cookiepolicy" element={<CookiePolicy />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
