import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import sideImg from "../images/loginmyhood.png";
import logo from "../images/Logo.svg";
// import axios from "axios";
import authRequest from "../Utils/requests";
function Login({ data }) {
  const [border, setBorder] = useState(false);
  const [border2, setBorder2] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState("password");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [userData, setUserData] = useState([]);

  const handleSub = async (event) => {
    event.preventDefault();
    // console.log(email);
    // console.log(password);
    // console.log(category);
    // e.preventDefault();
    // const data = {
    //   username: email,
    //   password: password,
    // };
    // axios
    //   .post("https://myhood.ng/mobile_back_end/api/login.php", {
    //     username: email,
    //     password: password,
    //   })
    //   .then((response) => {
    //     let dataSave = {
    //       hood_address: response.data.address,
    //       hood_caegory: response.data.category,
    //       hood_email: response.data.email,
    //       hood_id: response.data.id,
    //       hood_name: response.data.name,
    //       hood_phone: response.data.phone,
    //       hood_profile_pic: response.data.profile_pic,
    //       hood_role: response.data.role,
    //       hood_user_token: response.data.token,
    //     };
    //     console.log(dataSave);
    //     localStorage.setItem("myHoodData", JSON.stringify(dataSave));
    //     // setUserData(response);

    //     switch (response.data.role) {
    //       case "resident":
    //         navigate("/dashboarduser"); // Redirect to user admin page
    //         break;
    //       case "landlord":
    //         navigate("/dashboarduser"); // Redirect to user admin page
    //         break;
    //       case "staff":
    //         navigate("/dashboarduser"); // Redirect to user admin page
    //         break;
    //       case "dependant":
    //         navigate("/dashboarduser"); // Redirect to user admin page
    //         break;
    //       case "tenant":
    //         navigate("/dashboarduser"); // Redirect to user admin page
    //         break;
    //       case "estate admin":
    //         navigate("/dashboardexco"); // Redirect to exco admin page
    //         break;
    //       case "super admin":
    //         navigate("/dashboardsuper"); // Redirect to super admin page
    //         break;
    //       default:
    //         setError("Invalid user role");
    //     }
    //   });

    // const userRole = userData.data.role; // Adjust this based on your API response
    // console.log(userData.data.role);

    try {
      // const res = await axiosInstance.post(`/login.php`, data);
      // const userRole = res.data.role; // Adjust this based on your API response
      // switch (userRole) {
      //   case "user":
      //     navigate("/dashboarduser"); // Redirect to user admin page
      //     break;
      //   case "exco":
      //     navigate("/dashboardexco"); // Redirect to exco admin page
      //     break;
      //   case "superadmin":
      //     navigate("/dashboardsuper"); // Redirect to super admin page
      //     break;
      //   default:
      //     setError("Invalid user role");
      // }
    } catch (error) {
      console.error(error);
      setError("Login failed");
    }

    await authRequest.userLogin(email, password).then(async (res) => {
      // console.log(res);

      await authRequest.getUserInfo(res.data.id).then(async (response) => {
        console.log(res);

        let dataSave = {
          address: response.data.address,
          caegory: response.data.category,
          dob: response.data.dob,
          email: response.data.email,
          emergency_email: response.data.emergency_email,
          emergency_message: response.data.emergency_message,
          emergency_number: response.data.emergency_number,
          estate_id: response.data.estate_id,
          first_name: response.data.first_name,
          flat_id: response.data.flat_id,
          id: response.data.id,
          last_name: response.data.last_name,
          middle_name: response.data.middle_name,
          nin: response.data.nin,
          // password: response.data.password
          phone_number: response.data.phone_number,
          plot_number: response.data.plot_number,
          primary_vendor: response.data.primary_vendor,
          profile_pic: response.data.profile_pic,
          registration_code: response.data.registration_code,
          role: response.data.role,
          status: response.data.status,
          street_name: response.data.street_name,
          type: response.data.type,
          visible: response.data.visible,

          // hood_name: response.data.name,

          // hood_user_token: response.data.token,
        };
        console.log(dataSave);

        localStorage.setItem("myHoodData", JSON.stringify(dataSave));

        switch (response.data.role || response.data.category) {
          case "resident":
            navigate("/dashboarduser"); // Redirect to user admin page
            break;
          case "landlord":
            navigate("/dashboarduser"); // Redirect to user admin page
            break;
          case "staff":
            navigate("/dashboarduser"); // Redirect to user admin page
            break;
          case "dependant":
            navigate("/dashboarduser"); // Redirect to user admin page
            break;
          case "tenant":
            navigate("/dashboarduser"); // Redirect to user admin page
            break;
          case "estate admin":
            navigate("/dashboardexco"); // Redirect to exco admin page
            break;
          case "super admin":
            navigate("/dashboardsuper"); // Redirect to super admin page
            break;
          default:
            setError("Invalid user role");
        }
      });
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setInputType(showPassword ? "password" : "text");
  };
  const handleSubmitPassword = (e) => {
    e.preventDefault();
    if (data && data.password) {
      const { password } = data;

      if (password !== password) {
        setError("Passwords do not match");
      } else {
        // Proceed with form submission
      }
    } else {
      // Handle the case where 'data' or 'data.password' is undefined
      console.error("Data or password is undefined");
    }
  };
  // const userRole = "user";
  // switch (userRole) {
  //   case "user":
  //     navigate("/dashboarduser"); // Redirect to user admin page
  //     break;
  //   case "exco":
  //     navigate("/dashboardexco"); // Redirect to exco admin page
  //     break;
  //   case "superadmin":
  //     navigate("/dashboardsuper"); // Redirect to super admin page
  //     break;
  //   default:
  //     setError("Invalid user role");
  // }

  return (
    <>
      {/* BIG SCREENS */}
      <div className="flex w-full 100:hidden lg:flex h-screen items-start justify-between">
        <img className="fixed   h-full w-[50%]  " src={sideImg} alt="housing" />
        <div className=" ml-[52%] px-[48px] gap-[50px] flex flex-col justify-between items-center py-[52px] w-[50%] h-fit ">
          <div className="w-full h-[100px] float-left ">
            {" "}
            <Link className="" to="/homepage">
              <h3 className=" text-[16px]  w-[36px] font-normal font-openSans text-[#FF5722] tracking-[0.9px] ">Back</h3>
            </Link>
          </div>
          <div className="flex flex-col justify-between gap-[50px] items-center">
            <div className="flex flex-col text-center justify-between items-center gap-[40px]">
              <img src={logo} alt="myHood" />
              <div className=" flex flex-col items-center justify-between gap-[15px]">
                <h1 className="text-[32px] text-[#333333] font-semibold font-openSans leading-[35px] tracking-wide ">
                  Welcome Back to <span className="text-[#4CAF50]">my</span>
                  <span className="text-[#FF5722]">Hood</span>
                </h1>
                <p className="text-[#777777] text-[20px] w-[450px] font-normal font-monteserrat leading-[35px] tracking-tight">Log in to stay connected with your neighborhood community.</p>
              </div>
            </div>
            <form onSubmit={handleSub} className=" flex flex-col justify-between gap-[20px]" action="/" method="get">
              <div
                onClick={() => {
                  setBorder(true);
                  setBorder2(false);
                }}
                className={`w-[400px]  flex-grow h-[37px] ${border ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
              >
                <input
                  onChange={(e) => {
                    // const { name, value } = e.target;
                    setEmail(e.target.value);
                    // setInputValue({
                    //   ...inputValue,
                    //   [name]: value,
                    // });
                  }}
                  className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                  name="email"
                  value={email}
                  type="email"
                  placeholder="Enter Your Email"
                />
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.16669 3.71429L5.19928 5.99919C6.68595 6.84154 7.31409 6.84154 8.80077 5.99919L12.8334 3.71429" stroke="#777777" strokeWidth="0.875" strokeLinejoin="round" />
                  <path
                    d="M1.17589 8.07506C1.21402 9.86333 1.23309 10.7574 1.89291 11.4198C2.55273 12.0821 3.47105 12.1051 5.30767 12.1513C6.43961 12.1797 7.56043 12.1797 8.69239 12.1513C10.529 12.1051 11.4473 12.0821 12.1072 11.4198C12.767 10.7574 12.786 9.86333 12.8241 8.07506C12.8364 7.50007 12.8364 6.92852 12.8241 6.35353C12.786 4.56529 12.767 3.67118 12.1072 3.00884C11.4473 2.34651 10.529 2.32344 8.69239 2.27729C7.56043 2.24885 6.43961 2.24885 5.30767 2.27729C3.47105 2.32343 2.55273 2.3465 1.89291 3.00884C1.23308 3.67117 1.21402 4.56529 1.17588 6.35353C1.16362 6.92852 1.16362 7.50007 1.17589 8.07506Z"
                    stroke="#777777"
                    strokeWidth="0.875"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div
                onClick={() => {
                  setBorder(false);
                  setBorder2(true);
                }}
                className={`w-[400px]  flex-grow h-[37px] ${border2 ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
              >
                <input
                  onChange={(e) => {
                    // const { name, value } = e.target;
                    setPassword(e.target.value);
                    // setInputValue({
                    //   ...inputValue,
                    //   [name]: value,
                    // });
                  }}
                  name="password"
                  // value={inputValue.input2}
                  className="w-full h-full text-[16px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                  type={inputType}
                  value={password}
                  placeholder="Enter Your Password"
                />
                <span onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                  {showPassword ? (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                      />
                      <path d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z" stroke="#777777" strokeWidth="0.875" />
                    </svg>
                  ) : (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                      <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                </span>
              </div>
              {error && <p className=" text-[16px] font-openSans text-[#EB0000] ">{error}</p>}

              <div className=" w-full ">
                <Link className="float-right " to="/forgotpassword">
                  <h3 className=" font-openSans -tracking-tight text-[14px] font-normal text-[#3F51B5] "> Forgot Password</h3>
                </Link>
              </div>
            </form>
            <div className="flex flex-col justify-between items-center gap-[15px]">
              <button
                onClick={(e) => {
                  handleSub(e);
                  handleSubmitPassword(e);
                }}
                className="w-[243px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[16px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex"
              >
                Login
              </button>
              <h3 className="text-[#333] h-[38px] text-[16px] font-medium font-monteserrat tracking-tight">
                Not a member?{" "}
                <Link className="text-[#FF5722]" to="/signup">
                  Sign up
                </Link>
              </h3>
            </div>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1024PX */}
      <div className="flex flex-col w-full overflow-x-hidden 100:flex gap-[70px] lg:hidden h-fit py-[50px] items-center justify-center">
        <div className="w-full  left-[50px] relative float-left ">
          <Link className="" to="/homepage">
            <h3 className=" text-[16px]  w-[36px] font-normal font-openSans text-[#FF5722] tracking-[0.9px] ">Back</h3>
          </Link>
        </div>
        <div className=" phone:max-w-[450px]  px-[48px]  flex flex-col justify-center items-center pb-[52px] w-[90%] ">
          <div className="flex flex-col justify-between gap-[50px] items-center">
            <div className="flex flex-col text-center justify-between items-center gap-[40px]">
              <img src={logo} alt="myHood" />
              <div className=" flex flex-col items-center justify-between gap-[15px]">
                <h1 className="phone:text-[32px] 100:text-[28px] text-[#333333] font-semibold font-openSans leading-[35px] tracking-wide ">
                  Welcome Back to <span className="text-[#4CAF50]">my</span>
                  <span className="text-[#FF5722]">Hood</span>
                </h1>
                <p className="text-[#777777] 100:text-[18px] phone:text-[20px] w-full phone:w-[450px] font-normal font-monteserrat leading-[35px] tracking-tight">Log in to stay connected with your neighborhood community.</p>
              </div>
            </div>

            {/* <form
              // onSubmit={handleSub}
              className=" w-full flex flex-col items-center justify-between gap-[20px]"
              action="/"
              method="get"
            >
              <div
                // onClick={() => {
                //   setBorder(true);
                //   setBorder2(false);
                // }}
                className={`w-[300px] phone:w-[450px]  flex-grow h-[37px] ${border ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
              >
                <input
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setInputValue({
                      ...inputValue,
                      [name]: value,
                    });
                  }}
                  className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                  name="input1"
                  value={inputValue.input1}
                  type="email"
                  placeholder="Enter Your Email"
                />
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.16669 3.71429L5.19928 5.99919C6.68595 6.84154 7.31409 6.84154 8.80077 5.99919L12.8334 3.71429" stroke="#777777" strokeWidth="0.875" strokeLinejoin="round" />
                  <path
                    d="M1.17589 8.07506C1.21402 9.86333 1.23309 10.7574 1.89291 11.4198C2.55273 12.0821 3.47105 12.1051 5.30767 12.1513C6.43961 12.1797 7.56043 12.1797 8.69239 12.1513C10.529 12.1051 11.4473 12.0821 12.1072 11.4198C12.767 10.7574 12.786 9.86333 12.8241 8.07506C12.8364 7.50007 12.8364 6.92852 12.8241 6.35353C12.786 4.56529 12.767 3.67118 12.1072 3.00884C11.4473 2.34651 10.529 2.32344 8.69239 2.27729C7.56043 2.24885 6.43961 2.24885 5.30767 2.27729C3.47105 2.32343 2.55273 2.3465 1.89291 3.00884C1.23308 3.67117 1.21402 4.56529 1.17588 6.35353C1.16362 6.92852 1.16362 7.50007 1.17589 8.07506Z"
                    stroke="#777777"
                    strokeWidth="0.875"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div
                // onClick={() => {
                //   setBorder(false);
                //   setBorder2(true);
                // }}
                className={`w-[300px] phone:w-[450px]  flex-grow h-[37px] ${border2 ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
              >
                <input
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setInputValue({
                      ...inputValue,
                      [name]: value,
                    });
                  }}
                  name="input2"
                  value={inputValue.input2}
                  className="w-full h-full text-[16px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                  type={inputType}
                  placeholder="Enter Your Password"
                />
                <span onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                  {showPassword ? (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                      />
                      <path d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z" stroke="#777777" strokeWidth="0.875" />
                    </svg>
                  ) : (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                      <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                </span>
              </div>
              {error && <p className=" text-[16px] font-openSans text-[#EB0000] ">{error}</p>}

              <div className=" w-[300px] phone:w-[450px]">
                <Link className="float-right " to="/forgotpassword">
                  <h3 className=" font-openSans -tracking-tight text-[14px] font-normal text-[#3F51B5] "> Forgot Password</h3>
                </Link>
              </div>
            </form> */}
            <form onSubmit={handleSub} className=" flex flex-col justify-between gap-[20px]" action="/" method="get">
              <div
                onClick={() => {
                  setBorder(true);
                  setBorder2(false);
                }}
                className={`w-[300px] phone:w-[450px]  flex-grow h-[37px] ${border2 ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
              >
                <input
                  onChange={(e) => {
                    // const { name, value } = e.target;
                    setEmail(e.target.value);
                    // setInputValue({
                    //   ...inputValue,
                    //   [name]: value,
                    // });
                  }}
                  className="w-full h-full text-[14px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                  name="email"
                  value={email}
                  type="email"
                  placeholder="Enter Your Email"
                />
                <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.16669 3.71429L5.19928 5.99919C6.68595 6.84154 7.31409 6.84154 8.80077 5.99919L12.8334 3.71429" stroke="#777777" strokeWidth="0.875" strokeLinejoin="round" />
                  <path
                    d="M1.17589 8.07506C1.21402 9.86333 1.23309 10.7574 1.89291 11.4198C2.55273 12.0821 3.47105 12.1051 5.30767 12.1513C6.43961 12.1797 7.56043 12.1797 8.69239 12.1513C10.529 12.1051 11.4473 12.0821 12.1072 11.4198C12.767 10.7574 12.786 9.86333 12.8241 8.07506C12.8364 7.50007 12.8364 6.92852 12.8241 6.35353C12.786 4.56529 12.767 3.67118 12.1072 3.00884C11.4473 2.34651 10.529 2.32344 8.69239 2.27729C7.56043 2.24885 6.43961 2.24885 5.30767 2.27729C3.47105 2.32343 2.55273 2.3465 1.89291 3.00884C1.23308 3.67117 1.21402 4.56529 1.17588 6.35353C1.16362 6.92852 1.16362 7.50007 1.17589 8.07506Z"
                    stroke="#777777"
                    strokeWidth="0.875"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div
                onClick={() => {
                  setBorder(false);
                  setBorder2(true);
                }}
                className={`w-[300px] phone:w-[450px]  flex-grow h-[37px] ${border2 ? "border-[1px] border-orange-600 " : " border-[0.7px] border-[#777]"} flex justify-between items-center px-[15px]  bg-[#eee]  rounded-[4px] `}
              >
                <input
                  onChange={(e) => {
                    // const { name, value } = e.target;
                    setPassword(e.target.value);
                    // setInputValue({
                    //   ...inputValue,
                    //   [name]: value,
                    // });
                  }}
                  name="password"
                  // value={inputValue.input2}
                  className="w-full h-full text-[16px] placeholder:text-[14px]  font-monteserrat placeholder:text-[#777] font-normal text-[#777] outline-none border-none bg-transparent "
                  type={inputType}
                  value={password}
                  placeholder="Enter Your Password"
                />
                <span onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                  {showPassword ? (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.5674 6.65721C12.7447 6.90589 12.8334 7.03025 12.8334 7.2143C12.8334 7.39834 12.7447 7.5227 12.5674 7.77138C11.7705 8.88881 9.73539 11.2976 7.00002 11.2976C4.26464 11.2976 2.22958 8.88881 1.43271 7.77138C1.25536 7.5227 1.16669 7.39834 1.16669 7.2143C1.16669 7.03025 1.25536 6.90589 1.43271 6.65721C2.22958 5.5398 4.26464 3.13096 7.00002 3.13096C9.73539 3.13096 11.7705 5.5398 12.5674 6.65721Z"
                        stroke="#777777"
                        strokeWidth="0.875"
                      />
                      <path d="M8.75 7.21429C8.75 6.24777 7.96652 5.46429 7 5.46429C6.03347 5.46429 5.25 6.24777 5.25 7.21429C5.25 8.18082 6.03347 8.96429 7 8.96429C7.96652 8.96429 8.75 8.18082 8.75 7.21429Z" stroke="#777777" strokeWidth="0.875" />
                    </svg>
                  ) : (
                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.8334 5.16675C12.8334 5.16675 10.5001 8.66675 7.00008 8.66675C3.50008 8.66675 1.16675 5.16675 1.16675 5.16675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" />
                      <path d="M8.75 8.375L9.625 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M11.6667 6.91675L12.8334 8.08341" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M1.16675 8.08341L2.33341 6.91675" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M5.25 8.375L4.375 9.83333" stroke="#777777" strokeWidth="0.875" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}
                </span>
              </div>
              {error && <p className=" text-[16px] font-openSans text-[#EB0000] ">{error}</p>}

              <div className=" w-full ">
                <Link className="float-right " to="/forgotpassword">
                  <h3 className=" font-openSans -tracking-tight text-[14px] font-normal text-[#3F51B5] "> Forgot Password</h3>
                </Link>
              </div>
            </form>
            <div className="flex flex-col justify-between items-center gap-[15px]">
              <button
                onClick={(e) => {
                  handleSub(e);
                  handleSubmitPassword(e);
                }}
                className="w-[243px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[16px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex"
              >
                Login
              </button>
              <h3 className="text-[#333] h-[38px] text-[16px] font-medium font-monteserrat tracking-tight">
                Not a member?{" "}
                <Link className="text-[#FF5722]" to="/signup">
                  Sign up
                </Link>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
