function Footer({ page, setPage }) {
  return (
    <>
      <div className=" w-full flex justify-end items-end">
        <div className={`w-[318px] ${page > 0 ? " cursor-pointer bg-opacity-5" : "cursor-pointer "}  h-14 py-[17px] justify-end items-center gap-5 inline-flex`}>
          <div
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
            className={`justify-start items-center gap-2.5 flex ${page <= 0 ? "cursor-not-allowed text-gray-400" : "cursor-pointer text-[#FF5722]"}`}
            // aria-disabled={page <= 0}
            // aria-disabled={page === 1}
          >
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.75 5.5C13.75 5.5 8.25001 9.55066 8.25 11C8.24999 12.4494 13.75 16.5 13.75 16.5" stroke="#FF5722" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <div className="text-[#FF5722] text-[13px] font-normal font-['Montserrat'] tracking-wide">Previous</div>
          </div>
          <div className="justify-start flex items-center gap-[5px] ">
            <div className={` w-[20px] h-[20px]  ${page ? "bg-[#FF5722]" : "bg-transparent"} rounded-[100px] flex-col justify-center items-center  flex`}>
              <div className={` ${page ? "text-white" : "text-zinc-800"} text-[10px] font-medium font-['Montserrat'] tracking-wide`}>{page}</div>
            </div>
          </div>
          <div onClick={() => setPage(page + 1)} className=" cursor-pointer justify-start items-center gap-2.5 flex">
            <div className="text-[#FF5722] text-[13px] font-normal font-['Montserrat'] tracking-wide">Next</div>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.25005 5.5C8.25005 5.5 13.75 9.55066 13.75 11C13.75 12.4494 8.25 16.5 8.25 16.5" stroke="#FF5722" strokeWidth="1.375" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
