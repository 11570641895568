import { Link } from "react-router-dom";
import logo from "../images/Logo.svg";
function CookiePolicy() {
  return (
    <>
      {/* BIG SCREENS */}
      <div className=" 100:hidden xl:flex w-full pb-[100px] gap-[60px] flex flex-col justify-center items-center h-fit">
        <div className=" w-full px-[100px] flex flex-col justify-center gap-[20px] items-center py-[40px] bg-[#FFEEE9] h-[428px]">
          <div className="w-full justify-start flex items-start">
            <Link
              onClick={() =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                })
              }
              to="/"
            >
              <div className="text-orange-600 text-base font-normal font-openSans tracking-tight">Back</div>
            </Link>
          </div>
          <div className=" w-full text-center gap-[24px] flex flex-col justify-between items-center">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <img src={logo} alt="myHood" />
            </Link>
            <h4 className="text-orange-600 text-[12px] font-normal font-openSans tracking-tight">Cancellation</h4>
            <h1 className="text-[#333] text-[36px] font-bold font-openSans tracking-tight">Navigating the Legal Waters: Our Cancellation</h1>
            <p className="text-[#777]  text-[18px] font-normal font-monteserrat tracking-tight">By using our services, you agree to abide by the following cancellation</p>
          </div>
        </div>
        <div className="w-[781px]  gap-[20px] flex flex-col justify-between items-start ">
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              All purchases are non-refundable. You can cancel your subscription at any time by contacting us using the contact information provided below. Your cancellation will take effect at the end of the current paid term. If you have any
              questions or are unsatisfied with our Services, please email us at{" "}
              <a className=" text-orange-600" href="mailto:info@myhood.ng">
                info@myhood.ng
              </a>{" "}
            </p>
          </section>
        </div>
      </div>
      {/* SMALL SCREENS FROM MOBILE TO 1023PX */}
      <div className="100:flex xl:hidden w-full pb-[100px] gap-[40px] flex-col justify-center items-center h-fit">
        <div className=" w-full px-[40px] flex flex-col justify-center gap-[20px] items-center py-[40px] bg-[#FFEEE9] h-fit">
          <div className="w-full justify-start flex items-start">
            <Link
              onClick={() =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                })
              }
              to="/"
            >
              <div className="text-orange-600 text-base font-normal font-openSans tracking-tight">Back</div>
            </Link>
          </div>
          <div className=" w-full text-center gap-[15px] flex flex-col justify-between items-center">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <img src={logo} alt="myHood" />
            </Link>
            <h4 className="text-orange-600 text-[12px] font-normal font-openSans tracking-tight">Cancellation</h4>
            <h1 className="text-[#333] text-[30px] font-bold font-openSans tracking-tight">Navigating the Legal Waters: Our Cancellation</h1>
            <p className="text-[#777] sm:w-[600px] text-[14px] font-normal font-monteserrat tracking-tight">By using our services, you agree to abide by the following cancellation</p>
          </div>
        </div>
        <div className="w-[90%] sm:max-w-[550px]  gap-[20px] flex flex-col justify-between items-start ">
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              All purchases are non-refundable. You can cancel your subscription at any time by contacting us using the contact information provided below. Your cancellation will take effect at the end of the current paid term. If you have any
              questions or are unsatisfied with our Services, please email us at{" "}
              <a className=" text-orange-600" href="mailto:info@myhood.ng">
                info@myhood.ng
              </a>{" "}
            </p>
          </section>
        </div>
      </div>
    </>
  );
}

export default CookiePolicy;
