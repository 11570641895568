import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../images/Logo.svg";
// import { useEffect } from "react";

function TermsOfUse() {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the user is coming from the Signup page
  const handleGoBack = () => {
    // Get the previous location from the state, if available
    const previousLocation = location.state?.from;

    // If there's a previous location, navigate to it
    if (previousLocation) {
      navigate(previousLocation);
    } else {
      // If there's no previous location, go back in history
      navigate(-1);
    }
  };

  return (
    <>
      {/* BIG SCREENS */}
      <div className=" 100:hidden xl:flex w-full pb-[100px] gap-[60px] flex flex-col justify-center items-center h-fit">
        <div className=" w-full px-[100px] flex flex-col justify-center gap-[20px] items-center py-[40px] bg-[#FFEEE9] h-[428px]">
          <div className="w-full justify-start flex items-start">
            {/* <Link
              onClick={() =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                })
              }
              to={{
                pathname: "/",
                state: { fromSignup: true }, // Pass a state indicating that the user is coming from the Signup page
              }}
            > */}
            <button onClick={handleGoBack}>
              <div className="text-orange-600 text-base font-normal font-openSans tracking-tight">Back</div>
              {/* </Link> */}
            </button>
          </div>
          <div className=" w-full text-center gap-[24px] flex flex-col justify-between items-center">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <img src={logo} alt="myHood" />
            </Link>
            <h4 className="text-orange-600 text-[12px] font-normal font-openSans tracking-tight">Terms of Use</h4>
            <h1 className="text-[#333] text-[36px] font-bold font-openSans tracking-tight">Navigating the Legal Waters: Our Terms and Condition</h1>
            <p className="text-[#777] w-[802px] text-[18px] font-normal font-monteserrat tracking-tight">
              Dive into the fine print that governs your interactions with us. Explore our Terms and Condition for a clear understanding of your rights and responsibilities.
            </p>
          </div>
        </div>
        <div className="w-[781px]  gap-[20px] flex flex-col justify-between items-start ">
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">Introduction</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We are Strenatech Nigeria Limited ('Company', 'we', 'us', or 'our'), a company registered in Nigeria at CHIEF ORE FAMEWO STREET,, Ikate, Lekki, Lagos. We operate the website http://www.myhood.ng (the 'Site'), the mobile application
              <br />
              myHood (the 'App'), as well as any other related products and services that refer or link to these legal terms (the 'Legal Terms') (collectively, the 'Services').
              <br /> myHood is a mobile solution that breaks community boundaries. It is a centralized platform where communities can connect, pay for services, share information, and engage locally to foster togetherness and peaceful coexistence
              You can contact us by phone at +234 708 880 4536, email at info@myhood.ng.
              <br /> These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ('you'), and Strenatech Nigeria Limited, concerning your access to and use of the Services. You agree that by
              accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE
              USE IMMEDIATELY. <br />
              We will provide you with prior notice of any scheduled changes to the Services you are using. The modified Legal Terms will become effective upon posting or notifying you by notification@myhood.ng, as stated in the email message. By
              continuing to use the Services after the effective date of any changes, you agree to be bound by the modified terms.
              <br /> The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services. We recommend that you print a copy of these Legal Terms for your records
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">OUR SERVICES</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would
              subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance
              with local laws, if and to the extent local laws are applicable.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">INTELLECTUAL PROPERTY RIGHTS</h1>
            <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
              <h1 className="text-[#333] text-[14px] font-semibold font-openSans tracking-tight">Our intellectual property</h1>
              <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
                We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services
                (collectively, the 'Content'), as well as the trademarks, service marks, and logos contained therein (the 'Marks'). Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights
                and unfair competition laws) and treaties in the United States and around the world. The Content and Marks are provided in or through the Services 'AS IS' for your personal, non-commercial use or internal business purpose only.{" "}
              </p>
            </section>
            <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
              <h1 className="text-[#333] text-[14px] font-semibold font-openSans tracking-tight">Your use of our Services</h1>
              <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
                Subject to your compliance with these Legal Terms, including the 'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive, non-transferable, revocable licence to: <br />
                <ul className="text-[#777] list-disc list-inside text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
                  <li> access the Services; and</li>
                  <li>download or print a copy of any portion of the Content to which you have properly gained access.</li>{" "}
                </ul>
                <br />
                solely for your personal, non-commercial use or internal business purpose. Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated,
                republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission. If you wish
                to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: info@strenatech.ng. If we ever grant you the permission to post, reproduce, or
                publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting,
                reproducing, or displaying our Content. We reserve all rights not expressly granted to you in and to the Services, Content, and Marks. Any breach of these Intellectual Property Rights will constitute a material breach of our Legal
                Terms and your right to use our Services will terminate immediately.
              </p>
            </section>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">USER REGISTRATION</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select
              if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">PURCHASES AND PAYMENT</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We accept the following forms of payment:
              <br />
              - Visa
              <br /> - Mastercard
              <br /> - in-app Payment Method
              <br /> - Verve
              <br /> <br />
              You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. You further agree to promptly update account and payment information, including email address, payment
              method, and payment card expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time. All payments
              shall be in Naira. You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorise us to charge your chosen payment provider for any such amounts upon placing your order.
              We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment. We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or
              cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same payment method, and/or orders that use the same billing or shipping
              address. We reserve the right to limit or prohibit orders that, in our sole judgement, appear to be placed by dealers, resellers, or distributors.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">SUBSCRIPTIONS</h1>
            <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
              <h1 className="text-[#333] text-[14px] font-semibold font-openSans tracking-tight">Billing and Renewal</h1>
              <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
                Your subscription will continue and automatically renew unless cancelled. You consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge, until such time as you
                cancel the applicable order. The length of your billing cycle will depend on the type of subscription plan you choose when you subscribed to the Services.{" "}
              </p>
            </section>
            <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
              <h1 className="text-[#333] text-[14px] font-semibold font-openSans tracking-tight">Cancellation</h1>
              <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
                All purchases are non-refundable. You can cancel your subscription at any time by contacting us using the contact information provided below. Your cancellation will take effect at the end of the current paid term. If you have any
                questions or are unsatisfied with our Services, please email us at{" "}
                <a className=" text-orange-600 " href="mailto:info@myhood.ng">
                  info@myhood.ng
                </a>{" "}
              </p>
            </section>
            <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
              <h1 className="text-[#333] text-[14px] font-semibold font-openSans tracking-tight">Fee Changes</h1>
              <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
                We may, from time to time, make changes to the subscription fee and will communicate any price changes to you in accordance with applicable law.
              </p>
            </section>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">PROHIBITED ACTIVITIES</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavours except those that are specifically endorsed or
              approved by us.
              <br />
              <br /> As a user of the Services, you agree not to:
              <br />
            </p>
            <ul className="text-[#777] list-disc list-inside text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <li>Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
              <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
              <li>
                Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the
                Content contained therein.
              </li>
              <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
              <li>Use any information obtained from the Services in order to harass, abuse, or harm another person.</li>
              <li>Make improper use of our support services or submit false reports of abuse or misconduct. Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
              <li>
                Engage in unauthorised framing of or linking to the Services.Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous
                posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the
                Services.
              </li>
              <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
              <li>Delete the copyright or other proprietary rights notice from any Content.</li>
              <li>Attempt to impersonate another user or person or use the username of another user.</li>
              <li>
                Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ('gifs'), 1×1
                pixels, web bugs, cookies, or other similar devices (sometimes referred to as 'spyware' or 'passive collection mechanisms' or 'pcms').
              </li>
              <li>Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.</li>
              <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.</li>
              <li>Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.</li>
              <li>Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
              <li>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.</li>
              <li>
                Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that
                accesses the Services, or use or launch any unauthorised script or other software. &#8226; Use a buying agent or purchasing agent to make purchases on the Services.
              </li>
              <li>
                Make any unauthorised use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under
                false pretences.
              </li>
              <li>Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavour or commercial enterprise. Advertise pornography or sexually related content.</li>
              <li>Sell or otherwise transfer your profile.</li>
            </ul>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">THIRD-PARTY WEBSITES AND CONTENT</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              The Services may contain (or you may be sent via the Site or App) links to other websites ('Third-Party Websites') as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications,
              software, and other content or items belonging to or originating from third parties ('Third-Party Content'). Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or
              completeness by us, and we are not responsible for any Third-Party Websites accessed through the Services or any Third-Party Content posted on, available through, or installed from the Services, including the content, accuracy,
              offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or installation of any Third-Party
              Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Services and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk,
              and you should be aware these Legal Terms no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Services or relating to any
              applications you use or install from the Services. Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which
              are exclusively between you and the applicable third party. You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us blameless from any harm caused by your purchase
              of such products or services. Additionally, you shall hold us blameless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">ADVERTISERS</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We allow advertisers to display their advertisements and other information in certain areas of the Services, such as sidebar advertisements or banner advertisements. We simply provide the space to place such advertisements, and we have
              no other relationship with advertisers.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">SERVICES MANAGEMENT</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We reserve the right, but not the obligation, to:
              <ol>
                <li>(1) monitor the Services for violations of these Legal Terms;</li>
                <li>(2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities;</li>
                <li>(3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;</li>
                <li>(4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and</li>
                <li>(5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services. </li>
              </ol>
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">PRIVACY POLICY</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy posted on the Services, which is incorporated into these Legal Terms. Please be advised the Services are hosted in Nigeria. If
              you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in Nigeria, then through your continued use of the
              Services, you are transferring your data to Nigeria, and you expressly consent to have your data transferred to and processed in Nigeria.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">TERM AND TERMINATION</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              These Legal Terms shall remain in full force and effect while you use the Services.
              <br />
              <br />
              WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON
              FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN
              THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
              <br />
              <br /> If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf
              of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">GOVERNING LAW</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              These Legal Terms shall be governed by and defined following the laws of Nigeria. Strenatech Nigeria Limited and yourself irrevocably consent that the courts of Nigeria shall have exclusive jurisdiction to resolve any dispute which may
              arise in connection with these Legal Terms.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">DISCLAIMER</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH
              THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON- INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
              COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
              <ol>
                <li> (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,</li>
                <li>(2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES,</li>
                <li>(3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN,</li>
                <li>(4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES,</li>
                <li>(5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR</li>
                <li>
                  (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT,
                  ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY
                </li>{" "}
              </ol>
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">INDEMNIFICATION</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including
              reasonable attorneys’ fees and expenses, made by any third party due to or arising out of:
              <ol>
                <li>(1) your Contributions;</li>
                <li>(2) use of the Services;</li>
                <li>(3) breach of these Legal Terms;</li>
                <li>(4) any breach of your representations and warranties set forth in these Legal Terms;</li>
                <li>(5) your violation of the rights of a third party, including but not limited to intellectual property rights; or</li>
                <li>
                  (6) any overt harmful act toward any other user of the Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defence and control of any
                  matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defence of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject
                  to this indemnification upon becoming aware of it.
                </li>
              </ol>{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">USER DATA</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data, you
              are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby
              waive any right of action against us arising from any such loss or corruption of such data.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other
              communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
              RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or
              other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">CONTACT US</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at: <br />
              Strenatech Nigeria LimitedCHIEF ORE FAMEWO STREET,Ikate, Lekki, Lagos Nigeria <br />
              Phone:{" "}
              <a
                href="tel: +234 708
              880 4536 "
              >
                +234 708 880 4536
              </a>
              <br />
              <a className=" text-orange-600" href="mailto:info@myhood.ng">
                info@myhood.ng
              </a>{" "}
               {" "}
            </p>
          </section>
        </div>
      </div>
      {/* SMALL SCREENS FROM MOBILE TO 1023PX */}
      <div className="100:flex xl:hidden w-full pb-[100px] gap-[40px] flex-col justify-center items-center h-fit">
        <div className=" w-full px-[40px] flex flex-col justify-center gap-[20px] items-center py-[40px] bg-[#FFEEE9] h-fit">
          <div className="w-full justify-start flex items-start">
            {/* <Link
              onClick={() =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                })
              }
              to="/"
            > */}
            <button onClick={handleGoBack}>
              <div className="text-orange-600 text-base font-normal font-openSans tracking-tight">Back</div>
              {/* </Link> */}
            </button>
          </div>
          <div className=" w-full text-center gap-[15px] flex flex-col justify-between items-center">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <img src={logo} alt="myHood" />
            </Link>
            <h4 className="text-orange-600 text-[12px] font-normal font-openSans tracking-tight">Terms of Use</h4>
            <h1 className="text-[#333] text-[30px] font-bold font-openSans tracking-tight">Navigating the Legal Waters: Our Terms and Condition</h1>
            <p className="text-[#777] sm:w-[600px] text-[14px] font-normal font-monteserrat tracking-tight">
              Dive into the fine print that governs your interactions with us. Explore our Terms and Condition for a clear understanding of your rights and responsibilities.
            </p>
          </div>
        </div>
        <div className="w-[90%] sm:max-w-[550px]  gap-[20px] flex flex-col justify-between items-start ">
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">Introduction</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We are Strenatech Nigeria Limited ('Company', 'we', 'us', or 'our'), a company registered in Nigeria at CHIEF ORE FAMEWO STREET,, Ikate, Lekki, Lagos. We operate the website http://www.myhood.ng (the 'Site'), the mobile application
              <br />
              myHood (the 'App'), as well as any other related products and services that refer or link to these legal terms (the 'Legal Terms') (collectively, the 'Services').
              <br /> myHood is a mobile solution that breaks community boundaries. It is a centralized platform where communities can connect, pay for services, share information, and engage locally to foster togetherness and peaceful coexistence
              You can contact us by phone at +234 708 880 4536, email at info@myhood.ng.
              <br /> These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ('you'), and Strenatech Nigeria Limited, concerning your access to and use of the Services. You agree that by
              accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE
              USE IMMEDIATELY. <br />
              We will provide you with prior notice of any scheduled changes to the Services you are using. The modified Legal Terms will become effective upon posting or notifying you by notification@myhood.ng, as stated in the email message. By
              continuing to use the Services after the effective date of any changes, you agree to be bound by the modified terms.
              <br /> The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services. We recommend that you print a copy of these Legal Terms for your records
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">OUR SERVICES</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would
              subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance
              with local laws, if and to the extent local laws are applicable.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">INTELLECTUAL PROPERTY RIGHTS</h1>
            <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
              <h1 className="text-[#333] text-[14px] font-semibold font-openSans tracking-tight">Our intellectual property</h1>
              <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
                We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services
                (collectively, the 'Content'), as well as the trademarks, service marks, and logos contained therein (the 'Marks'). Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights
                and unfair competition laws) and treaties in the United States and around the world. The Content and Marks are provided in or through the Services 'AS IS' for your personal, non-commercial use or internal business purpose only.{" "}
              </p>
            </section>
            <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
              <h1 className="text-[#333] text-[14px] font-semibold font-openSans tracking-tight">Your use of our Services</h1>
              <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
                Subject to your compliance with these Legal Terms, including the 'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive, non-transferable, revocable licence to: <br />
                <ul className="text-[#777] list-disc list-inside text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
                  <li> access the Services; and</li>
                  <li>download or print a copy of any portion of the Content to which you have properly gained access.</li>{" "}
                </ul>
                <br />
                solely for your personal, non-commercial use or internal business purpose. Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated,
                republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission. If you wish
                to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: info@strenatech.ng. If we ever grant you the permission to post, reproduce, or
                publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting,
                reproducing, or displaying our Content. We reserve all rights not expressly granted to you in and to the Services, Content, and Marks. Any breach of these Intellectual Property Rights will constitute a material breach of our Legal
                Terms and your right to use our Services will terminate immediately.
              </p>
            </section>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">USER REGISTRATION</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select
              if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">PURCHASES AND PAYMENT</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We accept the following forms of payment:
              <br />
              - Visa
              <br /> - Mastercard
              <br /> - in-app Payment Method
              <br /> - Verve
              <br /> <br />
              You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. You further agree to promptly update account and payment information, including email address, payment
              method, and payment card expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time. All payments
              shall be in Naira. You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorise us to charge your chosen payment provider for any such amounts upon placing your order.
              We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment. We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or
              cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same payment method, and/or orders that use the same billing or shipping
              address. We reserve the right to limit or prohibit orders that, in our sole judgement, appear to be placed by dealers, resellers, or distributors.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">SUBSCRIPTIONS</h1>
            <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
              <h1 className="text-[#333] text-[14px] font-semibold font-openSans tracking-tight">Billing and Renewal</h1>
              <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
                Your subscription will continue and automatically renew unless cancelled. You consent to our charging your payment method on a recurring basis without requiring your prior approval for each recurring charge, until such time as you
                cancel the applicable order. The length of your billing cycle will depend on the type of subscription plan you choose when you subscribed to the Services.{" "}
              </p>
            </section>
            <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
              <h1 className="text-[#333] text-[14px] font-semibold font-openSans tracking-tight">Cancellation</h1>
              <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
                All purchases are non-refundable. You can cancel your subscription at any time by contacting us using the contact information provided below. Your cancellation will take effect at the end of the current paid term. If you have any
                questions or are unsatisfied with our Services, please email us at{" "}
                <a className=" text-orange-600 " href="mailto:info@myhood.ng">
                  info@myhood.ng
                </a>{" "}
              </p>
            </section>
            <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
              <h1 className="text-[#333] text-[14px] font-semibold font-openSans tracking-tight">Fee Changes</h1>
              <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
                We may, from time to time, make changes to the subscription fee and will communicate any price changes to you in accordance with applicable law.
              </p>
            </section>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">PROHIBITED ACTIVITIES</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavours except those that are specifically endorsed or
              approved by us.
              <br />
              <br /> As a user of the Services, you agree not to:
              <br />
            </p>
            <ul className="text-[#777] list-disc list-inside text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <li>Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
              <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
              <li>
                Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the
                Content contained therein.
              </li>
              <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
              <li>Use any information obtained from the Services in order to harass, abuse, or harm another person.</li>
              <li>Make improper use of our support services or submit false reports of abuse or misconduct. Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
              <li>
                Engage in unauthorised framing of or linking to the Services.Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous
                posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the
                Services.
              </li>
              <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
              <li>Delete the copyright or other proprietary rights notice from any Content.</li>
              <li>Attempt to impersonate another user or person or use the username of another user.</li>
              <li>
                Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ('gifs'), 1×1
                pixels, web bugs, cookies, or other similar devices (sometimes referred to as 'spyware' or 'passive collection mechanisms' or 'pcms').
              </li>
              <li>Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.</li>
              <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.</li>
              <li>Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.</li>
              <li>Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
              <li>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.</li>
              <li>
                Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that
                accesses the Services, or use or launch any unauthorised script or other software. &#8226; Use a buying agent or purchasing agent to make purchases on the Services.
              </li>
              <li>
                Make any unauthorised use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under
                false pretences.
              </li>
              <li>Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavour or commercial enterprise. Advertise pornography or sexually related content.</li>
              <li>Sell or otherwise transfer your profile.</li>
            </ul>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">THIRD-PARTY WEBSITES AND CONTENT</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              The Services may contain (or you may be sent via the Site or App) links to other websites ('Third-Party Websites') as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications,
              software, and other content or items belonging to or originating from third parties ('Third-Party Content'). Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, or
              completeness by us, and we are not responsible for any Third-Party Websites accessed through the Services or any Third-Party Content posted on, available through, or installed from the Services, including the content, accuracy,
              offensiveness, opinions, reliability, privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or installation of any Third-Party
              Websites or any Third-Party Content does not imply approval or endorsement thereof by us. If you decide to leave the Services and access the Third-Party Websites or to use or install any Third-Party Content, you do so at your own risk,
              and you should be aware these Legal Terms no longer govern. You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from the Services or relating to any
              applications you use or install from the Services. Any purchases you make through Third-Party Websites will be through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which
              are exclusively between you and the applicable third party. You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us blameless from any harm caused by your purchase
              of such products or services. Additionally, you shall hold us blameless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">ADVERTISERS</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We allow advertisers to display their advertisements and other information in certain areas of the Services, such as sidebar advertisements or banner advertisements. We simply provide the space to place such advertisements, and we have
              no other relationship with advertisers.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">SERVICES MANAGEMENT</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We reserve the right, but not the obligation, to:
              <ol>
                <li>(1) monitor the Services for violations of these Legal Terms;</li>
                <li>(2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities;</li>
                <li>(3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;</li>
                <li>(4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and</li>
                <li>(5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services. </li>
              </ol>
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">PRIVACY POLICY</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy posted on the Services, which is incorporated into these Legal Terms. Please be advised the Services are hosted in Nigeria. If
              you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in Nigeria, then through your continued use of the
              Services, you are transferring your data to Nigeria, and you expressly consent to have your data transferred to and processed in Nigeria.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">TERM AND TERMINATION</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              These Legal Terms shall remain in full force and effect while you use the Services.
              <br />
              <br />
              WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON
              FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN
              THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
              <br />
              <br /> If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf
              of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">GOVERNING LAW</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              These Legal Terms shall be governed by and defined following the laws of Nigeria. Strenatech Nigeria Limited and yourself irrevocably consent that the courts of Nigeria shall have exclusive jurisdiction to resolve any dispute which may
              arise in connection with these Legal Terms.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">DISCLAIMER</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH
              THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON- INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
              COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
              <ol>
                <li> (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,</li>
                <li>(2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES,</li>
                <li>(3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN,</li>
                <li>(4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES,</li>
                <li>(5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR</li>
                <li>
                  (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT,
                  ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY
                </li>{" "}
              </ol>
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">INDEMNIFICATION</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including
              reasonable attorneys’ fees and expenses, made by any third party due to or arising out of:
              <ol>
                <li>(1) your Contributions;</li>
                <li>(2) use of the Services;</li>
                <li>(3) breach of these Legal Terms;</li>
                <li>(4) any breach of your representations and warranties set forth in these Legal Terms;</li>
                <li>(5) your violation of the rights of a third party, including but not limited to intellectual property rights; or</li>
                <li>
                  (6) any overt harmful act toward any other user of the Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defence and control of any
                  matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defence of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject
                  to this indemnification upon becoming aware of it.
                </li>
              </ol>{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">USER DATA</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data, you
              are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby
              waive any right of action against us arising from any such loss or corruption of such data.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Visiting the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and other
              communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
              RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or
              other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">CONTACT US</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at: <br />
              Strenatech Nigeria LimitedCHIEF ORE FAMEWO STREET,Ikate, Lekki, Lagos Nigeria <br />
              Phone:{" "}
              <a
                href="tel: +234 708
              880 4536 "
              >
                +234 708 880 4536
              </a>
              <br />
              <a className=" text-orange-600" href="mailto:info@myhood.ng">
                info@myhood.ng
              </a>{" "}
               {" "}
            </p>
          </section>
        </div>
      </div>
    </>
  );
}

export default TermsOfUse;
