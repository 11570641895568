import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../images/Logo.svg";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const location = useLocation();

  // Check if the user is coming from the Signup page
  const handleGoBack = () => {
    // Get the previous location from the state, if available
    const previousLocation = location.state?.from;

    // If there's a previous location, navigate to it
    if (previousLocation) {
      navigate(previousLocation);
    } else {
      // If there's no previous location, go back in history
      navigate(-1);
    }
  };
  return (
    <>
      {/* BIG SCREENS */}
      <div className=" 100:hidden xl:flex w-full pb-[100px] gap-[60px] flex flex-col justify-center items-center h-fit">
        <div className=" w-full px-[100px] flex flex-col justify-center gap-[20px] items-center py-[40px] bg-[#FFEEE9] h-[428px]">
          <div className="w-full justify-start flex items-start">
            {/* <Link
              onClick={() =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                })
              }
              to="/"
            > */}
            <button onClick={handleGoBack}>
              <div className="text-orange-600 text-base font-normal font-openSans tracking-tight">Back</div>
              {/* </Link> */}
            </button>
          </div>
          <div className=" w-full text-center gap-[24px] flex flex-col justify-between items-center">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <img src={logo} alt="myHood" />
            </Link>
            <h4 className="text-orange-600 text-[12px] font-normal font-openSans tracking-tight">PRIVACY POLICY</h4>
            <h1 className="text-[#333] text-[36px] font-bold font-openSans tracking-tight">Navigating the Legal Waters: Our Privacy Policy</h1>
            <p className="text-[#777]  text-[18px] font-normal font-monteserrat tracking-tight">By using our services, you agree to abide by the following Privacy Policy </p>
          </div>
        </div>
        <div className="w-[781px]  gap-[20px] flex flex-col justify-between items-start ">
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">1. WHAT INFORMATION DO WE COLLECT?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Personal information you disclose to us In Short: We collect personal information that you provide to us.
              <br />
              <br /> We collect personal information that you voluntarily provide to us
              <br />
              <br /> when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
              <br />
              <br /> Personal Information Provided by You. The personal information that we collectdepends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal
              information we collect may include the following: names, phone numbers, email addresses, username, passwords, home address.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">Sensitive Information. We do not process sensitive information.</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Payment Data. We may collect data necessary to process your payment if you makepurchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Paystack. You
              may find their privacy notice link(s) here:{" "}
              <a className=" text-orange-600" href="https://paystack.com/terms.">
                paystack.com.
              </a>{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Application Data.</b> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Mobile Device Access.</b> We may request access or permission to certain features from your mobile device, including your mobile device's camera, and other features. If you wish to change our access or
              permissions, you may do so in your device's settings.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Mobile Device Data. </b> We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information,
              device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may
              also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device's unique device ID, and information about the
              featuresof our application(s) you accessed.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]">Push Notifications. </b> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you
              may turn them off in your device's settings.
              <br />
              <br /> This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes. All personal information that you provide to us must
              be true, complete, and accurate, and you must notify us of any changes to such personal information.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">Information automatically collected</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
              <br />
              <br />
              We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information,
              such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information.
              This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
              <br />
              <br />
              Like many businesses, we also collect information through cookies and similar technologies.
              <br />
              <br />
              The information we collect includes:
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Log and Usage Data. </b> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log
              files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with
              your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Device Data. </b> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include
              information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration
              information.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Location Data. </b>We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the
              device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this
              information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">Information collected from other sources</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: We may collect limited data from public databases, marketing partners, and other outside sources.
              <br />
              <br />
              In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate
              programs, data providers, and from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behaviour data), Internet Protocol (IP) addresses, social media
              profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">2. HOW DO WE PROCESS YOUR INFORMATION?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
              <br />
              <br /> We process your personal information for a variety of reasons, depending on how you interact with our Services, including <br />
              <br />
              To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.
              <br />
              <br /> To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.
              <br />
              <br /> To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
              <br />
              <br /> To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
              <br />
              <br /> To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.
              <br />
              <br /> To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing
              emails at any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?' below. <br />
              <br />
              To deliver targeted advertising to you. We may process your information to develop and display personalised content and advertising tailored to your interests, location, and more. <br />
              <br />
              To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention. <br />
              <br />
              To evaluate and improve our Services, products, marketing, and your experience. We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to
              evaluate and improve our Services, products, marketing, and your experience.
              <br />
              <br /> To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them. <br />
              To comply with our legal obligations. We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">2. HOW DO WE PROCESS YOUR INFORMATION?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
              <br />
              <br /> We process your personal information for a variety of reasons, depending on how you interact with our Services, including <br />
              <br />
              To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.
              <br />
              <br /> To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.
              <br />
              <br /> To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
              <br />
              <br /> To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
              <br />
              <br /> To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.
              <br />
              <br /> To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing
              emails at any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?' below. <br />
              <br />
              To deliver targeted advertising to you. We may process your information to develop and display personalised content and advertising tailored to your interests, location, and more. <br />
              <br />
              To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention. <br />
              <br />
              To evaluate and improve our Services, products, marketing, and your experience. We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to
              evaluate and improve our Services, products, marketing, and your experience.
              <br />
              <br /> To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them. <br />
              To comply with our legal obligations. We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.{" "}
            </p>
          </section>{" "}
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">In Short: We may share information in specific situations described in this section and/or with the following third parties.</p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">We may need to share your personal information in the following situations:</p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Business Transfers. </b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our
              business to another company.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Business Partners. </b> We may share your information with our business partners to offer you certain products, services, or promotions.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.
              <br />
              <br /> The Services may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or
              applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or application does not imply an
              endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and
              security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to
              respond to your questions.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: We may use cookies and other tracking technologies to collect and store your information.
              <br />
              <br />
              We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie
              Notice.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">6. HOW LONG DO WE KEEP YOUR INFORMATION?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.
              <br />
              <br />
              We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal
              requirements). No purpose in this notice will require us keeping your personal information for longer than twenty-four (24) months past the termination of the user's account.
              <br />
              <br />
              When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in
              backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: We aim to protect your personal information through a system of organisational and technical security measures.
              <br />
              <br />
              We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your
              information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will
              not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at
              your own risk. You should only access the Services within a secure environment.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">8. DO WE COLLECT INFORMATION FROM MINORS?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: We do not knowingly collect data from or market to children under 18 years of age.
              <br />
              <br />
              We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor
              dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from children under age 18, please contact us at{" "}
              <a className=" text-orange-600" href="https://privacy@myhood.ng">
                privacy@myhood.ng.
              </a>{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">9. WHAT ARE YOUR PRIVACY RIGHTS?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">In Short: You may review, change, or terminate your account at any time.</p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]">Withdrawing your consent: </b> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to
              withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details below.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing
              grounds other than consent.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the
              details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that
              are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">Account Information</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[18px] font-semibold font-openSans tracking-tight">Contact us using the contact information provided.</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with
              any investigations, enforce our legal terms and/or comply with applicable legal requirements.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]">Cookies and similar technologies: </b>Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to
              remove cookies or reject cookies, this could affect certain features or services of our Services. You may also opt out of interest-based advertising by advertisers on our Services. If you have questions or comments about your privacy
              rights, you may email us at privacy@myhood.ng.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">10. CONTROLS FOR DO-NOT-TRACK FEATURES</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities
              monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically
              communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">11. DO WE MAKE UPDATES TO THIS NOTICE?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
              <br />
              <br />
              We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy
              notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your
              information.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">If you have questions or comments about this notice, you may email us at privacy@myhood.ng</p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal
              information, please send an email to{" "}
              <a href="https://privacy@myhood.ng" className=" text-orange-600">
                {" "}
                privacy@myhood.ng
              </a>
            </p>
          </section>
        </div>
      </div>
      {/* SMALL SCREENS FROM MOBILE TO 1023PX */}
      <div className="100:flex xl:hidden w-full pb-[100px] gap-[40px] flex-col justify-center items-center h-fit">
        <div className=" w-full px-[40px] flex flex-col justify-center gap-[20px] items-center py-[40px] bg-[#FFEEE9] h-fit">
          <div className="w-full justify-start flex items-start">
            {/* <Link
              onClick={() =>
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: "smooth",
                })
              }
              to="/"
            > */}
            <button onClick={handleGoBack}>
              <div className="text-orange-600 text-base font-normal font-openSans tracking-tight">Back</div>
              {/* </Link> */}
            </button>
          </div>
          <div className=" w-full text-center gap-[15px] flex flex-col justify-between items-center">
            <Link onClick={() => window.scrollTo(0, 0)} to="/">
              <img src={logo} alt="myHood" />
            </Link>
            <h4 className="text-orange-600 text-[12px] font-normal font-openSans tracking-tight">PRIVACY POLICY</h4>
            <h1 className="text-[#333] text-[30px] font-bold font-openSans tracking-tight">Navigating the Legal Waters: Our Privacy Policy</h1>
            <p className="text-[#777] sm:w-[600px] text-[14px] font-normal font-monteserrat tracking-tight">By using our services, you agree to abide by the following Privacy Policy </p>
          </div>
        </div>
        <div className="w-[90%] sm:max-w-[550px]  gap-[20px] flex flex-col justify-between items-start ">
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">1. WHAT INFORMATION DO WE COLLECT?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Personal information you disclose to us In Short: We collect personal information that you provide to us.
              <br />
              <br /> We collect personal information that you voluntarily provide to us
              <br />
              <br /> when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
              <br />
              <br /> Personal Information Provided by You. The personal information that we collectdepends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal
              information we collect may include the following: names, phone numbers, email addresses, username, passwords, home address.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">Sensitive Information. We do not process sensitive information.</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Payment Data. We may collect data necessary to process your payment if you makepurchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is stored by Paystack. You
              may find their privacy notice link(s) here:{" "}
              <a className=" text-orange-600" href="https://paystack.com/terms.">
                paystack.com.
              </a>{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Application Data.</b> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Mobile Device Access.</b> We may request access or permission to certain features from your mobile device, including your mobile device's camera, and other features. If you wish to change our access or
              permissions, you may do so in your device's settings.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Mobile Device Data. </b> We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information,
              device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may
              also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device's unique device ID, and information about the
              featuresof our application(s) you accessed.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]">Push Notifications. </b> We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you
              may turn them off in your device's settings.
              <br />
              <br /> This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes. All personal information that you provide to us must
              be true, complete, and accurate, and you must notify us of any changes to such personal information.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">Information automatically collected</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
              <br />
              <br />
              We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information,
              such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information.
              This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
              <br />
              <br />
              Like many businesses, we also collect information through cookies and similar technologies.
              <br />
              <br />
              The information we collect includes:
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Log and Usage Data. </b> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log
              files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with
              your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Device Data. </b> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include
              information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration
              information.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Location Data. </b>We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the
              device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this
              information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">Information collected from other sources</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: We may collect limited data from public databases, marketing partners, and other outside sources.
              <br />
              <br />
              In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate
              programs, data providers, and from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behaviour data), Internet Protocol (IP) addresses, social media
              profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">2. HOW DO WE PROCESS YOUR INFORMATION?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
              <br />
              <br /> We process your personal information for a variety of reasons, depending on how you interact with our Services, including <br />
              <br />
              To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.
              <br />
              <br /> To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.
              <br />
              <br /> To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
              <br />
              <br /> To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
              <br />
              <br /> To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.
              <br />
              <br /> To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing
              emails at any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?' below. <br />
              <br />
              To deliver targeted advertising to you. We may process your information to develop and display personalised content and advertising tailored to your interests, location, and more. <br />
              <br />
              To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention. <br />
              <br />
              To evaluate and improve our Services, products, marketing, and your experience. We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to
              evaluate and improve our Services, products, marketing, and your experience.
              <br />
              <br /> To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them. <br />
              To comply with our legal obligations. We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">2. HOW DO WE PROCESS YOUR INFORMATION?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
              <br />
              <br /> We process your personal information for a variety of reasons, depending on how you interact with our Services, including <br />
              <br />
              To facilitate account creation and authentication and otherwise manage user accounts. We may process your information so you can create and log in to your account, as well as keep your account in working order.
              <br />
              <br /> To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.
              <br />
              <br /> To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
              <br />
              <br /> To send administrative information to you. We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
              <br />
              <br /> To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.
              <br />
              <br /> To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing
              emails at any time. For more information, see "WHAT ARE YOUR PRIVACY RIGHTS?' below. <br />
              <br />
              To deliver targeted advertising to you. We may process your information to develop and display personalised content and advertising tailored to your interests, location, and more. <br />
              <br />
              To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention. <br />
              <br />
              To evaluate and improve our Services, products, marketing, and your experience. We may process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to
              evaluate and improve our Services, products, marketing, and your experience.
              <br />
              <br /> To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them. <br />
              To comply with our legal obligations. We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.{" "}
            </p>
          </section>{" "}
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">In Short: We may share information in specific situations described in this section and/or with the following third parties.</p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">We may need to share your personal information in the following situations:</p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Business Transfers. </b> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our
              business to another company.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]"> Business Partners. </b> We may share your information with our business partners to offer you certain products, services, or promotions.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">4. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.
              <br />
              <br /> The Services may link to third-party websites, online services, or mobile applications and/or contain advertisements from third parties that are not affiliated with us and which may link to other websites, services, or
              applications. Accordingly, we do not make any guarantee regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or application does not imply an
              endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and
              security practices and policies of any third parties, including other websites, services, or applications that may be linked to or from the Services. You should review the policies of such third parties and contact them directly to
              respond to your questions.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: We may use cookies and other tracking technologies to collect and store your information.
              <br />
              <br />
              We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie
              Notice.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">6. HOW LONG DO WE KEEP YOUR INFORMATION?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.
              <br />
              <br />
              We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal
              requirements). No purpose in this notice will require us keeping your personal information for longer than twenty-four (24) months past the termination of the user's account.
              <br />
              <br />
              When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in
              backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: We aim to protect your personal information through a system of organisational and technical security measures.
              <br />
              <br />
              We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your
              information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will
              not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at
              your own risk. You should only access the Services within a secure environment.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">8. DO WE COLLECT INFORMATION FROM MINORS?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: We do not knowingly collect data from or market to children under 18 years of age.
              <br />
              <br />
              We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor
              dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from children under age 18, please contact us at{" "}
              <a className=" text-orange-600" href="https://privacy@myhood.ng">
                privacy@myhood.ng.
              </a>{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">9. WHAT ARE YOUR PRIVACY RIGHTS?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">In Short: You may review, change, or terminate your account at any time.</p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]">Withdrawing your consent: </b> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to
              withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details below.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing
              grounds other than consent.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the
              details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that
              are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">Account Information</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[18px] font-semibold font-openSans tracking-tight">Contact us using the contact information provided.</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with
              any investigations, enforce our legal terms and/or comply with applicable legal requirements.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              <b className="text-[#333]">Cookies and similar technologies: </b>Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to
              remove cookies or reject cookies, this could affect certain features or services of our Services. You may also opt out of interest-based advertising by advertisers on our Services. If you have questions or comments about your privacy
              rights, you may email us at privacy@myhood.ng.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">10. CONTROLS FOR DO-NOT-TRACK FEATURES</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities
              monitored and collected. At this stage no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically
              communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">11. DO WE MAKE UPDATES TO THIS NOTICE?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
              <br />
              <br />
              We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy
              notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your
              information.{" "}
            </p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">If you have questions or comments about this notice, you may email us at privacy@myhood.ng</p>
          </section>
          <section className=" w-full gap-[10px] flex flex-col justify-between items-start ">
            <h1 className="text-[#333] text-[20px] font-semibold font-openSans tracking-tight">13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h1>
            <p className="text-[#777] text-[14px] leading-[25px] font-normal font-monteserrat tracking-tight">
              Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal
              information, please send an email to{" "}
              <a href="https://privacy@myhood.ng" className=" text-orange-600">
                {" "}
                privacy@myhood.ng
              </a>
            </p>
          </section>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
