import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import SideBar from "./SideBar";
import Footer from "./Footer";
import moment from "moment";
import axios from "axios";
import FormatDate from "../../Utils/helpers";
function DashboardUser() {
  const [mini, setMini] = useState(false);
  const location = useLocation();
  // For Intending debtors
  const [debt, setDebt] = useState(true);
  const [dropDrown, setDropDrown] = useState(false);
  const [modal, setModal] = useState(false);
  const [inputField, setInputField] = useState(false);
  const [data, setData] = useState([]);
  const [debtInfo, setDebtInfo] = useState({});
  const [accessCode, setAccessCode] = useState([]);
  const [expiredAccessCode, setExpiredAccessCode] = useState([]);
  const [unUsedAccessCode, setUnUsedAccessCode] = useState([]);
  const [usedAccessCode, setUsedAccessCode] = useState([]);
  const [reciept, setReciept] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [page, setPage] = useState(1);

  //debt information
  useEffect(() => {
    const fetchData = async () => {
      const debtInfo = {
        user_id: 1,
        action_id: 1,
      };
      try {
        const res = await axios.post("https://www.myhood.ng/mobile_back_end/api/get_payments2.php", debtInfo);

        console.log("here", res.data);
        setDebtInfo(res.data[1]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // data information
  useEffect(() => {
    const fetchData = async () => {
      const dataSend = {
        page: page,
        id: "admin",
        status: "initiated",
        rows: "10",
      };
      try {
        const res = await axios.post("https://www.myhood.ng/mobile_back_end/api/get_payments.php", dataSend);
        // console.log("get payments ", res, dataSend);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [page]);

  // for expired access code
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("https://www.myhood.ng/mobile_back_end/api/list_tokens.php", { status: "expired", rows: "100000", page: "3" }, accessCode);
        // setExpiredAccessCode(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // for live access code
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("https://www.myhood.ng/mobile_back_end/api/list_tokens.php", { status: "live", rows: "100000", page: "3" }, accessCode);
        // console.log(res);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // for used access code
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("https://www.myhood.ng/mobile_back_end/api/list_tokens.php", { status: "utilized", rows: "100000", page: "3" }, accessCode, { headers: { "Content-Type": "application/json" } });
        // console.log(res);
        // setUsedAccessCode(res.data);
        // setAccessCode(res.accessCode);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  // Function to filter data based on search query
  useEffect(() => {
    const filterData = () => {
      const filtered = accessCode.filter((item) =>
        // Customize this condition to match your search criteria
        Object.values(item).some((value) => value.toString().toLowerCase().includes(searchQuery.toLowerCase()))
      );
      setFilteredData(filtered);
    };

    filterData();
  }, [searchQuery]);

  // Function to handle input change
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const menu = [
    {
      id: 1,
      path: "/dashboarduser",
      name: "Dashboard",
      title: "Dashboard",
      icon1: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83334 3.17332 9.83334 4.73812C9.83334 6.30293 11.1018 7.57146 12.6666 7.57146Z" stroke="#333333" />
          <path d="M5 7.57146C6.5648 7.57146 7.83333 6.30293 7.83333 4.73812C7.83333 3.17332 6.5648 1.90479 5 1.90479C3.43519 1.90479 2.16666 3.17332 2.16666 4.73812C2.16666 6.30293 3.43519 7.57146 5 7.57146Z" stroke="#333333" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83334 10.8398 9.83334 12.4046C9.83334 13.9694 11.1018 15.238 12.6666 15.238Z" stroke="#333333" />
          <path d="M5 15.238C6.5648 15.238 7.83333 13.9694 7.83333 12.4046C7.83333 10.8398 6.5648 9.57129 5 9.57129C3.43519 9.57129 2.16666 10.8398 2.16666 12.4046C2.16666 13.9694 3.43519 15.238 5 15.238Z" stroke="#333333" />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83331 3.17332 9.83331 4.73812C9.83331 6.30293 11.1018 7.57146 12.6666 7.57146Z" fill="#F5F5F5" />
          <path d="M4.99997 7.57146C6.56477 7.57146 7.8333 6.30293 7.8333 4.73812C7.8333 3.17332 6.56477 1.90479 4.99997 1.90479C3.43516 1.90479 2.16663 3.17332 2.16663 4.73812C2.16663 6.30293 3.43516 7.57146 4.99997 7.57146Z" fill="#F5F5F5" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83331 10.8398 9.83331 12.4046C9.83331 13.9694 11.1018 15.238 12.6666 15.238Z" fill="#F5F5F5" />
          <path d="M4.99997 15.238C6.56477 15.238 7.8333 13.9694 7.8333 12.4046C7.8333 10.8398 6.56477 9.57129 4.99997 9.57129C3.43516 9.57129 2.16663 10.8398 2.16663 12.4046C2.16663 13.9694 3.43516 15.238 4.99997 15.238Z" fill="#F5F5F5" />
        </svg>
      ),
    },
    {
      id: 2,
      path: "/paymentuser",
      name: "Payment",
      title: "Payment",
      icon1: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_1356_2637" maskUnits="userSpaceOnUse" x="0" y="1" width="16" height="17">
            <path d="M16 1.07129H0V17.0713H16V1.07129Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1356_2637)">
            <path
              d="M9.33333 13.0712C12.2789 13.0712 14.6667 10.6833 14.6667 7.73787C14.6667 4.79235 12.2789 2.40454 9.33333 2.40454C6.38781 2.40454 4 4.79235 4 7.73787C4 10.6833 6.38781 13.0712 9.33333 13.0712Z"
              stroke="#333333"
              strokeLinecap="round"
            />
            <path d="M2.10442 8.40454C1.61686 9.14958 1.33337 10.0402 1.33337 10.9971C1.33337 13.6153 3.45589 15.7378 6.07415 15.7378C7.03102 15.7378 7.92167 15.4543 8.66671 14.9668" stroke="#333333" strokeLinecap="round" />
            <path
              d="M11.9727 7.83441H11.3567V8.23341H11.9727V8.66741H11.3567V10.4454H10.4817L8.24874 7.12041V10.4454H7.33874V8.66741H6.72974V8.23341H7.33874V7.83441H6.72974V7.40041H7.33874V5.54541H8.21374L10.4607 8.89141V5.54541H11.3567V7.40041H11.9727V7.83441Z"
              fill="#333333"
            />
          </g>
        </svg>
      ),
      icon2: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_1388_7257" maskUnits="userSpaceOnUse" x="0" y="1" width="16" height="17">
            <path d="M16 1.07129H0V17.0713H16V1.07129Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1388_7257)">
            <path
              d="M9.33333 13.0712C12.2789 13.0712 14.6667 10.6833 14.6667 7.73787C14.6667 4.79235 12.2789 2.40454 9.33333 2.40454C6.38781 2.40454 4 4.79235 4 7.73787C4 10.6833 6.38781 13.0712 9.33333 13.0712Z"
              fill="#F5F5F5"
              stroke="#F5F5F5"
              strokeLinecap="round"
            />
            <path d="M2.10442 8.40454C1.61686 9.14958 1.33337 10.0402 1.33337 10.9971C1.33337 13.6153 3.45589 15.7378 6.07415 15.7378C7.03102 15.7378 7.92167 15.4543 8.66671 14.9668" stroke="#F5F5F5" strokeLinecap="round" />
            <path
              d="M11.9727 7.83441H11.3567V8.23341H11.9727V8.66741H11.3567V10.4454H10.4817L8.24874 7.12041V10.4454H7.33874V8.66741H6.72974V8.23341H7.33874V7.83441H6.72974V7.40041H7.33874V5.54541H8.21374L10.4607 8.89141V5.54541H11.3567V7.40041H11.9727V7.83441Z"
              fill="#FF5722"
            />
          </g>
        </svg>
      ),
    },
    {
      id: 3,
      path: "/advertmanagementuser",
      name: "Advert Management",
      title: "Advert Management",
      icon1: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.67053 11.7323L5.35427 7.09046C5.66045 6.44611 6.12866 5.9132 6.66514 7.19129C7.16036 8.3711 7.89923 10.5314 8.33536 11.7348M4.43555 9.7393H7.54763" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M2.30969 3.94997C1.33337 4.82865 1.33337 6.24287 1.33337 9.07129C1.33337 11.8996 1.33337 13.3139 2.30969 14.1926C3.28599 15.0712 4.85735 15.0712 8.00004 15.0712C11.1427 15.0712 12.7141 15.0712 13.6904 14.1926C14.6667 13.3139 14.6667 11.8996 14.6667 9.07129C14.6667 6.24287 14.6667 4.82865 13.6904 3.94997C12.7141 3.07129 11.1427 3.07129 8.00004 3.07129C4.85735 3.07129 3.28599 3.07129 2.30969 3.94997Z"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.3228 7.0625V9.05895M12.3228 9.05895V11.688M12.3228 9.05895H10.9773C10.8175 9.05895 10.659 9.08868 10.5096 9.14662C9.38042 9.58475 9.38042 11.2128 10.5096 11.651C10.659 11.7089 10.8175 11.7386 10.9773 11.7386H12.3228"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.14304 3.94997C2.16672 4.82865 2.16672 6.24287 2.16672 9.07129C2.16672 11.8996 2.16672 13.3139 3.14304 14.1926C4.11934 15.0712 5.6907 15.0712 8.83339 15.0712C11.976 15.0712 13.5474 15.0712 14.5237 14.1926C15.5 13.3139 15.5 11.8996 15.5 9.07129C15.5 6.24287 15.5 4.82865 14.5237 3.94997C13.5474 3.07129 11.976 3.07129 8.83339 3.07129C5.6907 3.07129 4.11934 3.07129 3.14304 3.94997Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M4.50388 11.7323L6.18762 7.09046C6.4938 6.44611 6.96201 5.9132 7.49849 7.19129C7.99371 8.3711 8.73257 10.5314 9.1687 11.7348M5.2689 9.7393H8.38098" stroke="#FF5722" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M13.1561 7.0625V9.05895M13.1561 9.05895V11.688M13.1561 9.05895H11.8106C11.6508 9.05895 11.4923 9.08868 11.3429 9.14662C10.2138 9.58475 10.2138 11.2128 11.3429 11.651C11.4923 11.7089 11.6508 11.7386 11.8106 11.7386H13.1561"
            stroke="#FF5722"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 4,
      path: "/issuetrackinguser",
      name: "Issue Tracking",
      title: "Issue Tracking",
      icon1: (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.38115 6.74101C5.99027 3.89372 6.79482 2.47006 7.89887 2.1036C8.50621 1.90199 9.16048 1.90199 9.76781 2.1036C10.8719 2.47006 11.6764 3.89372 13.2855 6.74101C14.8947 9.58832 15.6992 11.0119 15.4579 12.1719C15.3251 12.8101 14.9979 13.389 14.5233 13.8255C13.6607 14.6191 12.0516 14.6191 8.83334 14.6191C5.61511 14.6191 4.00599 14.6191 3.14331 13.8255C2.66873 13.389 2.3416 12.8101 2.20882 12.1719C1.96747 11.0119 2.77203 9.58832 4.38115 6.74101Z"
            stroke="#333333"
          />
          <path d="M8.99481 11.619V8.95231C8.99481 8.63804 8.99481 8.48091 8.89714 8.38324C8.79954 8.28564 8.64241 8.28564 8.32814 8.28564" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M8.828 6.28564H8.8355" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
      icon2: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.54785 7.52665C5.15697 4.67936 5.96152 3.2557 7.06558 2.88924C7.67291 2.68763 8.32718 2.68763 8.93451 2.88924C10.0386 3.2557 10.8431 4.67936 12.4522 7.52665C14.0614 10.374 14.8659 11.7976 14.6246 12.9576C14.4918 13.5958 14.1646 14.1747 13.69 14.6112C12.8274 15.4047 11.2183 15.4047 8.00004 15.4047C4.78181 15.4047 3.17269 15.4047 2.31002 14.6112C1.83543 14.1747 1.5083 13.5958 1.37552 12.9576C1.13417 11.7976 1.93873 10.374 3.54785 7.52665Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
          />
          <path d="M8.16142 12.4046V9.73796C8.16142 9.42369 8.16142 9.26656 8.06375 9.16889C7.96615 9.07129 7.80902 9.07129 7.49475 9.07129" stroke="#FF5722" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M7.99463 7.07129H8.00213" stroke="#FF5722" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      ),
    },
  ];
  const activeItem = menu.find((item) => item.path === location.pathname);

  const cards = [
    {
      id: 1,
      text: "Total Access Code",
      figure: expiredAccessCode.length + unUsedAccessCode.length + usedAccessCode.length,
      backgroundColor: "#EDF0FF",
      icon: (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.49998 1.66666C4.1988 1.66666 2.33331 3.53214 2.33331 5.83332C2.33331 7.37558 3.17123 8.72216 4.41665 9.44257V14.8692C4.41665 15.5505 4.41665 15.8912 4.54351 16.1974C4.67038 16.5037 4.91124 16.7446 5.39295 17.2263L6.49998 18.3333L8.2568 16.5765C8.33783 16.4955 8.37837 16.4549 8.41198 16.411C8.50024 16.2956 8.55678 16.1591 8.576 16.015C8.58331 15.9602 8.58331 15.9028 8.58331 15.7882C8.58331 15.6955 8.58331 15.6492 8.5784 15.6041C8.56551 15.4859 8.5275 15.3719 8.46694 15.2697C8.44384 15.2307 8.41601 15.1936 8.36036 15.1194L7.33331 13.75L7.91665 12.9722C8.24705 12.5317 8.41225 12.3114 8.49779 12.0548C8.58331 11.7982 8.58331 11.5229 8.58331 10.9722V9.44257C9.82873 8.72216 10.6666 7.37558 10.6666 5.83332C10.6666 3.53214 8.80116 1.66666 6.49998 1.66666Z"
            stroke="#333333"
            strokeWidth="1.25"
            strokeLinejoin="round"
          />
          <path d="M6.5 5.83334H6.50748" stroke="#333333" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M11.5 11.6667H16.5C17.2766 11.6667 17.6648 11.6667 17.9712 11.7935C18.3795 11.9627 18.704 12.2872 18.8732 12.6955C19 13.0018 19 13.3901 19 14.1667C19 14.9432 19 15.3315 18.8732 15.6378C18.704 16.0462 18.3795 16.3707 17.9712 16.5398C17.6648 16.6667 17.2766 16.6667 16.5 16.6667H11.5"
            stroke="#333333"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
          <path
            d="M13.1667 4.16666H16.5C17.2766 4.16666 17.6648 4.16666 17.9712 4.29352C18.3795 4.46268 18.704 4.78714 18.8732 5.19551C19 5.50181 19 5.89009 19 6.66666C19 7.44322 19 7.83151 18.8732 8.1378C18.704 8.54616 18.3795 8.87066 17.9712 9.03982C17.6648 9.16666 17.2766 9.16666 16.5 9.16666H13.1667"
            stroke="#333333"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
        </svg>
      ),
    },
    {
      id: 2,
      text: "Used Access Code",
      figure: usedAccessCode.length,
      backgroundColor: "#F0FFF1",
      icon: (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.49998 1.66666C4.1988 1.66666 2.33331 3.53214 2.33331 5.83332C2.33331 7.37558 3.17123 8.72216 4.41665 9.44257V14.8692C4.41665 15.5505 4.41665 15.8912 4.54351 16.1974C4.67038 16.5037 4.91124 16.7446 5.39295 17.2263L6.49998 18.3333L8.2568 16.5765C8.33783 16.4955 8.37837 16.4549 8.41198 16.411C8.50024 16.2956 8.55678 16.1591 8.576 16.015C8.58331 15.9602 8.58331 15.9028 8.58331 15.7882C8.58331 15.6955 8.58331 15.6492 8.5784 15.6041C8.56551 15.4859 8.5275 15.3719 8.46694 15.2697C8.44384 15.2307 8.41601 15.1936 8.36036 15.1194L7.33331 13.75L7.91665 12.9722C8.24705 12.5317 8.41225 12.3114 8.49779 12.0548C8.58331 11.7982 8.58331 11.5229 8.58331 10.9722V9.44257C9.82873 8.72216 10.6666 7.37558 10.6666 5.83332C10.6666 3.53214 8.80116 1.66666 6.49998 1.66666Z"
            stroke="#333333"
            strokeWidth="1.25"
            strokeLinejoin="round"
          />
          <path d="M6.5 5.83334H6.50748" stroke="#333333" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M11.5 11.6667H16.5C17.2766 11.6667 17.6648 11.6667 17.9712 11.7935C18.3795 11.9627 18.704 12.2872 18.8732 12.6955C19 13.0018 19 13.3901 19 14.1667C19 14.9432 19 15.3315 18.8732 15.6378C18.704 16.0462 18.3795 16.3707 17.9712 16.5398C17.6648 16.6667 17.2766 16.6667 16.5 16.6667H11.5"
            stroke="#333333"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
          <path
            d="M13.1667 4.16666H16.5C17.2766 4.16666 17.6648 4.16666 17.9712 4.29352C18.3795 4.46268 18.704 4.78714 18.8732 5.19551C19 5.50181 19 5.89009 19 6.66666C19 7.44322 19 7.83151 18.8732 8.1378C18.704 8.54616 18.3795 8.87066 17.9712 9.03982C17.6648 9.16666 17.2766 9.16666 16.5 9.16666H13.1667"
            stroke="#333333"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
        </svg>
      ),
    },
    {
      id: 3,
      text: "Expired Access Code",
      figure: expiredAccessCode.length,
      backgroundColor: "#FFF3E5",
      icon: (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.49998 1.66666C4.1988 1.66666 2.33331 3.53214 2.33331 5.83332C2.33331 7.37558 3.17123 8.72216 4.41665 9.44257V14.8692C4.41665 15.5505 4.41665 15.8912 4.54351 16.1974C4.67038 16.5037 4.91124 16.7446 5.39295 17.2263L6.49998 18.3333L8.2568 16.5765C8.33783 16.4955 8.37837 16.4549 8.41198 16.411C8.50024 16.2956 8.55678 16.1591 8.576 16.015C8.58331 15.9602 8.58331 15.9028 8.58331 15.7882C8.58331 15.6955 8.58331 15.6492 8.5784 15.6041C8.56551 15.4859 8.5275 15.3719 8.46694 15.2697C8.44384 15.2307 8.41601 15.1936 8.36036 15.1194L7.33331 13.75L7.91665 12.9722C8.24705 12.5317 8.41225 12.3114 8.49779 12.0548C8.58331 11.7982 8.58331 11.5229 8.58331 10.9722V9.44257C9.82873 8.72216 10.6666 7.37558 10.6666 5.83332C10.6666 3.53214 8.80116 1.66666 6.49998 1.66666Z"
            stroke="#333333"
            strokeWidth="1.25"
            strokeLinejoin="round"
          />
          <path d="M6.5 5.83334H6.50748" stroke="#333333" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M11.5 11.6667H16.5C17.2766 11.6667 17.6648 11.6667 17.9712 11.7935C18.3795 11.9627 18.704 12.2872 18.8732 12.6955C19 13.0018 19 13.3901 19 14.1667C19 14.9432 19 15.3315 18.8732 15.6378C18.704 16.0462 18.3795 16.3707 17.9712 16.5398C17.6648 16.6667 17.2766 16.6667 16.5 16.6667H11.5"
            stroke="#333333"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
          <path
            d="M13.1667 4.16666H16.5C17.2766 4.16666 17.6648 4.16666 17.9712 4.29352C18.3795 4.46268 18.704 4.78714 18.8732 5.19551C19 5.50181 19 5.89009 19 6.66666C19 7.44322 19 7.83151 18.8732 8.1378C18.704 8.54616 18.3795 8.87066 17.9712 9.03982C17.6648 9.16666 17.2766 9.16666 16.5 9.16666H13.1667"
            stroke="#333333"
            strokeWidth="1.25"
            strokeLinecap="round"
          />
        </svg>
      ),
    },
  ];
  const formatAmount = (amount) => {
    if (amount && typeof amount !== "undefined") {
      if (amount.toString().length === 5) {
        return amount.toString().slice(0, 2) + "," + amount.toString().slice(2);
      } else if (amount.toString().length === 6) {
        return amount.toString().slice(0, 3) + "," + amount.toString().slice(3);
      } else {
        return amount;
      }
    } else {
      return amount; // Return the original value if it's undefined
    }
  };

  const formattedAmount = formatAmount(reciept.amount);

  return (
    <>
      <div className="flex   justify-between items-start">
        <SideBar mini={mini} setMini={setMini} menu={menu} />
        {/* LARGE SCREENS */}
        <div className={` 100:hidden xl:flex w-full pr-[25px] pb-[50px] flex flex-col justify-between gap-[22px] h-fit ${mini ? "pl-[130px]" : "pl-[280px]"}`}>
          <Header mini={mini} name={activeItem ? activeItem.name : null} />
          {/* Message for debtors */}
          {debt && (
            <div className="  border-solid rounded-[10px] h-[78px] border-[1px] justify-between items-center border-[#E52C2C] bg-[#FFEEEE] w-full px-[20px] flex  ">
              <section className=" justify-between gap-[10px] items-center flex">
                <div onClick={() => setDebt(false)} className=" bg-[#FEF3F2] border-[#F8F6F6] rounded-full border-[10px] border-solid cursor-pointer w-fit p-[10px]">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.25 3.75L3.75 14.25M3.75 3.75L14.25 14.25" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>

                <div className=" rounded-[16px] w-fit h-[35px] py-[4px] px-[10px] flex justify-center items-center gap-[10px] bg-[#FEF3F2]">
                  <h3 className="rounded-[16px] tracking-[0.9px] font-openSans font-normal text-[14px] text-[#E65555] bg-[#fff]  py-[4px] px-[10px] w-fit ">Upcoming Payment</h3>
                  <h3 className="  font-medium font-monteserrat text-[18px] text-[#B42318] ">Subscription payment is due on {FormatDate(debtInfo?.end_date)}</h3>
                </div>
              </section>
              <button>
                <div className="w-fit h-[33px] px-[20px] py-[7px] bg-orange-600 rounded text-white text-[14px] font-semibold font-monteserrat tracking-tight justify-center items-center  inline-flex">Renew Payment</div>
              </button>
            </div>
          )}
          {/* Cards */}
          <div className=" py-[25px]  gap-[10px] flex justify-between items-center ">
            {cards?.map((item) => (
              <div title={item.text} className="w-full h-[102px] px-5 py-[21px] rounded-[10px] shadow border border-neutral-200 justify-between items-center inline-flex">
                <div className="py-[5px] flex-col justify-start items-start gap-5 inline-flex">
                  <h4 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[15px] tracking-tight">{item.text}</h4>
                  <h3 className="text-zinc-800 text-xl font-normal font-['Montserrat'] leading-[15px] tracking-tight">{item.figure}</h3>
                </div>
                <div style={{ backgroundColor: item.backgroundColor }} className={`p-2.5 rounded-[100px] justify-center items-center flex `}>
                  {item.icon}
                </div>
              </div>
            ))}
          </div>
          <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <div className=" flex gap-[16px] justify-between items-center w-full">
                <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Recent Payment</h4>
                <div className=" rounded-[20px] border-[0.6px] border-solid h-[38px] px-[23px] py-[10px] border-[#9E9E9E] flex justify-between items-center gap-[10px] w-[321px]">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.125 13.125L16.5 16.5" stroke="#777777" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15 8.25C15 4.52208 11.978 1.5 8.25 1.5C4.52208 1.5 1.5 4.52208 1.5 8.25C1.5 11.978 4.52208 15 8.25 15C11.978 15 15 11.978 15 8.25Z" stroke="#777777" strokeWidth="1.125" strokeLinejoin="round" />
                  </svg>
                  <input
                    placeholder="Search recent payment"
                    className=" text-[16px] font-monteserrat font-medium text-[#333] placeholder:text-[14px] placeholder:font-monteserrat placeholder:font-normal w-full h-full bg-transparent outline-none border-none"
                    type="text"
                    value={searchQuery}
                    onChange={handleInputChange}
                  />
                  <ul className=" ">
                    {/* Render filtered data */}
                    {filteredData?.map((item) => (
                      <li key={item.id}>{/* Render your item content here */}</li>
                    ))}
                  </ul>
                </div>
                <div className="w-[75px] h-[38px] px-[5px] bg-neutral-100 rounded-[10px] shadow justify-start items-center gap-[5px] inline-flex">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 11.5H14.5001" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.5 15H13.5" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.5 8H15.5" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>

                  <div className="text-neutral-500 text-sm font-normal font-['Montserrat'] tracking-tight">Filter</div>
                </div>
              </div>
            </section>
            {/* Table Starts here */}
            {page && (
              <div className=" flex justify-between w-full items-center">
                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Date </h2>
                  </div>{" "}
                  {data?.map((item) => {
                    const formattedDate = moment(item.date_created).format("Do MMMM YYYY");
                    return (
                      <>
                        <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                          <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">{formattedDate} </h2>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Item </h2>
                  </div>{" "}
                  {data?.map((item) => (
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">{item.name} </h2>
                    </div>
                  ))}
                </div>
                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Amount </h2>
                  </div>{" "}
                  {data?.map((item) => (
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">₦{formatAmount(item.amount)} </h2>
                    </div>
                  ))}
                </div>
                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Payment Status </h2>
                  </div>{" "}
                  {data?.map((item) => (
                    <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <h2
                        className={`${
                          item.pssp_status === "failed" ? "text-[#E52C2C] bg-[#FFECEC]" : "bg-[#E6FFE7] text-[#4CAF50]"
                        } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                      >
                        {item.pssp_status === "failed" ? (
                          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        ) : (
                          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        )}
                        {item.pssp_status}
                      </h2>
                    </div>
                  ))}
                </div>
                <div className="w-[150px] cursor-pointer h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Action </h2>
                  </div>{" "}
                  {data?.map((item) => (
                    <div onClick={() => setDropDrown(!dropDrown)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.8333 10C10.8333 9.53976 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16668 9.53976 9.16668 10C9.16668 10.4602 9.53977 10.8333 10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10Z"
                          stroke="#9E9E9E"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M5.00001 10C5.00001 9.53976 4.62691 9.16666 4.16668 9.16666C3.70644 9.16666 3.33334 9.53976 3.33334 10C3.33334 10.4602 3.70644 10.8333 4.16668 10.8333C4.62691 10.8333 5.00001 10.4602 5.00001 10Z"
                          stroke="#9E9E9E"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.6667 10C16.6667 9.53976 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53976 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10Z"
                          stroke="#9E9E9E"
                          strokeWidth="1.66667"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  ))}
                </div>{" "}
              </div>
            )}
          </div>
          <Footer setPage={setPage} page={page} />
          {dropDrown && (
            <>
              <div className="w-[118px] right-[40px] absolute top-[600px] h-[59px] px-[11px] py-[13px] bg-white rounded-[10px] shadow flex-col justify-start items-center gap-2.5 inline-flex">
                <div
                  onClick={() => {
                    setDropDrown(false);
                    setModal(true);
                  }}
                  className="self-stretch cursor-pointer px-5 py-[9px] border-b border-neutral-200 justify-start items-center gap-2.5 inline-flex"
                >
                  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.5674 6.94291C12.7447 7.19158 12.8334 7.31595 12.8334 7.49999C12.8334 7.68403 12.7447 7.8084 12.5674 8.05707C11.7705 9.17451 9.73545 11.5833 7.00008 11.5833C4.2647 11.5833 2.22964 9.17451 1.43277 8.05707C1.25542 7.8084 1.16675 7.68403 1.16675 7.49999C1.16675 7.31595 1.25542 7.19158 1.43277 6.94291C2.22964 5.8255 4.2647 3.41666 7.00008 3.41666C9.73545 3.41666 11.7705 5.8255 12.5674 6.94291Z"
                      stroke="#333333"
                      strokeWidth="0.875"
                    />
                    <path d="M8.75 7.5C8.75 6.53347 7.96652 5.75 7 5.75C6.03347 5.75 5.25 6.53347 5.25 7.5C5.25 8.46652 6.03347 9.25 7 9.25C7.96652 9.25 8.75 8.46652 8.75 7.5Z" stroke="#333333" strokeWidth="0.875" />
                  </svg>

                  <div className="text-zinc-800 text-xs font-normal font-['Montserrat'] tracking-wide">View</div>
                </div>
              </div>
            </>
          )}
        </div>
        {/* SMALL SCREENS */}
        <div className=" 100:flex xl:hidden w-full pr-[25px] pb-[50px] flex-col justify-between gap-[22px] h-fit pl-[130px]">
          <Header mini={mini} name={activeItem ? activeItem.name : null} />
          {/* Message for debtors */}
          {debt && (
            <div className="  border-solid rounded-[10px] gap-[15px] flex-col  h-fit border-[1px] justify-between items-start border-[#E52C2C] bg-[#FFEEEE] w-full p-[10px] flex  ">
              <section className=" w-full justify-between  items-center flex">
                <div className=" rounded-[16px] w-full  py-[4px] px-[10px] flex-col flex justify-start items-start gap-[5px] bg-[#FEF3F2]">
                  <h3 className="rounded-[16px] tracking-[0.9px] font-openSans font-semibold text-[8px] text-[#E65555] bg-[#fff]  py-[4px] px-[10px] w-fit ">Upcoming Payment</h3>

                  <h3 className="  font-semibold font-monteserrat text-[12px] text-[#B42318] ">Subscription payment is due </h3>
                </div>{" "}
                <div onClick={() => setDebt(false)} className=" bg-[#FEF3F2] border-[#F8F6F6] rounded-full border-[10px] border-solid cursor-pointer w-fit p-[5px]">
                  <svg width="10" height="10" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.25 3.75L3.75 14.25M3.75 3.75L14.25 14.25" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </section>
              <button>
                <div className="w-fit h-[33px] px-[10px] py-[7px] bg-orange-600 rounded whitespace-nowrap text-white text-[12px] font-semibold font-monteserrat tracking-tight justify-center items-center  inline-flex">Renew Payment</div>
              </button>
            </div>
          )}
          {/* Cards */}
          <div className=" py-[25px]  gap-[10px] flex-col flex justify-between items-center ">
            {cards?.map((item) => (
              <div title={item.text} className="w-full h-[102px] px-5 py-[21px] rounded-[10px] shadow border border-neutral-200 justify-between items-center inline-flex">
                <div className="py-[5px] flex-col justify-start items-start gap-5 inline-flex">
                  <h4 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[15px] tracking-tight">{item.text}</h4>
                  <h3 className="text-zinc-800 text-xl font-normal font-['Montserrat'] leading-[15px] tracking-tight">{item.figure}</h3>
                </div>
                <div style={{ backgroundColor: item.backgroundColor }} className={`p-2.5 rounded-[100px] justify-center items-center flex `}>
                  {item.icon}
                </div>
              </div>
            ))}
          </div>
          <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <div className=" flex gap-[16px] justify-between items-center w-full">
                <h4 className={`tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ${inputField ? " hidden" : " block"}`}>Recent Payment</h4>
                <div className={`rounded-[20px] border-[0.6px] border-solid h-[38px] px-[10px] py-[10px] border-[#9E9E9E] flex justify-between items-center gap-[10px] ${inputField ? "w-full" : "w-fit"} `}>
                  <svg onClick={() => setInputField(!inputField)} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.125 13.125L16.5 16.5" stroke="#777777" strokeWidth="1.125" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15 8.25C15 4.52208 11.978 1.5 8.25 1.5C4.52208 1.5 1.5 4.52208 1.5 8.25C1.5 11.978 4.52208 15 8.25 15C11.978 15 15 11.978 15 8.25Z" stroke="#777777" strokeWidth="1.125" strokeLinejoin="round" />
                  </svg>
                  {inputField && (
                    <input
                      placeholder="Search recent payment"
                      className=" text-[16px] font-monteserrat font-medium text-[#333] placeholder:text-[14px] placeholder:font-monteserrat placeholder:font-normal w-full h-full bg-transparent outline-none border-none"
                      type="text"
                    />
                  )}
                </div>
                <div className="w-fit h-[38px] px-[10px] bg-neutral-100 rounded-[10px] shadow justify-start items-center gap-[5px] inline-flex">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5 11.5H14.5001" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M9.5 15H13.5" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.5 8H15.5" stroke="#777777" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
            </section>
            {/* Table Starts here */}
            <div className=" overflow-x-scroll whitespace-nowrap flex justify-between w-full items-center">
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Date </h2>
                </div>{" "}
                {data?.map((item) => {
                  const formattedDate = moment(item.date_created).format("Do MMMM YYYY");
                  return (
                    <>
                      <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                        <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">{formattedDate} </h2>
                      </div>
                    </>
                  );
                })}
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Item </h2>
                </div>{" "}
                {data?.map((item) => (
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">{item.name} </h2>
                  </div>
                ))}
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Amount </h2>
                </div>{" "}
                {data?.map((item) => (
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">₦{formatAmount(item.amount)} </h2>
                  </div>
                ))}
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Payment Status </h2>
                </div>{" "}
                {data?.map((item) => (
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2
                      className={`${
                        item.pssp_status === "failed" ? "text-[#E52C2C] bg-[#FFECEC]" : "bg-[#E6FFE7] text-[#4CAF50]"
                      } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                    >
                      {item.pssp_status === "failed" ? (
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      ) : (
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      )}
                      {item.pssp_status}
                    </h2>
                  </div>
                ))}
              </div>
              <div className="w-[150px] cursor-pointer h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Action </h2>
                </div>{" "}
                {data?.map((item) => (
                  <div onClick={() => setDropDrown(!dropDrown)} className="self-stretch cursor-pointer h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.8333 10C10.8333 9.53976 10.4602 9.16666 10 9.16666C9.53977 9.16666 9.16668 9.53976 9.16668 10C9.16668 10.4602 9.53977 10.8333 10 10.8333C10.4602 10.8333 10.8333 10.4602 10.8333 10Z"
                        stroke="#9E9E9E"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5.00001 10C5.00001 9.53976 4.62691 9.16666 4.16668 9.16666C3.70644 9.16666 3.33334 9.53976 3.33334 10C3.33334 10.4602 3.70644 10.8333 4.16668 10.8333C4.62691 10.8333 5.00001 10.4602 5.00001 10Z"
                        stroke="#9E9E9E"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.6667 10C16.6667 9.53976 16.2936 9.16666 15.8333 9.16666C15.3731 9.16666 15 9.53976 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10Z"
                        stroke="#9E9E9E"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                ))}
              </div>{" "}
            </div>
          </div>
          <Footer setPage={setPage} page={page} />
        </div>
      </div>
      {modal && (
        <>
          <div className=" w-full bg-black bg-opacity-40 flex justify-center items-center h-screen fixed top-0 left-0">
            <div className=" w-fit  p-[10px]  h-fit gap-[10px] items-start flex flex-col rounded-[20px] bg-[#F5F5F5] ">
              <div className=" w-full bg-[#FFE2D9] h-[80px] px-[30px] rounded-[20px] flex justify-between items-center ">
                <h3 className=" font-openSans tracking-[-0.4px] text-[#333] font-semibold text-[20px]">Transaction Details</h3>
                <div onClick={() => setModal(false)} className=" bg-[#B8A9A9] rounded-full cursor-pointer w-fit p-[10px]">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8332 4.16675L4.1665 15.8334M4.1665 4.16675L15.8332 15.8334" stroke="#F5F5F5" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </div>
              </div>
              <div className=" w-full flex justify-between items-center gap-[10px] ">
                {/* <img className="w-full" src={footware} alt="footware" />*/}
                <div className="w-full h-fit px-[30px] py-[40px] bg-white rounded-[20px] flex-col justify-center items-center gap-5 inline-flex">
                  <div className="justify-between items-start gap-[70px] whitespace-nowrap flex">
                    <div className="p-2.5 flex-col justify-start items-start gap-[30px] flex">
                      <div className="pt-px flex-col h-[50px] justify-center items-start gap-[7px] inline-flex">
                        <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Date:</div>
                        <div className="text-zinc-800 text-sm font-semibold font-['Open Sans']">{moment(reciept.date_created).format("Do MMMM YYYY")}</div>
                      </div>
                      <div className=" pt-px flex-col h-[50px] justify-center items-start gap-[7px] inline-flex">
                        <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Amount:</div>
                        <div className="text-zinc-800 text-sm font-semibold font-['Open Sans']">₦{formattedAmount}</div>
                      </div>
                      <div className="flex-col h-[50px] justify-start items-start gap-[5px] inline-flex">
                        <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Status</div>

                        <h2
                          className={`${
                            reciept.pssp_status === "failed" ? "text-[#E52C2C] bg-[#FFECEC]" : "bg-[#E6FFE7] text-[#4CAF50]"
                          } text-xs font-normal w-fit rounded-[16px] h-[22px]  flex justify-center gap-[4px] px-[8px] py-[2px] items-center font-monteserrat leading-[18px]`}
                        >
                          {reciept.pssp_status === "failed" ? (
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9.75023 2.49994L2.75023 9.49994M2.75023 2.49994L9.75023 9.49994" stroke="#E52C2C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          ) : (
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.4" d="M2.75 7L4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              <path d="M4.5 8.75L9.75 3.25" stroke="#4CAF50" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                          )}
                          {reciept.pssp_status}
                        </h2>
                      </div>
                    </div>
                    <div className="p-2.5 flex-col justify-start items-start gap-[30px] flex">
                      <div className="pt-px flex-col h-[50px] justify-center items-start gap-[7px] inline-flex">
                        <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Time:</div>
                        <div className="text-zinc-800 text-sm font-semibold font-['Open Sans']">{moment(reciept.date_created).format("h:mm:ss a")}</div>
                      </div>
                      <div className="pt-px flex-col h-[50px] justify-center items-start gap-[7px] inline-flex">
                        <div className="text-neutral-500 text-xs font-semibold font-['Open Sans']">Transaction ID:</div>
                        <div className="text-zinc-800 text-sm font-semibold font-['Open Sans']">{reciept.id}</div>
                      </div>
                    </div>
                  </div>
                  <div className="p-2.5 rounded-[5px] border border-orange-600 justify-center items-center gap-2.5 inline-flex">
                    <div className="text-zinc-800 text-xs font-semibold font-['Open Sans'] tracking-tight">Download</div>
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M10.6719 5.75645C10.6762 5.75643 10.6806 5.75642 10.685 5.75642C12.1347 5.75642 13.31 6.93382 13.31 8.38626C13.31 9.73988 12.2891 10.8546 10.9766 11M10.6719 5.75645C10.6805 5.6602 10.685 5.56273 10.685 5.46422C10.685 3.68905 9.24858 2.25 7.47664 2.25C5.79853 2.25 4.42134 3.54072 4.28022 5.18529M10.6719 5.75645C10.6122 6.41943 10.3517 7.02435 9.95161 7.50963M4.28022 5.18529C2.80063 5.32634 1.64331 6.57477 1.64331 8.09401C1.64331 9.50766 2.64534 10.6869 3.97664 10.9576M4.28022 5.18529C4.3723 5.17651 4.46561 5.17202 4.55998 5.17202C5.21671 5.17202 5.82274 5.38947 6.31027 5.75642"
                        stroke="#333333"
                        strokeWidth="0.875"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.47664 12.75V8.08334M7.47664 12.75C7.06819 12.75 6.30504 11.5867 6.01831 11.2917M7.47664 12.75C7.88509 12.75 8.64827 11.5867 8.93498 11.2917"
                        stroke="#333333"
                        strokeWidth="0.875"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                {/* {receiptContent} */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default DashboardUser;
