import quote from "../images/quote.svg";
function Testimonial() {
  return (
    <>
      {/* BIG SCREENS */}
      <div className=" 100:hidden lg:flex justify-center items-center mt-[62px] w-full">
        <div className="w-[85%] flex flex-col items-center gap-[50px] justify-center ">
          <div className="flex justify-center flex-col items-center">
            <h3 className="text-zinc-800 text-[40px] font-semibold mb-[10px] font-openSans tracking-tight">What Our Community Says</h3>
            <p className="w-[553px] text-center text-neutral-500 text-[22px] font-normal font-['Montserrat'] leading-[45px] tracking-tight">Hear from residents about their myHood experience. Discover how myHood has redefined community engagement.</p>
          </div>
          <div className="flex justify-center flex-wrap gap-[30px] lg:w-[1000px] xl:w-[1200px]">
            <div className="w-[562px] h-[214px] px-[100px] py-[35px] bg-orange-600 bg-opacity-10 rounded-[10px] flex-col justify-start items-center gap-[17px] inline-flex">
              <img src={quote} alt="quote" />
              <p className="w-[362px] text-center text-neutral-500 text-lg font-normal font-monteserrat leading-[30px] tracking-tight">myHood transformed the way we connect. It's a game-changer!</p>
              <h4 className="text-zinc-800 text-base font-medium font-monteserrat tracking-tight">John M. - Maple Street Resident</h4>
            </div>
            <div className="w-[562px] h-[214px] px-[100px] py-[35px] bg-orange-600 bg-opacity-10 rounded-[10px] flex-col justify-start items-center gap-[17px] inline-flex">
              <img src={quote} alt="quote" />
              <p className="w-[362px] text-center text-neutral-500 text-lg font-normal font-monteserrat leading-[30px] tracking-tight">Love the sense of community. myHood keeps us engaged and informed.</p>
              <h4 className="text-zinc-800 text-base font-medium font-monteserrat tracking-tight">Sarah L. - Elm Avenue Neighbor</h4>
            </div>
            <div className="w-[562px] h-[214px] px-[100px] py-[35px] bg-orange-600 bg-opacity-10 rounded-[10px] flex-col justify-start items-center gap-[17px] inline-flex">
              <img src={quote} alt="quote" />
              <p className="w-[362px] text-center text-neutral-500 text-lg font-normal font-monteserrat leading-[30px] tracking-tight">Part of the Neighborhood Watch, myHood makes us proactive about safety.</p>
              <h4 className="text-zinc-800 text-base font-medium font-monteserrat tracking-tight">David W. - Oak Lane Resident</h4>
            </div>
            <div className="w-[562px] h-[214px] px-[100px] py-[35px] bg-orange-600 bg-opacity-10 rounded-[10px] flex-col justify-start items-center gap-[17px] inline-flex">
              <img src={quote} alt="quote" />
              <p className="w-[362px] text-center text-neutral-500 text-lg font-normal font-monteserrat leading-[30px] tracking-tight">myHood is a lifeline to our community. Local events and forums keep us involved.</p>
              <h4 className="text-zinc-800 text-base font-medium font-monteserrat tracking-tight">Michael P. - Pinecrest Avenue User</h4>
            </div>
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM MOBILE TO 1023PX */}
      <div className=" 100:flex lg:hidden mt-[62px] flex justify-center items-center w-full ">
        <div className="w-[90%] md:max-w-[600px] flex flex-col items-center gap-[50px] justify-center ">
          <div className="flex justify-between flex-col items-center">
            <h3 className="text-zinc-800 text-center text-[30px] font-semibold mb-[10px] font-openSans tracking-tight">What Our Community Says</h3>
            <p className=" text-center text-neutral-500 text-[18px] font-normal font-['Montserrat'] leading-[45px] tracking-tight">Hear from residents about their myHood experience. Discover how myHood has redefined community engagement.</p>
          </div>
          <div className="flex justify-center w-[95%] flex-wrap gap-[30px]  ">
            <div className="w-full h-[244px] mobile:px-[20px] sm:px-[50px] py-[35px] bg-orange-600 bg-opacity-10 rounded-[10px] flex-col justify-start items-center gap-[17px] inline-flex">
              <img src={quote} alt="quote" />
              <p className=" text-center phone:w-[400px] text-neutral-500 text-[16px] font-normal font-monteserrat leading-[30px] tracking-tight">myHood transformed the way we connect. It's a game-changer!</p>
              <h4 className="text-zinc-800 text-base font-medium font-monteserrat tracking-tight">John M. - Maple Street Resident</h4>
            </div>
            <div className="w-full h-[244px] mobile:px-[20px] sm:px-[50px] py-[35px] bg-orange-600 bg-opacity-10 rounded-[10px] flex-col justify-start items-center gap-[17px] inline-flex">
              <img src={quote} alt="quote" />
              <p className=" text-center phone:w-[400px] text-neutral-500 text-[16px] font-normal font-monteserrat leading-[30px] tracking-tight">Love the sense of community. myHood keeps us engaged and informed.</p>
              <h4 className="text-zinc-800 text-base font-medium font-monteserrat tracking-tight">Sarah L. - Elm Avenue Neighbor</h4>
            </div>
            <div className="w-full h-[244px] mobile:px-[20px] sm:px-[50px] py-[35px] bg-orange-600 bg-opacity-10 rounded-[10px] flex-col justify-start items-center gap-[17px] inline-flex">
              <img src={quote} alt="quote" />
              <p className=" text-center phone:w-[400px] text-neutral-500 text-[16px] font-normal font-monteserrat leading-[30px] tracking-tight">Part of the Neighborhood Watch, myHood makes us proactive about safety.</p>
              <h4 className="text-zinc-800 text-base font-medium font-monteserrat tracking-tight">David W. - Oak Lane Resident</h4>
            </div>
            <div className="w-full h-[244px] mobile:px-[20px] sm:px-[50px] py-[35px] bg-orange-600 bg-opacity-10 rounded-[10px] flex-col justify-start items-center gap-[17px] inline-flex">
              <img src={quote} alt="quote" />
              <p className=" text-center phone:w-[400px] text-neutral-500 text-[16px] font-normal font-monteserrat leading-[30px] tracking-tight">myHood is a lifeline to our community. Local events and forums keep us involved.</p>
              <h4 className="text-zinc-800 text-base font-medium font-monteserrat tracking-tight">Michael P. - Pinecrest Avenue User</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonial;
