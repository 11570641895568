import { useState } from "react";
import { useLocation } from "react-router-dom";
import { XAxis, Tooltip, BarChart, Bar } from "recharts";
import SideBarSuper from "./SideBar";
import HeaderSuper from "./HeaderSuper";

function RevenueTrackingSuper() {
  const [mini, setMini] = useState(false);
  const location = useLocation();
  const menu = [
    {
      id: 1,
      path: "/dashboardsuper",
      name: "Dashboard",
      title: "Dashboard",
      icon1: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83334 3.17332 9.83334 4.73812C9.83334 6.30293 11.1018 7.57146 12.6666 7.57146Z" stroke="#333333" />
          <path d="M5 7.57146C6.5648 7.57146 7.83333 6.30293 7.83333 4.73812C7.83333 3.17332 6.5648 1.90479 5 1.90479C3.43519 1.90479 2.16666 3.17332 2.16666 4.73812C2.16666 6.30293 3.43519 7.57146 5 7.57146Z" stroke="#333333" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83334 10.8398 9.83334 12.4046C9.83334 13.9694 11.1018 15.238 12.6666 15.238Z" stroke="#333333" />
          <path d="M5 15.238C6.5648 15.238 7.83333 13.9694 7.83333 12.4046C7.83333 10.8398 6.5648 9.57129 5 9.57129C3.43519 9.57129 2.16666 10.8398 2.16666 12.4046C2.16666 13.9694 3.43519 15.238 5 15.238Z" stroke="#333333" />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6666 7.57146C14.2314 7.57146 15.5 6.30293 15.5 4.73812C15.5 3.17332 14.2314 1.90479 12.6666 1.90479C11.1018 1.90479 9.83331 3.17332 9.83331 4.73812C9.83331 6.30293 11.1018 7.57146 12.6666 7.57146Z" fill="#F5F5F5" />
          <path d="M4.99997 7.57146C6.56477 7.57146 7.8333 6.30293 7.8333 4.73812C7.8333 3.17332 6.56477 1.90479 4.99997 1.90479C3.43516 1.90479 2.16663 3.17332 2.16663 4.73812C2.16663 6.30293 3.43516 7.57146 4.99997 7.57146Z" fill="#F5F5F5" />
          <path d="M12.6666 15.238C14.2314 15.238 15.5 13.9694 15.5 12.4046C15.5 10.8398 14.2314 9.57129 12.6666 9.57129C11.1018 9.57129 9.83331 10.8398 9.83331 12.4046C9.83331 13.9694 11.1018 15.238 12.6666 15.238Z" fill="#F5F5F5" />
          <path d="M4.99997 15.238C6.56477 15.238 7.8333 13.9694 7.8333 12.4046C7.8333 10.8398 6.56477 9.57129 4.99997 9.57129C3.43516 9.57129 2.16663 10.8398 2.16663 12.4046C2.16663 13.9694 3.43516 15.238 4.99997 15.238Z" fill="#F5F5F5" />
        </svg>
      ),
    },
    {
      id: 2,
      path: "/advertmanagementsuper",
      name: "Advert Management",
      title: "Advert Management",
      icon1: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.67053 11.7323L5.35427 7.09046C5.66045 6.44611 6.12866 5.9132 6.66514 7.19129C7.16036 8.3711 7.89923 10.5314 8.33536 11.7348M4.43555 9.7393H7.54763" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M2.30969 3.94997C1.33337 4.82865 1.33337 6.24287 1.33337 9.07129C1.33337 11.8996 1.33337 13.3139 2.30969 14.1926C3.28599 15.0712 4.85735 15.0712 8.00004 15.0712C11.1427 15.0712 12.7141 15.0712 13.6904 14.1926C14.6667 13.3139 14.6667 11.8996 14.6667 9.07129C14.6667 6.24287 14.6667 4.82865 13.6904 3.94997C12.7141 3.07129 11.1427 3.07129 8.00004 3.07129C4.85735 3.07129 3.28599 3.07129 2.30969 3.94997Z"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.3228 7.0625V9.05895M12.3228 9.05895V11.688M12.3228 9.05895H10.9773C10.8175 9.05895 10.659 9.08868 10.5096 9.14662C9.38042 9.58475 9.38042 11.2128 10.5096 11.651C10.659 11.7089 10.8175 11.7386 10.9773 11.7386H12.3228"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.14304 3.94997C2.16672 4.82865 2.16672 6.24287 2.16672 9.07129C2.16672 11.8996 2.16672 13.3139 3.14304 14.1926C4.11934 15.0712 5.6907 15.0712 8.83339 15.0712C11.976 15.0712 13.5474 15.0712 14.5237 14.1926C15.5 13.3139 15.5 11.8996 15.5 9.07129C15.5 6.24287 15.5 4.82865 14.5237 3.94997C13.5474 3.07129 11.976 3.07129 8.83339 3.07129C5.6907 3.07129 4.11934 3.07129 3.14304 3.94997Z"
            fill="#F5F5F5"
            stroke="#F5F5F5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M4.50388 11.7323L6.18762 7.09046C6.4938 6.44611 6.96201 5.9132 7.49849 7.19129C7.99371 8.3711 8.73257 10.5314 9.1687 11.7348M5.2689 9.7393H8.38098" stroke="#FF5722" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M13.1561 7.0625V9.05895M13.1561 9.05895V11.688M13.1561 9.05895H11.8106C11.6508 9.05895 11.4923 9.08868 11.3429 9.14662C10.2138 9.58475 10.2138 11.2128 11.3429 11.651C11.4923 11.7089 11.6508 11.7386 11.8106 11.7386H13.1561"
            stroke="#FF5722"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 3,
      path: "/accessmanagementsuper",
      name: "Access Management",
      title: "Access Management",
      icon1: (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.66671 2.40472C2.82576 2.40472 1.33337 3.89711 1.33337 5.73805C1.33337 6.97186 2.00371 8.04912 3.00004 8.62545V12.9668C3.00004 13.5118 3.00004 13.7843 3.10153 14.0293C3.20303 14.2744 3.39571 14.4671 3.78109 14.8525L4.66671 15.7381L6.07217 14.3326C6.13699 14.2678 6.16942 14.2353 6.19631 14.2002C6.26691 14.1079 6.31215 13.9987 6.32752 13.8834C6.33337 13.8395 6.33337 13.7937 6.33337 13.702C6.33337 13.6278 6.33337 13.5907 6.32944 13.5547C6.31913 13.4601 6.28873 13.3689 6.24027 13.2871C6.22179 13.2559 6.19953 13.2263 6.15501 13.1669L5.33337 12.0714L5.80004 11.4492C6.06437 11.0967 6.19653 10.9205 6.26495 10.7153C6.33337 10.51 6.33337 10.2897 6.33337 9.84919V8.62545C7.32971 8.04912 8.00004 6.97186 8.00004 5.73805C8.00004 3.89711 6.50765 2.40472 4.66671 2.40472Z"
            stroke="#333333"
            strokeLinejoin="round"
          />
          <path d="M4.66663 5.73798H4.67261" stroke="#333333" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M8.66663 10.4047H12.6666C13.2879 10.4047 13.5985 10.4047 13.8436 10.5062C14.1702 10.6415 14.4298 10.9011 14.5652 11.2278C14.6666 11.4729 14.6666 11.7835 14.6666 12.4047C14.6666 13.026 14.6666 13.3366 14.5652 13.5817C14.4298 13.9083 14.1702 14.1679 13.8436 14.3033C13.5985 14.4047 13.2879 14.4047 12.6666 14.4047H8.66663"
            stroke="#333333"
            strokeLinecap="round"
          />
          <path
            d="M10 4.40472H12.6667C13.2879 4.40472 13.5985 4.40472 13.8436 4.50621C14.1703 4.64154 14.4299 4.90111 14.5652 5.22781C14.6667 5.47284 14.6667 5.78347 14.6667 6.40472C14.6667 7.02597 14.6667 7.3366 14.5652 7.58163C14.4299 7.90832 14.1703 8.16792 13.8436 8.30325C13.5985 8.40472 13.2879 8.40472 12.6667 8.40472H10"
            stroke="#333333"
            strokeLinecap="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.8334 2.40479C2.99245 2.40479 1.50006 3.89718 1.50006 5.73812C1.50006 6.97193 2.1704 8.04919 3.16673 8.62552V12.9669C3.16673 13.5119 3.16673 13.7844 3.26822 14.0294C3.36972 14.2745 3.5624 14.4672 3.94778 14.8526L4.8334 15.7382L6.23886 14.3327C6.30368 14.2679 6.33611 14.2354 6.363 14.2003C6.4336 14.108 6.47884 13.9988 6.49421 13.8835C6.50006 13.8396 6.50006 13.7938 6.50006 13.7021C6.50006 13.6279 6.50006 13.5908 6.49613 13.5548C6.48582 13.4602 6.45542 13.369 6.40696 13.2872C6.38848 13.256 6.36622 13.2264 6.3217 13.167L5.50006 12.0715L5.96673 11.4493C6.23106 11.0968 6.36322 10.9206 6.43164 10.7154C6.50006 10.5101 6.50006 10.2898 6.50006 9.84926V8.62552C7.4964 8.04919 8.16673 6.97193 8.16673 5.73812C8.16673 3.89718 6.67434 2.40479 4.8334 2.40479Z"
            fill="#F5F5F5"
          />
          <path d="M4.83331 5.73779H4.83929H4.83331Z" fill="#FF5722" />
          <path d="M4.83331 5.73779H4.83929" stroke="#FF5722" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M8.83331 10.4045H12.8333C13.4546 10.4045 13.7652 10.4045 14.0103 10.506C14.3369 10.6413 14.5965 10.9009 14.7319 11.2276C14.8333 11.4727 14.8333 11.7833 14.8333 12.4045C14.8333 13.0258 14.8333 13.3364 14.7319 13.5815C14.5965 13.9081 14.3369 14.1677 14.0103 14.3031C13.7652 14.4045 13.4546 14.4045 12.8333 14.4045H8.83331"
            stroke="#F5F5F5"
            strokeLinecap="round"
          />
          <path
            d="M10.1667 4.40479H12.8334C13.4546 4.40479 13.7652 4.40479 14.0103 4.50628C14.337 4.64161 14.5966 4.90118 14.7319 5.22788C14.8334 5.47291 14.8334 5.78354 14.8334 6.40479C14.8334 7.02604 14.8334 7.33667 14.7319 7.5817C14.5966 7.90839 14.337 8.16799 14.0103 8.30332C13.7652 8.40479 13.4546 8.40479 12.8334 8.40479H10.1667"
            stroke="#F5F5F5"
            strokeLinecap="round"
          />
        </svg>
      ),
    },
    {
      id: 4,
      path: "/revenuetracking",
      name: "Revenue Tracking",
      title: "Revenue Tracking",
      icon1: (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.3334 14.7856H7.00004C4.80015 14.7856 3.70021 14.7856 3.01679 14.1022C2.33337 13.4188 2.33337 12.3188 2.33337 10.119V2.78564" stroke="#333333" strokeLinecap="round" />
          <path d="M9.00003 7.45239V14.7857" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.3334 9.45239V14.7857" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.66672 9.45239V14.1191" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M14.3334 6.11018C13.1067 6.11018 11.7948 6.28058 10.9181 5.11454C9.91991 3.78702 8.08017 3.78702 7.08197 5.11454C6.20525 6.28058 4.89339 6.11018 3.66671 6.11018H2.33337"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.8334 14.2856H7.50004C5.30015 14.2856 4.20021 14.2856 3.51679 13.6022C2.83337 12.9188 2.83337 11.8188 2.83337 9.61898V2.28564" stroke="#F5F5F5" strokeLinecap="round" />
          <path d="M9.5 6.95239V14.2857" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.8334 8.95239V14.2857" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6.16675 8.95239V13.6191" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M14.8334 5.61018C13.6067 5.61018 12.2948 5.78058 11.4181 4.61454C10.4199 3.28702 8.58017 3.28702 7.58197 4.61454C6.70525 5.78058 5.39339 5.61018 4.16671 5.61018H2.83337"
            stroke="#F5F5F5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      id: 5,
      path: "/subscriptionmanagement",
      name: "Subscription Management",
      title: "Subscription Management",
      icon1: (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.16675 6.95239H5.50008" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M2.16675 11.6189H5.50008" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M2.16675 2.28564H13.5001" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M11.5 12.0953V12.9524M11.5 12.0953C10.7288 12.0953 10.0494 11.7165 9.65091 11.1413M11.5 12.0953C12.2712 12.0953 12.9506 11.7165 13.3492 11.1413M9.65091 11.1413L8.83364 11.6667M9.65091 11.1413C9.41524 10.8011 9.27784 10.3922 9.27784 9.95239C9.27784 9.51259 9.41517 9.10379 9.65084 8.76366M13.3492 11.1413L14.1664 11.6667M13.3492 11.1413C13.5848 10.8011 13.7222 10.3922 13.7222 9.95239C13.7222 9.51259 13.5849 9.10379 13.3492 8.76366M11.5 7.80953C12.2713 7.80953 12.9508 8.18839 13.3492 8.76366M11.5 7.80953C10.7288 7.80953 10.0493 8.18839 9.65084 8.76366M11.5 7.80953V6.95239M13.3492 8.76366L14.1667 8.23813M9.65084 8.76366L8.83337 8.23813"
            stroke="#333333"
            strokeLinecap="round"
          />
        </svg>
      ),
      icon2: (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.33337 7.45239H4.66671" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M1.33337 12.1189H4.66671" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M1.33337 2.78564H12.6667" stroke="#F5F5F5" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M10.6667 12.5953V13.4524M10.6667 12.5953C9.89547 12.5953 9.21607 12.2165 8.81753 11.6413M10.6667 12.5953C11.4379 12.5953 12.1173 12.2165 12.5158 11.6413M8.81753 11.6413L8.00027 12.1667M8.81753 11.6413C8.58187 11.3011 8.44447 10.8922 8.44447 10.4524C8.44447 10.0126 8.5818 9.60379 8.81747 9.26366M12.5158 11.6413L13.3331 12.1667M12.5158 11.6413C12.7515 11.3011 12.8889 10.8922 12.8889 10.4524C12.8889 10.0126 12.7515 9.60379 12.5159 9.26366M10.6667 8.30953C11.4379 8.30953 12.1174 8.68839 12.5159 9.26366M10.6667 8.30953C9.8954 8.30953 9.21593 8.68839 8.81747 9.26366M10.6667 8.30953V7.45239M12.5159 9.26366L13.3333 8.73813M8.81747 9.26366L8 8.73813"
            stroke="#F5F5F5"
            strokeLinecap="round"
          />
        </svg>
      ),
    },
  ];
  const activeItem = menu.find((item) => item.path === location.pathname);
  const bar = [
    {
      name: "Jan",
      uv: 1560,
      pv: 1000,
      amt: 1400,
    },
    {
      name: "Feb",
      uv: 1600,
      pv: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      uv: 1700,
      pv: 1800,
      amt: 1290,
    },
    {
      name: "Apr",
      uv: 1780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "May",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Jun",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Jul",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Aug",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Sep",
      uv: 2780,
      pv: 3908,
      amt: 2000,
    },
    {
      name: "Oct",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Nov",
      uv: 1890,
      pv: 4800,
      amt: 2181,
    },
    {
      name: "Dec",
      uv: 3890,
      pv: 4800,
      amt: 2181,
    },
  ];

  // const COLORS = ["#FFE2D9", "#FFD9CD", "#FBCBBC"];

  return (
    <>
      <div className="flex   justify-between items-start">
        <SideBarSuper mini={mini} setMini={setMini} menu={menu} />
        {/* LARGE SCREENS */}
        <div className={` 100:hidden xl:flex w-full pr-[25px] pb-[50px] flex flex-col justify-between gap-[22px] h-fit ${mini ? "pl-[130px]" : "pl-[320px]"}`}>
          <HeaderSuper mini={mini} name={activeItem ? activeItem.name : null} />
          <div className="flex flex-col gap-[20px] py-[10px] px-[10px] items-start">
            {/* Bar Chart */}
            <div className="w-fit   h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
              <section className=" flex h-[44px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
                <div className=" flex gap-[16px] justify-between items-center w-full">
                  <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Total Value</h4>
                </div>
              </section>
              <div className=" xl:flex 1xl:hidden 2xl:hidden 3xl:hidden 4xl:hidden 5xl:hidden">
                <BarChart className="  text-[12px] text-[#777777] " width={991} height={300} data={bar}>
                  {/* <CartesianGrid strokeDasharrayX="2 " /> */}
                  <XAxis dataKey="name" />

                  <Tooltip />
                  <Bar className=" rounded-t-[8px]" width="32px" dataKey="pv" stackId="a" fill="#FFE2D9" />
                </BarChart>
              </div>
              <div className=" xl:hidden 1xl:flex 2xl:hidden 3xl:hidden 4xl:hidden 5xl:hidden">
                <BarChart className="  text-[12px] text-[#777777] " width={1020} height={300} data={bar}>
                  {/* <CartesianGrid strokeDasharrayX="2 " /> */}
                  <XAxis dataKey="name" />

                  <Tooltip />
                  <Bar className=" rounded-t-[8px]" width="32px" dataKey="pv" stackId="a" fill="#FFE2D9" />
                </BarChart>
              </div>
              <div className=" xl:hidden 1xl:hidden 3xl:hidden 2xl:flex 4xl:hidden 5xl:hidden">
                <BarChart className="  text-[12px] text-[#777777] " width={1091} height={300} data={bar}>
                  {/* <CartesianGrid strokeDasharrayX="2 " /> */}
                  <XAxis dataKey="name" />

                  <Tooltip />
                  <Bar className=" rounded-t-[8px]" width="32px" dataKey="pv" stackId="a" fill="#FFE2D9" />
                </BarChart>
              </div>
              <div className=" xl:hidden  1xl:hidden  2xl:hidden 3xl:flex 4xl:hidden 5xl:hidden">
                <BarChart className="  text-[12px] text-[#777777] " width={1201} height={300} data={bar}>
                  {/* <CartesianGrid strokeDasharrayX="2 " /> */}
                  <XAxis dataKey="name" />

                  <Tooltip />
                  <Bar className=" rounded-t-[8px]" width="32px" dataKey="pv" stackId="a" fill="#FFE2D9" />
                </BarChart>
              </div>
              <div className="  xl:hidden  1xl:hidden  2xl:hidden 3xl:hidden 4xl:flex 5xl:hidden">
                <BarChart className="  text-[12px] text-[#777777] " width={1431} height={300} data={bar}>
                  {/* <CartesianGrid strokeDasharrayX="2 " /> */}
                  <XAxis dataKey="name" />

                  <Tooltip />
                  <Bar className=" rounded-t-[8px]" width="32px" dataKey="pv" stackId="a" fill="#FFE2D9" />
                </BarChart>
              </div>
              <div className="  xl:hidden   1xl:hidden 2xl:hidden 3xl:hidden 4xl:hidden 5xl:flex">
                <BarChart className="  text-[12px] text-[#777777] " width={1601} height={300} data={bar}>
                  {/* <CartesianGrid strokeDasharrayX="2 " /> */}
                  <XAxis dataKey="name" />

                  <Tooltip />
                  <Bar className=" rounded-t-[8px]" width="32px" dataKey="pv" stackId="a" fill="#FFE2D9" />
                </BarChart>
              </div>
            </div>
            {/* Table  */}
            <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
              <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
                <div className=" flex gap-[16px] justify-between items-center w-full">
                  <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Transaction Count</h4>
                </div>
              </section>

              <div className=" flex justify-between w-full items-center">
                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Date </h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                  </div>
                </div>
                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Revenue Generated</h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">4000 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">4000 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">4000 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">4000 </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">4000 </h2>
                  </div>
                </div>
                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Click-Through Rate (CTR) </h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                      {" "}
                      <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        100%
                      </span>
                    </h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                      {" "}
                      <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        100%
                      </span>
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                      {" "}
                      <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        100%
                      </span>
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                      {" "}
                      <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        100%
                      </span>
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                      {" "}
                      <span className="text-[#E52C2C] items-center gap-[4px] flex flex-row">
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.5 10V2" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 7.5C9 7.5 7.1588 10 6.5 10C5.8412 10 4 7.5 4 7.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        20%
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                  <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Metrics </h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                      {" "}
                      <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        70%
                      </span>
                      Impression{" "}
                    </h2>
                  </div>
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                      {" "}
                      <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        70%
                      </span>
                      Impression{" "}
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                      {" "}
                      <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        70%
                      </span>
                      Impression{" "}
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                      {" "}
                      <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        70%
                      </span>
                      Impression{" "}
                    </h2>
                  </div>{" "}
                  <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                    <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                      {" "}
                      <span className="text-[#E52C2C] items-center gap-[4px] flex flex-row">
                        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M6.5 10V2" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9 7.5C9 7.5 7.1588 10 6.5 10C5.8412 10 4 7.5 4 7.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        20%
                      </span>
                      Impression
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SMALL SCREENS */}
        <div className=" 100:flex xl:hidden w-full pr-[25px] pb-[50px] flex-col justify-between gap-[22px] h-fit pl-[130px]">
          <HeaderSuper mini={mini} name={activeItem ? activeItem.name : null} />
          {/* table charts */}
          <div className=" flex justify-between  flex-col gap-[30px] py-[20px] ">
            <div className="w-full   h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
              <section className=" flex h-[44px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
                <div className=" flex gap-[16px] justify-between items-center w-full">
                  <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Total Value</h4>
                </div>
              </section>
              <div className=" w-full  overflow-x-scroll">
                <BarChart className="  text-[12px] text-[#777777] " width={791} height={300} data={bar}>
                  {/* <CartesianGrid strokeDasharrayX="2 " /> */}
                  <XAxis dataKey="name" />

                  <Tooltip />
                  <Bar className=" rounded-t-[8px]" width="32px" dataKey="pv" stackId="a" fill="#FFE2D9" />
                </BarChart>
              </div>
            </div>
          </div>
          {/* Table  */}
          <div className="w-full h-fit gap-[24px]  p-[24px] rounded-[10px] shadow border border-neutral-200 justify-between flex-col items-center inline-flex">
            <section className=" flex h-[60px] flex-col gap-[20px] w-full border-b-[1px] border-b-[#E7E7E7] border-solid">
              <div className=" flex gap-[16px] justify-between items-center w-full">
                <h4 className=" tracking-[0.9px] w-full font-openSans font-semibold text-[14px] text-[#333] ">Transaction Count</h4>
              </div>
            </section>

            <div className=" whitespace-nowrap overflow-x-scroll flex justify-between w-full items-center">
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Date </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">14th December 2024 </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Revenue Generated</h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">4000 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">4000 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">4000 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">4000 </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 text-xs font-normal font-['Montserrat'] leading-[18px]">4000 </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Click-Through Rate (CTR) </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      100%
                    </span>
                  </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      100%
                    </span>
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      100%
                    </span>
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      100%
                    </span>
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#E52C2C] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 10V2" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 7.5C9 7.5 7.1588 10 6.5 10C5.8412 10 4 7.5 4 7.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      20%
                    </span>
                  </h2>
                </div>
              </div>
              <div className="w-full h-fit flex-col justify-start items-start inline-flex">
                <div className="self-stretch h-11 border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-500 text-xs font-normal font-['Montserrat'] leading-[18px]">Metrics </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      70%
                    </span>
                    Impression{" "}
                  </h2>
                </div>
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      70%
                    </span>
                    Impression{" "}
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      70%
                    </span>
                    Impression{" "}
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#4CAF50] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 2V10" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 4.49998C9 4.49998 7.1588 2.00001 6.5 2C5.8412 2 4 4.5 4 4.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      70%
                    </span>
                    Impression{" "}
                  </h2>
                </div>{" "}
                <div className="self-stretch h-[73px] border-b-[1px] border-b-neutral-200 px-6 py-3 justify-start items-center gap-3 inline-flex">
                  <h2 className="text-neutral-800 gap-[8px] text-xs justify-between items-center flex font-normal font-['Montserrat'] leading-[18px]">
                    {" "}
                    <span className="text-[#E52C2C] items-center gap-[4px] flex flex-row">
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 10V2" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9 7.5C9 7.5 7.1588 10 6.5 10C5.8412 10 4 7.5 4 7.5" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      20%
                    </span>
                    Impression
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RevenueTrackingSuper;
