import { Link, useLocation, useNavigate } from "react-router-dom";
import sideImg from "../images/loginmyhood.png";
import { useRef, useState } from "react";
// import axios from "axios";

function Otp() {
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const otpInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const location = useLocation();
  // const [password, setPassword] = useState("");
  const email = location.state?.email || "";
  // const inputRefs = useRef({});
  const navigate = useNavigate();

  const handleOTPEnter = (value, index) => {
    if (value.length > 1) return;

    const newOTPValues = [...otpValues];
    newOTPValues[index] = value;
    setOtpValues(newOTPValues);

    if (value !== "" && index < 5) {
      // Move focus to the next input field
      otpInputRefs[index + 1]?.current?.focus();
    }
  };
  // const focusNext = (currentIndex) => {
  //   if (currentIndex < 5) {
  //     // Move focus to the next input field
  //     otpInputRefs[currentIndex + 1]?.current?.focus();
  //   }
  // };
  const handleSubmit = async () => {
    const otpPayload = otpValues.join("");
    navigate("/createpassword", { state: { token: otpPayload, email: email } });
  };
  return (
    <>
      {/* BIG SCREENS */}
      <div className="flex w-full 100:hidden lg:flex h-screen items-center justify-between">
        <img className="fixed   h-full w-[50%]  " src={sideImg} alt="housing" />
        <div className=" ml-[52%] px-[48px] gap-[50px] flex flex-col justify-start items-center pb-[52px] w-[50%] h-full ">
          <div className="w-full top-[70px]  relative float-left ">
            <Link className="" to="/forgotpassword">
              <h3 className=" text-[16px]  w-[36px] font-normal font-openSans text-[#FF5722] tracking-[0.9px] ">Back</h3>
            </Link>
          </div>
          <div className="flex mt-[200px] flex-col justify-between gap-[50px] items-center">
            <div className="flex flex-col text-center justify-between items-center gap-[40px]">
              <div className=" flex flex-col items-center justify-between gap-[15px]">
                <h1 className="text-[32px] text-[#333333] font-semibold font-openSans leading-[35px] tracking-wide ">OTP Verification </h1>
                <p className="text-[#777777] text-[20px] w-[450px] font-normal font-monteserrat leading-[35px] tracking-tight">Enter the OTP sent to your registered email or phone number to secure your account.</p>
              </div>
            </div>
            <form className=" flex flex-col justify-center items-center gap-[10px]" action="/" method="get">
              <div className="w-fit gap-[16px] flex justify-between items-center   ">
                {otpInputRefs.map((ref, index) => (
                  <input
                    key={index}
                    ref={ref}
                    onChange={(e) => handleOTPEnter(e.target.value, index)}
                    value={otpValues[index]}
                    className="w-[80px] outline-none text-[#777777] text-center p-[15px] text-[24px] h-[80px] bg-[#eee] border-[0.7px] border-[#777] rounded-[4px]"
                    type="text" //yes
                    maxLength="1"
                  />
                ))}{" "}
              </div>
              <p className="text-[15px] font-monteserrat tracking-tight font-normal text-[#333] ">Remaining 00:60 Sec</p>
            </form>
            {/* <Link to="/createpassword"> */}
            <button onClick={handleSubmit} className="w-[243px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[16px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex">
              Continue
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
      {/* SMALL SCREENS FROM 100PX TO 1024PX */}
      <div className="flex flex-col w-full overflow-x-hidden 100:flex gap-[100px] lg:hidden h-fit py-[50px] items-center justify-center">
        <div className="w-full  left-[50px] relative float-left ">
          <Link className="" to="/forgotpassword">
            <h3 className=" text-[16px]  w-[36px] font-normal font-openSans text-[#FF5722] tracking-[0.9px] ">Back</h3>
          </Link>
        </div>
        <div className=" phone:max-w-[450px]  px-[48px]  flex flex-col justify-center items-center pb-[52px] w-[90%] ">
          <div className="flex flex-col justify-between gap-[50px] items-center">
            <div className="flex flex-col text-center justify-between items-center gap-[40px]">
              <div className=" flex flex-col items-center justify-between gap-[15px]">
                <h1 className="phone:text-[32px] 100:text-[28px] text-[#333333] whitespace-nowrap font-semibold font-openSans leading-[35px] tracking-wide ">OTP Verification </h1>
                <p className="text-[#777777] 100:text-[18px] phone:text-[20px] w-full phone:w-[450px] font-normal font-monteserrat leading-[35px] tracking-tight">Enter the OTP sent to your registered email or phone number to secure your account.</p>
              </div>
            </div>

            <form className=" w-full justify-between items-center flex-col gap-[10px]  flex" action="/" method="get">
              <div className="w-fit gap-[16px] flex justify-between items-center   ">
                {otpInputRefs.map((ref, index) => (
                  <input
                    key={index}
                    ref={ref}
                    onChange={(e) => handleOTPEnter(e.target.value, index)}
                    value={otpValues[index]}
                    className="w-[50px] outline-none text-[#777777] text-center p-[15px] text-[24px] h-[70px] bg-[#eee] border-[0.7px] border-[#777] rounded-[4px]"
                    type="number"
                    maxLength="1"
                  />
                ))}{" "}
              </div>
              <p className="text-[15px] font-monteserrat tracking-tight font-normal text-[#333] ">Remaining 00:60 Sec</p>
            </form>
            {/* <Link to="/createpassword"> */}
            <button onClick={handleSubmit} className="w-[243px] h-[38px] px-[30px] py-[7px] bg-orange-600 rounded text-white text-[16px] font-medium font-monteserrat tracking-tight justify-center items-center gap-2.5 inline-flex">
              Continue
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Otp;
