import about from "../images/aboutUs.png";
function About() {
  return (
    <>
      {/* BIG SCREENS */}
      <div className=" xl:flex mobile:hidden 100:hidden w-full flex justify-center mt-[40px] ">
        <div className="lg:w-[90%] 3xl:max-w-[1350px]   h-[594.08px] px-[80px] py-[120px] bg-orange-600 bg-opacity-10 rounded-[10px] flex justify-between items-center gap-[50px] ">
          <div className="">
            <h3 className="text-zinc-800 text-[40px] font-semibold font-openSans tracking-tight">
              About <span className="text-[#4CAF50]">my</span>
              <span className="text-[#FF5722]">Hood</span>
            </h3>
            <p className="text-neutral-500 mt-[10px]  text-[22px] font-normal font-monteserrat leading-[45px] tracking-tight">
              Connect with your neighborhood like never before. myHood is designed to enhance community living, fostering stronger bonds and collaboration. myHood transforms community communication in the digital age.
            </p>
          </div>
          <img className="w-[450px]" src={about} alt="People" />
        </div>
      </div>
      {/* SMALL SCREENS FROM MOBILE TO 1023PX */}
      <div className="mobile:flex xl:hidden mt-[40px] w-full justify-center items-center ">
        <div className="w-[90%]  items-center h-[700px] px-[40px] py-[80px] bg-orange-600 bg-opacity-10 rounded-[10px] flex flex-col justify-between  gap-[40px] ">
          <div className="">
            <h3 className="text-zinc-800 text-[30px] font-semibold font-openSans tracking-tight">
              About <span className="text-[#4CAF50]">my</span>
              <span className="text-[#FF5722]">Hood</span>
            </h3>
            <p className="text-neutral-500 md:max-w-[80%] text-[18px] mt-[10px] font-normal font-monteserrat leading-[35px] tracking-tight">
              Connect with your neighborhood like never before. myHood is designed to enhance community living, fostering stronger bonds and collaboration. myHood transforms community communication in the digital age.
            </p>
          </div>
          <img className="w-[450px]" src={about} alt="People" />
        </div>
      </div>
    </>
  );
}

export default About;
